import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import firebase from '../../config/fbConfig'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import { openDatabaseIndexedDB } from '../../config/idbConfig'

// i18n
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

export const setProduto = produto => {
  return dispatch => {
    return dispatch({
      type: 'SET_PRODUTO',
      payload: produto
    })
  }
}

export const setProdutos = produtos => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_PRODUTOS',
      payload: produtos
    })
  }
}

export const fetchProdutos = () => {
  return dispatch => {
    return axios.get(`${BASE_URL}/produtos`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.fetchAll.error.title"),
            i18n.t("produtos.toastr.fetchAll.error.message")
          )
          dispatch(nop())
        }

        dispatch([setProdutos(res.data.data), unsetLoading()])
      })
      .catch(async err => {
        if (err.message === 'Network Error') {
          // find all produtos from indexedDB
          const db = await openDatabaseIndexedDB('franchini-offline');
          const idbProdutos = await db.getAll('produto');

          const produtos = idbProdutos.map(produto => produto.data);

          dispatch([setProdutos(produtos), unsetLoading()]);
          return;
        }

        toastr.error(
          i18n.t("produtos.toastr.fetchAll.error.title"),
          i18n.t("produtos.toastr.fetchAll.error.message")
        )
        throw err
      })
  }
}

export const fetchByPontoDeVenda = pontoDeVendaId => {
  return dispatch => {
    return axios.get(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}/produtos`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.fetchByPontoDeVenda.error.title"),
            i18n.t("produtos.toastr.fetchByPontoDeVenda.error.message")
          )
          return dispatch(nop())
        }

        return dispatch([
          setProdutos(res.data.data),
          unsetLoading()
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.fetchByPontoDeVenda.error.title"),
          i18n.t("produtos.toastr.fetchByPontoDeVenda.error.message")
        )
        throw err;
      })
  }
}

export const fetchAllProdutosByPontoDeVenda = pontoDeVendaId => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/produtos/pontoDeVenda/${pontoDeVendaId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.fetchByPontoDeVenda.error.title"),
            i18n.t("produtos.toastr.fetchByPontoDeVenda.error.message")
          )
          return dispatch(nop())
        }

        dispatch([
          setProdutos(res.data.data),
          unsetLoading()
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.fetchByPontoDeVenda.error.title"),
          i18n.t("produtos.toastr.fetchByPontoDeVenda.error.message")
        )
        throw err
      })
  }
}

export const fetchProdutosByFornecedor = fornecedor => {
  return dispatch => {
    if (!fornecedor) {
      toastr.error(
        i18n.t("produtos.toastr.fetchByFornecedor.error.title"),
        i18n.t("produtos.toastr.fetchByFornecedor.error.message")
      )
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/fornecedores/${fornecedor.id}/produtos`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.fetchByFornecedor.error.title"),
            i18n.t("produtos.toastr.fetchByFornecedor.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(setProdutos(res.data.data))
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.fetchByFornecedor.error.title"),
          i18n.t("produtos.toastr.fetchByFornecedor.error.message")
        )
        throw err
      })
  }
}

// ref -> reference text to be used in the query
export const searchProdutos = ref => {
  return dispatch => {
    if (!ref) {
      return dispatch([fetchProdutos(), unsetLoading()])
    }

    axios.get(`${BASE_URL}/produtos/search`, {
      params: {
        ref
      }
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.search.error.title"),
            i18n.t("produtos.toastr.search.error.message")
          )
          return dispatch(nop())
        }

        return dispatch([
          unsetLoading(),
          setProdutos(res.data.data)
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.search.error.title"),
          i18n.t("produtos.toastr.search.error.message")
        )
        dispatch(unsetLoading())
        throw err
      })
  }
}

export const searchProdutosByPontoDeVendaUnbound = (ref, pontoDeVendaId) => {
  return dispatch => {
    if (!ref) {
      return dispatch([fetchProdutos(), unsetLoading()])
    }

    axios.get(`${BASE_URL}/produtos/pontosDeVenda/${pontoDeVendaId}/unbound/search`, {
      params: {
        ref
      }
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.searchByPontoDeVendaUnbound.error.title"),
            i18n.t("produtos.toastr.searchByPontoDeVendaUnbound.error.message")
          )
          return dispatch(nop())
        }

        return dispatch([
          unsetLoading(),
          setProdutos(res.data.data)
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.searchByPontoDeVendaUnbound.error.title"),
          i18n.t("produtos.toastr.searchByPontoDeVendaUnbound.error.message")
        )
        dispatch(unsetLoading())
        throw err
      })
  }
}

export const saveProduto = (fornecedor, produto) => {
  return dispatch => {
    if (!fornecedor) {
      toastr.error(
        i18n.t("produtos.toastr.save.error.title"),
        i18n.t("produtos.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!produto) {
      toastr.error(
        i18n.t("produtos.toastr.save.error.title"),
        i18n.t("produtos.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    axios.post(`${BASE_URL}/produtos`, { fornecedor, produto })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.save.error.title"),
            i18n.t("produtos.toastr.save.error.message")
          )
          return dispatch([nop(), unsetLoading()])
        }

        toastr.success(
          i18n.t("produtos.toastr.save.success.title"),
          i18n.t("produtos.toastr.save.success.message")
        )
        return dispatch([fetchProdutos(), unsetLoading()])
      })
      .catch(err => {
        unsetLoading()
        toastr.error(
          i18n.t("produtos.toastr.save.error.title"),
          i18n.t("produtos.toastr.save.error.message")
        )
        throw err
      })
  }
}

export const saveImage = (blob, blobName) => {
  return dispatch => {
    // create a storage ref
    const storageRef = firebase.storage().ref(blobName)

    // upload file
    const task = storageRef.put(blob)

    // upload progress bar
    task.on('state_changed',
      function progress(snapshot) {

      },

      function error(err) {

      },

      function complete() {
        return dispatch(fetchProdutos())
      }
    );
  }
}

export const updateProduto = (produto) => {
  return dispatch => {
    if (!produto) {
      toastr.error(
        i18n.t("produtos.toastr.update.error.title"),
        i18n.t("produtos.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    axios.put(`${BASE_URL}/produtos`, {
      produto
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.update.error.title"),
            i18n.t("produtos.toastr.update.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("produtos.toastr.update.success.title"),
          i18n.t("produtos.toastr.update.success.message")
        )
        return dispatch(fetchProdutos())
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.update.error.title"),
          i18n.t("produtos.toastr.update.error.message")
        )
        throw err
      })
  }
}

export const removeProduto = produto => {
  return dispatch => {
    const produtoId = produto.id

    if (!produtoId) {
      toastr.error(
        i18n.t("produtos.toastr.remove.error.title"),
        i18n.t("produtos.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    const reqBody = {
      produto: {
        id: produtoId
      }
    }

    axios.delete(`${BASE_URL}/produtos`, {
      headers: {},
      data: reqBody
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("produtos.toastr.remove.error.title"),
            i18n.t("produtos.toastr.remove.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("produtos.toastr.remove.success.title"),
          i18n.t("produtos.toastr.remove.success.message")
        )
        return dispatch(fetchProdutos())
      })
      .catch(err => {
        toastr.error(
          i18n.t("produtos.toastr.remove.error.title"),
          i18n.t("produtos.toastr.remove.error.message")
        )
        throw err
      })
  }
}

import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'

// i18n
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

const setFeatures = features => {
  return dispatch => {
    return dispatch({
      type: 'SET_FEATURES',
      payload: features
    })
  }
}

const clearFeatures = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_FEATURES'
    })
  }
}

export const searchFeaturesByAddress = async address => {
  const response = await axios.get(`${BASE_URL}/geolocation/search/${address}`)

  return response
}

export const searchLocation = location => {
  return async dispatch => {
    if (!location) {
      toastr.warning("Algo está faltando", "Você deve passar um endereço")
      return dispatch(unsetLoading())
    }

    try {
      const res = await searchFeaturesByAddress(location)

      if (res.data.error) {
        toastr.warning(
          i18n.t("pontosDeVenda.toastr.searchLocation.notFound.title"),
          i18n.t("pontosDeVenda.toastr.searchLocation.notFound.message")
        );
        return dispatch(unsetLoading())
      }

      if (res.data.data.length === 0) {
        toastr.warning(
          i18n.t("pontosDeVenda.toastr.searchLocation.notFound.title"),
          i18n.t("pontosDeVenda.toastr.searchLocation.notFound.message")
        );
        return dispatch(unsetLoading())
      }

      return dispatch([
        setFeatures(res.data.data),
        unsetLoading()
      ])
    } catch (err) {
      toastr.warning(
        i18n.t("pontosDeVenda.toastr.searchLocation.notFound.title"),
        i18n.t("pontosDeVenda.toastr.searchLocation.notFound.message")
      );
      dispatch(unsetLoading())
      throw err
    }
  }
}

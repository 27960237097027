export default {
  title: 'Clientes',
  
  table: {
    cliente: 'Cliente',
    acoes: 'Ações',
    notFound: 'Nenhum cliente encontrado'
  },

  modalDelete: {
    title: 'Confirmar ação',
    text: 'Você tem certeza de que quer remover este cliente?',
    buttonCancelar: 'Cancelar',
    buttonRemover: 'Remover'
  },

  form: {
    title: 'Cadastro de cliente',
    nome: 'Nome: ',
    email: 'E-mail: ',
    senha: {
      label: 'Senha: ',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmarSenha: 'Confirme a senha: ',
    buttonCadastrar: 'Cadastrar',
    toastr: {
      validation: {
        minLength: {
          title: 'Algo está faltando',
          message: 'Sua senha deve ter no mínimo 6 caracteres'
        },
        confirmPassword: {
          title: 'Algo está faltando',
          message: 'As senhas devem ser iguais'
        }
      }
    }
  },

  edit: {
    title: 'Editar cliente',
    buttonAlterarSenha: 'Alterar senha',
    buttonHabilitarCampos: 'Habilitar campos',
    buttonCancelar: 'Cancelar',
    buttonSalvar: 'Salvar'
  },

  changePassword: {
    title: 'Trocar senha',
    novaSenha: {
      label: 'Nova senha',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmPassword: 'Confirme a senha',
    buttonSalvar: 'Salvar',
    validation: {
      novaSenha: {
        required: 'Digite uma senha',
        minLength: 'A senha deve ter no mínimo 6 caracteres'
      },
      confirmPassword: {
        required: 'Confirme sua senha',
        notEqualTo: 'As senhas devem ser iguais'
      }
    }
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar clientes'
      }
    },
    fetchById: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar cliente'
      }
    },
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o cliente'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o cliente'
      },
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o cliente'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar cliente'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o cliente'
      }
    }
  }
}
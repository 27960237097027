import React from 'react'
import PropTypes from 'prop-types'

const Alert = props => {
  const { text, body, color } = props

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-md-12">
          <div className={`alert alert-${color}`}>
            <span style={{ fontSize: '1.2em' }}>
              {text}
            </span>
            <hr/>
            {body}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  body: PropTypes.string,
  color: PropTypes.string
}

Alert.defaultProps = {
  body: '',
  color: 'primary'
}

export default Alert

const INITIAL_STATE = {
  notification: {
    pontoDeVenda: 0,
    cadastro: 0,
    fornecedor: 0,
    total: 0
  }
}

function getTotal({ pontoDeVenda, cadastro, fornecedor }) {
  return pontoDeVenda + cadastro + fornecedor;
}

function updateNotifications(state, field, value) {
  let newState = {
    ...state,
    notification: {
      ...state.notification,
      [field]: value
    }
  }

  const total = getTotal(newState.notification);
  
  return {
    ...newState,
    notification: {
      ...newState.notification,
      total
    }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_NAVBAR_NOTIFICATION_PONTO_DE_VENDA': 
      return updateNotifications(state, 'pontoDeVenda', action.payload);
    case 'SET_NAVBAR_NOTIFICATION_CADASTRO':
      return updateNotifications(state, 'cadastro', action.payload);
    case 'SET_NAVBAR_NOTIFICATION_FORNECEDOR':
      return updateNotifications(state, 'fornecedor', action.payload);
    case 'CLEAR_NAVBAR_NOTIFICATIONS':
      return {
        ...state,
        notification: {
          pontoDeVenda: 0,
          cadastro: 0,
          fornecedor: 0,
          total: 0
        }
      }
    default:
      return state;
  }
}

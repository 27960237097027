import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Breadcrumb from '../template/breadcrumb';
import Row from '../template/row';
import Grid from '../template/grid';
import Button from '../template/button';
import { useHistory } from 'react-router-dom';
import ExpositorList from './expositorList';
import { useSelector, useDispatch } from 'react-redux';
import { fetchExpositores, clearAll } from './expositorListActions';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import consts from '../../consts';
import Skeleton from 'react-loading-skeleton';

const BASE_URL = consts.API_URL;

export default function ExpositorListPage() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();
  const expositores = useSelector(state => state.expositor.list.expositores);

  useEffect(() => {
    if (isFirst) setFirst(false)
    else setLoading(false);
  }, [expositores]);

  useEffect(() => {
    dispatch(fetchExpositores());

    return () => dispatch(clearAll());
  }, []);

  async function handleRemoveExpositor(expositor) {
    try {
      await axios.delete(`${BASE_URL}/expositores/${expositor.id}`);

      dispatch(fetchExpositores());
    } catch (e) {
      toastr.error("Erro", "Tivemos um problema ao remover o expositor");
    }
  }

  function handleEditExpositor(expositor) {
    history.push('/expositores/new', {
      isEditting: true,
      expositor,
      step: 'EXPOSITOR_ITENS'
    });
  }

  return (
    <div>
      <Breadcrumb title={t("expositores.list.title")} linkTo="/" component={() => (
        <Button size="sm" color="success"
          handleclick={() => history.push('/expositores/new')}>
          <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
        </Button>
      )} />

      {isLoading ? <Skeleton count={6} height={30} /> : (
        <Row>
          <Grid cols="12">
            <ExpositorList expositores={expositores}
              onRemove={handleRemoveExpositor}
              onEdit={handleEditExpositor} />
          </Grid>
        </Row>
      )}
    </div>
  )
}

export default {
  form: {
    title: 'Login',
    email: {
      label: 'E-mail',
      placeholder: 'exemplo@mail.com'
    },
    senha: {
      label: 'Senha',
      placeholder: '******'
    },
    forgotPassword: 'Esqueceu sua senha?',
    buttonLogin: 'Login'
  },

  forgotPassword: {
    title: 'Você esqueceu sua senha?',
    form: {
      email: {
        label: 'Digite seu e-mail e enviamos uma nova para você',
        placeholder: 'exemplo@mail.com'
      },
      buttonEnviar: 'Enviar'
    }
  },

  changePassword: {
    title: 'Trocar senha',
    form: {
      senhaAtual: {
        label: 'Sua senha atual: ',
        placeholder: '******'
      },
      novaSenha: {
        label: 'Sua nova senha: ',
        placeholder: '******',
        small: 'A senha deve conter no mínimo 6 caracteres'
      },
      confirmarSenha: {
        label: 'Confirme sua senha: ',
        placeholder: '******'
      },
      buttonSalvar: 'Salvar',
      validation: {
        minLength: {
          title: 'Algo está faltando',
          message: 'Sua senha deve ter no mínimo 6 caracteres'
        },
        notEqualTo: {
          title: 'Algo está faltando',
          message: 'As senhas devem ser iguais'
        }
      }
    }
  },

  toastr: {
    fetchUsers: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar usuários'
      }
    },
    changePassword: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao trocar a senha'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao alterar a senha'
      }
    },
    validateUser: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao validar o usuário'
      },
      success: {
        title: 'Sucesso',
        message: 'Usuário validado com sucesso'
      }
    },
    auth: {
      email: {
        warning: {
          title: 'Algo está faltando',
          message: 'Digite seu e-mail'
        }
      },
      senha: {
        warning: {
          title: 'Algo está faltando',
          message: 'Digite sua senha'
        }
      },
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao autenticar'
      },
      validation: {
        warning: {
          title: 'Algo está faltando',
          message: 'Seu gestor ainda não aprovou seu cadastro'
        }
      },
      auth: {
        error: {
          title: 'Oops!',
          message: 'Usuário ou senha inválidos'
        }
      },
      success: {
        title: 'Sucesso',
        message: 'Logado com sucesso'
      }
    },
    recoverPassword: {
      warning: {
        title: 'Algo está faltando',
        message: 'Digite seu e-mail'
      },
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao recuperar sua senha'
      },
      success: {
        title: 'Sucesso',
        message: 'Verifique seu e-mail para sua nova senha'
      }
    }
  }
}

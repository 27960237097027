import consts from '../../consts'

const BASE_URL = consts.API_URL;

const INITIAL_STATE = {
  tables: [
    { table: 'ponto_de_venda', url: `${BASE_URL}/pontosDeVenda`, sync: false },
    { table: 'ponto_de_venda_representante', url: `${BASE_URL}/pontosDeVenda/representantes`, sync: false },
    { table: 'ponto_de_venda_fornecedor', url: `${BASE_URL}/pontosDeVenda/fornecedores`, sync: false },
    { table: 'fornecedor', url: `${BASE_URL}/fornecedores`, sync: false },
    { table: 'representante', url: `${BASE_URL}/representantes`, sync: false },
    { table: 'produto', url: `${BASE_URL}/produtos`, sync: false },
    { table: 'expositor', url: `${BASE_URL}/expositores`, sync: false },
  ]
}

export default function databaseSyncReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DB_SYNC_SET_TABLE_IS_SYNC':
      const tables = state.tables.map(item => {
        if (item.table === action.payload.table) {
          return {
            ...item,
            sync: action.payload.sync
          }
        }

        return item;
      });

      return {
        ...state,
        tables
      }
    case 'DB_SYNC_SET_TABLES':
      return {
        ...state,
        tables: action.payload
      }
    case 'DB_SYNC_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

import { openDB } from 'idb'
import axios from 'axios'
import md5 from 'md5'

// databases
const database = {
  'franchini-offline': {
    version: 9,
    tables: [
      'ponto_de_venda',
      'ponto_de_venda_representante',
      'ponto_de_venda_fornecedor',
      'fornecedor',
      'representante',
      'produto',
      'expositor',
      'imagem'
    ]
  },
  'gpv-offline': {
    version: 1,
    tables: [
      'request-history'
    ]
  },
  'workbox-background-sync': {
    version: 1, // NOTE: don't change the version
    tables: [
      'requests'
    ]
  },
  'visitacao': {
    version: 1,
    tables: [
      'visitacao',
      'representante',
      'ponto_de_venda',
      'fornecedores',
      'visitas'
    ]
  },
  'pedidoVenda': {
    version: 2,
    tables: [
      'visita',
      'fornecedor',
      'ponto_de_venda',
      'expositor'
    ]
  }
}

export const openDatabaseIndexedDB = async databaseName => {
  const dbObj = database[databaseName];

  const onUpgrade = (db, oldVersion, newVersion, transaction) => {
    try {
      dbObj.tables.forEach(t => {
        if (!db.objectStoreNames.contains(t))
          createStore(db, t);
      });
    } catch (e) {
      console.log(`Erro ao criar stores: ${e}`);
    }
  }

  return await openDB(databaseName, dbObj.version, {
    upgrade(db, oldVersion, newVersion, transaction) {
      onUpgrade(db, oldVersion, newVersion, transaction)
    },
    blocked() {
      // …
    },
    blocking() {
      // …
    },
    terminated() {
      // …
    }
  });
}

// store a offline request to gpv-offline database
export const storeRequestOnIndexedDB = async request => {
  const db = await openDatabaseIndexedDB('gpv-offline')

  await db.add('request-history', {
    timestamp: new Date().getTime(),
    request
  })
}

export const insertMultiple = (db, table, items) => {
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.transaction(table, 'readwrite');

      items.forEach(item => {
        tx.store.add({
          id: item.id,
          timestamp: new Date().getTime(),
          data: item
        });
      });

      await tx.done;

      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

const generateHashByItems = items => {
  return md5(JSON.stringify(items));
}

const orderArrayById = arr => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      if (arr[i].id < arr[j].id) {
        const temp = arr[j];
        arr[j] = arr[i];
        arr[i] = temp;
      }
    }
  }

  return arr;
}

const createStore = (db, storeName) => {
  const store = db.createObjectStore(storeName, {
    keyPath: 'id',
    autoIncrement: true
  });

  store.createIndex('timestamp', 'timestamp');
}

export const syncDatabase = tables => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('[download-db-table] -- INÍCIO --');
      console.log('[download-db-table] Abrindo indexedDB...');
      const db = await openDatabaseIndexedDB('franchini-offline');

      const promises = tables.map(async t => await downloadTable(db, t.table, t.url));

      await Promise.all(promises);

      resolve();
    } catch (e) {
      reject(e);
    }
  })
}

export const isDataSync = (db, table, findAllItemsRequestUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      // find all offline items
      const offlineItemsResponse = await db.getAll(table);
      const offlineItems = offlineItemsResponse.map(offlineItem => offlineItem.data);

      // find all online items
      console.log('[download-db-table] Buscando itens da tabela...')
      const response = await axios.get(findAllItemsRequestUrl);
      const items = response.data.data;

      console.log('[download-db-table] Verificando se há discrepância entre os dados do banco e os dados offline...');

      // order both arrays before compare
      const itemsOrdered = orderArrayById(items);
      const offlineItemsOrdered = orderArrayById(offlineItems);

      // generate hashs
      const itemsHash = generateHashByItems(itemsOrdered);
      const offlineItemsHash = generateHashByItems(offlineItemsOrdered);

      console.log('[download-db-table] Itens da tabela -> (Hash)', itemsHash);
      console.log('[download-db-table] Itens do IndexedDB -> (Hash)', offlineItemsHash);

      // comparing hashs
      if (itemsHash === offlineItemsHash) {
        console.log('[download-db-table] Não há necessidade de sincronizar');
        resolve(true);
        return;
      } else {
        resolve(false);
      }
    } catch (e) {
      reject(e);
    }
  });
}

export const downloadTable = (db, table, findAllItemsRequestUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('[download-db-table] Tabela: ', table);
      console.log('[download-db-table] URL para buscar itens: ', findAllItemsRequestUrl);

      if (await isDataSync(db, table, findAllItemsRequestUrl)) {
        resolve();
        return;
      }

      // find all online items
      console.log('[download-db-table] Buscando itens da tabela...')
      const response = await axios.get(findAllItemsRequestUrl);
      const items = response.data.data;

      // delete all items
      console.log('[download-db-table] Limpando store...')
      await db.clear(table);

      // save all on db
      console.log('[download-db-table] Inserindo itens...')
      if (items.length > 0) await insertMultiple(db, table, items);

      resolve();
    } catch (e) {
      reject(e);
    }
  })
}

export const clearAll = (db, databaseName) => {
  return new Promise((resolve, reject) => {
    try {
      const dbObj = database[databaseName];

      dbObj.tables.forEach(async t => {
        await db.clear(t);
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  })
}

import React, { Component } from 'react';
import './selectFornecedor.css';
import { storage } from '../../config/fbConfig';
import defaultImg from '../common/imgs/default-img.png';
import Modal from '../template/modal';
import Row from '../template/row';
import Grid from '../template/grid';
import Button from '../template/button';
import ModalButton from '../template/modalButton';
import Breadcrumb from '../template/breadcrumb';
import Media from 'react-media';

import { withTranslation, Trans } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clearFornecedores,
  unattendFornecedor,
} from '../fornecedor/fornecedorActions';
import {
  fetchFornecedoresByPontoDeVendaAndVisitacao,
  setFornecedores,
} from '../pontoDeVenda/fornecedor/fornecedorActions';
import { setFornecedor, fetchVisitas } from './visitaActions';
import {
  closeVisitacao,
  removeVisitacao,
  clearVisitacoes,
} from '../visitacao/visitacaoActions';
import {
  setLoading,
  unsetLoading,
} from '../loadingSpinner/loadingSpinnerActions';
import { openDatabaseIndexedDB, clearAll } from '../../config/idbConfig';

import { toastr } from 'react-redux-toastr';

export class SelectFornecedor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEndVisitacao: false,
      isSomeFornecedorOk: false,
      // remove fornecedor
      fornecedor: null,
      representante: null,
      motivo: '',
    };
  }

  componentDidMount() {
    this.props.setLoading()
    const { fornecedores, isEdit } = this.props;
    this.props.fetchVisitas()
    if (isEdit) {
      this.getFornecedores();
    }
    
    this.loadImages();
    this.checkFornecedores(fornecedores);
    setTimeout(() => {
      this.props.unsetLoading()
    }, 1500);
  }

  async componentDidUpdate(prevProps) {
    const { pontoDeVenda, visitacao, fornecedores } = this.props;

    if (!pontoDeVenda) return;

    if (!visitacao) return;

    // check what changed in application state

    // visitacao changed: first time the component is opened
    if (prevProps.visitacao !== visitacao) {
      this.getFornecedores();
      this.loadImages();
      return;
    }

    // fornecedores changed: a fornecedor was visited
    // NOTE: when a fornecedor is visited, both (fornecedor and pontoDeVenda) changes
    if (prevProps.fornecedores !== fornecedores) {
      this.setState(
        {
          isLoading: false,
        },
        () => {
          this.checkFornecedores(fornecedores);
          this.loadImages();
        }
      );

      // save fornecedores
      // set fornecedor as visited and save on indexedDB
      try {
        const db = await openDatabaseIndexedDB('visitacao');

        this.props.fornecedores.forEach(async (fornecedor) => {
          await db.add('fornecedores', {
            id: fornecedor.id,
            timestamp: new Date().getTime(),
            data: fornecedor,
          });
        });
      } catch (e) {
        console.log(
          `%c Erro ao inserir fornecedor no indexedDB: ${e}`,
          'color: tomato'
        );
      }
      return ;
    }
  }

  loadImages = () => {
    const fornecedores = this.props.fornecedores || [];

    fornecedores.forEach((fornecedor, index) => {
      if (fornecedor.imagem) {
        const storageRef = storage.ref(fornecedor.imagem);
        storageRef
          .getDownloadURL()
          .then((url) => {
            const element = document.getElementById(`imagem-${index}`);

            if (element) element.src = url;
          })
          .catch((err) => {
            throw err;
          });
      }
    });
  };

  getFornecedores = () => {
    const {
      visitacao,
      pontoDeVenda,
      fetchFornecedoresByPontoDeVendaAndVisitacao,
    } = this.props;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        fetchFornecedoresByPontoDeVendaAndVisitacao(pontoDeVenda, visitacao);
        this.checkFornecedores(pontoDeVenda.fornecedores);
      }
    );
  };

  isEqualTo = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  // fornecedores visit validation
  checkFornecedores = (fornecedores) => {
    
    if (!fornecedores) {
      return;
    }

    if (fornecedores.length <= 0) {
      return;
    }

    this.setState({ isLoading: false });


    if(fornecedores.some((fornecedor) => fornecedor.visita_fornecedor_id)) {
      this.setState({
        isSomeFornecedorOk: true,
      })
    }

    if (!this.isAllFornecedoresOk(fornecedores)) {
      return;
    }

    if (this.state.isEndVisitacao) {
      return;
    }

    this.setState({
      isEndVisitacao: true,
    });
    this.closeVisitacao();
  };

  // check if all fornecedores are visited
  isAllFornecedoresOk = (fornecedores) => {
    // console.log('Fornecedor nulo', fornecedores.find(
    //   (fornecedor) =>
    //     fornecedor.expositor === null));
    // if no fornecedores has the visita_fornecedor_id equals to null it will return undefined
    return (
      fornecedores.find(
        (fornecedor) =>
          fornecedor.visita_fornecedor_id === null ||
          fornecedor.visita_fornecedor_id === undefined
      ) === undefined
    );
  };

  selectFornecedor = (fornecedor) => {
    // set fornecedor
    this.props.setFornecedor(fornecedor);

    // load next step
    this.props.loadNextStep();
  };

  markVisitacaoAsFinished = async () => {
    // remove visitando attr from localstorage
    localStorage.removeItem('visitando');

    try {
      const db = await openDatabaseIndexedDB('visitacao');
      await clearAll(db, 'visitacao');
    } catch (e) {
      console.log(`%c Erro ao limpar db: ${e}`, 'color: tomato');
    }
  };

  closeVisitacao = async () => {
    this.markVisitacaoAsFinished();

    this.props.closeVisitacao(this.props.visitacao);

    // clear store
    this.props.clearVisitacoes();
    this.props.clearFornecedores();

    this.handleRedirectToVisitaList();
  };

  cancelVisitacao = async () => {
    try {
      this.markVisitacaoAsFinished();

      if (!this.props.isEdit) {
        this.props.clearFornecedores();

        await this.props.removeVisitacao(this.props.visitacao);
      }
    } catch (e) {
      console.log(`%c Falha ao remover a visitação: ${e}`, 'color: tomato');
    } finally {
      // clear store
      this.props.clearVisitacoes();
      this.handleRedirectToVisitaList();
    }
  };

  handleRedirectToVisitaList = () => {
    // redirect to visita list
    this.props.redirect();
  };

  handleUnattendFornecedor = async ({
    representante,
    pontoDeVenda,
    fornecedor,
    motivo,
  }) => {
    this.props.setLoading();

    await this.props.unattendFornecedor(
      representante,
      pontoDeVenda,
      fornecedor,
      motivo
    );
    this.getFornecedores();

    this.props.unsetLoading();
  };

  clearState = () => {
    this.setState({
      representante: null,
      pontoDeVenda: null,
      fornecedor: null,
      motivo: '',
    });
  };

  renderFornecedoresMobile = () => {
    const fornecedores = this.props.fornecedores || [];

    return fornecedores.map((fornecedor, index) => {
      return (
        <div key={fornecedor.id} className='card text-center mb-3'>
          <div className='card-body'>
            <h5 className='card-title'>
              <div className='mx-auto' style={{ width: 100 }}>
                <img
                  className='img-resposive img-thumbnail'
                  src={defaultImg}
                  id={`imagem-${index}`}
                  alt='Logo do fornecedor'
                />
              </div>
            </h5>
            <p className='card-text'>
              {fornecedor.visita_fornecedor_id ? (
                <div className='circle circle-success mx-auto'>
                  <span className='circle-text'>OK</span>
                </div>
              ) : (
                <div className='circle circle-warning mx-auto'>
                  <span className='circle-text text-dark'>P</span>
                </div>
              )}
            </p>
            {
              // if fornecedor visited?
              !fornecedor.visita_fornecedor_id ? (
                <button
                  className='btn btn-info'
                  onClick={() => this.selectFornecedor(fornecedor)}
                >
                  Visitar
                </button>
              ) : null
            }
            <ModalButton
              target='#modalUnattend'
              className='ml-2'
              size='md'
              color='danger'
              handleclick={() => {
                this.setState({
                  fornecedor,
                  pontoDeVenda: this.props.pontoDeVenda,
                  representante: this.props.representante,
                });
              }}
            >
              <span className='font-weight-bold'>X</span>
            </ModalButton>
          </div>
        </div>
      );
    });
  };

  renderFornecedores = () => {
    const fornecedores = this.props.fornecedores || [];
    const visitas = this.props.visitas || [];
    const { t: translate } = this.props;
    return fornecedores.map((fornecedor, index) => {
      const visitasFilter = visitas.filter((v) => v.fornecedor_id === fornecedor.id).filter((v) => v.visitacao_id === fornecedor?.visitacao_id)
      const visitasFormated = visitasFilter[0] ? `${visitasFilter[0].data_visita.split(' ')[0].split('-')[2]}/${visitasFilter[0].data_visita.split('-')[1]} - ${visitasFilter[0].data_visita.split(' ')[1].split(':')[0]}:${visitasFilter[0].data_visita.split(':')[1]}` : null
      return (
        <tr key={fornecedor.id}>
          <td
            data-label={translate(
              'visitas.wizard.new.selectFornecedor.table.nome'
            )}
            className='align-middle d-flex align-items-center'
            style={{ textAlign: 'start' }}
          >
            <div className='mr-1' style={{ width: 100 }}>
              <img
                className='img-resposive img-thumbnail'
                src={defaultImg}
                id={`imagem-${index}`}
                alt='Logo do fornecedor'
              />
            </div>

            {fornecedor.nome}
          </td>
          <td
            data-label={translate(
              'visitas.wizard.new.selectFornecedor.table.visita'
            )}
            className='align-middle'
          >
            
            {fornecedor.visita_fornecedor_id ? (
              <div className='circle circle-success'>
                <span className='circle-text'>OK</span>
              </div>
            ) : (
              <div className='circle circle-warning'>
                <span className='circle-text text-dark'>P</span>
              </div>
            )}
          </td>
          <td
            data-label={translate(
              'visitas.wizard.new.selectFornecedor.table.visita'
            )}
            className='align-middle'
          >
            
            {visitasFilter[0] ? (
                <b>{visitasFormated}</b>
            ) : (
                <b>--/-- - --:--</b>
            )}
          </td>
          <td
            data-label={translate(
              'visitas.wizard.new.selectFornecedor.table.acao'
            )}
            className='align-middle'
          >
            {!fornecedor.visita_fornecedor_id ? (
              <button
                className='btn btn-sm btn-info'
                onClick={() => this.selectFornecedor(fornecedor)}
              >
                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.buttonVisitar'>
                  trans
                </Trans>
              </button>
            ) : null}
          </td>
          <td
            data-label={translate(
              'visitas.wizard.new.selectFornecedor.table.removerFornecedor'
            )}
            className='align-middle'
          >
            <ModalButton
              target='#modalUnattend'
              style={{ width: 70 }}
              size='sm'
              color='danger'
              handleclick={() => {
                this.setState({
                  fornecedor,
                  pontoDeVenda: this.props.pontoDeVenda,
                  representante: this.props.representante,
                });
              }}
            >
              <span className='font-weight-bold'>X</span>
            </ModalButton>
          </td>
        </tr>
      );
      
    });
    
  };

  render() {
    const { pontoDeVenda, t: translate } = this.props;

    const blockPontoDeVenda = () => {
      return (
        <h4>
          <span className='badge badge-primary'>{pontoDeVenda.nome}</span>
        </h4>
      );
    };

    return (
      <React.Fragment>
        <Breadcrumb
          title={`${translate('visitas.wizard.new.selectFornecedor.title')} ${
            pontoDeVenda && pontoDeVenda.nome
          } ${
            pontoDeVenda && pontoDeVenda.cidade
              ? `- ${pontoDeVenda && pontoDeVenda.cidade}`
              : ''
          }`}
          linkTo='/visitas/new'
          btnFunction={() => this.props.loadPreviousStep()}
          // component={blockPontoDeVenda}
          isHideBackBtn={true}
        />

        <div className='row'>
          <div className='col-12'>
            <Media query={{ maxWidth: 599 }}>
              {(matches) =>
                matches ? (
                  <>
                    {this.state.isLoading ? (
                      <Skeleton count={4} height={70} />
                    ) : this.props.fornecedores.length <= 0 ? (
                      <p>
                        <Trans i18nKey='visitas.wizard.new.selectFornecedor.notFound'>
                          trans
                        </Trans>
                      </p>
                    ) : (
                      <div>{this.renderFornecedoresMobile()}</div>
                    )}
                  </>
                ) : (
                  <>
                    {this.state.isLoading ? (
                      <Skeleton count={4} height={70} />
                    ) : this.props.fornecedores.length <= 0 ? (
                      <p>
                        <Trans i18nKey='visitas.wizard.new.selectFornecedor.notFound'>
                          trans
                        </Trans>
                      </p>
                    ) : (
                      <div id='card'>
                        <table className='table table-fluid table-striped text-center'>
                          <thead className='thead-dark'>
                            <tr>
                              <th scope='col' style={{ textAlign: 'start' }}>
                                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.nome'>
                                  trans
                                </Trans>
                              </th>
                              <th scope='col' style={{ textAlign: 'start' }}>
                                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.visita'>
                                  trans
                                </Trans>
                              </th>
                              <th scope='col'>
                                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.data'>
                                  trans
                                </Trans>
                              </th>
                              <th scope='col'>
                                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.acao'>
                                  trans
                                </Trans>
                              </th>
                              <th scope='col'>
                                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.removerFornecedor'>
                                  trans
                                </Trans>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.renderFornecedores()}</tbody>
                        </table>
                      </div>
                    )}
                  </>
                )
              }
            </Media>
          </div>
        </div>

        <div className='row mb-3'>
          {this.state.isSomeFornecedorOk ? (
            <div className='col-md-6 mb-2'>
              <button
                className='btn btn-block btn-primary'
                onClick={this.closeVisitacao}
              >
                <Trans i18nKey='visitas.wizard.new.selectFornecedor.buttonEncerrarVisitacao'>
                  trans
                </Trans>
              </button>
            </div>
          ) : (
            <div className='col-md-6 mb-2'>
              <button
                className='btn btn-block btn-secondary'
                onClick={this.cancelVisitacao}
              >
                <Trans i18nKey='visitas.wizard.new.selectFornecedor.buttonCancelar'>
                  trans
                </Trans>
              </button>
            </div>
          )}
        </div>

        <Modal
          id='modalUnattend'
          title={translate(
            'visitas.wizard.new.selectFornecedor.table.modalDelete.title'
          )}
          handleCancelarClick={this.clearState}
          handleClick={() => {
            this.handleUnattendFornecedor({ ...this.state });
            this.clearState();
          }}
          size='lg'
          btnColor='success'
          btnText={translate(
            'visitas.wizard.new.selectFornecedor.table.modalDelete.buttonConfirmar'
          )}
          btnCancelarText={translate(
            'visitas.wizard.new.selectFornecedor.table.modalDelete.buttonCancelar'
          )}
        >
          <Row>
            <Grid cols='12'>
              <p>
                <span className='font-weight-bold'>
                  <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.modalDelete.title2'>
                    trans
                  </Trans>
                </span>
              </p>
              <textarea
                name='motivo'
                id='motivo'
                cols='30'
                rows='10'
                className='form-control'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                value={this.state.motivo}
              ></textarea>
              <small className='text-muted'>
                <Trans i18nKey='visitas.wizard.new.selectFornecedor.table.modalDelete.small'>
                  trans
                </Trans>
              </small>
            </Grid>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visitacao: state.visitacao.visitacao,
    fornecedores: state.fornecedor.fornecedores,
    isEdit: state.visitacao.isEdit,
    visitas: state.visita.visitas,
    representante: state.representante.representante,
    pontoDeVenda: state.visita.pontoDeVenda,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFornecedoresByPontoDeVendaAndVisitacao,
      unattendFornecedor,
      clearFornecedores,
      removeVisitacao,
      clearVisitacoes,
      setFornecedores,
      closeVisitacao,
      setFornecedor,
      unsetLoading,
      setLoading,
      fetchVisitas,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SelectFornecedor));

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  fetchAllClientes,
  removeCliente,
  clearClientes
} from './clienteActions'

import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'
import Icon from '../template/icon'
import Row from '../template/row'
import Grid from '../template/grid'
import ClienteList from './clienteList'
import Skeleton from 'react-loading-skeleton'

export default function ClienteListPage({ ...props }) {
  const [firstRun, setFirstRun] = useState(true);
  const [isLoading, setLoading] = useState(true);

  // i18n
  const { t } = useTranslation();

  // application state
  const dispatch = useDispatch();
  const clientesState = useSelector(state => state.cliente.clientes);

  // fetch all clientes
  useEffect(() => {
    dispatch(fetchAllClientes());

    return () => dispatch(clearClientes());
  }, []);

  // unset loading when clientes arrive
  useEffect(() => {
    if(firstRun) {
      setFirstRun(false);
      return;
    }

    if (clientesState) {
      setLoading(false);
    }
  }, [clientesState]);

  function deleteCliente(cliente) {
    dispatch(removeCliente(cliente));
  }

  function editCliente(cliente) {
    props.history.push(`/clientes/${cliente.id}/edit`);
  }

  // component
  function ButtonNew() {
    return (
      <Button size="sm" color="success"
        handleclick={() => props.history.push('/clientes/new')}>
        <Icon icon="add" />
      </Button>
    )
  }

  return (
    <>
      <Breadcrumb title={t("clientes.title")} component={ButtonNew} />

      <Row>
        <Grid cols="12">
          {isLoading ? <Skeleton count={5} height={40} />
            : (
              <ClienteList clientes={clientesState}
                removeCliente={deleteCliente}
                editCliente={editCliente} />
            )}
        </Grid>
      </Row>
    </>
  )
}

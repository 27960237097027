const INITIAL_STATE = {
  pedidoVenda: null,
  pedidosVenda: [],
  produtos: [],
  redirect: false,
  pagination: {
    totalItems: 0
  },
  search: {
    ref: ''
  }
}

const pedidoVendaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_PEDIDOS_VENDA':
      return {
        ...state,
        pedidosVenda: action.payload
      }
    case 'SET_PEDIDO_VENDA':
      return {
        ...state,
        pedidoVenda: action.payload
      }
    case 'SET_PEDIDO_VENDA_PRODUTOS':
      return {
        ...state,
        produtos: action.payload
      }
    case 'SET_PEDIDO_VENDA_REDIRECT':
      return {
        ...state,
        redirect: action.payload
      }
    case 'SET_PEDIDO_VENDA_PAGINATION_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'SET_PEDIDO_VENDA_SEARCH_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'CLEAR_PEDIDO_VENDA_PAGINATION':
      return {
        ...state,
        pagination: {
          totalItems: 0
        }
      }
    case 'CLEAR_PEDIDO_VENDA_SEARCH':
      return {
        ...state,
        search: {
          ref: ''
        }
      }
    case 'CLEAR_PEDIDOS_VENDA':
      return {
        ...state,
        pedidoVenda: null,
        pedidosVenda: [],
        produtos: [],
        pagination: {
          totalItems: 0
        },
        search: {
          ref: ''
        }
      }
    default:
      return state
  }
}

export default pedidoVendaReducer

import React, { Component } from 'react'
import consts from '../../../consts'
import Breadcrumb from '../../template/breadcrumb'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUsers, validateUser } from '../../user/userActions'
import { getCountUsersNotValidated } from '../../navbar/navbarActions'
import { setLoading, unsetLoading } from '../../loadingSpinner/loadingSpinnerActions'

class ValidarCadastro extends Component {
  componentDidMount() {
    this.props.setLoading()

    this.props.getUsers()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.users !== this.props.users) {
      this.props.unsetLoading()
    }
  }

  validateUser = async user => {
    await this.props.validateUser(user)
    this.props.getCountUsersNotValidated();
  }

  renderUsers = () => {
    const { t: translate } = this.props;

    return this.props.users.map(user => {
      const validado = user.status === 'VA' ? true : false
      const { GE, RP, FN, CL } = consts.userType

      return (
        <tr key={user.id} className={validado ? null : 'table-danger'}>
          <td data-label={translate("validacao.cadastros.table.email")}>{user.email}</td>
          <td data-label={translate("validacao.cadastros.table.tipo.label")} className="text-center">
            {
              user.tipo === GE
              ? translate("validacao.cadastros.table.tipo.gestor") 
              : user.tipo === RP 
              ? translate("validacao.cadastros.table.tipo.representante")  
              : user.tipo === FN
              ? translate("validacao.cadastros.table.tipo.fornecedor")  
              : user.tipo === CL
              ? translate("validacao.cadastros.table.tipo.cliente") 
              : 'INVÁLIDO'
            }
          </td>
          <td data-label={translate("validacao.cadastros.table.status.label")} className="text-center">
            { validado 
              ? translate("validacao.cadastros.table.status.validado") 
              : translate("validacao.cadastros.table.status.naoValidado") 
              }
          </td>
          <td data-label={translate("validacao.cadastros.table.acao")} className="text-center">
            {
              validado 
              ? '-' 
              : <button className="btn btn-sm btn-success"
                  onClick={() => this.validateUser(user)}>
                  <Trans i18nKey="validacao.cadastros.table.buttonValidarCadastro">trans</Trans>
                </button>
            }
          </td>
        </tr>
      )
    })
  }
  
  render() {
    const { t: translate } = this.props;
    
    return (
      <React.Fragment>
        <Breadcrumb title={translate("validacao.cadastros.title")} />
  
        <div className="row">
          <div className="col-12">
            <div id="flip-scroll">
              <table className="table table-fluid table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="validacao.cadastros.table.email">trans</Trans>
                    </th>
                    <th scope="col" className="text-center">
                      <Trans i18nKey="validacao.cadastros.table.tipo.label">trans</Trans>
                    </th>
                    <th scope="col" className="text-center">
                      <Trans i18nKey="validacao.cadastros.table.status.label">trans</Trans>
                    </th>
                    <th scope="col" className="text-center">
                      <Trans i18nKey="validacao.cadastros.table.acao">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderUsers()}
                </tbody>
              </table>
            </div>
          </div>
        </div>      
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    users: state.user.users
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  getUsers, 
  validateUser,
  unsetLoading,
  setLoading,
  getCountUsersNotValidated
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ValidarCadastro))

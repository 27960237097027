import React, { Component } from 'react';
import SelectPontoDeVenda from './selectPontoDeVenda';
import SelectFornecedor from './selectFornecedor';
import VisitaForm from './visitaForm';

import { withTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchRepresentanteByUserId } from '../representante/representanteActions';
import { clearVisitas } from './visitaActions';
import VisitaEdit from './visitaEdit';
import { openDatabaseIndexedDB, clearAll } from '../../config/idbConfig';
import { setPontoDeVenda } from './visitaActions';
import { setVisitacao } from '../visitacao/visitacaoActions';
import { setFornecedores } from '../fornecedor/fornecedorActions';
import { setRepresentante } from '../representante/representanteActions';
import { clearAll as clear } from '../router/routerActions';

export class VisitaWizard extends Component {
  // Guide to steps:
  // Step 1: Select ponto de venda
  // Step 2: Select Fornecedor
  // Step 3: Save visita
  constructor(props) {
    super(props);

    this.state = {
      step: this.props?.location?.state?.step || 1,
    };
  }

  async componentDidMount() {
    const isVisitando = localStorage.getItem('visitando');

    // clear pedido de venda indexedDB
    this.clearPedidoVendaIdb();

    // i18n
    const { t: translate, location } = this.props;

    // in case user comes from heatmap, it is not necessary to show isVisitando message
    if (location.state && location.state.ignoreInitiatedVisitacao) {
      this.props.fetchRepresentanteByUserId(this.props.user.id);
      return;
    }

    // user already initiated a visitacao before
    if (isVisitando) {
      toastr.info(
        translate('visitas.wizard.new.toastr.isVisitando.title'),
        translate('visitas.wizard.new.toastr.isVisitando.message')
      );
      this.restoreVisitacaoSession();
      return;
    }

    this.props.fetchRepresentanteByUserId(this.props.user.id);
  }

  componentWillUnmount() {
    this.props.clearVisitas();
  }

  clearPedidoVendaIdb = async () => {
    try {
      localStorage.removeItem('isPedidoVenda');

      const db = await openDatabaseIndexedDB('pedidoVenda');

      await clearAll(db, 'pedidoVenda');
    } catch (e) {
      console.log(
        `%c [IDB] Erro ao limpar o pedido de venda: ${e}`,
        'color: tomato'
      );
    }
  };

  restoreVisitacaoSession = async () => {
    try {
      const db = await openDatabaseIndexedDB('visitacao');

      // set visitacao
      const idbVisitacao = await db.getAll('visitacao');
      const visitacao = idbVisitacao[0].data;
      this.props.setVisitacao(visitacao);

      // set ponto de venda
      const idbPontoDeVenda = await db.getAll('ponto_de_venda');
      const pontoDeVenda = idbPontoDeVenda[0].data;
      this.props.setPontoDeVenda(pontoDeVenda);

      // set fornecedores
      const idbFornecedores = await db.getAll('fornecedores');
      const fornecedores = idbFornecedores.map((f) => f.data);
      this.props.setFornecedores(fornecedores);

      // set representante
      const idbRepresentante = await db.getAll('representante');
      const representante = idbRepresentante[0].data;
      this.props.setRepresentante(representante);
    } catch (e) {
      console.log(
        `%c Falha ao restaurar a sessão da visitação: ${e}`,
        'color: tomato'
      );
    }
  };

  changeStep = (step) => {
    this.setState({
      ...this.state,
      step,
    });
  };

  renderVisitaForm = () => {
    if (this.props.visita && this.props.visita.id) {
      return (
        <VisitaEdit
          pontoDeVenda={this.props.pontoDeVenda}
          fornecedor={this.props.fornecedor}
          loadPreviousStep={() => this.changeStep(2)}
          loadNextStep={() => this.changeStep(1)}
        />
      );
    }

    return (
      <VisitaForm
        pontoDeVenda={this.props.pontoDeVenda}
        fornecedor={
          this.props.fornecedor ||
          this.props?.location?.state?.saveVisitObjects?.fornecedor
        }
        representante={
          this.props.representante ||
          this.props?.location?.state?.saveVisitObjects?.representante
        }
        visitacao={
          this.props.visitacao ||
          this.props?.location?.state?.saveVisitObjects?.visitacao
        }
        loadPreviousStep={() => this.changeStep(2)}
        loadNextStep={() => this.changeStep(1)}
      />
    );
  };

  goBack = () => {
    if (this.props.pathToRedirect) {
      this.props.history.push(this.props.pathToRedirect);
      this.props.clear();
      return;
    }

    this.props.history.push('/visitas');
  };

  render() {
    const { t: translate } = this.props;

    return (
      <>
        <h1 className='text-center mb-3'>
          {this.props.visita && this.props.visita.id
            ? translate('visitas.wizard.edit.title')
            : translate('visitas.wizard.new.title')}
        </h1>

        {
          {
            1: <SelectPontoDeVenda loadNextStep={() => this.changeStep(2)} />,
            2: (
              <SelectFornecedor
                pontoDeVenda={this.props.pontoDeVenda}
                loadPreviousStep={() => this.changeStep(1)}
                loadNextStep={() => this.changeStep(3)}
                redirect={this.goBack}
              />
            ),
            3: this.renderVisitaForm(),
          }[this.state.step]
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    visita: state.visita.visita,
    pontoDeVenda: state.visita.pontoDeVenda,
    fornecedor: state.visita.fornecedor,
    representante: state.representante.representante,
    visitacao: state.visitacao.visitacao,
    pathToRedirect: state.router.redirectTo,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRepresentanteByUserId,
      setRepresentante,
      setPontoDeVenda,
      setFornecedores,
      clearVisitas,
      setVisitacao,
      clear,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VisitaWizard));

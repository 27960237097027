import React from 'react'
import PropTypes from 'prop-types'

const Icon = props => {
  const iconStyle = {
    color: props.color
  };

  return <i className="material-icons" style={iconStyle}>{props.icon}</i>
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default Icon

export default {
  list: {
    title: 'I nostri prodotti',
    search: {
      placeholder: 'Cerca per prodotto o descrizione o fornitore ...',
      buttonSearch: {
        title: 'Ricerca'
      },
      buttonClear: {
        title: 'Pulito'
      },
    },
    card: {
      fornecedor: 'Fornitore',
      unidade: 'Unità'
    },
    modal: {
      title: "Conferma l'azione",
      text: 'Sei sicuro di voler rimuovere questo prodotto?',
      buttonCancelar: 'Annullare',
      buttonRemover: 'Remove'
    }
  },

  form: {
    title: 'Registrazione del prodotto',
    codigoArtigo: {
      label: 'Codice articolo: ',
      placeholder: 'Codice identificativo del prodotto'
    },
    codigoEan: 'Codice EAN: ',
    descricaoEan: 'Descrizione EAN: ',
    descricao: {
      label: 'Descrizione: ',
      placeholder: 'Descrizione del prodotto che verrà visualizzata dal rappresentante'
    },
    fornecedor: 'Fornitore: ',
    quantidadeKit: {
      label: 'Quantità del kit: ',
      placeholder: '10'
    },
    unidade: {
      label: 'Unità: ',
      pc: 'Parte'
    },
    imagem: 'Immagine: ',
    buttonEscolherImagem: 'Scegli immagine',
    buttonCadastrar: 'Registrazione'
  },

  edit: {
    title: 'Modifica prodotto',
    codigoArtigo: 'Codice Articolo: ',
    codigoEan: 'Codice EAN: ',
    descricaoEan: 'Descrizione EAN: ',
    descricao: 'Descrizione: ',
    quantidadeKit: 'Quantità del kit: ',
    unidade: {
      label: 'Unità: ',
      pc: 'Parte'
    },
    imagem: 'Immagine: ',
    buttonHabilitarCampos: 'Abilita campi',
    buttonCancelar: 'Annullare',
    buttonSalvar: 'Salva'
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema nella ricerca di prodotti'
      }
    },
    fetchByPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema nella ricerca di prodotti'
      }
    },
    fetchByFornecedor: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema nella ricerca di prodotti da questo fornitore'
      }
    },
    search: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca del prodotto'
      }
    },
    searchByPontoDeVendaUnbound: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca del prodotto'
      }
    },
    save: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il salvataggio del prodotto'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvataggio del prodotto'
      }
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il salvataggio del prodotto'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvataggio del prodotto'
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del prodotto'
      },
      success: {
        title: 'Successo',
        message: 'Operazione riuscita durante la rimozione del prodotto'
      }
    }
  }
}
import React from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'

import './rowLabel.css'

function RowLabel({ row }) {
  return (
    <div className="row-label">
      <Trans i18nKey="expositores.estande.linha">trans</Trans> {row}
    </div>
  )
}

RowLabel.propTypes = {
  row: PropTypes.number,
}

export default RowLabel;

export const setLoading = () => {
  return dispatch => {
    return dispatch({
      type: 'SET_LOADING'
    })
  }
}

export const setMessage = (message) => {
  return dispatch => {
    return dispatch({
      type: 'SET_LOADING_MESSAGE',
      payload: message,
    })
  }
}

export const unsetLoading = () => {
  return dispatch => {
    return dispatch({
      type: 'UNSET_LOADING'
    })
  }
}

export const changeLoading = () => {
  return dispatch => {
    return dispatch({
      type: 'CHANGE_LOADING'
    })
  }
}
import React, { Component } from 'react'
import Modal from '../template/modal.jsx'
import Button from '../template/button'
import Breadcrumb from '../template/breadcrumb'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchFornecedores, removeFornecedor, setFornecedor } from './fornecedorActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions'

class FornecedorList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fornecedor: null
    }
  }

  componentDidMount() {
    this.props.setLoading()

    this.props.fetchFornecedores()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.fornecedores !== this.props.fornecedores) {
      this.props.unsetLoading()
    }
  }

  removerFornecedor = async fornecedor => {
    await this.props.removeFornecedor(fornecedor);
    this.props.getCountUsersNotValidated();
  }

  editarFornecedor = fornecedor => {
    // set fornecedor
    this.props.setFornecedor(fornecedor)
    this.props.history.push('/fornecedores/edit')
  }

  setFornecedor = fornecedor => {
    this.setState({
      ...this.state,
      fornecedor
    })
  }

  renderFornecedor = () => {
    const fornecedores = this.props.fornecedores || []
    const { t } = this.props;

    return fornecedores.map(fornecedor => {
      return (
        <tr key={fornecedor.id}>
          <td data-label={t("fornecedores.list.table.nome")} className="align-middle">{fornecedor.nome}</td>
          <td data-label={t("fornecedores.list.table.telefone")} className="align-middle">{fornecedor.telefone}</td>
          <td data-label={t("fornecedores.list.table.email")} className="align-middle">
            {
              fornecedor.email.length > 36 && visualViewport.width <= 600 
                ? fornecedor.email.substr(0, 33) + '...' 
                : fornecedor.email
            }
          </td>
          <td data-label={t("fornecedores.list.table.acao")}>
            <Button size="sm" color="warning"
              handleclick={() => this.editarFornecedor(fornecedor)}>
                <Trans i18nKey="fornecedores.list.table.buttonEditar">trans</Trans>
            </Button>
            <Button className="ml-2" size="sm" color="danger"
              handleclick={() => this.setFornecedor(fornecedor)}
              data-toggle="modal" data-target="#modalDeletarFornecedor">
                <Trans i18nKey="fornecedores.list.table.buttonRemover">trans</Trans>
            </Button>
          </td>
        </tr>
      )
    })
  }

  render() {
    // i18n
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("fornecedores.list.title")}
          linkTo="/home" component={() => (
            <Button size="sm" color="success" 
              handleclick={() => this.props.history.push('/fornecedores/user/new')}>
                <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
            </Button>
          )} />
        
        <div className="row">
          <div className="col-12">
            <div id="card">
              <table className="table table-fluid table-striped text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="fornecedores.list.table.nome">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="fornecedores.list.table.telefone">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="fornecedores.list.table.email">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="fornecedores.list.table.acao">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderFornecedor()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal id="modalDeletarFornecedor" 
          title={t("fornecedores.list.modal.title")} 
          text={t("fornecedores.list.modal.text")} 
          handleClick={() => this.removerFornecedor(this.state.fornecedor)} 
          size="sm" 
          btnColor="danger" 
          btnText={t("fornecedores.list.modal.buttonRemover")}
          btnCancelarText={t("fornecedores.list.modal.buttonCancelar")} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    fornecedores: state.fornecedor.fornecedores
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  getCountUsersNotValidated,
  fetchFornecedores, 
  removeFornecedor, 
  setFornecedor,
  unsetLoading,
  setLoading, 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FornecedorList))

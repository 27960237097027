export default {
  // representantes
  visitas: 'Visite',
  pedidosVenda: 'Ordini',
  mapaCalor: 'Mappa di calore',

  // gestores
  clientes: 'Clienti',
  gestores: 'Manager',
  representantes: 'Rappresentanti',
  fornecedores: 'Fornitori',
  produtos: 'Prodotti',
  pontosDeVenda: 'Punti di vendita',
  tabelasPreco: 'Tabelle dei prezzi',
  validacao: {
    validacao: 'Convalida',
    exclusaoPontosDeVenda: 'Esclusione di punti vendita',
    novosCadastros: 'Nuove registrazioni',
    exclusaoFornecedores: 'Esclusione di fornitori',
  },
  expositores: 'Espositori',

  alterarSenha: "Cambia password",
  entrar: "Accesso",
  cadastrar: "Iscriviti",

  reso: "Reso",
  estatistiche: "Estatistiche",
  planogrammi: "Planogrammi",
}

import axios from 'axios'
import consts from '../../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../../loadingSpinner/loadingSpinnerActions'
import { openDatabaseIndexedDB } from '../../../config/idbConfig'

const BASE_URL = consts.API_URL

const nop = () => {
  return dispatch => {
    return dispatch({
      type: 'NOP'
    })
  }
}

export const setFornecedor = fornecedor => {
  return dispatch => {
    return dispatch({
      type: 'SET_FORNECEDOR_PONTO_DE_VENDA',
      payload: fornecedor
    })
  }
}

const setFornecedoresPontoDeVenda = fornecedores => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_FORNECEDORES_PONTO_DE_VENDA',
      payload: fornecedores
    })
  }
}

// fetch to fornecedor reducer
export const setFornecedores = fornecedores => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_FORNECEDORES',
      payload: fornecedores
    })
  }
}

export const fetchFornecedores = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/fornecedores`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
          return dispatch(nop())
        }

        return dispatch(setFornecedoresPontoDeVenda(res.data.data))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
        throw err
      })
  }
}

// will fetch all fornecedores by ponto de venda bound or not
export const fetchAllFornecedoresByPontoDeVenda = pontoDeVendaId => {
  return dispatch => {
    if (!pontoDeVendaId) {
      toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/pontosDeVenda/fornecedores/pontosDeVenda/${pontoDeVendaId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
          return dispatch(nop())
        }

        return dispatch(setFornecedoresPontoDeVenda(res.data.data))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
        throw err
      })
  }
}

export const fetchFornecedoresByPontoDeVenda = pontoDeVenda => {
  return dispatch => {
    if (!pontoDeVenda) {
      toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/pontosDeVenda/fornecedores/bound/pontosDeVenda/${pontoDeVenda.id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
          return dispatch(nop())
        }

        return dispatch(setFornecedores(res.data.data))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
        throw err
      })
  }
}

export const fetchFornecedoresByPontoDeVendaAndVisitacao = (pontoDeVenda, visitacao) => {
  return dispatch => {
    if (!pontoDeVenda) {
      toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
      return dispatch(nop())
    }

    if (!visitacao) {
      toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/pontosDeVenda/fornecedores/bound/pontosDeVenda/${pontoDeVenda.id}?visitacaoId=${visitacao.id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
          return dispatch(nop())
        }
        return dispatch(setFornecedores(res.data.data))
      })
      .catch(async err => {
        // if (err.message === 'Network Error') {
        //   // fetch all fornecedores by ponto de venda?
        //   // or use the store to save stats of the visited fornecedor
        //   const db = await openDatabaseIndexedDB('franchini-offline');

        //   const idbFornecedores = await db.getAll('fornecedor');
        //   const idbPontoDeVendaFornecedores = await db.getAll('ponto_de_venda_fornecedor');

        //   const filteredPontosDeVendaFornecedores = idbPontoDeVendaFornecedores.filter(pvr => pvr.data.ponto_de_venda_id === pontoDeVenda.id);

        //   const fornecedoresPontoDeVenda = idbFornecedores
        //     .filter(f => filteredPontosDeVendaFornecedores.find(pvf => pvf.data.fornecedor_id === f.data.id))
        //     .map(item => item.data);

        //   return dispatch(setFornecedores(fornecedoresPontoDeVenda));
        // }

        toastr.error("Oops!", "Tivemos um problema ao buscar fornecedores")
        throw err
      })
  }
}

import React, { Component } from 'react'
import Routes from './routes'

import Toastr from '../common/toastr'

class Main extends Component {
  render() {
    return (
      <div>
        <Routes />
        <Toastr />
      </div>
    )
  }
}

export default Main;

import React, { useEffect, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { fetchClienteById, clearClientes } from '../clienteActions'
import { changePassword } from '../../user/userActions'

import { Form } from '@unform/web'
import Input from '../../form/input'
import * as Yup from 'yup'

import Breadcrumb from '../../template/breadcrumb'
import Button from '../../template/button'
import Row from '../../template/row'
import Grid from '../../template/grid'

export default function ChangePasswordForm({ match, ...props }) {
  const { id } = match.params;
  const formRef = useRef(null);

  // i18n
  const { t } = useTranslation();

  // application state
  const dispatch = useDispatch();
  const clienteState = useSelector(state => state.cliente.cliente);

  // fetch cliente by id
  useEffect(() => {
    dispatch(fetchClienteById(id));

    return () => dispatch(clearClientes());
  }, []);

  async function handleSubmit(data, { reset }) {
    try {
      // validation
      const schema = Yup.object().shape({
        password: Yup.string()
          .required(t("clientes.changePassword.validation.novaSenha.required"))
          .min(6, t("clientes.changePassword.validation.novaSenha.minLength")),
        confirmPassword: Yup.string()
          .required(t("clientes.changePassword.validation.confirmPassword.required"))
          .oneOf([Yup.ref('password')], t("clientes.changePassword.validation.confirmPassword.notEqualTo"))
      });

      await schema.validate(data, { abortEarly: false });

      // change password
      dispatch(changePassword(
        clienteState.user_id,
        clienteState.email,
        data.password,
        data.confirmPassword
      ));

      // redirect
      props.history.push('/clientes');

      // clear form
      reset();

      // clear errors
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // set errors
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  return (
    <div>
      <Breadcrumb title={t("clientes.changePassword.title")} linkTo={`/clientes/${id}/edit`}/>

      <Row>
        <Grid cols="12">
          <div className="form-container">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input type="password" name="password" id="password" label={t("clientes.changePassword.novaSenha.label")}
                placeholder="******">
                <small className="text-muted">
                  <Trans i18nKey="clientes.changePassword.novaSenha.small">trans</Trans>
                </small>
              </Input>

              <Input type="password" name="confirmPassword" id="confirmPassword" label={t("clientes.changePassword.confirmPassword")}
                placeholder="******" />

              <hr className="divider" />
              <Button type="submit" size="block" color="primary">
                <Trans i18nKey="clientes.changePassword.buttonSalvar">trans</Trans>
              </Button>
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  )
}

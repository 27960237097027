import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVisitaById, clearVisitas } from './visitaActions';
import {
  fetchPedidoVendaByVisita,
  clearPedidosVenda,
} from '../pedidoVenda/pedidoVendaActions';
import { clearAll as clearRedirect } from '../router/routerActions';

import { useTranslation, Trans } from 'react-i18next';

import PedidoVendaCard from '../pedidoVenda/pedidoVendaCard';
import Row from '../template/row';
import Grid from '../template/grid';
import Breadcrumb from '../template/breadcrumb';
import ImageGallery from 'react-image-gallery';
import firebase from '../../config/fbConfig';
import moment from 'moment';
import Button from '../template/button';
import { MdPrint } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import Modal from '../template/modal';
import { storage } from '../../config/fbConfig';

import './visitaPage.css';
import CreateGlassMagnifier from '../template/CreateGlassMagnifier';
import SlideWithZoom from '../slideWithZoom/slideWithZoom';

function ButtonPrint() {
  return (
    <Button size='sm' color='secondary' handleclick={() => window.print()}>
      <MdPrint size={18} />
    </Button>
  );
}

export default function VisitaPage(props) {
  // component state
  const [visita, setVisita] = useState(null);
  const [images, setImages] = useState([]);
  const [pedidoVenda, setPedidoVenda] = useState(null);

  // pedido img
  const [pedidoImage, setPedidoImage] = useState(null);

  // application state
  const state = useSelector((state) => state.visita.visita);
  const pedidoVendaState = useSelector(
    (state) => state.pedidoVenda.pedidoVenda
  );
  const redirectTo = useSelector((state) => state.router.redirectTo);
  const dispatch = useDispatch();

  // i18n
  const { t } = useTranslation();

  // fetch visita by id
  useEffect(() => {
    const visitaId = props.match.params.id;

    // fetch visita by id
    dispatch(fetchVisitaById(visitaId));

    return () => dispatch(clearVisitas());
  }, []);

  // find visita images
  useEffect(() => {
    async function setImagesState() {
      // get all visita images paths
      const imagesPaths = getImagesPaths(state);

      // get firebase urls
      const imagesURLs = await getImagesURLs(imagesPaths);

      // format image objects
      const imagesGallery = formatImages(imagesURLs);

      setImages(imagesGallery);
    }

    if (state) {
      // set visita on state
      setVisita(state);

      // set visita images on state
      setImagesState();
    }
  }, [state]);

  // find pedido venda by visita
  useEffect(() => {
    if (state) {
      dispatch(fetchPedidoVendaByVisita(state.id));
    }

    return () => dispatch(clearPedidosVenda());
  }, [state]);

  // set pedido venda
  useEffect(() => {
    if (pedidoVendaState) {
      setPedidoVenda(pedidoVendaState);
    }
  }, [pedidoVendaState]);

  function formatImages(urls) {
    return urls.map((url) => ({
      original: url,
      thumbnail: url,
    }));
  }

  async function getImagesURLs(images) {
    return Promise.all(
      images.map((img) => {
        const storageRef = firebase.storage().ref(img);

        return storageRef.getDownloadURL();
      })
    );
  }

  function getImagesPaths(visita) {
    let visitaImgs = [];
    if (visita.foto_ponto_de_venda) {
      visitaImgs = [...visitaImgs, visita.foto_ponto_de_venda];

      if (visita.foto_ponto_de_venda_2) {
        visitaImgs = [...visitaImgs, visita.foto_ponto_de_venda_2];

        if (visita.foto_ponto_de_venda_3) {
          visitaImgs = [...visitaImgs, visita.foto_ponto_de_venda_3];
        }
      }
    }

    return visitaImgs;
  }

  function handleLoadImage(imagePath) {
    const ref = storage.ref(imagePath);
    ref.getDownloadURL().then((url) => {
      setPedidoImage(url);
    });
  }

  return (
    <>
      {/* TODO: set lat/lng on map? */}
      <div className='top-header'>
        <Breadcrumb
          title={t('visitas.page.title')}
          linkTo={redirectTo || '/'}
          btnFunction={() => dispatch(clearRedirect())}
          component={() => <ButtonPrint />}
        />
      </div>

      {visita ? (
        <div className='visita-page-container'>
          <div className='visita-imagem'>
            <div className='image-gallery'>
              <SlideWithZoom items={images} height={300} />

              {/* <ImageGallery items={ images.map(el => ({original: <CreateGlassMagnifier src={el.original} />, thumbnail: el.thumbnail}) )} showPlayButton={false} showFullscreenButton={false} /> */}
            </div>
            <div className='hidden-image-container'>
              {images.map((img) => (
                <div className='image-box'>
                  <img src={img.original} alt='Imagem do pedido' />
                </div>
              ))}
            </div>
          </div>

          <div className='visita-box'>
            <h4>
              <Trans i18nKey='visitas.page.title2'>trans</Trans>
            </h4>

            <p>
              <Trans i18nKey='visitas.page.data'>
                Visita realizada na data:{' '}
                <span className='font-weight-bold'>
                  {{
                    data: moment(visita.data_visita).format(
                      'DD/MM/YYYY HH:mm:ss'
                    ),
                  }}
                </span>
              </Trans>
            </p>

            <p>
              <Trans i18nKey='visitas.page.estoque_alto.label'>trans</Trans>{' '}
              <span className='font-weight-bold'>
                {visita.estoque_alto
                  ? t('visitas.page.estoque_alto.sim')
                  : t('visitas.page.estoque_alto.nao')}
              </span>
            </p>

            <p>
              <Trans i18nKey='visitas.page.exposicao_completa.label'>
                trans
              </Trans>{' '}
              <span className='font-weight-bold'>
                {visita.exposicao_completa
                  ? t('visitas.page.exposicao_completa.sim')
                  : t('visitas.page.exposicao_completa.nao')}
              </span>
            </p>

            <p>
              <Trans i18nKey='visitas.page.gerente_rejeitou_pedido.label'>
                trans
              </Trans>{' '}
              <span className='font-weight-bold'>
                {visita.gerente_rejeitou_pedido
                  ? t('visitas.page.gerente_rejeitou_pedido.sim')
                  : t('visitas.page.gerente_rejeitou_pedido.nao')}
              </span>
            </p>

            <p>
              <Trans i18nKey='visitas.page.expositor_organizado.label'>
                trans
              </Trans>{' '}
              <span className='font-weight-bold'>
                {visita.expositor_organizado
                  ? t('visitas.page.expositor_organizado.sim')
                  : t('visitas.page.expositor_organizado.nao')}
              </span>
            </p>

            <br />

            <p>
              <Trans i18nKey='visitas.page.obs.label'>trans</Trans>:{' '}
              <span>{visita.obs || t('visitas.page.obs.nenhuma')}</span>
            </p>
          </div>

          <div className='ponto-de-venda-box'>
            <div>
              <h4>
                <Trans i18nKey='visitas.page.pontoDeVenda'>trans</Trans>
              </h4>
              <p>{visita.ponto_de_venda_nome}</p>
            </div>
            <div>
              <h4>
                <Trans i18nKey='visitas.page.pontoDeVendaCidade'>trans</Trans>
              </h4>
              <p>{visita.ponto_de_venda_cidade}</p>
            </div>
            <div>
              <h4>
                <Trans i18nKey='visitas.page.pontoDeVendaEndereco'>trans</Trans>
              </h4>
              <p>{visita.ponto_de_venda_endereco}</p>
            </div>

            <div>
              <h4>
                <Trans i18nKey='visitas.page.representante'>trans</Trans>
              </h4>
              <p>{visita.representante_nome}</p>
            </div>

            <div>
              <h4>
                <Trans i18nKey='visitas.page.fornecedor'>trans</Trans>
              </h4>
              <p>{visita.fornecedor_nome}</p>
            </div>
          </div>
        </div>
      ) : (
        <p>
          <Trans i18nKey='visitas.page.loading'>trans</Trans>
        </p>
      )}

      {/* Pedido de venda */}
      {pedidoVenda ? (
        <Row>
          <Grid cols='12'>
            <div id='accordionPedidoVenda'>
              <PedidoVendaCard
                pedidoVenda={pedidoVenda}
                dataParent='accordionPedidoVenda'
                isShowItems={true}
                isReadOnly={true}
                imgModal='#modalViewImg'
                handleSetImage={handleLoadImage}
              />
            </div>
          </Grid>
        </Row>
      ) : (
        <p>
          <span className='font-weight-bold'>
            <Trans i18nKey='visitas.page.pedidosVenda.notFound'>trans</Trans>
          </span>
        </p>
      )}

      {/* Modal view img */}
      <Modal
        id='modalViewImg'
        title={t('pedidosVenda.list.modalViewImg.title')}
        hideFooter
      >
        {pedidoImage ? (
          <CreateGlassMagnifier
            src={pedidoImage}
            /* className="img-thumbnail img-responsive" */ style={{
              'min-height': '25vh',
              'max-height': '50vh',
            }}
            alt='Pedido img'
          />
        ) : (
          <Skeleton count={1} height={100} />
        )}
      </Modal>
    </>
  );
}

import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import CreateGlassMagnifier from '../template/CreateGlassMagnifier';

function SlideWithZoom({ items, height }) {
  
  const [photo, setPhoto] = useState(null)
  const [slide, setSlide] = useState(null)

  useEffect(() => {
    if (items[0]) {
      setPhoto(items[0].original)
    }
  }, [items])

  useEffect(() => {
    setSlide(items.findIndex(el => el.original === photo))
  }, [photo])
  
  function handleClick(element) {
    setPhoto(element)
    const aux = Number(items.findIndex(el => el.original === element))
    setSlide(aux)
  }

  function handleSlide(choice) {
    if (choice && slide < items.length - 1) {
      setPhoto(items[slide + 1].original)
    }
    else if (slide > 0) setPhoto(items[slide - 1].original)
  }
  
  return (
    <div className={styles.carrossel} >
      <div className={styles.mainSlide}>
        {  items.length > 1 && <BsChevronLeft className={styles.button1} onClick={() => handleSlide(false) }/>}
          <CreateGlassMagnifier src={photo} className={styles.photo} />
        { items.length > 1 && <BsChevronRight className={styles.button2} onClick={() => handleSlide(true) }/>}
      </div>
        <div className={styles.slides} >
          {items  && items.map(el => {
            return <img className={styles.slide} key={el.original} src={el.original} style={{height: '80px', width: '130px' }} onClick={() => handleClick(el.original)} />
          })} 
      </div>
    </div>
  )
}



export default SlideWithZoom

import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import { fetchAllProdutosByPontoDeVenda } from '../produto/produtoActions'
import { fetchRepresentantesByPontoDeVenda, setRepresentante } from '../representante/representanteActions'
import { fetchAllFornecedoresByPontoDeVenda } from './fornecedor/fornecedorActions'
import { searchFeaturesByAddress } from '../geolocation/geolocationActions'
import { openDatabaseIndexedDB } from '../../config/idbConfig'

// i18n
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

const setLogs = logs => {
  return dispatch => {
    return dispatch({
      type: 'SET_PONTO_DE_VENDA_LOGS',
      payload: logs
    })
  }
}

const setCount = count => {
  return dispatch => {
    return dispatch({
      type: 'SET_PONTO_DE_VENDA_COUNT',
      payload: count
    })
  }
}

export const setPontoDeVenda = pontoDeVenda => {
  return dispatch => {
    return dispatch({
      type: 'SET_PONTO_DE_VENDA',
      payload: pontoDeVenda
    })
  }
}

export const setPontosDeVenda = pontosDeVenda => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_PONTOS_DE_VENDA',
      payload: pontosDeVenda
    })
  }
}

export const setRef = ref => {
  return dispatch => {
    return dispatch({
      type: 'SET_PONTO_DE_VENDA_REF',
      payload: ref
    })
  }
}

export const clearRef = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_PONTO_DE_VENDA_REF'
    })
  }
}

export const setWithoutRepresentantes = withoutRepresentantes => {
  return dispatch => {
    return dispatch({
      type: 'SET_PONTO_DE_VENDA_WOREPRESENTANTES',
      payload: withoutRepresentantes
    })
  }
}

export const clearWithoutRepresentantes = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_PONTO_DE_VENDA_WOREPRESENTANTES'
    })
  }
}

export const clearPontosDeVenda = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_PONTOS_DE_VENDA'
    })
  }
}

export const fetchPontosDeVenda = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchAll.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchAll.error.message")
          )
          return dispatch(nop())
        }

        return dispatch([
          setCount(res.data.data.total),
          setPontosDeVenda(res.data.data.data),
          unsetLoading()
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchAll.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchAll.error.message")
        )
        throw err
      })
  }
}

export const fetchCountAllPontosDeVenda = () => {
  return dispatch => {
    return axios.get(`${BASE_URL}/pontosDeVenda/count`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
          return dispatch(nop());
        }

        return dispatch(setCount(res.data.data.count));
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
        throw err;
      })
  }
}

export const fetchPontosDeVendaPageable = (page, itemsPerPage = 15) => {
  return dispatch => {
    if (!page) {
      toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
      return dispatch(nop());
    }

    axios.get(`${BASE_URL}/pontosDeVenda/page/${page}?itemsPerPage=${itemsPerPage}`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
          return dispatch(nop());
        }

        return dispatch([
          setCount(res.data.data.total),
          setPontosDeVenda(res.data.data.data),
        ]);
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
        throw err;
      })
  }
}

export const searchPontosDeVenda = (ref, page = 1, itemsPerPage = 15, withoutRepresentantes = false) => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/search/page/${page}?ref=${ref}&itemsPerPage=${itemsPerPage}&${withoutRepresentantes ? 'withoutRepresentantes=true' : null}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.search.error.title"),
            i18n.t("pontosDeVenda.toastr.search.error.message")
          )
          return dispatch(nop());
        }

        return dispatch([
          setCount(res.data.data.total),
          setPontosDeVenda(res.data.data.data)
        ]);
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.search.error.title"),
          i18n.t("pontosDeVenda.toastr.search.error.message")
        )
        throw err;
      })
  }
}

export const fetchPontosDeVendaToUnattend = () => {
  return dispatch => {
    return axios.get(`${BASE_URL}/pontosDeVenda/un_attended`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.unattend.error.title"),
            i18n.t("pontosDeVenda.toastr.unattend.error.message")
          )
          dispatch(nop());
        }

        dispatch(setPontosDeVenda(res.data.data));
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.unattend.error.title"),
          i18n.t("pontosDeVenda.toastr.unattend.error.message")
        )
        throw err;
      })
  }
}

export const fetchPontosDeVendaJoinRepresentantes = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/join/representantes`)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
          return dispatch(nop());
        }

        return dispatch(setPontosDeVenda(res.data.data));
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda");
        throw err;
      })
  }
}

export const searchPontosDeVendaByRepresentantes = (representante, ref, page = 1, itemsPerPage = 15, latitude, longitude) => {
  return dispatch => {
    if (!representante) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.title"),
        i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.message")
      )
      return dispatch(nop());
    }

    if (ref === null) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.title"),
        i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.message")
      )
      return dispatch(nop());
    }

    let url = `${BASE_URL}/pontosDeVenda/search/representante/${representante.id}/page/${page}?ref=${ref}&itemsPerPage=${itemsPerPage}`;
    if (latitude && longitude) {
      url = `${BASE_URL}/pontosDeVenda/search/representante/${representante.id}/page/${page}?ref=${ref}&itemsPerPage=${itemsPerPage}&lat=${latitude}&lng=${longitude}`;
    }

    axios.get(url)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.title"),
            i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.message")
          )
          return dispatch(nop());
        }

        return dispatch([
          setCount(res.data.data.total),
          setPontosDeVenda(res.data.data.data)
        ]);
      })
      .catch(async err => {
        if (err.message === 'Network Error') {
          // get pontos de venda from idb
          const db = await openDatabaseIndexedDB('franchini-offline');

          const idbPontosDeVenda = await db.getAll('ponto_de_venda');
          const idbPontosDeVendaRepresentantes = await db.getAll('ponto_de_venda_representante');

          // filter pontos de venda by representante
          const pontosDeVendaRepresentantes = idbPontosDeVendaRepresentantes.filter(pvr => pvr.data.representante_id === representante.id);

          const idbFilteredPontosDeVenda = idbPontosDeVenda.filter(item => {
            const contains = pontosDeVendaRepresentantes.find(pvr => pvr.data.ponto_de_venda_id === item.data.id)

            return contains;
          });

          const pontosDeVenda = idbFilteredPontosDeVenda.map(pv => pv.data);

          return dispatch(setPontosDeVenda(pontosDeVenda));
        }

        toastr.error(
          i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.title"),
          i18n.t("pontosDeVenda.toastr.searchByRepresentante.error.message")
        )
        throw err;
      })
  }
}

export const fetchPontosDeVendaJoinFornecedores = (latitude, longitude, representanteId) => {
  return dispatch => {
    let url = ''

    if (representanteId) {
      url = `${BASE_URL}/pontosDeVenda/representante/${representanteId}/join/fornecedores?lat=${latitude}&lng=${longitude}`
    } else {
      url = `${BASE_URL}/pontosDeVenda/join/fornecedores?lat=${latitude}&lng=${longitude}`
    }

    axios.get(url)
      .then(res => {
        if (res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda")
          return dispatch(nop())
        }

        return dispatch([
          setPontosDeVenda(res.data.data),
          unsetLoading()
        ])
      })
      .catch(async err => {
        if (err.message === 'Network Error') {
          // get pontos de venda from idb
          const db = await openDatabaseIndexedDB('franchini-offline');

          const idbPontosDeVenda = await db.getAll('ponto_de_venda');
          const idbPontosDeVendaRepresentantes = await db.getAll('ponto_de_venda_representante');

          // filter pontos de venda by representante
          const pontosDeVendaRepresentantes = idbPontosDeVendaRepresentantes.filter(pvr => pvr.data.representante_id === representanteId);

          const idbFilteredPontosDeVenda = idbPontosDeVenda.filter(item => {
            const contains = pontosDeVendaRepresentantes.find(pvr => pvr.data.ponto_de_venda_id === item.data.id)

            return contains;
          });

          const pontosDeVenda = idbFilteredPontosDeVenda.map(pv => pv.data);

          return dispatch(setPontosDeVenda(pontosDeVenda));
        }

        toastr.error("Oops!", "Tivemos um problema ao buscar os pontos de venda")
        throw err
      })
  }
}

export const fetchPontoDeVendaById = id => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/${id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchById.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchById.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(setPontoDeVenda(res.data.data[0]))
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchById.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchById.error.message")
        )
        throw err
      })
  }
}

export const fetchPontosDeVendaByRepresentantes_nextVisit = (representanteId, ref = '', page = 1, itemsPerPage = 15) => {
  return dispatch => {
    return axios.get(`${BASE_URL}/representantes/${representanteId}/pontosDeVenda/days_next_visit/search?ref=${ref}&page=${page}&itemsPerPage=${itemsPerPage}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchByRepresentanteNextVisit.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchByRepresentanteNextVisit.error.message")
          )
          dispatch(nop())
        }

        dispatch([
          setPontosDeVenda(res.data.data),
          setCount(res.data["_metadata"].totalItems)
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchByRepresentanteNextVisit.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchByRepresentanteNextVisit.error.message")
        )
        throw err
      })
  }
}

export const fetchPontosDeVenda_nextVisit = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/days_next_visit`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchNextVisit.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchNextVisit.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(setPontosDeVenda(res.data.data))
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchNextVisit.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchNextVisit.error.message")
        )
        throw err
      })
  }
}

export const fetchCountPontosDeVenda_nextVisit = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/days_next_visit/count`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchCountNextVisit.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchCountNextVisit.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(setCount(res.data.data.count));
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchCountNextVisit.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchCountNextVisit.error.message")
        )
        throw err
      })
  }
}

export const fetchPontosDeVenda_nextVisitPageable = (page = 1, itemsPerPage = 15) => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/days_next_visit/page/${page}?itemsPerPage=${itemsPerPage}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.message")
          )
          return dispatch(nop());
        }

        return dispatch(setPontosDeVenda(res.data.data));
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.message")
        )
        throw err;
      })
  }
}

export const searchPontosDeVenda_nextVisitPageable = (ref = '', page = 1, itemsPerPage = 15, withoutRepresentantes = false) => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/search/days_next_visit/page/${page}?itemsPerPage=${itemsPerPage}&ref=${ref}&withoutRepresentantes=${withoutRepresentantes}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.title"),
            i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.message")
          )
          return dispatch(nop());
        }

        return dispatch([
          setCount(res.data.data.total),
          setPontosDeVenda(res.data.data.data)
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.title"),
          i18n.t("pontosDeVenda.toastr.fetchNextVisitPageable.error.message")
        )
        throw err;
      })
  }
}

export const fetchLogsPontoDeVenda = pontoDeVendaId => {
  return dispatch => {
    if (!pontoDeVendaId) {
      return dispatch(nop());
    }

    axios.get(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}/logs`)
      .then(res => {
        if (res.data.error) {
          return dispatch(nop());
        }

        return dispatch(setLogs(res.data.data));
      })
      .catch(err => {
        throw err;
      })
  }
}

export const savePontoDeVenda = pontoDeVenda => {
  return async dispatch => {
    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.save.error.title"),
        i18n.t("pontosDeVenda.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!pontoDeVenda.latitude || !pontoDeVenda.longitude) {
      // get latitude and longitude from state
      const response = await searchFeaturesByAddress(pontoDeVenda.endereco)

      if (response.data.error || response.data.data.length <= 0) {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.save.address.error.title"),
          i18n.t("pontosDeVenda.toastr.save.address.error.message")
        )
        return dispatch(nop())
      }

      const feature = response.data.data[0]

      const latitude = feature.center[0]
      const longitude = feature.center[1]

      pontoDeVenda.latitude = latitude
      pontoDeVenda.longitude = longitude
    }

    // save ponto de venda
    axios.post(`${BASE_URL}/pontosDeVenda`, {
      pontoDeVenda
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.save.error.title"),
            i18n.t("pontosDeVenda.toastr.save.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("pontosDeVenda.toastr.save.success.title"),
          i18n.t("pontosDeVenda.toastr.save.success.message")
        )
        return dispatch([
          searchPontosDeVenda('', 1, 15),
          unsetLoading()
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.save.error.title"),
          i18n.t("pontosDeVenda.toastr.save.error.message")
        )
        throw err
      })
  }
}

export const updatePontoDeVenda = pontoDeVenda => {
  return async dispatch => {
    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.update.error.title"),
        i18n.t("pontosDeVenda.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    if ((!pontoDeVenda.latitude || !pontoDeVenda.longitude)
      || (pontoDeVenda.latitude === 0.00 && pontoDeVenda.longitude === 0.00)) {
      // get latitude and longitude from state
      const response = await searchFeaturesByAddress(pontoDeVenda.endereco)

      if (response.data.error || response.data.data.length <= 0) {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.save.address.error.title"),
          i18n.t("pontosDeVenda.toastr.save.address.error.message")
        )
        return dispatch(nop())
      }

      const feature = response.data.data[0]

      const latitude = feature.center[0]
      const longitude = feature.center[1]

      pontoDeVenda.latitude = latitude
      pontoDeVenda.longitude = longitude
    }

    // update ponto de venda
    axios.put(`${BASE_URL}/pontosDeVenda`, {
      pontoDeVenda
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.save.error.title"),
            i18n.t("pontosDeVenda.toastr.save.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("pontosDeVenda.toastr.save.success.title"),
          i18n.t("pontosDeVenda.toastr.save.success.message")
        )
        return dispatch([
          searchPontosDeVenda('', 1, 15),
          unsetLoading()
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.save.error.title"),
          i18n.t("pontosDeVenda.toastr.save.error.message")
        )
        throw err
      })
  }
}

export const removePontoDeVenda = pontoDeVenda => {
  return dispatch => {
    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.remove.error.title"),
        i18n.t("pontosDeVenda.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    axios.delete(`${BASE_URL}/pontosDeVenda`, {
      headers: {},
      data: {
        pontoDeVenda
      }
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.remove.error.title"),
            i18n.t("pontosDeVenda.toastr.remove.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("pontosDeVenda.toastr.save.success.title"),
          i18n.t("pontosDeVenda.toastr.save.success.message")
        )
        return dispatch([
          searchPontosDeVenda('', 1, 15),
          unsetLoading()
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.remove.error.title"),
          i18n.t("pontosDeVenda.toastr.remove.error.message")
        )
        throw err
      })
  }
}

export const bindPontoDeVendaToProduto = (pontoDeVenda, produto) => {
  return dispatch => {
    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.bindProduto.error.title"),
        i18n.t("pontosDeVenda.toastr.bindProduto.error.message")
      )
      return dispatch(nop())
    }

    if (!produto) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.bindProduto.error.title"),
        i18n.t("pontosDeVenda.toastr.bindProduto.error.message")
      )
      return dispatch(nop())
    }

    axios.post(`${BASE_URL}/pontosDeVenda/produtos`, {
      pontoDeVenda,
      produto
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.bindProduto.error.title"),
            i18n.t("pontosDeVenda.toastr.bindProduto.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("pontosDeVenda.toastr.bindProduto.success.title"),
          i18n.t("pontosDeVenda.toastr.bindProduto.success.message")
        )
        return dispatch(fetchAllProdutosByPontoDeVenda(pontoDeVenda.id))
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.bindProduto.error.title"),
          i18n.t("pontosDeVenda.toastr.bindProduto.error.message")
        )
        throw err
      })
  }
}

export const bindPontoDeVendaToRepresentante = (pontoDeVenda, representante) => {
  return dispatch => {
    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.bindRepresentante.error.title"),
        i18n.t("pontosDeVenda.toastr.bindRepresentante.error.message")
      )
      return dispatch(nop())
    }

    if (!representante) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.bindRepresentante.error.title"),
        i18n.t("pontosDeVenda.toastr.bindRepresentante.error.message")
      )
      return dispatch(nop())
    }

    axios.post(`${BASE_URL}/pontosDeVenda/representantes`, {
      pontoDeVenda,
      representante
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.bindRepresentante.error.title"),
            i18n.t("pontosDeVenda.toastr.bindRepresentante.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("pontosDeVenda.toastr.bindRepresentante.success.title"),
          i18n.t("pontosDeVenda.toastr.bindRepresentante.success.message")
        )
        return dispatch([
          fetchRepresentantesByPontoDeVenda(pontoDeVenda.id),
          setRepresentante(representante)
        ])
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.bindRepresentante.error.title"),
          i18n.t("pontosDeVenda.toastr.bindRepresentante.error.message")
        )
        throw err
      })
  }
}

export const removeLinkToRepresentante = (pontoDeVendaRepresentanteId) => {
  return dispatch => {
    if (!pontoDeVendaRepresentanteId) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.unlinkRepresentante.error.title"),
        i18n.t("pontosDeVenda.toastr.unlinkRepresentante.error.message")
      )
      return dispatch(nop())
    }

    axios.delete(`${BASE_URL}/pontosDeVenda/representantes/${pontoDeVendaRepresentanteId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.unlinkRepresentante.error.title"),
            i18n.t("pontosDeVenda.toastr.unlinkRepresentante.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(nop())
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.unlinkRepresentante.error.title"),
          i18n.t("pontosDeVenda.toastr.unlinkRepresentante.error.message")
        )
        throw err
      })
  }
}

export const bindPontoDeVendaToFornecedor = (pontoDeVenda, fornecedor) => {
  return dispatch => {
    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.bindFornecedor.error.title"),
        i18n.t("pontosDeVenda.toastr.bindFornecedor.error.message")
      )
      return dispatch(nop())
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.bindFornecedor.error.title"),
        i18n.t("pontosDeVenda.toastr.bindFornecedor.error.message")
      )
      return dispatch(nop())
    }

    axios.post(`${BASE_URL}/pontosDeVenda/fornecedores`, {
      pontoDeVenda,
      fornecedor
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.bindFornecedor.error.title"),
            i18n.t("pontosDeVenda.toastr.bindFornecedor.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("pontosDeVenda.toastr.bindFornecedor.success.title"),
          i18n.t("pontosDeVenda.toastr.bindFornecedor.success.message")
        )
        return dispatch(fetchAllFornecedoresByPontoDeVenda(pontoDeVenda.id))
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.bindFornecedor.error.title"),
          i18n.t("pontosDeVenda.toastr.bindFornecedor.error.message")
        )
        throw err
      })
  }
}

export const unbindFornecedorFromPontoDeVenda = (id, pontoDeVenda) => {
  return dispatch => {
    if (!id) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.unlinkFornecedor.error.title"),
        i18n.t("pontosDeVenda.toastr.unlinkFornecedor.error.message")
      )
      return dispatch(nop());
    }

    axios.delete(`${BASE_URL}/pontosDeVenda/fornecedores/${id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.unlinkFornecedor.error.title"),
            i18n.t("pontosDeVenda.toastr.unlinkFornecedor.error.message")
          )
          return dispatch(nop());
        }

        return dispatch(fetchAllFornecedoresByPontoDeVenda(pontoDeVenda.id));
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.unlinkFornecedor.error.title"),
          i18n.t("pontosDeVenda.toastr.unlinkFornecedor.error.message")
        )
        throw err;
      })
  }
}

export const unbindProdutoFromPontoDeVenda = (produtoId, pontoDeVenda) => {
  return dispatch => {
    if (!produtoId) {
      toastr.error(
        i18n.t("pontosDeVenda.toastr.unlinkProduto.error.title"),
        i18n.t("pontosDeVenda.toastr.unlinkProduto.error.message")
      );
      return dispatch(nop());
    }

    axios.delete(`${BASE_URL}/pontosDeVenda/produtos/${produtoId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pontosDeVenda.toastr.unlinkProduto.error.title"),
            i18n.t("pontosDeVenda.toastr.unlinkProduto.error.message")
          );
          return dispatch(nop());
        }

        return dispatch(fetchAllProdutosByPontoDeVenda(pontoDeVenda.id));
      })
      .catch(err => {
        toastr.error(
          i18n.t("pontosDeVenda.toastr.unlinkProduto.error.title"),
          i18n.t("pontosDeVenda.toastr.unlinkProduto.error.message")
        );
        throw err;
      })
  }
}

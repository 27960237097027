import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Button from '../template/button'
import Row from '../template/row'
import Grid from '../template/grid'

function PontoDeVendaSearch({ onClearSearch: handleClearSearch, onSubmit: handleSubmit, showFilters }) {
  const { t: translate } = useTranslation();

  return (
    <>
      <div className="search-container mb-3">
        <form onSubmit={handleSubmit}>
          {showFilters ? (
            <Row>
              <Grid cols="12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="withoutRepresentantes" id="withoutRepresentantes" />
                  <label className="form-check-label" htmlFor="withoutRepresentantes">
                    {translate("pontosDeVenda.list.search.notLinked")} <small className="text-muted secondary">{translate("pontosDeVenda.list.search.filterClick")}</small>
                  </label>
                </div>
              </Grid>
            </Row>
          ) : null}

          <Row>
            <Grid cols="12 10">
              <input type="search" className="form-control mb-2" name="ref" id="ref"
                placeholder={translate("pontosDeVenda.list.search.placeholder")} />
            </Grid>

            <Grid cols="12 2">
              <div className="d-flex justify-content-between">
                <Button color="secondary" className="d-flex mb-2" title={translate("pontosDeVenda.list.search.buttonSearch.title")}>
                  <i className="material-icons ml-1">search</i>
                </Button>

                <Button type="button" color="danger" className="d-flex mb-2"
                  handleclick={handleClearSearch} title={translate("pontosDeVenda.list.search.buttonClear.title")}>
                  <i className="material-icons ml-1">clear</i>
                </Button>
              </div>
            </Grid>
          </Row>
        </form>
      </div>
    </>
  )
}

PontoDeVendaSearch.propTypes = {
  /** Form submit function -> perform search */
  onSubmit: PropTypes.func.isRequired,
  /** Clear search params function */
  onClearSearch: PropTypes.func.isRequired,
  /** Show filters? */
  showFilters: PropTypes.bool
}

PontoDeVendaSearch.defaultProps = {
  showFilters: true
}

export default PontoDeVendaSearch;

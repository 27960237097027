import React, { Component } from 'react'
import readXlsxFile from 'read-excel-file'

class Workbook extends Component {
  handleSubmit = async e => {
    e.preventDefault()

    const file = e.target.file.files[0]
    const rows = await readXlsxFile(file)

    const headers = rows[0]

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i];
      let obj = {}

      for (let j = 0; j < headers.length; j++) {
        obj = { 
          ...obj, 
          [headers[j]]: row[j] 
        }
      }

      console.log(obj)
    }

    // readXlsxFile(file).then(rows => {
    //   rows.forEach(cells => {
    //     console.log(cells)
    //   })
    // })
  }

  render() {
    return (
      <div>
        <h4>Upload de arquivos excel</h4>
        <br/>
        <form onSubmit={this.handleSubmit}>
          <input type="file" name="file" id="file"/>
          <br/>
          <button type="submit">enviar</button>
        </form>
      </div>
    )
  }
}

export default Workbook

import React from 'react'
import ReactImageMagnify from 'react-image-magnify';

function CreateGlassMagnifier({ src, className, style, alt }) {
  return (
    <>
     <ReactImageMagnify {...{
       className,
      style,
      smallImage: {
            alt: 'Wristwatch by Ted Baker London',
            isFluidWidth: true,
            src: src
        },
        largeImage: {
          src: src,
          width: 1200,
          height: 1800
        },
        enlargedImagePosition: 'over',
      }} />
    </>
  )
}

export default CreateGlassMagnifier
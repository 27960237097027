export default {
  list: {
    title: 'Manager',
    table: {
      nome: 'Nome',
      telefone: 'Numero di telefono',
      email: 'E-mail',
      recebe_email: 'Email Ricevute',
      acao: 'Azione',
      buttonEditar: 'Modifica',
      buttonRemover: 'Remove',
      nao: 'Non',
      sim: 'Sì'
    },
    modal: {
      title: "Conferma l'azione",
      text: 'Sei sicuro di voler rimuovere questo gestore?',
      buttonRemover: 'Remove',
      buttonCancelar: 'Annullare'
    }
  },

  form: {
    title: 'Nuovo manager',
    h1: 'Registrazione del gestore',
    nome: 'Nome: ',
    email: 'E-mail: ',
    telefone: 'Numero di telefono: ',
    maskTelefone: '+3\\9 999 999 9999',
    recebe_email: {
      label: 'Vuoi ricevere e-mail?',
      sim: 'Sì',
      nao: 'Non'},
    senha: {
      label: 'Password: ',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmarSenha: 'Conferma password',
    buttonProximo: 'Prossimo',
    buttonCadastrar: 'Registrazione',
    validation: {
      password: {
        minLength: {
          title: 'Manca qualcosa',
          message: 'La password deve contenere almeno 6 caratteri'
        },
        notEqualTo: {
          title: 'Manca qualcosa',
          message: 'Le password devono essere le stesse'
        }
      }
    }
  },

  edit: {
    title: 'Modifica manager',
    nome: 'Nome: ',
    telefone: 'Numero di telefono: ',
    email: 'E-mail: ',
    buttonHabilitarCampos: 'Abilita campi',
    buttonCancelar: 'Annullare',
    buttonSalvar: 'Salva',
    recebe_email: {
      label: 'Vuoi ricevere e-mail?',
      sim: 'Sì',
      nao: 'Non'
    },
  },

  toastr: {
    save: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la registrazione del gestore'
      },
      success: {
        title: 'Successo',
        message: 'Manager registrato correttamente'
      }
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del gestore'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il manager'
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del gestore'
      },
      success: {
        title: 'Successo',
        message: 'Rimozione riuscita del gestore'
      }
    }
  }
}
import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'

import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

export const getUsers = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/user`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("login.toastr.fetchUsers.error.title"),
            i18n.t("login.toastr.fetchUsers.error.message")
          )
          return dispatch(nop())
        }

        return dispatch({
          type: 'FETCH_USERS',
          payload: res.data.data
        })
      })
      .catch(err => {
        toastr.error(
          i18n.t("login.toastr.fetchUsers.error.title"),
          i18n.t("login.toastr.fetchUsers.error.message")
        )
        throw err
      })
  }
}

export const setUser = user => {
  return dispatch => {
    return dispatch({
      type: 'SET_USER',
      payload: user
    })
  }
}

// method overloading
export const changePassword = (userId, email, password, confirmPassword, oldPassword) => {
  return dispatch => {
    if (oldPassword) {
      return dispatch(changePasswordWithOldPassword(userId, email, password, confirmPassword, oldPassword))
    }

    return dispatch(changePasswordWithoutOldPassword(userId, email, password, confirmPassword))
  }
}

export const changePasswordWithOldPassword = (userId, email, password, confirmPassword, oldPassword) => {
  return dispatch => {
    if (!userId) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (!email) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (!password) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (password !== confirmPassword) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (!oldPassword) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    axios.put(`${BASE_URL}/password/${userId}`, {
      email,
      password,
      oldPassword
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("login.toastr.changePassword.error.title"),
            i18n.t("login.toastr.changePassword.error.message")
          )
        } else {
          toastr.success(
            i18n.t("login.toastr.changePassword.success.title"),
            i18n.t("login.toastr.changePassword.success.message")
          )
        }

        return dispatch([nop(), unsetLoading()])
      })
      .catch(err => {
        toastr.error(
          i18n.t("login.toastr.changePassword.error.title"),
          i18n.t("login.toastr.changePassword.error.message")
        )
        dispatch(unsetLoading())
        throw err
      })
  }
}

export const changePasswordWithoutOldPassword = (userId, email, password, confirmPassword) => {
  return dispatch => {
    if (!userId) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (!email) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (!password) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if (password !== confirmPassword) {
      toastr.error(
        i18n.t("login.toastr.changePassword.error.title"),
        i18n.t("login.toastr.changePassword.error.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    axios.put(`${BASE_URL}/password/${userId}`, {
      email,
      password
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("login.toastr.changePassword.error.title"),
            i18n.t("login.toastr.changePassword.error.message")
          )
        } else {
          toastr.success(
            i18n.t("login.toastr.changePassword.success.title"),
            i18n.t("login.toastr.changePassword.success.message")
          )
        }

        return dispatch([nop(), unsetLoading()])
      })
      .catch(err => {
        toastr.error(
          i18n.t("login.toastr.changePassword.error.title"),
          i18n.t("login.toastr.changePassword.error.message")
        )
        dispatch(unsetLoading())
        throw err
      })
  }
}

export const validateUser = user => {
  return dispatch => {
    if (!user) {
      toastr.error(
        i18n.t("login.toastr.validateUser.error.title"),
        i18n.t("login.toastr.validateUser.error.message")
      )
      return dispatch(nop())
    }

    return axios.post(`${BASE_URL}/user/validate/${user.id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("login.toastr.validateUser.error.title"),
            i18n.t("login.toastr.validateUser.error.message")
          )
          dispatch(nop())
        }

        toastr.success(
          i18n.t("login.toastr.validateUser.success.title"),
          i18n.t("login.toastr.validateUser.success.message")
        )
        dispatch(getUsers())
      })
      .catch(err => {
        toastr.error(
          i18n.t("login.toastr.validateUser.error.title"),
          i18n.t("login.toastr.validateUser.error.message")
        )
        throw err
      })
  }
}

import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
import loadingSpinnerReducer from '../loadingSpinner/loadingSpinnerReducer'
import authReducer from '../auth/authReducer'
import userReducer from '../user/userReducer'
import gestorReducer from '../gestor/gestorRedeucer'
import representanteReducer from '../representante/representanteReducer'
import fornecedorReducer from '../fornecedor/fornecedorReducer'
import produtoReducer from '../produto/produtoReducer'
import geolocationReducer from '../geolocation/geolocationReducer'
import pontoDeVendaReducer from '../pontoDeVenda/pontoDeVendaReducer'
import pontoDeVendaFornecedorReducer from '../pontoDeVenda/fornecedor/fornecedorReducer'
import contatoReducer from '../pontoDeVenda/contato/contatoReducer'
import tabelaPrecoReducer from '../tabelaPreco/tabelaPrecoReducer'
import visitaReducer from '../visita/visitaReducer'
import pedidoVendaReducer from '../pedidoVenda/pedidoVendaReducer'
import visitacaoReducer from '../visitacao/visitacaoReducer'
import clienteReducer from '../cliente/clienteReducer'
import navbarReducer from '../navbar/navbarReducer'
import routerReducer from '../router/routerReducer'
import expositorListReducer from '../expositor/expositorListReducer'
import newExpositorReducer from '../expositor/newExpositorReducer'
import databaseSyncReducer from '../databaseSync/databaseSyncReducer'

export default combineReducers({
  toastr: toastrReducer,
  auth: authReducer,
  user: userReducer,
  gestor: gestorReducer,
  representante: representanteReducer,
  fornecedor: fornecedorReducer,
  produto: produtoReducer,
  loading: loadingSpinnerReducer,
  geolocation: geolocationReducer,
  pontoDeVenda: pontoDeVendaReducer,
  contato: contatoReducer,
  pontoDeVendaFornecedor: pontoDeVendaFornecedorReducer,
  tabelaPreco: tabelaPrecoReducer,
  visita: visitaReducer,
  pedidoVenda: pedidoVendaReducer,
  visitacao: visitacaoReducer,
  cliente: clienteReducer,
  navbar: navbarReducer,
  router: routerReducer,
  expositor: combineReducers({
    list: expositorListReducer,
    new: newExpositorReducer,
  }),
  databaseSync: databaseSyncReducer
})
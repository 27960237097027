import {
  fetchFornecedoresByPontoDeVendaAndVisitacao,
  setFornecedores,
} from '../pontoDeVenda/fornecedor/fornecedorActions';

import axios from 'axios';
import consts from '../../consts';
import firebase from '../../config/fbConfig';
import i18n from '../../_i18n/i18n';
import { openDatabaseIndexedDB } from '../../config/idbConfig';
import { storeRequestOnIndexedDB } from '../../config/idbConfig';
import { toastr } from 'react-redux-toastr';
import uuid from 'uuid';
import { savePedidoVenda } from '../pedidoVenda/pedidoVendaActions';

const BASE_URL = consts.API_URL;

const nop = () => {
  return (dispatch) => {
    return dispatch({
      type: 'NOP',
    });
  };
};

export const clearVisitas = () => {
  return (dispatch) => {
    return dispatch({
      type: 'CLEAR_VISITAS',
    });
  };
};

export const clearPedidoVendaVisita = () => {
  return (dispatch) => {
    return dispatch({
      type: 'CLEAR_VISITAS_PEDIDO_VENDA',
    });
  };
};

export const setVisitaPedidoVenda = (visita) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITAS_SET_VISITA_PEDIDO_VENDA',
      payload: visita,
    });
  };
};

export const setFornecedorPedidoVenda = (fornecedor) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITAS_SET_FORNECEDOR_PEDIDO_VENDA',
      payload: fornecedor,
    });
  };
};

export const setPontoDeVendaPedidoVenda = (pontoDeVenda) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITAS_SET_PONTO_DE_VENDA_PEDIDO_VENDA',
      payload: pontoDeVenda,
    });
  };
};

export const setVisita = (visita) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_VISITA',
      payload: visita,
    });
  };
};

const setVisitas = (visitas) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_VISITAS',
      payload: visitas,
    });
  };
};

export const setPontoDeVenda = (pontoDeVenda) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITAS_SET_PONTO_DE_VENDA',
      payload: pontoDeVenda,
    });
  };
};

export const setFornecedor = (fornecedor) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITAS_SET_FORNECEDOR',
      payload: fornecedor,
    });
  };
};

export const fetchVisitas = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/visitas`)
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar visitas');
          return dispatch(nop());
        }

        return dispatch(setVisitas(res.data.data));
      })
      .catch((err) => {
        toastr.error('Oops!', 'Tivemos um problema ao buscar visitas');
        throw err;
      });
  };
};

export const fetchVisitaById = (id) => {
  return (dispatch) => {
    if (!id) {
      toastr.error(
        i18n.t('visitas.toastr.fetchById.error.title'),
        i18n.t('visitas.toastr.fetchById.error.message')
      );
      return dispatch(nop());
    }

    axios
      .get(`${BASE_URL}/visitas/${id}`)
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.fetchById.error.title'),
            i18n.t('visitas.toastr.fetchById.error.message')
          );
          return dispatch(nop());
        }

        return dispatch(setVisita(res.data.data[0]));
      })
      .catch((err) => {
        toastr.error(
          i18n.t('visitas.toastr.fetchById.error.title'),
          i18n.t('visitas.toastr.fetchById.error.message')
        );
        throw err;
      });
  };
};

export const fetchVisitasByVisitacao = (visitacao) => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/visitacoes/${visitacao.id}/visitas`)
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.fetchByVisitacao.error.title'),
            i18n.t('visitas.toastr.fetchByVisitacao.error.message')
          );
          return dispatch(nop());
        }

        return dispatch(setVisitas(res.data.data));
      })
      .catch((err) => {
        toastr.error(
          i18n.t('visitas.toastr.fetchByVisitacao.error.title'),
          i18n.t('visitas.toastr.fetchByVisitacao.error.message')
        );
        throw err;
      });
  };
};

export const fetchVisitasByPontoDeVenda = (pontoDeVendaId) => {
  return (dispatch) => {
    if (!pontoDeVendaId) {
      toastr.error(
        i18n.t('visitas.toastr.fetchByPontoDeVenda.error.title'),
        i18n.t('visitas.toastr.fetchByPontoDeVenda.error.message')
      );
      return dispatch(nop());
    }

    axios
      .get(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}/visitas`)
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.fetchByPontoDeVenda.error.title'),
            i18n.t('visitas.toastr.fetchByPontoDeVenda.error.message')
          );
          return dispatch(nop());
        }

        return dispatch(setVisitas(res.data.data));
      })
      .catch((err) => {
        toastr.error(
          i18n.t('visitas.toastr.fetchByPontoDeVenda.error.title'),
          i18n.t('visitas.toastr.fetchByPontoDeVenda.error.message')
        );
        throw err;
      });
  };
};

export const saveImage = (blob, blobName) => {
  return (dispatch) => {
    try {
      // create a storage ref
      const storageRef = firebase.storage().ref(blobName);

      // upload file
      const task = storageRef.put(blob);

      // upload progress bar
      task.on(
        'state_changed',
        function progress(snapshot) {},

        async function error(err) {
          console.log('[FIREBASE] Erro ao salvar imagem da visita: ', err);

          toastr.warning(
            i18n.t('visitas.toastr.saveImage.error.title'),
            i18n.t('visitas.toastr.saveImage.error.message')
          );

          // store the file locally
          try {
            const db = await openDatabaseIndexedDB('franchini-offline');

            await db.add('imagem', {
              timestamp: new Date().getTime(),
              data: {
                blobName,
                blob,
              },
            });
          } catch (e) {
            console.log(
              `%c Erro ao inserir imagem no indexedDB: ${e}`,
              'color: tomato'
            );
          }
        },

        function complete() {
          return dispatch(fetchVisitas());
        }
      );
    } catch (err) {
      console.log('[FIREBASE] Erro ao salvar imagem da visita: ', err);
    }
  };
};

export const visitFornecedor = (visita, pontoDeVenda, fornecedor) => {
  return (dispatch, getState) => {
    const fornecedores = getState().fornecedor.fornecedores.map((f) => {
      if (f.id === fornecedor.id) {
        return {
          ...f,
          visita_fornecedor_id: visita.id,
        };
      }

      return f;
    });

    const updatedPontoDeVenda = {
      ...pontoDeVenda,
      fornecedores,
    };

    return dispatch([
      setFornecedores(fornecedores),
      setPontoDeVenda(updatedPontoDeVenda),
    ]);
  };
};

const setVisitaOnline = (id) => {
  return (dispatch) => {
    if (!id) {
      return dispatch(nop());
    }

    axios
      .post(`${BASE_URL}/set_online/visitas/${id}`)
      .then((res) => {
        return dispatch(nop());
      })
      .catch((err) => {
        return dispatch(nop());
      });
  };
};

export const saveVisita = (
  visita,
  representante,
  pontoDeVenda,
  fornecedor,
  visitacao,
  produtos,
  blobName,
  obs,
  savePedido
) => {
  return (dispatch) => {
    if (!visita) {
      toastr.error(
        i18n.t('visitas.toastr.save.error.title'),
        i18n.t('visitas.toastr.save.error.message')
      );
      return dispatch(nop());
    }

    if (!representante) {
      toastr.error(
        i18n.t('visitas.toastr.save.error.title'),
        i18n.t('visitas.toastr.save.error.message')
      );
      return dispatch(nop());
    }

    if (!pontoDeVenda) {
      toastr.error(
        i18n.t('visitas.toastr.save.error.title'),
        i18n.t('visitas.toastr.save.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t('visitas.toastr.save.error.title'),
        i18n.t('visitas.toastr.save.error.message')
      );
      return dispatch(nop());
    }

    if (!visitacao) {
      toastr.error(
        i18n.t('visitas.toastr.save.error.title'),
        i18n.t('visitas.toastr.save.error.message')
      );
      return dispatch(nop());
    }
    let visitaToSave
    let id
    if(visita.id) {
      id = visita.id
      visitaToSave = visita
    } else{
      id = uuid.v4().replace(/-/g, '');
      visitaToSave = {
        ...visita,
        id
      };
    }

    const req = {
      method: 'post',
      url: `${BASE_URL}/visitas`,
      data: {
        visita: visitaToSave,
        representante,
        pontoDeVenda,
        fornecedor,
        visitacao,
      },
    };

    return axios(req)
      .then(async (res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.save.error.title'),
            i18n.t('visitas.toastr.save.error.message')
          );
          return dispatch(nop());
        }
        // set fornecedor as visited and save on indexedDB
        try {
          const db = await openDatabaseIndexedDB('visitacao');
          await db.delete('fornecedores', fornecedor.id);

          await db.add('fornecedores', {
            id: fornecedor.id,
            timestamp: new Date().getTime(),
            data: {
              ...fornecedor,
              visita_fornecedor_id: id,
            },
          });
        } catch (e) {
          console.log(
            `%c Erro ao inserir fornecedor no indexedDB: ${e}`,
            'color: tomato'
          );
        }

        toastr.success(
          i18n.t('visitas.toastr.save.success.title'),
          i18n.t('visitas.toastr.save.success.message')
        );
        if(savePedido) {
          return dispatch([
            fetchVisitas(),
            fetchFornecedoresByPontoDeVendaAndVisitacao(pontoDeVenda, visitacao),
  
            // visitas are first saved as offline
            setVisitaOnline(id),
  
            savePedidoVenda(fornecedor, representante, pontoDeVenda, visitaToSave, produtos, blobName, obs)
          ]);
        } else {
          return dispatch([
            fetchVisitas(),
            fetchFornecedoresByPontoDeVendaAndVisitacao(pontoDeVenda, visitacao),
            
            // visitas are first saved as offline
            setVisitaOnline(id),
            
            // in case is there any new pedido de venda
            setFornecedorPedidoVenda(fornecedor),
            setPontoDeVendaPedidoVenda(pontoDeVenda),
            setVisitaPedidoVenda(visitaToSave),
          ]);
        }
        })
      .catch((err) => {
        // User is offline -> request failed
        // if (err.message === 'Network Error') {
        //   // save request on indexedDB
        //   storeRequestOnIndexedDB(req);

        //   return dispatch([
        //     // to set fornecedor as visited
        //     visitFornecedor(visitaToSave, pontoDeVenda, fornecedor),

        //     // in case is there any new pedido de venda
        //     setFornecedorPedidoVenda(fornecedor),
        //     setPontoDeVendaPedidoVenda(pontoDeVenda),
        //     setVisitaPedidoVenda(visitaToSave)
        //   ])
        // }
        toastr.error(
          i18n.t('visitas.toastr.save.error.title'),
          i18n.t('visitas.toastr.save.error.message')
        );
        throw err;
      });
  };
};

export const updateVisita = (visita, pontoDeVenda, fornecedor) => {
  return (dispatch) => {
    if (!visita) {
      toastr.error(
        i18n.t('visitas.toastr.update.error.title'),
        i18n.t('visitas.toastr.update.error.message')
      );
      return dispatch(nop());
    }

    if (!pontoDeVenda) {
      toastr.error(
        i18n.t('visitas.toastr.update.error.title'),
        i18n.t('visitas.toastr.update.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t('visitas.toastr.update.error.title'),
        i18n.t('visitas.toastr.update.error.message')
      );
      return dispatch(nop());
    }

    axios
      .put(`${BASE_URL}/visitas`, {
        visita,
        pontoDeVenda,
        fornecedor,
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.update.error.title'),
            i18n.t('visitas.toastr.update.error.message')
          );
          return dispatch(nop());
        }

        toastr.success(
          i18n.t('visitas.toastr.update.success.title'),
          i18n.t('visitas.toastr.update.success.message')
        );
        return dispatch([
          fetchVisitas(),
          setFornecedorPedidoVenda(fornecedor),
          setPontoDeVendaPedidoVenda(pontoDeVenda),
          setVisitaPedidoVenda(visita),
        ]);
      })
      .catch((err) => {
        toastr.error(
          i18n.t('visitas.toastr.update.error.title'),
          i18n.t('visitas.toastr.update.error.message')
        );
        throw err;
      });
  };
};

export const removeVisita = (visita, visitacao) => {
  return (dispatch) => {
    if (!visita) {
      toastr.error(
        i18n.t('visitas.toastr.remove.error.title'),
        i18n.t('visitas.toastr.remove.error.message')
      );
      return dispatch(nop());
    }

    axios
      .delete(`${BASE_URL}/visitas`, {
        headers: {},
        data: {
          visita,
        },
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.remove.error.title'),
            i18n.t('visitas.toastr.remove.error.message')
          );
          return dispatch(nop());
        }

        toastr.success(
          i18n.t('visitas.toastr.remove.success.title'),
          i18n.t('visitas.toastr.remove.success.message')
        );

        if (visitacao) {
          return dispatch(fetchVisitasByVisitacao(visitacao));
        }

        return dispatch(fetchVisitas());
      })
      .catch((err) => {
        // in case a pedidoVenda is blocking this visita from being deleted
        if (err.response.data.error && err.response.data.error.errno === 1451) {
          toastr.error(
            i18n.t('visitas.toastr.remove.block.title'),
            i18n.t('visitas.toastr.remove.block.message')
          );
          throw i18n.t('visitas.toastr.remove.block.message');
        }

        toastr.error(
          i18n.t('visitas.toastr.remove.error.title'),
          i18n.t('visitas.toastr.remove.error.message')
        );
        throw err;
      });
  };
};

export const saveLog = (userId, pvId, fnId, message) => {
  const data = { user_id: userId, pv_id: pvId, fn_id: fnId, error: message };
  console.log('data on savelog: ', data);
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/logs`, data)
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao salvar o log');
          return dispatch(nop());
        }
      })
      .catch((err) => {
        toastr.error('Oops!', 'Tivemos um problema ao salvar o log');
        console.log('error on savelog: ', err);
        return dispatch(nop());
      });
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import axios from 'axios';
import consts from '../../consts';

const BASE_URL = consts.API_URL;

async function changeTimezone(user, tz) {
  // set timezone on localstorage
  localStorage.setItem("timezone", tz);

  try {
    // update user timezone
    await axios.put(`${BASE_URL}/usuarios/${user.id}/locale`, {
      locale: tz
    })
  } catch (e) {
    console.log(`%c Erro ao alterar o timezone do usuario: ${e}`, 'color: tomato');
  }
}

export default function SelectTimezone() {
  const { t } = useTranslation();
  const timezone = localStorage.getItem("timezone");

  const user = useSelector(state => state.auth.user);

  return (
    <div className="p-3">
      <p>{t("settings.timezone.text")}</p>

      <select name="timezone" id="timezone"
        className="form-control"
        defaultValue={timezone}
        onChange={e => changeTimezone(user, e.target.value)}>
        {moment.tz.names().map((tz, i) => <option value={tz} key={i}>{tz}</option>)}
      </select>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import {
  fetchClienteById,
  updateCliente,
  saveImage,
  clearClientes
} from './clienteActions'

import defaultImg from '../common/imgs/default-img.png'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'
import Icon from '../template/icon'
import Row from '../template/row'
import Grid from '../template/grid'
import ImageInput from '../template/imageInput'
import ImageGallery from 'react-image-gallery'
import uuid from 'uuid'
import firebase from '../../config/fbConfig'
import consts from '../../consts'

export default function ClienteEdit({ match, ...props }) {
  const [images, setImages] = useState([{ original: defaultImg, thumbnail: defaultImg }]);
  const [cliente, setCliente] = useState(null);
  const [isReadonly, setReadonly] = useState(true);

  // i18n
  const { t } = useTranslation();

  // application state
  const dispatch = useDispatch();
  const clienteState = useSelector(state => state.cliente.cliente);

  const { id } = match.params;

  // fetch cliente by id
  useEffect(() => {

    dispatch(fetchClienteById(id));

    return () => dispatch(clearClientes());
  }, []);

  // set cliente on component state
  useEffect(() => {
    if (clienteState) {
      setCliente(clienteState);
      loadInfo(clienteState);
      loadImage(clienteState);
    }
  }, [clienteState]);

  function handleSaveImage(clienteNome, image) {
    let blobName = null;

    if (image) {
      const fileExtension = getFileExtension(image.name);
      blobName = `clientes/${clienteNome}/${uuid.v4() + fileExtension}`;
      dispatch(saveImage(image, blobName));
    }

    return blobName;
  }

  function loadImage(cliente) {
    if (!cliente.imagem) {
      return;
    }

    const ref = firebase.storage().ref(cliente.imagem);
    ref.getDownloadURL()
      .then(url => {
        setImages([{ original: url, thumbnail: url }]);
      })
      .catch(err => {
        console.log("Erro ao buscar imagem: ", err);
      })
  }

  function loadInfo(cliente) {
    document.getElementById('nome').value = cliente.nome;
    document.getElementById('email').value = cliente.email;
  }

  function getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'));
  }

  function handleLoadImage(e) {
    const file = e.target.files[0];

    const url = window.URL.createObjectURL(file);

    setImages([{ original: url, thumbnail: url }]);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  function saveCliente() {
    const file_1 = document.getElementById('imagem').files[0];
    const nome = document.getElementById('nome').value;
    const email = document.getElementById('email').value;

    let clienteToSave = {
      ...cliente,
      nome
    }

    if (file_1) {
      const blobName = handleSaveImage(nome, file_1)

      clienteToSave = {
        ...clienteToSave,
        imagem: blobName
      }
    }

    const userToSave = {
      id: cliente.user_id,
      nome,
      email,
      tipo: consts.userType.CL
    }

    // save cliente
    dispatch(updateCliente(clienteToSave, userToSave));

    // redirect the user
    props.history.push('/clientes');
  }

  function ButtonChangePassword() {
    return (
      <Button className="d-flex align-items-center" size="sm" color="secondary"
        handleclick={() => props.history.push(`/clientes/${id}/password`)}>
        <span className="mr-1">
          <Trans i18nKey="clientes.edit.buttonAlterarSenha">trans</Trans>
        </span>
        <Icon icon="edit"/>
      </Button>
    )
  }

  return (
    <div>
      <Breadcrumb title={t("clientes.edit.title")} linkTo="/clientes" component={ButtonChangePassword} />

      <Row>
        <Grid cols="12 6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="nome">
                <Trans i18nKey="clientes.form.nome">trans</Trans>
              </label>
              <input className="form-control" type="text" name="nome" id="nome"
                disabled={isReadonly} />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                <Trans i18nKey="clientes.form.email">trans</Trans>
              </label>
              <input className="form-control" type="email" name="email" id="email"
                disabled={isReadonly} />
            </div>

            {!isReadonly && (
              <ImageInput className="form-group" id="imagem" onLoadImage={handleLoadImage} isRequired={false} />
            )}

            {isReadonly ? (
              <Button size="block" color="primary"
                handleclick={() => setReadonly(false)}>
                  <Trans i18nKey="clientes.edit.buttonHabilitarCampos">trans</Trans>
                </Button>
            ) : (
                <Row>
                  <Grid cols="12 6">
                    <Button color="secondary" size="block"
                      handleclick={() => setReadonly(true)}>
                        <Trans i18nKey="clientes.edit.buttonCancelar">trans</Trans>
                      </Button>
                  </Grid>

                  <Grid cols="12 6">
                    <Button color="primary" size="block"
                      handleclick={saveCliente}>
                        <Trans i18nKey="clientes.edit.buttonSalvar">trans</Trans>
                      </Button>
                  </Grid>
                </Row>
              )}
          </form>
        </Grid>

        <Grid cols="12 6">
          <ImageGallery items={images}
            showFullscreenButton={false}
            showBullets={false}
            showNav={false}
            showPlayButton={false} />
        </Grid>
      </Row>
    </div>
  )
}

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Breadcrumb from '../template/breadcrumb';
import RequestsQueue from './requestsQueue';
import UserOfflineRequests from './userOfflineRequests';
import DatabaseSync from './databaseSync';
import SelectLanguage from './selectLanguage';
import UserGeoposition from './userGeoposition';
import SelectTimezone from './selectTimezone';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class Home extends Component {
  state = {
    render: 'fila_de_requisicoes',
  };

  renderConfig = (screen) => {
    this.setState({
      render: screen,
    });
  };

  render() {
    const { t } = this.props;

    const configs =
      this.props.auth.user.tipo === 'FN' || this.props.auth.user.tipo === 'CL'
        ? [
            {
              name: 'selecionar_idioma',
              title: t('settings.language.title'),
              render: <SelectLanguage key={5} />,
            },
            {
              name: 'select_timezone',
              title: t('settings.timezone.title'),
              render: <SelectTimezone key={7} />,
            },
          ]
        : [
            {
              name: 'fila_de_requisicoes',
              title: t('settings.requestsQueue.title'),
              render: <RequestsQueue key={2} />,
            },
            {
              name: 'requisições_offline',
              title: t('settings.offlineRequests.title'),
              render: <UserOfflineRequests key={3} />,
            },
            {
              name: 'sincronizar_banco_de_dados',
              title: t('settings.databaseSync.title'),
              render: <DatabaseSync key={4} />,
            },
            {
              name: 'selecionar_idioma',
              title: t('settings.language.title'),
              render: <SelectLanguage key={5} />,
            },
            {
              name: 'user_geoposition',
              title: t('settings.geoposition.title'),
              render: <UserGeoposition key={6} />,
            },
            {
              name: 'select_timezone',
              title: t('settings.timezone.title'),
              render: <SelectTimezone key={7} />,
            },
            {
              name: 'mock_pedido_venda',
              title: t('settings.mockPedidoVenda.title'),
              render: <Redirect key={8} to='/mocks/pedido_venda' />,
            },
          ];

    return (
      <>
        <Breadcrumb title={t('settings.title')} />
        <div className='row'>
          <div className='col-md-3'>
            <aside
              className='nav flex-column nav-pills'
              id='v-pills-tab'
              role='tablist'
              aria-orientation='vertical'
            >
              {configs.map((config, key) => (
                <a
                  key={key}
                  className={`nav-link ${
                    this.state.render === config.name ? 'active' : null
                  }`}
                  role='tab'
                  aria-selected={this.state.render === config.name}
                  onClick={() => this.renderConfig(config.name)}
                  href={`#${config.name}`}
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    {config.title}
                  </span>
                </a>
              ))}
            </aside>
          </div>
          <div className='col-md-9'>
            <div className='tab-content' id='v-pills-tabContent'>
              {configs.map((config) =>
                config.name === this.state.render ? config.render : null
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withTranslation()(Home));

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Row from '../template/row'
import Grid from '../template/grid'
import './pontoDeVendaLog.css'

import { useTranslation, Trans } from 'react-i18next'

import {
  fetchLogsPontoDeVenda
} from './pontoDeVendaActions'

import moment from 'moment'

export default function PontoDeVendaLog({ pontoDeVenda }) {
  const [logs, setLogs] = useState([]);

  // i18n
  const { t } = useTranslation();

  // application state
  const dispatch = useDispatch();
  const logsState = useSelector(state => state.pontoDeVenda.logs);

  // find logs by ponto de venda
  useEffect(() => {
    dispatch(fetchLogsPontoDeVenda(pontoDeVenda.id));
  }, []);

  // set logs
  useEffect(() => {
    if (logsState) setLogs(logsState);
  }, [logsState]);

  function renderLogs(logs) {
    return logs.map((log, index) => {
      // check if log is 'Mudança de fornecedor' or 'Mudança de representante'
      const tipo = log.mensagem.toUpperCase().indexOf("FORNECEDOR") !== -1 ? 'FORNECEDOR' : 'REPRESENTANTE';

      // check if log is 'add' or 'remove'
      const action = log.mensagem.toUpperCase().indexOf("ADICIONADO") !== -1 ? 'ADD' : 'REMOVE';

      // build key
      const key = 'pontosDeVenda.edit.logs.list.table'
        .concat(tipo === 'FORNECEDOR' ? '.fornecedor' : '.representante')
        .concat(action === 'ADD' ? '.add' : '.remove');

      // extract fornecedor/representante name from log
      const nome = log.mensagem
        .replace("Mudança de fornecedor: O fornecedor", "")
        .replace("foi removido do ponto de venda", "")
        .replace("foi adicionado ao ponto de venda", "")
        .replace("Mudança de representante: O representante ", "")
        .replace("foi adicionado ao ponto de venda", "")
        .replace("foi removido do ponto de venda", "")
        .trim();

      // split the string to inject the prop into it
      const mensagem = log.mensagem.split(nome);

      return (
        <tr key={index}>
          <td>
            <Trans i18nKey={key} nome={nome}>
              {mensagem[0]}
              {{ nome }}
              {mensagem[1]}
            </Trans>
          </td>
          <td>{moment(log.data).format('DD/MM/YYYY - HH:mm:ss')}</td>
        </tr>
      )
    })
  }

  return (
    <div>
      <Row>
        <Grid cols="12">
          <h4>
            <Trans i18nKey="pontosDeVenda.edit.logs.list.title">trans</Trans>
          </h4>
        </Grid>
      </Row>

      <Row>
        <div className="scroll-container-wrapper">
          <Grid cols="12">
            {logs.length > 0 ? (
              <table className="table table-sm table-fluid table-striped">
                <thead className="table-dark">
                  <tr>
                    <th>
                      <Trans i18nKey="pontosDeVenda.edit.logs.list.table.mensagem">trans</Trans>
                    </th>
                    <th>
                      <Trans i18nKey="pontosDeVenda.edit.logs.list.table.data">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderLogs(logs)}
                </tbody>
              </table>
            ) : <p>{t("pontosDeVenda.edit.logs.list.notFound")}</p>}
          </Grid>
        </div>
      </Row>
    </div>
  )
}

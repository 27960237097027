import React, { Component } from 'react'
import Inputmask from 'inputmask'
import Button from '../template/button'
import defaultImg from '../common/imgs/default-img.png'
import firebase from '../../config/fbConfig'
import uuid from 'uuid'

import { withTranslation, Trans } from 'react-i18next'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import Row from '../template/row'
import Grid from '../template/grid'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateRepresentante, saveImage } from './representanteActions'

class RepresentanteEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReadonly: true
    }
  }

  componentDidMount() {
    if(!this.props.representante) this.props.history.push('/representantes');

    this.loadInfo();
    this.handleLoadImage();

    // Inputmask("+39 999 999 9999").mask(document.getElementById('telefone'))
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  handleChangeImage = e => {
    const file = e.target.files[0]

    if(!file) {
      return
    }

    document.getElementById('imagem_representante').src = window.URL.createObjectURL(file);
  }

  handleLoadImage = () => {
    const { imagem } = this.props.representante

    if(imagem) {
      const storageRef = firebase.storage().ref(imagem)
      storageRef.getDownloadURL()
        .then(url => {
          // set image
          document.getElementById("imagem_representante").src = url
        })
        .catch(err => {
          throw err
        })
    }
  }

  loadInfo = () => {
    const { nome, telefone, email } = this.props.representante

    document.getElementById('nome').value = nome
    document.getElementById('telefone').value = telefone
    document.getElementById('email').value = email
  }

  handleSubmit = e => {
    e.preventDefault()

    let representante = {
      id: this.props.representante.id,
      telefone: e.target.telefone.value,
    }

    const user = {
      id: this.props.representante.user_id,
      nome: e.target.nome.value,
      email: e.target.email.value,
      senha: this.props.representante.senha,
      status: this.props.representante.status,
      tipo: this.props.representante.tipo
    }

    const imagem = e.target.imagem.files[0];

    if(imagem) {
      const blobName = this.handleSaveImage(user, imagem);

      representante = {
        ...representante,
        imagem: blobName
      }
    }

    // update representante
    this.props.updateRepresentante(user, representante)

    // redirect
    this.props.history.push('/representantes')
  }

  handleSaveImage = (user, image) => {
    let blobName = null;

    if (image) {
      const fileExtension = this.getFileExtension(image.name);
      blobName = `representantes/${user.nome}/${uuid.v4() + fileExtension}`;
      this.props.saveImage(image, blobName);
    }

    return blobName;
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  ButtonChangePassword = () => {
    return (
      <button type="button" className="btn btn-sm btn-secondary d-flex"
        onClick={() => this.props.history.push('/representantes/password')}>
        <Trans i18nKey="representantes.edit.buttonAlterarSenha">trans</Trans>
        <i className="material-icons ml-1" style={{ fontSize: 18 }}>edit</i>
      </button>
    )
  }

  render() {
    if (!this.props.representante) {
      return <Redirect to="/representantes" />
    }

    // i18n
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("representantes.edit.title")} linkTo="/representantes" 
          component={this.ButtonChangePassword} />

        <Row>
          <Grid cols="12 6">
            <form onSubmit={this.handleSubmit} className="mt-3">
              <div className="form-group">
                <label htmlFor="nome">
                  <Trans i18nKey="representantes.edit.nome">trans</Trans>
                </label>
                <input type="text" className="form-control" name="nome" id="nome" placeholder="José da Silva" required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="telefone">
                  <Trans i18nKey="representantes.edit.telefone">trans</Trans>
                </label>
                <input type="text" className="form-control" name="telefone" id="telefone" required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="email">
                  <Trans i18nKey="representantes.edit.email">trans</Trans>
                </label>
                <input type="email" className="form-control" name="email" id="email" placeholder="exemplo@mail.com" required readOnly={this.state.isReadonly} />
              </div>

              {
                this.state.isReadonly ? (
                  <button type="button" className="btn btn-primary btn-block"
                    onClick={this.changeReadonly}>
                      <Trans i18nKey="representantes.edit.buttonHabilitarCampos">trans</Trans>
                  </button>
                ) : (
                  <>
                    <Row>
                      <Grid cols="12">
                        <Button type="button" size="block" color="light"
                          style={{ height: '100%', border: '3px solid' }}
                          handleclick={() => document.getElementById('imagem').click()}>
                          <i className="material-icons" style={{ lineHeight: 'inherit', fontSize: '3em' }}>camera_alt</i>
                        </Button>

                        <input type="file" name="imagem" id="imagem"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={this.handleChangeImage} />
                      </Grid>
                    </Row>

                    <Row>
                      <Grid cols="12 6">
                        <Button type="button" color="secondary" size="block"
                          handleclick={this.changeReadonly}>
                            <Trans i18nKey="representantes.edit.buttonCancelar">trans</Trans>
                        </Button>
                      </Grid>
                      <Grid cols="12 6">
                        <Button type="submit" color="primary" size="block">
                          <Trans i18nKey="representantes.edit.buttonSalvar">trans</Trans>
                        </Button>
                      </Grid>
                    </Row>
                  </>
                  )
              }
            </form>
          </Grid>

          <Grid cols="12 6">
            <div className="w-100 d-flex justify-content-center">
              <img id="imagem_representante" className="img-thumbnail img-responsive"
                style={{ height: 450 }}
                src={defaultImg} alt="Foto do representante" />
            </div>
          </Grid>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    representante: state.representante.representante
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  updateRepresentante,
  saveImage,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RepresentanteEdit))

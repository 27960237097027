export default {
  list: {
    title: 'Fornecedores',
    table: {
      nome: 'Nome',
      telefone: 'Telefone',
      email: 'E-mail',
      acao: 'Ação',
      buttonEditar: 'Editar',
      buttonRemover: 'Remover'
    },
    modal: {
      title: 'Confirmar ação',
      text: 'Você tem certeza de que quer remover este fornecedor?',
      buttonCancelar: 'Cancelar',
      buttonRemover: 'Remover'
    }
  },

  form: {
    title: 'Novo fornecedor',
    title2: 'Cadastro de fornecedor',
    nome: 'Nome: ',
    email: 'E-mail: ',
    telefone: 'Telefone: ',
    maskTelefone: '+55 (99) 99999 9999',
    prazoEntrega: {
      label: 'Prazo de entrega',
      small: '(dias)'
    },
    senha: {
      label: 'Senha: ',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmarSenha: 'Confirme a senha: ',
    buttonProximo: 'Próximo',
    buttonCadastrar: 'Cadastrar',
    validation: {
      senha: {
        minLength: {
          title: 'Algo está faltando',
          message: 'Sua senha deve ter no mínimo 6 caracteres'
        },
        notEqualTo: {
          title: 'Algo está faltando',
          message: 'As senhas devem ser iguais'
        }
      },
      imagem: {
        required: {
          title: 'Está faltando algo',
          message: 'Por favor, selecione uma imagem'
        }
      }
    }
  },

  edit: {
    title: 'Editar fornecedor',
    nome: 'Nome: ',
    telefone: 'Telefone: ',
    email: 'E-mail',
    prazoEntrega: {
      label: 'Prazo de entrega',
      small: '(dias)'
    },
    buttonAlterarSenha: 'Alterar senha',
    buttonHabilitarCampos: 'Habilitar campos',
    buttonCancelar: 'Cancelar',
    buttonSalvar: 'Salvar'
  },

  changePassword: {
    title: 'Trocar senha',
    novaSenha: {
      label: 'Nova senha: ',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmarSenha: 'Confirme a senha: ',
    buttonSalvar: 'Salvar',
    validation: {
      password: {
        minLength: {
          title: 'Algo está faltando',
          message: 'A senha deve ter no mínimo 6 caracteres'
        },
        notEqualTo: {
          title: 'Algo está faltando',
          message: 'As senhas devem ser iguais'
        }
      }
    }
  },

  toastr: {
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o fornecedor'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o fornecedor'
      }
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o fornecedor'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o fornecedor'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o fornecedor'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao remover o fornecedor'
      }
    },
    unattend: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o fornecedor'
      },
      info: {
        title: 'Em andamento ...',
        message: 'Sua solicitação foi enviada para a aprovação de um gestor'
      }
    },
    fetchUnattended: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os fornecedores removidos do ponto de venda'
      }
    },
    removeFromPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o fornecedor do ponto de venda'
      }
    },
    attend: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao vincular o fornecedor de volta ao ponto de venda'
      }
    }
  }
}
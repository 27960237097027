const INITIAL_STATE = {
  expositores: [],
}

export default function expositorListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EXPOSITOR_LIST_SET_EXPOSITORES':
      return {
        ...state,
        expositores: action.payload
      }
    case 'EXPOSITOR_LIST_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { storeRequestOnIndexedDB, openDatabaseIndexedDB, clearAll } from '../../config/idbConfig'
import { unsetLoading, setMessage } from '../loadingSpinner/loadingSpinnerActions'
import uuid from 'uuid'
import moment from 'moment-timezone'

// i18n
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return dispatch => {
    return dispatch({
      type: 'NOP'
    })
  }
}

const setPedidoVenda = pedidoVenda => {
  return dispatch => {
    return dispatch({
      type: 'SET_PEDIDO_VENDA',
      payload: pedidoVenda
    })
  }
}

const setPedidosVenda = pedidosVenda => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_PEDIDOS_VENDA',
      payload: pedidosVenda
    })
  }
}

export const setRef = ref => {
  return {
    type: 'SET_PEDIDO_VENDA_SEARCH_REF',
    payload: ref
  }
}

const setTotalItems = count => {
  return {
    type: 'SET_PEDIDO_VENDA_PAGINATION_TOTAL_ITEMS',
    payload: count
  }
}

export const clearSearch = () => {
  return {
    type: 'CLEAR_PEDIDO_VENDA_SEARCH'
  }
}

export const clearPagination = () => {
  return {
    type: 'CLEAR_PEDIDO_VENDA_PAGINATION'
  }
}

export const clearPedidosVenda = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_PEDIDOS_VENDA'
    })
  }
}

export const fetchPedidosVenda = () => {

  return dispatch => {
    axios.get(`${BASE_URL}/pedidosVenda`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.fetchAll.error.title"),
            i18n.t("pedidosVenda.toastr.fetchAll.error.message")
          )
          return dispatch(nop())
        }

        console.log(res.data.data);

        return dispatch(setPedidosVenda(res.data.data))
      })
      .catch(err => {
        if (err.message === 'Network Error') {
          return dispatch(unsetLoading())
        }

        toastr.error(
          i18n.t("pedidosVenda.toastr.fetchAll.error.title"),
          i18n.t("pedidosVenda.toastr.fetchAll.error.message")
        )
        throw err
      })
  }
}

export const fetchPontodeVendaRepresentanteOfPedidosVenda = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontoVendaRepresentante`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.fetchAll.error.title"),
            i18n.t("pedidosVenda.toastr.fetchAll.error.message")
          )
          return dispatch(nop())
        }
        console.log(res.data.data);
        return dispatch(setPedidosVenda(res.data.data))
      })
      .catch(err => {
        if (err.message === 'Network Error') {
          return dispatch(unsetLoading())
        }

        toastr.error(
          i18n.t("pedidosVenda.toastr.fetchAll.error.title"),
          i18n.t("pedidosVenda.toastr.fetchAll.error.message")
        )
        throw err
      })
  }
}

export const fetchPedidoVendaByVisita = visitaId => {
  return dispatch => {
    axios.get(`${BASE_URL}/pedidosVenda/visita/${visitaId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.fetchByVisita.error.title"),
            i18n.t("pedidosVenda.toastr.fetchByVisita.error.message")
          )
          return dispatch(nop())
        }

        if (res.data.data.length <= 0)
          return dispatch(nop())
        return dispatch(setPedidoVenda(res.data.data));
      })
      .catch(err => {
        toastr.error(
          i18n.t("pedidosVenda.toastr.fetchByVisita.error.title"),
          i18n.t("pedidosVenda.toastr.fetchByVisita.error.message")
        )
        throw err;
      })
  }
}

export const search = (ref = '', page = 1, itemsPerPage = 15) => {

  return dispatch => {
    axios.get(`${BASE_URL}/pedidosVenda/search?ref=${ref}&page=${page}&itemsPerPage=${itemsPerPage}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.search.error.title"),
            i18n.t("pedidosVenda.toastr.search.error.message")
          );
          return dispatch(nop());
        }
        console.log(res.data.data);
        return dispatch([
          setPedidosVenda(res.data.data),
          setRef(ref),
          setTotalItems(parseInt(res.headers['pagination-total-count']))
        ]);
      })
      .catch(err => {
        toastr.error(
          i18n.t("pedidosVenda.toastr.search.error.title"),
          i18n.t("pedidosVenda.toastr.search.error.message")
        );
        throw err;
      })
  }
}

export const searchId = (id, ref = '', page = 1, itemsPerPage = 15) => {

  return dispatch => {
    axios.get(`${BASE_URL}/pedidosVenda/search/r/${id}?ref=${ref}&page=${page}&itemsPerPage=${itemsPerPage}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.search.error.title"),
            i18n.t("pedidosVenda.toastr.search.error.message")
          );
          return dispatch(nop());
        }
        console.log(res.data.data);
        return dispatch([
          setPedidosVenda(res.data.data),
          setRef(ref),
          setTotalItems(parseInt(res.headers['pagination-total-count']))
        ]);
      })
      .catch(err => {
        toastr.error(
          i18n.t("pedidosVenda.toastr.search.error.title"),
          i18n.t("pedidosVenda.toastr.search.error.message")
        );
        throw err;
      })
  }
}

const setOnline = id => {
  return dispatch => {
    if (!id) {
      return dispatch(nop())
    }

    axios.post(`${BASE_URL}/set_online/pedidoVenda/${id}`)
      .then(res => {
        return dispatch(nop())
      })
      .catch(err => {
        return dispatch(nop())
      })
  }
}

const languageCode = {
  'pt': 'pt-BR',
  'it': 'it-IT'
}

export const setRedirect = redirect => ({ type: 'SET_PEDIDO_VENDA_REDIRECT', payload: redirect });

export const savePedidoVenda = (fornecedor, representante, pontoDeVenda, visita, produtos, imagePath, obs) => {
  return dispatch => {
    if (!fornecedor) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.save.error.title"),
        i18n.t("pedidosVenda.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!representante) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.save.error.title"),
        i18n.t("pedidosVenda.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.save.error.title"),
        i18n.t("pedidosVenda.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!visita) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.save.error.title"),
        i18n.t("pedidosVenda.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!produtos) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.save.error.title"),
        i18n.t("pedidosVenda.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    const id = uuid.v4().replace(/-/g, '')

    const timezone = localStorage.getItem('timezone') || 'America/Sao_Paulo';

    const data = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss")

    const lang = languageCode[localStorage.getItem('i18nextLng')] || 'pt-BR';

    const req = {
      method: 'post',
      url: `${BASE_URL}/pedidosVenda`,
      headers: {
        "Content-Language": lang
      },
      data: {
        id,
        fornecedor,
        representante,
        pontoDeVenda,
        visita,
        data,
        pedidoVendaProdutos: produtos,
        imagem: imagePath,
        obs
      }
    }

    axios(req)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.save.error.title"),
            i18n.t("pedidosVenda.toastr.save.error.message")
          )
          return dispatch(nop())
        }

        console.log('pedido venda quase no fim - dando certo');
        
        return dispatch([
          fetchPedidosVenda(),
          setRedirect(true),
          // at first, pedidoVenda is saved as offline
          setOnline(id),
        ])
      })
      .catch(err => {
        // if (err.message === 'Network Error') {
        //   // save request on idb
        //   storeRequestOnIndexedDB(req);

        //   return;
        // }

        dispatch(unsetLoading());
        console.log('Erro na requisição do axios ao salvar pedido venda. Loading pausado.');
        toastr.error(
          i18n.t("pedidosVenda.toastr.save.error.title"),
          i18n.t("pedidosVenda.toastr.save.error.message")
        )
        throw err
      })
  }
}

export const updatePedidoVenda = (id, fornecedor, pontoDeVenda, visita) => {
  return dispatch => {
    if (!id) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.update.error.title"),
        i18n.t("pedidosVenda.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.update.error.title"),
        i18n.t("pedidosVenda.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.update.error.title"),
        i18n.t("pedidosVenda.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    if (!visita) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.update.error.title"),
        i18n.t("pedidosVenda.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    axios.put(`${BASE_URL}/pedidosVenda/${id}`, {
      fornecedor,
      pontoDeVenda,
      visita
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.update.error.title"),
            i18n.t("pedidosVenda.toastr.update.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(fetchPedidosVenda())
      })
      .catch(err => {
        toastr.error(
          i18n.t("pedidosVenda.toastr.update.error.title"),
          i18n.t("pedidosVenda.toastr.update.error.message")
        )
        throw err
      })
  }
}

async function clearPedidoVendaIndexedDB(pedidoVenda) {
  try {
    const db = await openDatabaseIndexedDB('pedidoVenda');
    const idbPedidoVenda = await db.getAll('pedidoVenda');

    // only clear if pedido de venda is same as the one being removed
    if (idbPedidoVenda[0].id !== pedidoVenda.id) {
      return;
    }

    await clearAll(db, 'pedidoVenda');

    localStorage.removeItem('isPedidoVenda');
  } catch (e) {
    console.log(`%c [IDB] Erro ao limpar o pedido de venda: ${e}`, 'color: tomato');
  }
}

export const removePedidoVenda = id => {
  return dispatch => {
    if (!id) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.remove.error.title"),
        i18n.t("pedidosVenda.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    return axios.delete(`${BASE_URL}/pedidosVenda/${id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.remove.error.title"),
            i18n.t("pedidosVenda.toastr.remove.error.message")
          )
          return dispatch(nop())
        }

        // find and delete pedido de venda on indexedDB
        clearPedidoVendaIndexedDB({ id });

        toastr.success(
          i18n.t("pedidosVenda.toastr.remove.success.title"),
          i18n.t("pedidosVenda.toastr.remove.success.message")
        )
        return dispatch(search('', 1, 15))
      })
      .catch(err => {
        toastr.error(
          i18n.t("pedidosVenda.toastr.remove.error.title"),
          i18n.t("pedidosVenda.toastr.remove.error.message")
        )
        throw err
      })
  }
}

export const removePedidoByVisitaId = visitaId => {
  return dispatch => {
    if (!visitaId) {
      toastr.error(
        i18n.t("pedidosVenda.toastr.removeByVisita.error.title"),
        i18n.t("pedidosVenda.toastr.removeByVisita.error.message")
      )
      return dispatch(nop());
    }

    return axios.delete(`${BASE_URL}/pedidosVenda/visitas/${visitaId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("pedidosVenda.toastr.removeByVisita.error.title"),
            i18n.t("pedidosVenda.toastr.removeByVisita.error.message")
          )
        }

        return dispatch(nop());
      })
      .catch(err => {
        toastr.error(
          i18n.t("pedidosVenda.toastr.removeByVisita.error.title"),
          i18n.t("pedidosVenda.toastr.removeByVisita.error.message")
        )
        throw err;
      })
  }
}

import React, { Component } from 'react'
import Breadcrumb from '../components/template/breadcrumb'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { recoverPassword } from '../components/auth/authActions'

class PasswordRecovery extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const email = e.target.email.value

    // send email to user
    this.props.recoverPassword(email)

    // redirect the user
    this.props.history.push('/login')
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("login.forgotPassword.title")} />

        <div className="row">
          <div className="col-12">
            <div className="bg-light p-5">
              <p className="text-center">
                <Trans i18nKey="login.forgotPassword.form.email.label">trans</Trans>  
              </p>

              <div className="d-flex" style={{ justifyContent: 'center' }}>
                <form className="form-inline" onSubmit={this.handleSubmit}>
                  <input type="text" className="form-control mr-sm-2" id="email" name="email" 
                    placeholder={t("login.forgotPassword.form.email.placeholder")} />

                  <button type="submit" className="btn btn-primary pull-right">
                    <Trans i18nKey="login.forgotPassword.form.buttonEnviar">trans</Trans>
                  </button>
                </form>            
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ recoverPassword }, dispatch)

export default connect(null, mapDispatchToProps)(withTranslation()(PasswordRecovery))

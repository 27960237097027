import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProdutosByFornecedor, clearAll } from './newExpositorActions'
import defaultImg from '../common/imgs/default-img.png'
import ButtonSelect from '../common/buttonSelect'
import { storage } from 'firebase'
import Skeleton from 'react-loading-skeleton'

function ExpositorProdutoList({ fornecedorId, onSelectProduto }) {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const produtos = useSelector(state => state.expositor.new.produtos);

  useEffect(() => {
    if (isFirst) setFirst(false)
    else setLoading(false);
  }, [produtos]);

  useEffect(() => {
    dispatch(fetchProdutosByFornecedor(fornecedorId));

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    produtos.forEach(produto => {
      if (produto.imagem) {
        storage().ref(produto.imagem).getDownloadURL()
          .then(url => {
            if (document.getElementById(`img-${produto.id}`))
              document.getElementById(`img-${produto.id}`).src = url;
          })
      }
    });
  }, [produtos]);

  function renderProdutos(produtos) {
    return produtos.map(produto => {
      const imgId = 'img-' + produto.id;

      return (
        <div key={produto.id} className="card mb-3" style={{ maxWidth: 540, minWidth: 300 }}>
          <div className="row no-gutters">
            <div className="col-md-4">
              <img id={imgId} src={defaultImg} className="card-img" alt={produto.descricao} />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">{produto.cod_artigo}</h5>
                <p className="card-text">
                  {produto.descricao}
                  <br />
                </p>
                <div className="card-text">
                  <div className="text-right">
                    <ButtonSelect onClick={() => onSelectProduto(produto)} />
                  </div>
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    <b><Trans i18nKey="expositores.form.produtos.card.fornecedor">trans</Trans></b>{produto.fornecedor_nome}
                    {' '}
                    | <b><Trans i18nKey="expositores.form.produtos.card.unidade.label">trans</Trans></b>{produto.unidade}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      {isLoading ? <Skeleton count={6} height={40} /> : produtos.length === 0
        ? <Trans i18nKey="expositores.form.produtos.notFound">trans</Trans>
        : (
          <div className="d-flex card-container">
            {renderProdutos(produtos)}
          </div>
        )}
    </div>
  )
}

ExpositorProdutoList.propTypes = {
  /** Find produtos of this fornecedor */
  fornecedorId: PropTypes.number,
  onSelectProduto: PropTypes.func,
}

export default ExpositorProdutoList;

export default {
  list: {
    title: 'Visitações',
    notFound: 'Nenhuma visitação encontrada ...',
    search: {
      inicio: 'Início',
      fim: 'Fim',
      status: {
        label: 'Status',
        todas: 'Todas',
        iniciada: 'Iniciada',
        finalizada: 'Finalizada',
      },
      placeholder: 'Pesquise por ponto de venda ...',
      buttonPesquisar: {
        title: 'Pesquisar',
      },
      buttonLimpar: {
        title: 'Limpar',
      },
      validation: {
        ref: {
          required: {
            title: 'Está faltando algo',
            message: 'Digite o nome do ponto de venda ou selecione uma data',
          },
        },
      },
    },
    table: {
      codigoVisitacao: 'Cód. visitação',
      pontoDeVenda: 'Ponto de venda',
      cidade: 'Cidade',
      endereco: 'Endereço',
      data: 'Data',
      status: {
        label: 'Status',
        iniciada: 'Iniciada',
        finalizada: 'Finalizada',
        naoIniciada: 'Não iniciada',
      },
      acao: 'Ação',
      buttonVisualizar: 'Visualizar',
      toastr: {
        offline: {
          title: 'Você está offline',
          message:
            'Parece que você não tem uma conexão estável com a internet, sua visitação será processada assim que a conexão for restabelecida',
        },
      },
    },
  },

  page: {
    title: 'Informações sobre a visita',
    loading: 'Carregando ...',
    title2: 'Visita',
    data: 'Visita realizada na data: <1>{{data}}</1>',
    estoque_alto: {
      label: 'Estoque alto?',
      sim: 'Sim',
      nao: 'Não',
    },
    exposicao_completa: {
      label: 'Exposição completa - sem esclarecimento?',
      sim: 'Sim',
      nao: 'Não',
    },
    gerente_rejeitou_pedido: {
      label: 'Gerente recusou pedido de venda?',
      sim: 'Sim',
      nao: 'Não',
    },
    expositor_organizado: {
      label: 'Expositor reorganizou produtos?',
      sim: 'Sim',
      nao: 'Não',
    },
    obs: {
      label: 'Observações',
      nenhuma: 'Nenhuma'
    },
    pontoDeVenda: 'Ponto de venda',
    pontoDeVendaCidade: 'Cidade',
    pontoDeVendaEndereco: 'Endereço',
    representante: 'Representante',
    fornecedor: 'Fornecedor',
    pedidosVenda: {
      notFound: 'Nenhum pedido de venda ...',
    },
  },

  wizard: {
    new: {
      title: 'Nova visita',
      selectPontoDeVenda: {
        title: 'Selecione o ponto de venda para iniciar a visitação',
        notFound: 'Nenhum ponto de venda encontrado ...',
        table: {
          nome: 'Nome',
          regiao: 'Região',
          tipo: 'Tipo',
          frequenciaVisita: 'Frequência de visita (d)',
          endereco: 'Endereço',
          obs: 'Observações',
          acao: 'Ação',
          buttonSelecionar: 'Selecionar',
        },
      },
      selectFornecedor: {
        title: 'Fornecedores de:',
        notFound: 'Nenhum fornecedor encontrado ...',
        table: {
          nome: 'Nome',
          visita: 'Visita',
          data: 'Data',
          acao: 'Ação',
          removerFornecedor: 'Remover fornecedor',
          buttonVisitar: 'Visitar',
          modalDelete: {
            title: 'Formulário de exclusão de fornecedor',
            title2: 'Motivo da exclusão:',
            small:
              'Esta exclusão passará por aprovação da equipe gestora da Franchini, você poderá ser indagado a justificar estes argumentos.',
            buttonCancelar: 'Cancelar',
            buttonConfirmar: 'Confirmar',
          },
        },
        buttonCancelar: 'Cancelar',
        buttonEncerrarVisitacao: 'Encerrar visitação',
      },
      form: {
        selecionados: 'Ponto de venda e fornecedor selecionados',
        pontoDeVenda: 'Ponto de venda: ',
        fornecedor: 'Fornecedor: ',
        title: 'Foto do ponto de venda',
        estoque_alto: 'Estoque alto?',
        exposicao_completa: 'Exposição completa - sem esclarecimento?',
        gerente_rejeitou_pedido: 'Gerente recusou pedido de venda?',
        expositor_organizado: 'Expositor reorganizou produtos?',
        obs: 'Observações',
        lancarPedido: 'Lançar pedido de venda?',
        buttonNao: 'Não',
        buttonLancar: 'Lançar',
      },
      toastr: {
        isVisitando: {
          title: 'Visitação já iniciada',
          message: 'Você está continuando uma visitação anteriormente iniciada',
        },
      },
    },

    edit: {
      title: 'Editar visita',
    },
  },

  visitacaoPage: {
    title: 'Voltar às visitações',
    loading: 'Carregando ...',
    card: {
      codigoVisitacao: 'Código da visitação: ',
      text:
        'Visitação ao ponto de venda <1>{{pontoDeVendaNome}}</1> na data <1>{{data}}</1>',
      status: {
        iniciada: 'Iniciada',
        finalizada: 'Finalizada',
        naoIniciada: 'Não iniciada',
      },
      buttonEncerrarVisitacao: 'Encerrar visitação',
      modalRemover: {
        title: 'Confirmar ação',
        text: 'Você tem certeza de que quer remover esta visitação?',
        buttonCancelar: 'Cancelar',
        buttonRemover: 'Remover',
      },
    },
    list: {
      title: 'Visitas',
      notFound: 'Nenhuma visita a ser exibida',
      table: {
        pontoDeVenda: 'Ponto de venda',
        fornecedor: 'Fornecedor',
        representante: 'Representante',
        data: 'Data',
        estoque_alto: {
          label: 'Estoque alto?',
          sim: 'Sim',
          nao: 'Não',
        },
        exposicao_completa: {
          label: 'Exposição completa - sem esclarecimento?',
          sim: 'Sim',
          nao: 'Não',
        },
        gerente_rejeitou_pedido: {
          label: 'Gerente recusou pedido de venda?',
          sim: 'Sim',
          nao: 'Não',
        },
        expositor_organizado: {
          label: 'Expositor reorganizou produtos?',
          sim: 'Sim',
          nao: 'Não',
        },
        acao: 'Ação',
        buttonVisualizar: 'Visualizar',
        buttonEditar: 'Editar',
        buttonRemover: 'Remover',
        modalRemover: {
          title: 'Confirmar ação',
          text: 'Você tem certeza de que quer remover esta visita?',
          buttonCancelar: 'Cancelar',
          buttonRemover: 'Remover',
        },
      },
    },
  },

  toastr: {
    fetchById: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar a visita',
      },
    },
    fetchByVisitacao: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar visitas',
      },
    },
    fetchByPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar visitas',
      },
    },
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar a visita. Verifique sua conexão e tente novamente',
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar visita',
      },
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar a visita',
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar visita',
      },
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover a visita',
      },
      success: {
        title: 'Sucesso',
        message: 'Visita removida com sucesso',
      },
      block: {
        title: 'Oops!',
        message: 'Falha ao remover visita pois existem pedidos de venda vinculados à uma das visitas',
      },
    },
    close: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto problemi a chiudere la visita',
      },
      success: {
        title: 'Sucesso',
        message: 'Visita finalizzata',
      },
    },
    saveImage: {
      error: {
        title: 'Erro ao salvar imagem',
        message:
          'Tivemos um problema ao salvar imagem. Salvamos sua imagem localmente para tentar novamente mais tarde, para maior segurança salve-a na galeria',
      },
    },
  },
};

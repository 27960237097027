import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types'

import EstandeCard from './estandeCard';
import EstandeRow from './estandeRow';
import RowLabel from './rowLabel';
import ButtonAdd from '../common/buttonAdd'
import ButtonRemove from '../common/buttonRemove'

import './expositor.css'
import { useTranslation } from 'react-i18next';

const columnLabels = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G',
  'H', 'I', 'J', 'K', 'L', 'M', 'N',
  'O', 'P', 'Q', 'R', 'S', 'T', 'U',
  'V', 'W', 'X', 'Y', 'Z'
];

function Expositor({ data, rows, isBuildingExpositor, onAddColumn, onRemoveColumn, onAddRow, onRemoveRow, onChangeQuantidade }) {
  const [selected, setSelected] = useState(null);

  const { t } = useTranslation();

  function onSelect(item) {
    setSelected(item);
  }

  const defaultRows = rows || [...new Set(data.map(item => item.pos[0]))];

  return defaultRows.map(row => {
    const column = data.filter(item => item.pos[0] === row);
    const isLastRow = defaultRows[defaultRows.length - 1] === row;
    const isFirstRow = defaultRows[0] === row;

    return (
      <Fragment key={row}>
        
        <RowLabel row={row} />
        <EstandeRow>
          {(column.length === 0 && isBuildingExpositor) && <EstandeCard empty />}

          {column.map((item, index) => (
            <div className="estande-card-container" key={index}>
              {isBuildingExpositor && <span className="estande-card-remove" onClick={() => onRemoveColumn(item)}>x</span>}

              <EstandeCard readonly={isBuildingExpositor}
                onChangeQuantidade={onChangeQuantidade}
                label={columnLabels[index] || '-'}
                item={item}
                selected={selected === item}
                onSelect={onSelect} />
            </div>
          ))}

          {isBuildingExpositor ? (
            <div className="d-flex align-items-center ml-4">
              <ButtonAdd onClick={() => onAddColumn(row)} />
            </div>
          ) : null}
        </EstandeRow>

        <div className="mt-4 mb-4 d-flex">
          {(isBuildingExpositor && !isFirstRow) ? <ButtonRemove className="mr-1" onClick={() => onRemoveRow(row)} /> : null}
          {(isBuildingExpositor && isLastRow) ? <ButtonAdd onClick={onAddRow} /> : null}
        </div>
      </Fragment>
    )
  })
}

Expositor.propTypes = {
  /** data to be rendered */
  data: PropTypes.array,
  /** rows to map */
  rows: PropTypes.array,
  /** Building expositor mode or pedido venda mode */
  isBuildingExpositor: PropTypes.bool,
  onAddColumn: PropTypes.func,
  onRemoveColumn: PropTypes.func,
  onAddRow: PropTypes.func,
  onRemoveRow: PropTypes.func,
  /** Change quantidade of an item */
  onChangeQuantidade: PropTypes.func,
}

export default Expositor;

import React from 'react'

import './estandeRow.css'

export default function EstandeRow({ children }) {
  return (
    <div className="scroll-container">
      {children}
    </div>
  )
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchFornecedoresByPontoDeVenda } from './fornecedorActions'
import Button from '../../template/button'

class FornecedorList extends Component {
  componentDidMount() {
    if (!this.props.pontoDeVenda) {
      this.props.history.push('/pontosDeVenda')
    }

    this.props.fetchFornecedoresByPontoDeVenda(this.props.pontoDeVenda)
  }

  renderFornecedores = fornecedores => {
    const { t } = this.props;

    return fornecedores.map((fornecedor, index) => {
      return (
        <tr key={index}>
          <td data-label={t("pontosDeVenda.edit.fornecedores.list.table.nome")} className="align-middle" style={{ textAlign: 'start' }}>{fornecedor.nome}</td>
          <td data-label={t("pontosDeVenda.edit.fornecedores.list.table.email")} className="align-middle" style={{ textAlign: 'start' }}>
            {
              fornecedor.email.length > 36 && visualViewport.width <= 600
                ? fornecedor.email.substr(0, 33) + '...'
                : fornecedor.email
            }
          </td>
          <td data-label={t("pontosDeVenda.edit.fornecedores.list.table.expositor")} className="align-middle" style={{ textAlign: 'start' }}>
            {fornecedor.expositor?.nome || 'Nenhum expositor vinculado'}
          </td>
          <td data-label={t("pontosDeVenda.edit.fornecedores.list.table.acao")} className="align-middle" style={{ textAlign: 'start' }}>
            <Button type="button"
              color="info"
              size="sm"
              handleclick={() => this.props.onLinkExpositor(this.props.pontoDeVenda, fornecedor)}>
              <Trans i18nKey="pontosDeVenda.edit.fornecedores.list.table.buttonExpositor">trans</Trans>
            </Button>
          </td>
        </tr>
      )
    })
  }

  render() {
    const fornecedores = this.props.fornecedores || [];

    return (
      <React.Fragment>
        <div className="mb-3 d-flex align-items-center">
          <h4 style={{ flex: 2 }}>
            <Trans i18nKey="pontosDeVenda.edit.fornecedores.list.title">trans</Trans>
          </h4>

          <Link to="/pontosDeVenda/fornecedores" className="btn btn-sm btn-success">
            <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
          </Link>
        </div>

        <div style={{ overflowX: 'auto' }}>
          <table className="table table-sm table-fluid table-striped text-center">
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.fornecedores.list.table.nome">trans</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.fornecedores.list.table.email">trans</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.fornecedores.list.table.expositor">trans</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.fornecedores.list.table.acao">trans</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderFornecedores(fornecedores)}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    fornecedores: state.fornecedor.fornecedores
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchFornecedoresByPontoDeVenda,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FornecedorList))

import React from 'react'
import PropTypes from 'prop-types'

import Button from '../template/button'
import { MdAdd } from 'react-icons/md'

function ButtonAdd({ onClick, ...props }) {
  return (
    <Button color="success"
      handleclick={onClick} {...props}>
      <MdAdd size={18} />
    </Button>
  )
}

ButtonAdd.propTypes = {
  onClick: PropTypes.func,
}

export default ButtonAdd;

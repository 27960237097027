import React, { Component } from 'react'
import './login.css'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, Link } from 'react-router-dom'
import { login } from '../components/auth/authActions'
import { setLoading } from '../components/loadingSpinner/loadingSpinnerActions'

import { withTranslation, Trans } from 'react-i18next'

export class Login extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const user = {
      email: e.target.email.value,
      senha: e.target.password.value
    }

    this.props.setLoading()
    this.props.login(user)
  }
  
  render() {
    if(this.props.auth.isAuthenticated) return <Redirect to="/" />

    // i18n
    const { t: translate } = this.props;

    return (
      <div className="fake-background">
        <div className="box-login bg-light p-5">
          <h3 className="mb-4">
            <Trans i18nKey="login.form.title">trans</Trans>
          </h3>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">
                <Trans i18nKey="login.form.email.label">trans</Trans>
              </label>
              <input type="email" className="form-control" name="email" id="email" 
                placeholder={translate("login.form.email.placeholder")} required />
            </div>
    
            <div className="form-group">
              <label htmlFor="password">
                <Trans i18nKey="login.form.senha.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="password" id="password" 
                placeholder={translate("login.form.senha.placeholder")} required />
            </div>
    
            <small>
              <Link className="secondary" to="/passwordRecovery">
                <Trans i18nKey="login.form.forgotPassword">trans</Trans>
              </Link>
            </small>
            
            <hr className="divider"/>
    
            <button type="submit" className="btn btn-primary btn-block mb-3">
              <Trans i18nKey="login.form.buttonLogin">trans</Trans>
            </button>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  login, 
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login))

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../template/button'
import Icon from '../template/icon'

import { openDatabaseIndexedDB } from '../../config/idbConfig'
import moment from 'moment'

export default function UserOfflineRequests() {
  const [requests, setRequests] = useState([]);
  const [db, setDB] = useState(null);

  // i18n
  const { t } = useTranslation();

  useEffect(() => {
    initIndexedDB();
  }, []);

  async function initIndexedDB() {
    const database = await openDatabaseIndexedDB('gpv-offline');

    setDB(database);
  }

  async function getRequests() {
    const data = await db.getAll('request-history');

    setRequests(data);
  }

  function renderRequests(reqArray) {
    return reqArray.map(req =>
      <tr key={req.id}>
        <th scope="col">
          <span className="font-weight-bold">{req.id}</span>
        </th>
        <td>{moment(req.timestamp).format("DD/MM/YYYY - HH:mm:ss")}</td>
        <td>{req.request.method}</td>
        <td>{req.request.url}</td>
        <td>
          <pre>
            {JSON.stringify(req.request.data, undefined, 2)}
          </pre>
        </td>
      </tr>
    )
  }

  return (
    <div className="p-3">
      <Button className="d-flex mb-4 w-200" color="default" size="sm"
        handleclick={getRequests}
        title={t("settings.offlineRequests.buttonRefresh.title")}>
        <Icon icon="refresh" />
      </Button>

      {
        requests.length > 0 ?
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>#</th>
                <th>{t('settings.offlineRequests.table.date')}</th>
                <th>{t('settings.offlineRequests.table.method')}</th>
                <th>{t('settings.offlineRequests.table.url')}</th>
                <th>{t('settings.offlineRequests.table.body')}</th>
              </tr>
            </thead>
            <tbody>
              {renderRequests(requests)}
            </tbody>
          </table> : <p>{t("settings.offlineRequests.notFound")}</p>
      }
    </div>
  )
}

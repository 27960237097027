import React, { Component } from 'react'

import InputMask from 'react-input-mask'

import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withTranslation, Trans } from 'react-i18next'

import { saveContato } from './contatoActions'
import { setLoading } from '../../loadingSpinner/loadingSpinnerActions'
import Breadcrumb from '../../template/breadcrumb'

class ContatoForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nome: '',
      descricao: '',
      telefone: '',
      celular: '',
      cargo: '',
      email: ''
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const pontoDeVenda = this.props.pontoDeVenda
    const contato = {
      ...this.state
    }

    this.props.setLoading()
    this.props.saveContato(contato, pontoDeVenda)

    // redirect to /pontosDeVenda
    this.props.history.push('/pontosDeVenda/edit')
  }

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }
  
  render() {
    if(!this.props.pontoDeVenda) {
      return <Redirect to="/pontosDeVenda" />
    }

    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("pontosDeVenda.edit.contatos.form.title")} linkTo="/pontosDeVenda/edit" />

        <div className="box-login mt-4 mb-3">
          <form onSubmit={this.handleSubmit} className="mt-3">   
            <div className="form-group">
              <label htmlFor="nome">
                <Trans i18nKey="pontosDeVenda.edit.contatos.form.nome.label">trans</Trans>
              </label>
              <input type="text" className="form-control" name="nome" id="nome" 
                placeholder={t("pontosDeVenda.edit.contatos.form.nome.placeholder")}
                onChange={this.handleChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="cargo">
                <Trans i18nKey="pontosDeVenda.edit.contatos.form.cargo.label">trans</Trans>
              </label>
              <input type="text" className="form-control" name="cargo" id="cargo" 
                placeholder={t("pontosDeVenda.edit.contatos.form.cargo.placeholder")}
                onChange={this.handleChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="telefone">
                <Trans i18nKey="pontosDeVenda.edit.contatos.form.telefone">trans</Trans>
              </label>
              <InputMask mask={t("pontosDeVenda.edit.contatos.form.maskTelefone")} maskChar=" " 
                type="text" className="form-control" 
                name="telefone" id="telefone" onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <label htmlFor="celular">
                <Trans i18nKey="pontosDeVenda.edit.contatos.form.celular">trans</Trans>
              </label>
              <InputMask mask={t("pontosDeVenda.edit.contatos.form.maskCelular")} maskChar=" " 
                type="text" className="form-control" 
                name="celular" id="celular" onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                <Trans i18nKey="pontosDeVenda.edit.contatos.form.email.label">trans</Trans>
              </label>
              <input type="email" className="form-control" name="email" id="email" 
                placeholder={t("pontosDeVenda.edit.contatos.form.email.placeholder")}
                onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <label htmlFor="descricao">
                <Trans i18nKey="pontosDeVenda.edit.contatos.form.obs">trans</Trans>
              </label>
              <input type="text" className="form-control" name="descricao" id="descricao"
                onChange={this.handleChange}/>
            </div>
      
            <button type="submit" className="btn btn-primary btn-block">
              <Trans i18nKey="pontosDeVenda.edit.contatos.form.buttonCadastrar">trans</Trans>
            </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    pontoDeVenda: state.pontoDeVenda.pontoDeVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  saveContato, 
  setLoading 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContatoForm))

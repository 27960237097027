import React, { Component } from 'react'
import TabelaPrecoForm from './tabelaPrecoForm'
import SelectProduto from './selectProduto'
import SelectPontoDeVenda from './selectPontoDeVenda'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearPontosDeVenda } from './tabelaPrecoActions'
import TabelaPrecoEdit from './tabelaPrecoEdit'
import Alert from '../template/alert'

export class TabelaPrecoWizard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1
    }
  }

  componentWillUnmount() {
    this.props.clearPontosDeVenda()
  }

  changeStep = step => {
    this.setState({
      ...this.state,
      step
    })
  }

  renderTabelaPrecoForm = () => {
    // in case user is editting, then render the edit form
    if (this.props.tabelaPreco && this.props.tabelaPreco.id) {
      return <TabelaPrecoEdit loadNextStep={() => this.changeStep(2)} />
    }

    return <TabelaPrecoForm loadNextStep={() => this.changeStep(2)} />
  }

  renderSelectProduto = () => {
    return <SelectProduto loadNextStep={() => this.changeStep(3)} previousStep={() => this.changeStep(1)} />
  }

  renderSelectPontoDeVenda = () => {
    return (
      <>
        <Alert color="secondary" text="Produto selecionado: "
          body={`${this.props.produto.descricao} - ${this.props.produto.cod_artigo}`} />
        <SelectPontoDeVenda loadNextStep={() => this.changeStep(1)} previousStep={() => this.changeStep(2)} />
      </>
    )
  }

  render() {
    return (
      <>
        {
          this.state.step === 1 ? this.renderTabelaPrecoForm()
          : this.state.step === 2 ? this.renderSelectProduto()
          : this.renderSelectPontoDeVenda()
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    tabelaPreco: state.tabelaPreco.tabelaPreco,
    produto: state.tabelaPreco.produto
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  clearPontosDeVenda
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TabelaPrecoWizard)

const INITIAL_STATE = {
  gestor: null,
  gestores: []
}

const gestorReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_GESTORES':
      return {
        ...state,
        gestores: action.payload
      }
    case 'SET_GESTOR':
      return {
        ...state,
        gestor: action.payload
      }
    default: 
      return state
  }
}

export default gestorReducer
import React, { Component } from 'react'
import Row from '../template/row'
import Grid from '../template/grid'
import Icon from '../template/icon'
import FlexContainer from '../template/flexContainer'
import defaultImg from '../common/imgs/default-img.png'
import Button from '../template/button'
import uuid from 'uuid'
import firebase from '../../config/fbConfig'

import { withTranslation, Trans } from 'react-i18next'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateFornecedor, saveImage } from './fornecedorActions'

class FornecedorEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReadonly: true
    }
  }

  componentDidMount() {
    this.loadInfo();
    this.handleLoadImage();

    // Inputmask("+39 999 999 9999").mask(document.getElementById('telefone'))
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  handleChangeImage = e => {
    const file = e.target.files[0]

    if (!file) {
      return
    }

    document.getElementById('imagem_fornecedor').src = window.URL.createObjectURL(file);
  }

  handleLoadImage = () => {
    const { imagem } = this.props.fornecedor

    if (imagem) {
      const storageRef = firebase.storage().ref(imagem)
      storageRef.getDownloadURL()
        .then(url => {
          // set image
          document.getElementById("imagem_fornecedor").src = url
        })
        .catch(err => {
          throw err
        })
    }
  }

  loadInfo = () => {
    const { nome, telefone, email, prazo_entrega } = this.props.fornecedor

    document.getElementById('nome').value = nome
    document.getElementById('telefone').value = telefone
    document.getElementById('email').value = email
    document.getElementById('prazo_entrega').value = prazo_entrega
  }

  handleSubmit = e => {
    e.preventDefault()

    let fornecedor = {
      id: this.props.fornecedor.id,
      telefone: e.target.telefone.value,
      prazo_entrega: e.target.prazo_entrega.value
    }

    const user = {
      id: this.props.fornecedor.user_id,
      nome: e.target.nome.value,
      email: e.target.email.value,
      senha: this.props.fornecedor.senha,
      tipo: this.props.fornecedor.tipo,
      status: this.props.fornecedor.status
    }

    const imagem = e.target.imagem.files[0];

    if (imagem) {
      const blobName = this.handleSaveImage(user, imagem);

      fornecedor = {
        ...fornecedor,
        imagem: blobName
      }
    }

    // update fornecedor
    this.props.updateFornecedor(user, fornecedor)

    // redirect
    this.props.history.push('/fornecedores')
  }

  handleSaveImage = (user, image) => {
    let blobName = null;

    if (image) {
      const fileExtension = this.getFileExtension(image.name);
      blobName = `fornecedores/${user.nome}/${uuid.v4() + fileExtension}`;
      this.props.saveImage(image, blobName);
    }

    return blobName;
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  ButtonChangePassword = () => {
    return (
      <Button size="sm" color="secondary" className="d-flex"
        handleclick={() => this.props.history.push('/fornecedores/password')}>
        <span className="mr-1">
          <Trans i18nKey="fornecedores.edit.buttonAlterarSenha">trans</Trans>
        </span> 
        <Icon icon="edit" />
      </Button>
    )
  }

  render() {
    if (!this.props.fornecedor) {
      return <Redirect to="/fornecedores" />
    }

    // i18n
    const { t } = this.props;

    return (
      <>
        <Breadcrumb title={t("fornecedores.edit.title")} linkTo="/fornecedores" component={this.ButtonChangePassword} />

        <Row>
          <Grid cols="12 6">
            <form onSubmit={this.handleSubmit} className="mt-3">
              <div className="form-group">
                <label htmlFor="nome">
                  <Trans i18nKey="fornecedores.edit.nome">trans</Trans>
                </label>
                <input type="text" className="form-control" name="nome" id="nome" placeholder="José da Silva" required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="telefone">
                  <Trans i18nKey="fornecedores.edit.telefone">trans</Trans>
                </label>
                <input type="text" className="form-control" name="telefone" id="telefone" required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="email">
                  <Trans i18nKey="fornecedores.edit.email">trans</Trans>
                </label>
                <input type="email" className="form-control" name="email" id="email" placeholder="exemplo@mail.com" required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="prazo_entrega">
                  <Trans i18nKey="fornecedores.edit.prazoEntrega.label">trans</Trans>
                  {' '}
                  <small className="text-muted">
                    <Trans i18nKey="fornecedores.edit.prazoEntrega.small">trans</Trans>
                  </small>
                </label>
                <input type="number" className="form-control" name="prazo_entrega" id="prazo_entrega" placeholder="10" required readOnly={this.state.isReadonly} />
              </div>

              {
                this.state.isReadonly ? (
                  <Button color="primary" size="block"
                    handleclick={this.changeReadonly}>
                    <Trans i18nKey="fornecedores.edit.buttonHabilitarCampos">trans</Trans>
                  </Button>
                ) : (
                    <>
                      <Row>
                        <Grid cols="12">
                          <Button type="button" size="block" color="light"
                            style={{ height: '100%', border: '3px solid' }}
                            handleclick={() => document.getElementById('imagem').click()}>
                            <i className="material-icons" style={{ lineHeight: 'inherit', fontSize: '3em' }}>camera_alt</i>
                          </Button>

                          <input type="file" name="imagem" id="imagem"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={this.handleChangeImage} />
                        </Grid>
                      </Row>

                      <Row>
                        <Grid cols="12 6">
                          <Button type="button" color="secondary" size="block"
                            handleclick={this.changeReadonly}>
                            <Trans i18nKey="fornecedores.edit.buttonCancelar">trans</Trans>
                          </Button>
                        </Grid>

                        <Grid cols="12 6">
                          <Button type="submit" color="primary" size="block">
                            <Trans i18nKey="fornecedores.edit.buttonSalvar">trans</Trans>
                          </Button>
                        </Grid>
                      </Row>
                    </>
                  )
              }
            </form>
          </Grid>

          <Grid cols="12 6">
            <FlexContainer className="w-100" justifyContentCenter>
              <img id="imagem_fornecedor" className="img-thumbnail img-responsive"
                style={{ height: 450 }}
                src={defaultImg} alt="Foto do fornecedor" />
            </FlexContainer>
          </Grid>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    fornecedor: state.fornecedor.fornecedor
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFornecedor,
  saveImage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FornecedorEdit))

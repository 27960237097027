export default {
  list: {
    title: 'Pontos de venda',
    notFound: 'Nenhum ponto de venda a ser exibido ...',
    search: {
      notLinked: 'Sem representantes vinculados?',
      filterClick: 'Clique em filtrar',
      placeholder: 'Pesquise por cód. identificação, nome, cidade, região, endereço, observações',
      buttonSearch: {
        title: 'Pesquisar'
      },
      buttonClear: {
        title: 'Limpar'
      }
    },
    table: {
      codigoIdentificacao: 'Cód. Identificação',
      nome: 'Nome',
      cidade: 'Cidade',
      estado: 'Estado',
      regiao: 'Região',
      estabelecimento: 'Estabelecimento',
      tamanho: 'Tamanho',
      frequenciaVisita: {
        text: 'Frequência de visita',
        small: '(dias)'
      },
      endereco: 'Endereço',
      obs: 'Observações',
      acao: 'Ação',
      buttonEditar: 'Editar',
      buttonRemover: 'Remover'
    },
    modal: {
      title: 'Confirmar ação',
      text: 'Você tem certeza de que quer remover este ponto de venda?',
      buttonCancelar: 'Cancelar',
      buttonRemover: 'Remover'
    }
  },

  form: {
    title: 'Cadastro de ponto de venda',
    cliente: 'Cliente: ',
    selecione: 'Selecione ...',
    codigoIdentificacao: 'Código de identificação: ',
    nome: {
      label: 'Nome: ',
      placeholder: 'Nome do ponto de venda'
    },
    endereco: {
      label: 'Endereço: ',
      placeholder: 'Rua da Pêra, Bairro Felicidade, Santo Graal - AB'
    },
    cidade: 'Cidade: ',
    estado: 'Estado: ',
    regiao: 'Região: ',
    tipoEstabelecimento: 'Tipo de estabelecimento: ',
    tamanho: 'Tamanho: ',
    frequenciaVisita: {
      label: 'Frequência de visitas',
      small: '(dias)'
    },
    obs: 'Observações',
    buttonCadastrar: 'Cadastrar',
    validation: {
      cliente: {
        required: {
          title: 'Está faltando algo',
          message: 'Selecione o cliente'
        }
      }
    }
  },

  edit: {
    title: 'Editar ponto de venda',
    buttonVincularProdutos: 'Vincular produtos',
    buttonRepresentante: 'Representante: ',
    buttonNoRepresentante: 'Selecionar representante',
    selecione: 'Selecione ...',
    cliente: 'Cliente: ',
    codigoIdentificacao: 'Código de identificação: ',
    nome: 'Nome: ',
    endereco: 'Endereço: ',
    cidade: 'Cidade: ',
    estado: 'Estado: ',
    regiao: 'Região: ',
    tipoEstabelecimento: 'Tipo de estabelecimento: ',
    tamanho: 'Tamanho: ',
    frequenciaVisita: {
      label: 'Frequência de visitas',
      small: '(dias)'
    },
    obs: 'Observações',
    buttonHabilitarCampos: 'Habilitar campos',
    buttonCancelar: 'Cancelar',
    buttonSalvar: 'Salvar',

    produtos: {
      link: {
        title: 'Vincular produtos à',

        search: {
          placeholder: 'Pesquise por produto descrição ou fornecedor ...',
        },

        card: {
          produtoVinculado: 'Produto vinculado',
          fornecedor: 'Fornecedor: ',
          unidade: 'Unidade: ',
          buttonVincular: 'Vincular',
          buttonDesvincular: 'Desvincular'
        }
      }
    },

    representantes: {
      link: {
        title: 'Vincular representantes à',
        table: {
          nome: 'Nome',
          telefone: 'Telefone',
          email: 'E-mail',
          acao: 'Ação',
          representanteVinculado: 'Representante vinculado',
          buttonSelecionar: 'Selecionar',
          buttonDesvincular: 'Desvincular'
        }
      }
    },

    contatos: {
      list: {
        title: 'Contatos',
        table: {
          nome: 'Nome',
          cargo: 'Cargo',
          celular: 'Celular',
          email: 'E-mail',
          obs: 'Obsevações',
          acao: 'Ação',
          buttonEditar: 'Editar',
          buttonRemover: 'Remover'
        },
        modal: {
          title: 'Confirmar ação',
          text: 'Você tem certeza de que quer remover este contato?',
          buttonCancelar: 'Cancelar',
          buttonRemover: 'Remover'
        }
      },
      form: {
        title: 'Cadastro de contato',
        nome: {
          label: 'Nome: ',
          placeholder: 'José da Silva'
        },
        cargo: {
          label: 'Cargo: ',
          placeholder: 'Gerente'
        },
        telefone: 'Telefone: ',
        maskTelefone: '+55 (99) 9999 9999',
        celular: 'Celular',
        maskCelular: '+55 (99) 99999 9999',
        email: {
          label: 'E-mail',
          placeholder: 'exemplo@mail.com'
        },
        obs: 'Observações: ',
        buttonCadastrar: 'Cadastrar'
      },
      edit: {
        title: 'Editar contato',
        nome: 'Nome: ',
        cargo: 'Cargo: ',
        telefone: 'Telefone: ',
        celular: 'Celular: ',
        email: 'E-mail: ',
        obs: 'Observações: ',
        buttonHabilitarCampos: 'Habilitar campos',
        buttonCancelar: 'Cancelar',
        buttonSalvar: 'Salvar'
      }
    },

    fornecedores: {
      list: {
        title: 'Fornecedores',
        table: {
          nome: 'Nome',
          email: 'E-mail',
          expositor: 'Expositor',
          buttonExpositor: 'Vincular expositor',
          acao: 'Ação',
        },
      },
      link: {
        title: 'Vincular fornecedores à',
        table: {
          nome: 'Nome',
          telefone: 'Telefone',
          email: 'E-mail',
          acao: 'Ação',
          buttonDesvincular: 'Desvincular',
          buttonVincular: 'Vincular'
        }
      },
      expositores: {
        title: 'Vincular um expositor ao fornecedor do ponto de venda',
        table: {
          nome: 'Expositor',
          acao: 'Ação',
          buttonVincular: 'Vincular',
          smallVinculado: 'Vinculado'
        },
        notFound: "Nenhum expositor encontrado",
        error: {
          getExpositores: 'Erro ao buscar expositores',
          getExpositorPontoDeVenda: 'Erro ao buscar expositor vinculado ao ponto de venda',
          getPontoDeVenda: 'Erro ao buscar ponto de venda',
          link: 'Erro ao vincular o expositor ao ponto de venda'
        }
      }
    },

    logs: {
      list: {
        title: 'Logs',
        notFound: 'Nenhum log encontrado ...',
        table: {
          mensagem: 'Mensagem',
          data: 'Data',
          representante: {
            add: 'Mudança de representante: O representante {{nome}} foi adicionado ao ponto de venda',
            remove: 'Mudança de representante: O representante {{nome}} foi removido do ponto de venda'
          },
          fornecedor: {
            add: 'Mudança de fornecedor: O fornecedor {{nome}} foi adicionado ao ponto de venda',
            remove: 'Mudança de fornecedor: O fornecedor {{nome}} foi removido do ponto de venda'
          }
        }
      }
    }
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os pontos de venda'
      }
    },
    search: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao pesquisar o ponto de venda'
      }
    },
    fetchUnattend: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os pontos de venda'
      }
    },
    searchByRepresentante: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os pontos de venda'
      }
    },
    fetchById: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o ponto de venda'
      }
    },
    fetchByRepresentanteNextVisit: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os pontos de venda por representante'
      }
    },
    fetchNextVisit: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o tempo para a próxima visita dos pontos de venda'
      }
    },
    fetchCountNextVisit: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o tempo para a próxima visita dos pontos de venda'
      }
    },
    fetchNextVisitPageable: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o tempo para próxima visita dos pontos de venda'
      }
    },
    save: {
      address: {
        error: {
          title: 'Oops!',
          message: 'Não encontramos o endereço informado, você poderia ser mais específico?'
        }
      },
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o ponto de venda'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar ponto de venda'
      }
    },
    update: {
      address: {
        error: {
          title: 'Oops!',
          message: 'Não encontramos o endereço informado, você poderia ser mais específico?'
        }
      },
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o ponto de venda'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar ponto de venda'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o ponto de venda'
      },
      success: {
        title: 'Sucesso',
        message: 'Ponto de venda removido com sucesso'
      }
    },
    bindProduto: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao vincular o ponto de venda ao produto'
      },
      success: {
        title: 'Sucesso',
        message: 'O ponto de venda foi vinculado ao produto selecionado'
      }
    },
    unlinkProduto: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao desvincular o produto do ponto de venda'
      }
    },
    bindRepresentante: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao vincular o ponto de venda ao representante'
      },
      success: {
        title: 'Sucesso',
        message: 'O ponto de venda foi vinculado ao representante selecionado'
      }
    },
    unlinkRepresentante: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o vinculo com representante'
      }
    },
    bindFornecedor: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao vincular o ponto de venda ao fornecedor'
      },
      success: {
        title: 'Sucesso',
        message: 'O ponto de venda foi vinculado ao fornecedor selecionado'
      }
    },
    unlinkFornecedor: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao desvincular o fornecedor do ponto de venda'
      }
    },

    searchLocation: {
      error: {
        title: 'Algo deu errado',
        message: 'Tivemos um problema ao buscar por esse endereço'
      },
      notFound: {
        title: 'Endereço não encontrado',
        message: 'O Google Maps não encontrou o endereço informado'
      },
    }
  }
}
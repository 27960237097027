import React, { Component } from 'react';
import Breadcrumb from '../template/breadcrumb';
import Modal from '../template/modal';
import Button from '../template/button';
import Icon from '../template/icon';
import VisitaList from '../visita/visitaList';

import { withTranslation, Trans } from 'react-i18next';

import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchVisitacoesByRepresentante,
  fetchVisitacoesByFornecedor,
  fetchVisitasByVisitacao,
  fetchVisitacaoById,
  setReturnToSearch,
  removeVisitacao,
  fetchVisitacoes,
  closeVisitacao,
  setVisitacao,
  clearVisitas,
  setEdit,
} from './visitacaoActions';
import {
  fetchFornecedorByUserId,
  clearFornecedores,
} from '../fornecedor/fornecedorActions';
import { setRedirectTo } from '../router/routerActions';
import {
  removeVisita,
  setVisita,
  setPontoDeVenda,
} from '../visita/visitaActions';
import { fetchPontoDeVendaById } from '../pontoDeVenda/pontoDeVendaActions';
import { fetchFornecedoresByPontoDeVendaAndVisitacao } from '../pontoDeVenda/fornecedor/fornecedorActions';
import { openDatabaseIndexedDB, clearAll } from '../../config/idbConfig';
import consts from '../../consts';

export class VisitacaoPage extends Component {
  state = {
    visitacao: null,
    isButtonEncerrarVisitacao: false,
  };

  componentDidMount() {
    if (this.props.role === 'FN') {
      console.log('is fornecedor');
      this.props.fetchFornecedorByUserId(this.props.user.id);
    }
    // fetch visitacao
    this.props.fetchVisitacaoById(this.props.match.params.id);

    // clear visitacao on indexedDB
    this.clearIDB();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitacao !== this.props.visitacao) {
      // fetch visitas by visitacao
      this.props.fetchVisitasByVisitacao(this.props.visitacao);

      // fetch pontoDeVenda (to edit visita)
      this.props.fetchPontoDeVendaById(this.props.visitacao.pontoDeVenda.id);

      // set url to go back
      this.props.setRedirectTo(`/visitacoes/${this.props.visitacao.id}`);
    }

    // fetch fornecedores of this ponto de venda on this visitacao
    if (prevProps.pontoDeVenda !== this.props.pontoDeVenda) {
      const visitacaoId = this.props.match.params.id;
      const pontoDeVendaId = this.props.pontoDeVenda.id;

      this.props.fetchFornecedoresByPontoDeVendaAndVisitacao(
        { id: pontoDeVendaId },
        { id: visitacaoId }
      );
    }

    // check if all fornecedores are visited
    if (prevProps.fornecedores !== this.props.fornecedores) {
      if (this.isAllFornecedoresOk(this.props.fornecedores)) {
        this.setState({ isButtonEncerrarVisitacao: true });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearVisitas();
    this.props.clearFornecedores();
  }

  clearIDB = async () => {
    try {
      // clear visitas and pedidos de venda on idb
      localStorage.removeItem('visitando');
      localStorage.removeItem('isPedidoVenda');

      const dbVisitacao = await openDatabaseIndexedDB('visitacao');
      const dbPedidoVenda = await openDatabaseIndexedDB('pedidoVenda');

      await clearAll(dbVisitacao, 'visitacao');
      await clearAll(dbPedidoVenda, 'pedidoVenda');
    } catch (e) {
      console.log(`%c Erro ao limpar IDB: ${e}`, 'color: tomato');
    }
  };

  isAllFornecedoresOk = (fornecedores) => {
    // if no fornecedores has the visita_fornecedor_id equals to null it will return undefined
    return (
      fornecedores.find(
        (fornecedor) =>
          fornecedor.visita_fornecedor_id === null ||
          fornecedor.visita_fornecedor_id === undefined
      ) === undefined
    );
  };

  closeVisitacao = (visitacao) => {
    this.props.closeVisitacao(visitacao);
  };

  removeVisitacao = (visitacao) => {
    // GE: Pass the fetchVisitacoes action as a callback function to removeVisitacao
    // RP: Pass the fetchVisitacoesByRepresentante action as a callback function to removeVisitacao

    const { RP, GE, FN } = consts.userType;
    const { role, user } = this.props;

    if (role === GE)
      this.props.removeVisitacao(visitacao, () => this.props.fetchVisitacoes());
    if (role === RP)
      this.props.removeVisitacao(visitacao, () =>
        this.props.fetchVisitacoesByRepresentante(user.id)
      );
    if (role === FN)
      this.props.removeVisitacao(visitacao, () =>
        this.props.fetchVisitacoesByFornecedor(user.id)
      );

    // redirect the user
    this.props.history.push('/visitas');
  };

  setVisitacao = (visitacao) => {
    this.setState({
      visitacao,
    });
  };

  removerVisita = (visita) => {
    this.props.removeVisita(visita, this.props.visitacao);
  };

  editVisitacao = (visitacao) => {
    // set editting
    this.props.setEdit(true);

    // set visitaçcao
    this.props.setVisitacao(visitacao);

    // set pontoDeVenda
    this.props.setPontoDeVenda(this.props.pontoDeVenda);

    // redirect the user
    this.props.history.push('/visitas/new');
  };

  render() {
    // styles
    const titleStyle = {
      fontSize: '1.5em',
    };

    const statusStyle = (status) => {
      return {
        fontSize: '1.3em',
        color: status === 'FN' ? 'green' : 'red',
      };
    };

    const visitacaoTitleBoxStyle = {
      padding: 10,
      background: '#fefefe',
      borderRadius: 2,
      boxShadow: '1px 1px 5px 0 rgba(0,0,0,0.3)',
    };

    const { visitacao, t: translate } = this.props;

    if (!visitacao) {
      return <p>{translate('visitas.visitacaoPage.loading')}</p>;
    }

    return (
      <>
        <Breadcrumb
          btnFunction={() => {
            this.props.setReturnToSearch(true);
          }}
          title={translate('visitas.visitacaoPage.title')}
          linkTo='/visitas'
        />
        <div style={visitacaoTitleBoxStyle}>
          <p style={titleStyle}>
            <Trans i18nKey='visitas.visitacaoPage.card.codigoVisitacao'>
              trans
            </Trans>{' '}
            <span className='font-weight-bold'>{visitacao.cod_visitacao}</span>
          </p>
          <p style={titleStyle}>
            <Trans i18nKey='visitas.visitacaoPage.card.text'>
              Visitação ao ponto de venda{' '}
              <span className='font-weight-bold'>
                {{ pontoDeVendaNome: visitacao.pontoDeVenda.nome }}
              </span>{' '}
              na data{' '}
              <span className='font-weight-bold'>
                {{
                  data: moment
                    .utc(visitacao.data)
                    .format('DD/MM/YYYY - HH:mm:ss'),
                }}
              </span>
            </Trans>
          </p>
          <p style={statusStyle(visitacao.status)}>
            {visitacao.status === 'FN'
              ? translate('visitas.visitacaoPage.card.status.finalizada')
              : visitacao.status === 'IN'
              ? translate('visitas.visitacaoPage.card.status.iniciada')
              : translate('visitas.visitacaoPage.card.status.naoIniciada')}
          </p>
          <div className='d-flex justify-content-end'>
            {visitacao.status !== 'FN' &&
            this.state.isButtonEncerrarVisitacao ? (
              <Button
                className='mr-1'
                type='button'
                color='outline-danger'
                size='sm'
                handleclick={() => this.closeVisitacao(visitacao)}
              >
                <Trans i18nKey='visitas.visitacaoPage.card.buttonEncerrarVisitacao'>
                  trans
                </Trans>
              </Button>
            ) : null}

            <Button
              className='mr-1'
              type='button'
              color='warning'
              size='sm'
              handleclick={() => this.editVisitacao(visitacao)}
              disabled={this.props.role === 'FN' || this.props.role === 'CL'}
            >
              <Icon icon='edit' />
            </Button>

            {visitacao.status === 'FN' ? null : (
              <Button
                type='button'
                color='danger'
                size='sm'
                handleclick={() => this.setVisitacao(visitacao)}
                data-toggle='modal'
                data-target='#modalDeletarVisitacao'
                disabled={this.props.role === 'FN' || this.props.role === 'CL'}
              >
                <Icon icon='delete' />
              </Button>
            )}
          </div>
        </div>
        <br />

        <p className='lead'>
          <Trans i18nKey='visitas.visitacaoPage.list.title'>trans</Trans>
        </p>
        <VisitaList
          fornecedor={this.props.fornecedor ? this.props.fornecedor.id : false}
          visitas={this.props.visitas}
          removeVisita={
            this.props.visitacao.status === 'FN' ? false : this.removerVisita
          }
          editarVisita={false}
          loadPontoDeVendaNome={false}
        />
        <Modal
          id='modalDeletarVisitacao'
          title={translate(
            'visitas.visitacaoPage.list.table.modalRemover.title'
          )}
          text={translate('visitas.visitacaoPage.list.table.modalRemover.text')}
          handleClick={() => this.removeVisitacao(this.state.visitacao)}
          size='sm'
          btnColor='danger'
          btnText={translate(
            'visitas.visitacaoPage.list.table.modalRemover.buttonRemover'
          )}
          btnCancelarText={translate(
            'visitas.visitacaoPage.list.table.modalRemover.buttonCancelar'
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visitacao: state.visitacao.visitacao,
    visitas: state.visitacao.visitas,
    pontoDeVenda: state.pontoDeVenda.pontoDeVenda,
    fornecedores: state.fornecedor.fornecedores,
    fornecedor: state.fornecedor.fornecedor,
    user: state.auth.user,
    role: state.auth.user.tipo,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFornecedoresByPontoDeVendaAndVisitacao,
      fetchVisitacoesByRepresentante,
      fetchVisitacoesByFornecedor,
      fetchVisitasByVisitacao,
      fetchFornecedorByUserId,
      clearFornecedores,
      fetchPontoDeVendaById,
      fetchVisitacaoById,
      setReturnToSearch,
      fetchVisitacoes,
      removeVisitacao,
      setPontoDeVenda,
      closeVisitacao,
      setRedirectTo,
      clearVisitas,
      setVisitacao,
      removeVisita,
      setVisita,
      setEdit,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VisitacaoPage));

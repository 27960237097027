import React from 'react';
import PropTypes from 'prop-types';

function getClasses(props) {
  let classes = 'd-flex';
  const {
    alignItemsCenter,
    justifyContentCenter,

    // other classes
    className
  } = props;

  if (alignItemsCenter) classes = classes.concat(' align-items-center');
  if (justifyContentCenter) classes = classes.concat(' justify-content-center');
  if (className) classes = classes.concat(` ${className}`);

  return classes;
}

function FlexContainer({ children, ...props }) {
  return (
    <div className={getClasses(props)}>
      {children}
    </div>
  )
}

FlexContainer.propTypes = {
  children: PropTypes.element.isRequired,
  alignItemsCenter: PropTypes.bool,
  justifyContentCenter: PropTypes.bool,
  className: PropTypes.string,
}

export default FlexContainer;

export const setTables = tables => {
  return {
    type: 'DB_SYNC_SET_TABLES',
    payload: tables
  }
}

export const setTableIsSync = (table, isSync) => {
  return {
    type: 'DB_SYNC_SET_TABLE_IS_SYNC',
    payload: { table, sync: isSync }
  }
}

export const clearAll = () => {
  return {
    type: 'DB_SYNC_CLEAR_ALL'
  }
}

const INITIAL_STATE = {
  pontoDeVenda: null,
  pontosDeVenda: [],
  logs: [],
  count: 0,
  ref: '',
  withoutRepresentantes: false
}

const pontoDeVendaReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_PONTOS_DE_VENDA':
      return {
        ...state,
        pontosDeVenda: action.payload
      }
    case 'SET_PONTO_DE_VENDA':
      return {
        ...state,
        pontoDeVenda: action.payload
      }
    case 'SET_PONTO_DE_VENDA_COUNT':
      return {
        ...state,
        count: action.payload
      }

    case 'SET_PONTO_DE_VENDA_LOGS':
      return {
        ...state,
        logs: action.payload
      }
    case 'SET_PONTO_DE_VENDA_REF':
      return {
        ...state,
        ref: action.payload
      }
    case 'SET_PONTO_DE_VENDA_WOREPRESENTANTES':
      return {
        ...state,
        withoutRepresentantes: action.payload
      }
    case 'CLEAR_PONTO_DE_VENDA_REF':
      return {
        ...state,
        ref: ''
      }
    case 'CLEAR_PONTO_DE_VENDA_WOREPRESENTANTES':
      return {
        ...state,
        withoutRepresentantes: false
      }
    case 'CLEAR_PONTOS_DE_VENDA':
      return {
        ...state,
        pontoDeVenda: null,
        pontosDeVenda: [],
        logs: [],
        count: 0,
        ref: '',
        withoutRepresentantes: false
      }
    default:
      return state
  }
}

export default pontoDeVendaReducer

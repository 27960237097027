import React, { Component } from 'react'
import Modal from '../template/modal'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import CurrencyFormat from 'react-currency-format'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { 
  removeTabelaPreco, 
  fetchTabelasPreco, 
  fetchProdutoByTabelaPreco,
  fetchPontoDeVendaByTabelaPreco,
  setTabelaPreco } from './tabelaPrecoActions'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import Breadcrumb from '../template/breadcrumb'

class TabelaPrecoList extends Component {
  componentDidMount() {
    this.props.setLoading()
  
    this.props.fetchTabelasPreco()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.tabelasPreco !== this.props.tabelasPreco) {
      this.props.unsetLoading()
    }
  }

  constructor(props) {
    super(props)
    
    this.state = {
      tabelaPreco: null
    }
  }

  removerTabelaPreco = tabelaPreco => {
    this.props.removeTabelaPreco(tabelaPreco)
  }

  editarTabelaPreco = tabelaPreco => {
    // set tabela de preco
    this.props.setTabelaPreco(tabelaPreco)

    // set produto
    this.props.fetchProdutoByTabelaPreco(tabelaPreco)

    // set pontoDeVenda
    this.props.fetchPontoDeVendaByTabelaPreco(tabelaPreco)
  }

  setTabelaPreco = tabelaPreco => {
    this.setState({
      ...this.state,
      tabelaPreco
    })
  }

  renderTabelasPreco = () => {
    const tabelasPreco = this.props.tabelasPreco || []

    return tabelasPreco.map(tabelaPreco => {
      return (
        <tr key={tabelaPreco.id}>
          <td data-label="Produto" className="align-middle">{tabelaPreco.produto_descricao}</td>
          <td data-label="Ponto de Venda" className="align-middle">{tabelaPreco.ponto_de_venda_nome}</td>
          <td data-label="Vigência" className="align-middle">
            <Moment date={tabelaPreco.vigencia_inicio} format="DD/MM/YYYY" /> <b>-</b> <Moment date={tabelaPreco.vigencia_fim} format="DD/MM/YYYY" />
          </td>
          <td data-label="Valor" className="align-middle">
            <CurrencyFormat value={tabelaPreco.valor} 
              displayType={'text'} 
              thousandSeparator={'.'} 
              prefix={'R$'}
              decimalSeparator={','} />
          </td>
          <td data-label="Ação" className="align-middle">
            <Link to="/tabelasPreco/save" className="btn btn-sm btn-warning"
              onClick={() => this.editarTabelaPreco(tabelaPreco)}>Editar</Link>
            <button className="ml-2 btn btn-sm btn-danger" data-toggle="modal" data-target="#modalDeletarTabelaPreco"
              onClick={() => this.setTabelaPreco(tabelaPreco)}>Remover</button>
          </td>
        </tr>
      )
    })
  }

  ButtonNew = () => {
    return (
      <Link to="/tabelasPreco/save" className="btn btn-sm btn-success">
        <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
      </Link>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Tabelas de preço" component={this.ButtonNew} />

        <div style={{ overflowX: 'auto' }}>
          <table className="table table-fluid table-striped text-center">
            <thead className="thead-dark">
              <tr>
                <th className="align-middle" scope="col">Produto</th>
                <th className="align-middle" scope="col">Ponto de venda</th>
                <th className="align-middle" scope="col">Vigência</th>
                <th className="align-middle" scope="col">Valor</th>
                <th className="align-middle" scope="col" width="170">Ação</th>
              </tr>
            </thead>
            <tbody>
              {this.renderTabelasPreco()}
            </tbody>
          </table>
        </div>

        {/* Modal */}
        <Modal id="modalDeletarTabelaPreco" 
          title="Confirmar ação" 
          text="Você tem certeza de que quer remover esta tabela de preço?" 
          handleClick={() => this.removerTabelaPreco(this.state.tabelaPreco)} 
          size="sm" 
          btnColor="danger" 
          btnText="Remover" />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    tabelasPreco: state.tabelaPreco.tabelasPreco
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  removeTabelaPreco, 
  fetchTabelasPreco, 
  fetchProdutoByTabelaPreco,
  fetchPontoDeVendaByTabelaPreco,
  setTabelaPreco,
  unsetLoading,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TabelaPrecoList)

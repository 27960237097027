export default {
  list: {
    title: 'Rappresentanti',
    table: {
      nome: 'Nome',
      telefone: 'Numero di telefono',
      email: 'E-mail',
      acao: 'Azione',
      buttonEditar: 'Modifica',
      buttonRemover: 'Remove'
    },
    modal: {
      title: "Conferma l'azione",
      text: 'Sei sicuro di voler rimuovere questo rappresentante?',
      buttonCancelar: 'Annullare',
      buttonRemover: 'Remove'
    }
  },

  form: {
    title: 'Nuovo rappresentante',
    nome: 'Nome: ',
    email: 'E-mail: ',
    telefone: 'Numero di telefono: ',
    maskTelefone: '+3\\9 999 999 9999',
    senha: {
      label: 'Password: ',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmarSenha: 'Conferma password: ',
    buttonProximo: 'Prossimo',
    buttonCadastrar: 'Registrazione',
    validation: {
      password: {
        minLength: {
          title: 'Manca qualcosa',
          message: 'La password deve contenere almeno 6 caratteri'
        },
        notEqualTo: {
          title: 'Manca qualcosa',
          message: 'Le password devono essere le stesse'
        }
      }
    }
  },

  edit: {
    title: 'Modifica rappresentante',
    nome: 'Nome: ',
    telefone: 'Numero di telefono: ',
    email: 'E-mail: ',
    buttonAlterarSenha: 'Cambia password',
    buttonHabilitarCampos: 'Abilita campi',
    buttonCancelar: 'Annullare',
    buttonSalvar: 'Salva'
  },

  changePassword: {
    title: 'Cambia password',
    novaSenha: {
      label: 'Nuova password: ',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmarSenha: 'Conferma password: ',
    buttonSalvar: 'Salva',
    validation: {
      minLength: {
        title: 'Manca qualcosa',
        message: 'La password deve contenere almeno 6 caratteri'
      },
      notEqualTo: {
        title: 'Manca qualcosa',
        message: 'Le password devono essere le stesse'
      }
    }
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto un problema nella ricerca di rappresentanti'
      }
    },
    fetchById: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante la ricerca del rappresentante'
      }
    },
    fetchByUserId: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante la ricerca del rappresentante'
      }
    },
    fetchByPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema nella ricerca di rappresentanti non collegati al punto vendita'
      }
    },
    findOneByPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca del rappresentante di questo punto vendita'
      }
    },
    save: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del rappresentante'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il rappresentante'
      }
    },
    unlinkPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del collegamento tra il rappresentante e il punto vendita'
      }
    },
    attendPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema nel contrassegnare il punto vendita come riparato da un rappresentante'
      }
    },
    unattendPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante lo scollegamento del punto vendita'
      },
      info: {
        title: 'Richiesta inviata',
        message: 'La tua richiesta è stata inviata ai gestori, attendi la tua approvazione'
      }
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del rappresentante'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il rappresentante'
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del rappresentante'
      },
      success: {
        title: 'Successo',
        message: 'Rimozione riuscita del rappresentante'
      }
    }
  }
}
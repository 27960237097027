import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import defaultImg from '../common/imgs/default-img.png'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'
import Alert from '../template/alert'
import uuid from 'uuid'
import firebase from '../../config/fbConfig'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateVisita, saveImage } from './visitaActions'

export class VisitaEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirect: false,

      // to redirect only after data is retrieved from api
      permissionToRedirectToNewPedidoVenda: false,
      redirectToNewPedidoVenda: false,
      isReadonly: true,
      userLocation: {
        latitude: 0.00,
        longitude: 0.00
      }
    }
  }

  componentDidMount() {
    this.loadInfo()
    this.getUserLocation()

    setTimeout(() => {
      this.loadImg()
    }, 500)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.pedidoVenda) {
      return
    }

    if (!this.state.permissionToRedirectToNewPedidoVenda) {
      return
    }

    if (!this.props.pedidoVenda.fornecedor) {
      return
    }

    if (!this.props.pedidoVenda.visita) {
      return
    }

    if (!this.props.pedidoVenda.pontoDeVenda) {
      return
    }

    // redirect the user to new pedido de venda
    this.setState({
      redirectToNewPedidoVenda: true
    })
  }

  loadImg = () => {
    const visita = this.props.visita
    const imgId = 'img-' + visita.id

    if (visita.foto_ponto_de_venda && visita.foto_ponto_de_venda !== 'undefined') {
      const storageRef = firebase.storage().ref(visita.foto_ponto_de_venda)

      storageRef.getDownloadURL()
        .then(url => {
          if (document.getElementById(imgId)) {
            document.getElementById(imgId).src = url
          }
        })
        .catch(err => {
          console.log("Erro ao carregar imagem: ", err)
          throw err
        })
    }
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  loadInfo = () => {
    const {
      obs,
      estoque_alto, 
      exposicao_completa, 
      gerente_rejeitou_pedido, 
      expositor_organizado
    } = this.props.visita

    document.getElementById('obs').value = obs

    if (estoque_alto) document.getElementById('estoque_alto').checked = true

    if (exposicao_completa) document.getElementById('exposicao_completa').checked = true

    if (gerente_rejeitou_pedido) document.getElementById('gerente_rejeitou_pedido').checked = true

    if (expositor_organizado) document.getElementById('expositor_organizado').checked = true
  }

  loadImage = () => {
    const image = document.getElementById('foto_ponto_de_venda').files[0]

    document.getElementById(`img-${this.props.visita.id}`).src = window.URL.createObjectURL(image)
  }

  getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(pos => {
      const latitude = pos.coords.latitude
      const longitude = pos.coords.longitude

      this.setState({
        ...this.state,
        userLocation: {
          latitude,
          longitude
        }
      })
    })
  }

  renderAlertBody = (pontoDeVenda, fornecedor) => {
    return (
      <div>
        <b>Ponto de venda: </b> {pontoDeVenda.nome} <br />
        <b>Fornecedor: </b> {fornecedor.nome}
      </div>
    )
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  handleSaveImage = (pontoDeVenda, image) => {
    let blobName = null

    if (image) {
      const fileExtension = this.getFileExtension(image.name)
      blobName = `visitas/${pontoDeVenda.nome}/${uuid.v4() + fileExtension}`
      this.props.saveImage(image, blobName)
    }

    return blobName
  }

  handleUpdateVisita = () => {
    const { pontoDeVenda, fornecedor } = this.props

    // if the user changes the image
    let imagem
    let blobName

    if(document.getElementById('foto_ponto_de_venda').files[0]) {
      imagem = document.getElementById('foto_ponto_de_venda').files[0]
      blobName = this.handleSaveImage(pontoDeVenda, imagem)
    } else {
      blobName = this.props.visita.foto_ponto_de_venda
    }


    // if the visita does not have an image, is not necessary to send an 'foto_ponto_de_venda' attr
    const visitaImg = blobName ? {
      foto_ponto_de_venda: blobName
    } : {}

    // visita attrs
    const estoque_alto = document.getElementById('estoque_alto').checked
    const exposicao_completa = document.getElementById('exposicao_completa').checked
    const gerente_rejeitou_pedido = document.getElementById('gerente_rejeitou_pedido').checked
    const expositor_organizado = document.getElementById('expositor_organizado').checked
    const obs = document.getElementById('obs').value

    const visita = {
      ...visitaImg,
      id: this.props.visita.id,
      data_visita: new Date(),
      estoque_alto, 
      exposicao_completa, 
      gerente_rejeitou_pedido, 
      expositor_organizado,
      obs
    }

    // save visita
    this.props.updateVisita(visita, pontoDeVenda, fornecedor)
  }

  handleRedirectToVisitaList = () => {
    this.setState({
      ...this.state,
      redirect: true
    })
  }

  handleRedirectToNewPedidoVenda = () => {
    this.setState({
      permissionToRedirectToNewPedidoVenda: true
    })
  }

  render() {
    if (this.state.redirect) return <Redirect to="/visitas" />

    if (this.state.redirectToNewPedidoVenda) return <Redirect to="/pedidosVenda/new" />

    const { pontoDeVenda, fornecedor } = this.props

    return (
      <React.Fragment>
        <Alert text="Ponto de venda e fornecedor selecionados"
          body={this.renderAlertBody(pontoDeVenda, fornecedor)} color="light" />

        <Breadcrumb title="Foto do ponto de venda" linkTo="/visitas/new"
          btnFunction={() => this.props.loadPreviousStep()} />

        <div className="row">
          <div className="col-md-6">
            <form onSubmit={this.handleSubmit}>
              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="estoque_alto" disabled={this.state.isReadonly} />
                    <label className="form-check-label" htmlFor="estoque_alto">Estoque alto?</label>
                  </div>

                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="exposicao_completa" disabled={this.state.isReadonly} />
                    <label className="form-check-label" htmlFor="exposicao_completa">Exposição completa?</label>
                  </div>

                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="gerente_rejeitou_pedido" disabled={this.state.isReadonly} />
                    <label className="form-check-label" htmlFor="gerente_rejeitou_pedido">Gerente rejeitou pedido?</label>
                  </div>

                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="expositor_organizado" disabled={this.state.isReadonly} />
                    <label className="form-check-label" htmlFor="expositor_organizado"> Expositor organizado?</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group" style={{ height: '100%' }}>
                    {
                      this.state.isReadonly ? null : (
                        <React.Fragment>
                          <button type="button" className="btn btn-block btn-light"
                            style={{ height: '100%', border: '3px solid' }}
                            onClick={() => document.getElementById("foto_ponto_de_venda").click()}>
                            <i className="material-icons" style={{ lineHeight: 'inherit', fontSize: '3em' }}>camera_alt</i>
                          </button>
                          <input type="file" name="foto_ponto_de_venda" id="foto_ponto_de_venda" accept="image/*"
                            style={{ display: 'none' }}
                            onChange={this.loadImage} />
                        </React.Fragment>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="obs">Observações: </label>
                <textarea name="obs" className="form-control" id="obs" cols="30" rows="5"
                  readOnly={this.state.isReadonly}></textarea>
              </div>

              {
                this.state.isReadonly ? (
                  <button type="button" className="btn btn-primary btn-block"
                    onClick={this.changeReadonly}>Habilitar campos</button>
                ) : (
                    <button type="button" className="btn btn-secondary btn-block"
                      onClick={this.changeReadonly}>Cancelar</button>
                  )
              }
            </form>
          </div>

          <div className="col-md-6">
            <img id={`img-${this.props.visita.id}`} src={defaultImg} alt="Foto do ponto de venda"
              className="img-fluid img-thumbnail" style={{ height: 350, margin: '0 auto', display: 'block' }} />
          </div>
        </div>

        <hr />

        {
          !this.state.isReadonly && (
            <>
              <div className="row mb-3">
                <div className="col-md-12">
                  <p style={{ textAlign: 'center', fontSize: '1.5em', fontWeight: 600 }}>Lançar pedido de venda?</p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <Button color="secondary" size="block"
                    className="mb-3"
                    handleclick={() => {
                      this.handleUpdateVisita();
                      this.handleRedirectToVisitaList();
                    }}>
                    Não
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button color="success" size="block"
                    handleclick={() => {
                      this.handleUpdateVisita();
                      this.handleRedirectToNewPedidoVenda();
                    }}>
                    Lançar
                  </Button>
                </div>
              </div>
            </>
          )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    visita: state.visita.visita,
    pedidoVenda: state.visita.pedidoVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  updateVisita,
  saveImage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VisitaEdit)

import React, { Component } from 'react'
import './visitaList.css'

import VisitaList from './visitaList'
import Breadcrumb from '../template/breadcrumb'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchVisitas, removeVisita, setVisita } from './visitaActions'

export class VisitaListPage extends Component {
  componentDidMount() {
    this.props.fetchVisitas()
  }

  constructor(props) {
    super(props)

    this.state = {
      visitas: []
    }
  }

  removerVisita = visita => {
    this.props.removeVisita(visita)

    const visitas = this.state.visitas.filter(v => {
      return v.id !== visita.id
    })

    this.setState({
      ...this.state,
      visitas
    })
  }

  editarVisita = visita => {
    // set visita
    this.props.setVisita(visita)
    this.props.history.push('/visitas/new')
  }

  render() {
     // update the visitas list in real-time
     setTimeout(() => {
      this.setState({
        ...this.state,
        visitas: this.props.visitas
      }) 
    }, 100);

    return (
      <React.Fragment>
        <Breadcrumb title="Visitas" component={this.ButtonNew} />

        <VisitaList visitas={this.state.visitas} 
          removeVisita={this.removerVisita}
          editarVisita={this.editarVisita}/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    visitas: state.visita.visitas
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchVisitas,
  removeVisita,
  setVisita
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VisitaListPage)

import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'
import Row from '../template/row'
import Grid from '../template/grid'
import Media from 'react-media'

import { withTranslation, Trans } from 'react-i18next'

import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchPontosDeVendaByRepresentantes, setRef, clearRef } from '../pontoDeVenda/pontoDeVendaActions'
import { setPontoDeVenda } from './visitaActions'
import { saveVisitacao } from '../visitacao/visitacaoActions'
import PontoDeVendaSearch from '../pontoDeVenda/pontoDeVendaSearch'
import { openDatabaseIndexedDB } from '../../config/idbConfig'
import Pagination from '../pagination/index'

export class SelectPontoDeVenda extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      pontosDeVenda: [],

      page: 1,
      itemsPerPage: 15
    }
  }

  componentDidMount() {
    // check if a ponto de venda is already set
    if (this.props.pontoDeVenda) this.props.loadNextStep();

    if (this.props.pontosDeVenda && this.props.pontosDeVenda.length > 0) this.setPontosDeVenda(this.props.pontosDeVenda);

    if (this.props.representante) this.getPontosDeVendaByRepresentante(this.props.representante);
  }

  getPontosDeVendaByRepresentante = representante => {
    this.setState({
      isLoading: true
    }, () => {
      navigator.geolocation.getCurrentPosition(pos => {
        const latitude = pos.coords.latitude
        const longitude = pos.coords.longitude

        this.props.searchPontosDeVendaByRepresentantes(representante, '', this.state.page, this.state.itemsPerPage, latitude, longitude);
      })
    });
  }

  setPontosDeVenda = pontosDeVenda => {
    this.setState({
      isLoading: false,
      pontosDeVenda
    });
  }

  componentDidUpdate(prevProps) {
    // visita wizard found a visitacao already running
    if (prevProps.pontoDeVenda !== this.props.pontoDeVenda) {
      if (this.props.pontoDeVenda) this.props.loadNextStep();
    }

    // fetch pontos de venda by representante
    if (prevProps.representante !== this.props.representante) {
      this.getPontosDeVendaByRepresentante(this.props.representante);
    }

    if (prevProps.pontosDeVenda !== this.props.pontosDeVenda) {
      this.setPontosDeVenda(this.props.pontosDeVenda);
    }
  }

  selectPontoDeVenda = async pontoDeVenda => {
    // set visitacao flow has begun
    localStorage.setItem('visitando', 'true');

    // set ponto de venda and representante on indexedDB
    try {
      const db = await openDatabaseIndexedDB('visitacao');

      await db.add('ponto_de_venda', {
        id: pontoDeVenda.id,
        timestamp: new Date().getTime(),
        data: pontoDeVenda
      });

      await db.add('representante', {
        id: this.props.representante.id,
        timestamp: new Date().getTime(),
        data: this.props.representante
      });
    } catch (e) {
      console.log(`%c Erro ao inserir ponto de venda no indexedDB: ${e}`, 'color: tomato');
    }

    // set ponto de venda
    this.props.setPontoDeVenda(pontoDeVenda)

    // init visitacao
    this.props.saveVisitacao(pontoDeVenda)

    // call next step
    this.props.loadNextStep()
  }

  renderPontosDeVenda = () => {
    const pontosDeVenda = this.state.pontosDeVenda || []

    return pontosDeVenda.map(pontoDeVenda => {
      return (
        <tr key={pontoDeVenda.id}>
          <td data-label="Nome" className="align-middle">{pontoDeVenda.nome}</td>
          <td data-label="Região" className="align-middle">{pontoDeVenda.regiao}</td>
          <td data-label="Tipo" className="align-middle">{pontoDeVenda.tipo}</td>
          <td data-label="Frequência de visitas (d)" className="align-middle">{pontoDeVenda.frequencia_visita}</td>
          <td data-label="Endereço" className="align-middle">
            <Media query={{ maxWidth: 599 }}>
              {
                matches =>
                  matches ? (
                    pontoDeVenda.endereco.length > 30 ? `${pontoDeVenda.endereco.substr(0, 30)}...` : pontoDeVenda.endereco
                  ) : pontoDeVenda.endereco
              }
            </Media>
          </td>
          <td data-label="Observações" className="align-middle">
            {
              pontoDeVenda.obs ? (
                <Media query={{ maxWidth: 599 }}>
                  {
                    matches =>
                      matches ? (
                        pontoDeVenda.obs.length > 30 ? `${pontoDeVenda.obs.substr(0, 30)}...` : pontoDeVenda.obs
                      ) : pontoDeVenda.obs
                  }
                </Media>
              ) : ' - '
            }
          </td>
          <td data-label="Ação">
            <button className="btn btn-sm btn-info"
              onClick={() => this.selectPontoDeVenda(pontoDeVenda)}>
              <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.buttonSelecionar">trans</Trans>
            </button>
          </td>
        </tr>
      )
    })
  }

  handlePaginate = page => {
    if (page === this.state.page) return;

    const { ref } = this.props.pontoDeVendaRef;

    this.setState({
      page,
      isLoading: true
    }, this.props.searchPontosDeVendaByRepresentantes(this.props.representante, ref, page, this.state.itemsPerPage));
  }

  handleSearch = e => {
    e.preventDefault();

    const page = 1;
    const ref = e.target.ref.value;

    // set reference text on application state
    this.props.setRef(ref);
    
    // search pontos de venda with reference text and navigate to page 1
    this.setState({
      page,
      isLoading: true
    }, this.props.searchPontosDeVendaByRepresentantes(this.props.representante, ref, page, this.state.itemsPerPage));
  }

  handleClearSearch = () => {
    // clear search reference on application state
    this.props.clearRef();

    // clear form
    document.getElementById('ref').value = ""

    const page = 1;

    // go back to page 1 with all pontos de venda
    this.setState({
      page,
      isLoading: true
    }, this.props.searchPontosDeVendaByRepresentantes(this.props.representante, '', this.state.page, this.state.itemsPerPage));
  }

  render() {
    const { t: translate } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={translate("visitas.wizard.new.selectPontoDeVenda.title")} linkTo="/visitas" />

        <PontoDeVendaSearch onSubmit={this.handleSearch}
          onClearSearch={this.handleClearSearch} />

        <Row>
          <Grid cols="12">
            {this.state.isLoading ? <Skeleton count={5} height={50} />
              : this.state.pontosDeVenda.length <= 0 ? <p><Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.notFound">trans</Trans></p>
                : (
                  <div id="card">
                    <table className="table table-fluid table-striped text-center">
                      <thead className="thead-dark">
                        <tr>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.nome">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.regiao">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.tipo">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.frequenciaVisita">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.endereco">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.obs">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col" width="170">
                            <Trans i18nKey="visitas.wizard.new.selectPontoDeVenda.table.acao">trans</Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderPontosDeVenda()}
                      </tbody>
                    </table>
                  </div>
                )}
          </Grid>
        </Row>

        <Row>
          <Grid cols="12">
            <Pagination page={this.state.page}
              itemsPerPage={this.state.itemsPerPage}
              paginate={this.handlePaginate}
              totalItems={this.props.count}
              previousLabel={translate("pagination.buttonAnterior")}
              nextLabel={translate("pagination.buttonProximo")} />
          </Grid>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    representante: state.representante.representante,

    // search
    pontoDeVendaRef: state.pontoDeVenda,
    count: state.pontoDeVenda.count,

    pontoDeVenda: state.visita.pontoDeVenda,
    pontosDeVenda: state.pontoDeVenda.pontosDeVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  searchPontosDeVendaByRepresentantes,
  setPontoDeVenda,
  saveVisitacao,
  clearRef,
  setRef, 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectPontoDeVenda))

const INITIAL_STATE = {
  visita: null,
  visitas: [],
  pontoDeVenda: null,
  fornecedor: null,
  pedidoVenda: {
    visita: null,
    fornecedor: null,
    pontoDeVenda: null
  }
}

const visitaReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_VISITAS':
      return {
        ...state,
        visitas: action.payload
      }
    case 'SET_VISITA':
      return {
        ...state,
        visita: action.payload
      }
    case 'VISITAS_SET_PONTO_DE_VENDA':
      return {
        ...state,
        pontoDeVenda: action.payload
      }
    case 'VISITAS_SET_FORNECEDOR':
      return {
        ...state,
        fornecedor: action.payload
      }
    case 'VISITAS_SET_VISITA_PEDIDO_VENDA':
      return {
        ...state,
        pedidoVenda: {
          ...state.pedidoVenda,
          visita: action.payload
        }
      }
    case 'VISITAS_SET_PONTO_DE_VENDA_PEDIDO_VENDA':
      return {
        ...state,
        pedidoVenda: {
          ...state.pedidoVenda,
          pontoDeVenda: action.payload
        }
      }
    case 'VISITAS_SET_FORNECEDOR_PEDIDO_VENDA':
      return {
        ...state,
        pedidoVenda: {
          ...state.pedidoVenda,
          fornecedor: action.payload
        }
      }
    case 'CLEAR_VISITAS':
      return {
        ...state,
        visita: null,
        visitas: [],
        pontoDeVenda: null,
        fornecedor: null
      }
    case 'CLEAR_VISITAS_PEDIDO_VENDA':
      return {
        ...state,
        pedidoVenda: {
          visita: null,
          fornecedor: null,
          pontoDeVenda: null
        }
      }
    default:
      return state
  }
}

export default visitaReducer

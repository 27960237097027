import React from 'react'
import PropTypes from 'prop-types'

import Button from '../template/button'
import { MdRemove } from 'react-icons/md'

function ButtonRemove({ onClick, ...props }) {
  return (
    <Button color="danger"
      handleclick={onClick} {...props}>
      <MdRemove size={18} />
    </Button>
  )
}

ButtonRemove.propTypes = {
  onClick: PropTypes.func,
}

export default ButtonRemove;

import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchPontosDeVenda } from '../pontoDeVenda/pontoDeVendaActions'
import { setPontoDeVenda } from './tabelaPrecoActions'

export class SelectPontoDeVenda extends Component {
  componentDidMount() {
    this.props.fetchPontosDeVenda()
  }

  selectPontoDeVenda = pontoDeVenda => {
    // set ponto de venda
    this.props.setPontoDeVenda(pontoDeVenda)

    // move on to the next step
    this.props.loadNextStep()
  }

  renderPontosDeVenda = () => {
    const pontosDeVenda = this.props.pontosDeVenda || []
    return pontosDeVenda.map(pontoDeVenda => {
      return (
        <tr key={pontoDeVenda.id}>
          <td data-label="Nome" className="align-middle">{pontoDeVenda.nome}</td>
          <td data-label="Região" className="align-middle">{pontoDeVenda.regiao}</td>
          <td data-label="Tipo" className="align-middle">{pontoDeVenda.tipo}</td>
          <td data-label="Frequência de visitas (d)" className="align-middle">{pontoDeVenda.frequencia_visita}</td>
          <td data-label="Endereço" className="align-middle">{pontoDeVenda.endereco}</td>
          <td data-label="Observações" className="align-middle">
            {
              pontoDeVenda.obs ? pontoDeVenda.obs : ' - '
            }
          </td>
          <td data-label="Ação">
            <button className="btn btn-sm btn-info" onClick={() => this.selectPontoDeVenda(pontoDeVenda)}>
              Selecionar
            </button>
          </td>
        </tr>
      )
    })
  }
  
  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Selecionar ponto de venda" linkTo="/tabelasPreco/save" 
          btnFunction={this.props.previousStep} />

        <div className="row">
          <div className="col-12">
            <div id="card">
              <table className="table table-fluid table-striped text-center">
                <thead className="thead-dark">
                  <tr>
                    <th className="align-middle" scope="col">Nome</th>
                    <th className="align-middle" scope="col">Região</th>
                    <th className="align-middle" scope="col">Tipo</th>
                    <th className="align-middle" scope="col">Frequência de visita (d)</th>
                    <th className="align-middle" scope="col">Endereço</th>
                    <th className="align-middle" scope="col">Observações</th>
                    <th className="align-middle" scope="col" width="170">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderPontosDeVenda()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    pontosDeVenda: state.pontoDeVenda.pontosDeVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  fetchPontosDeVenda,
  setPontoDeVenda
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SelectPontoDeVenda)

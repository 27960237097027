export default {
  list: {
    title: 'Representantes',
    table: {
      nome: 'Nome',
      telefone: 'Telefone',
      email: 'E-mail',
      acao: 'Ação',
      buttonEditar: 'Editar',
      buttonRemover: 'Remover'
    },
    modal: {
      title: 'Confirmar ação',
      text: 'Você tem certeza de que quer remover este representante?',
      buttonCancelar: 'Cancelar',
      buttonRemover: 'Remover'
    }
  },
  
  form: {
    title: 'Novo representante',
    nome: 'Nome: ',
    email: 'E-mail: ',
    telefone: 'Telefone: ',
    maskTelefone: '+55 (99) 99999 9999',
    senha: {
      label: 'Senha: ',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmarSenha: 'Confirme a senha: ',
    buttonProximo: 'Próximo',
    buttonCadastrar: 'Cadastrar',
    validation: {
      password: {
        minLength: {
          title: 'Algo está faltando',
          message: 'Sua senha deve ter no mínimo 6 caracteres'
        },
        notEqualTo: {
          title: 'Algo está faltando',
          message: 'As senhas devem ser iguais'
        }
      }
    }
  },

  edit: {
    title: 'Editar representante',
    nome: 'Nome: ',
    telefone: 'Telefone: ',
    email: 'E-mail: ',
    buttonAlterarSenha: 'Alterar senha',
    buttonHabilitarCampos: 'Habilitar campos',
    buttonCancelar: 'Cancelar',
    buttonSalvar: 'Salvar'
  },

  changePassword: {
    title: 'Trocar senha',
    novaSenha: {
      label: 'Nova senha: ',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmarSenha: 'Confirme a senha: ',
    buttonSalvar: 'Salvar',
    validation: {
      minLength: {
        title: 'Algo está faltando',
        message: 'A senha deve ter no mínimo 6 caracteres'
      },
      notEqualTo: {
        title: 'Algo está faltando',
        message: 'As senhas devem ser iguais'
      }
    }
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os representantes'
      }
    },
    fetchById: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o representante'
      }
    },
    fetchByUserId: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o representante'
      }
    },
    fetchByPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os representantes não vinculados ao ponto de venda'
      }
    },
    findOneByPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o representante deste ponto de venda'
      }
    },
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o representante'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o representante'
      }
    },
    unlinkPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o vinculo do representante com o ponto de venda'
      }
    },
    attendPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao marcar ponto de venda como atendido por um representante'
      }
    },
    unattendPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao desvincular o ponto de venda'
      },
      info: {
        title: 'Solicitação enviada',
        message: 'Sua solicitação foi enviada aos gestores, aguarde sua aprovação'
      }
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o representante'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o representante'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o representante'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao remover o representante'
      }
    }
  }
}
import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import firebase from '../../config/fbConfig'
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

const setCliente = cliente => {
  return dispatch => {
    return dispatch({
      type: 'SET_CLIENTE',
      payload: cliente
    })
  }
}

const setClientes = clientes => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_CLIENTES',
      payload: clientes
    })
  }
}

export const clearClientes = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_CLIENTES'
    })
  }
}

export const fetchAllClientes = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/clientes`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("clientes.toastr.fetchAll.error.title"),
            i18n.t("clientes.toastr.fetchAll.error.message")
          );
          return dispatch(nop());
        }

        return dispatch(setClientes(res.data.data));
      })
      .catch(err => {
        toastr.error(
          i18n.t("clientes.toastr.fetchAll.error.title"),
          i18n.t("clientes.toastr.fetchAll.error.message")
        );
        throw err;
      })
  }
}

export const fetchClienteById = id => {
  return dispatch => {
    if (!id) {
      toastr.error(
        i18n.t("clientes.toastr.fetchById.error.title"),
        i18n.t("clientes.toastr.fetchById.error.message")
      );
      return dispatch(nop());
    }

    axios.get(`${BASE_URL}/clientes/${id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("clientes.toastr.fetchById.error.title"),
            i18n.t("clientes.toastr.fetchById.error.message")
          );
          return dispatch(nop());
        }

        return dispatch(setCliente(res.data.data[0]));
      })
      .catch(err => {
        toastr.error(
          i18n.t("clientes.toastr.fetchById.error.title"),
          i18n.t("clientes.toastr.fetchById.error.message")
        );
        throw err;
      })
  }
}

export const saveImage = (blob, blobName) => {
  return dispatch => {
    // create a storage ref
    const storageRef = firebase.storage().ref(blobName)

    // upload file
    const task = storageRef.put(blob)

    // upload progress bar
    task.on('state_changed',
      function progress(snapshot) {

      },

      function error(err) {

      },

      function complete() {
        return dispatch(fetchAllClientes())
      }
    );
  }
}

export const saveCliente = (cliente, user) => {
  return dispatch => {
    if (!cliente) {
      toastr.error(
        i18n.t("clientes.toastr.save.error.title"),
        i18n.t("clientes.toastr.save.error.message")
      );
      return dispatch(nop());
    }

    if (!user) {
      toastr.error(
        i18n.t("clientes.toastr.save.error.title"),
        i18n.t("clientes.toastr.save.error.message")
      );
      return dispatch(nop());
    }

    axios.post(`${BASE_URL}/clientes`, {
      cliente,
      user
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("clientes.toastr.save.error.title"),
            i18n.t("clientes.toastr.save.error.message")
          );
          return dispatch(nop());
        }

        toastr.success(
          i18n.t("clientes.toastr.save.success.title"),
          i18n.t("clientes.toastr.save.success.message")
        );
        return dispatch([
          fetchAllClientes(),
          getCountUsersNotValidated()
        ]);
      })
      .catch(err => {
        toastr.error(
          i18n.t("clientes.toastr.save.error.title"),
          i18n.t("clientes.toastr.save.error.message")
        );
        throw err;
      })
  }
}

export const updateCliente = (cliente, user) => {
  return dispatch => {
    if (!cliente) {
      toastr.error(
        i18n.t("clientes.toastr.update.error.title"),
        i18n.t("clientes.toastr.update.error.message")
      );
      return dispatch(nop());
    }

    if (!user) {
      toastr.error(
        i18n.t("clientes.toastr.update.error.title"),
        i18n.t("clientes.toastr.update.error.message")
      );
      return dispatch(nop());
    }

    axios.put(`${BASE_URL}/clientes/${cliente.id}`, {
      cliente,
      user
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("clientes.toastr.update.error.title"),
            i18n.t("clientes.toastr.update.error.message")
          );
          return dispatch(nop());
        }

        return dispatch(fetchAllClientes());
      })
      .catch(err => {
        toastr.error(
          i18n.t("clientes.toastr.update.error.title"),
          i18n.t("clientes.toastr.update.error.message")
        );
        throw err;
      })
  }
}

export const removeCliente = cliente => {
  return dispatch => {
    if (!cliente) {
      toastr.error(
        i18n.t("clientes.toastr.remove.error.title"),
        i18n.t("clientes.toastr.remove.error.message")
      );
      return dispatch(nop());
    }

    const userId = cliente.user_id;

    axios.delete(`${BASE_URL}/clientes/${cliente.id}`, {
      headers: {},
      data: {
        user: {
          id: userId
        }
      }
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("clientes.toastr.remove.error.title"),
            i18n.t("clientes.toastr.remove.error.message")
          );
          return dispatch(nop());
        }

        return dispatch([
          fetchAllClientes(),
          getCountUsersNotValidated()
        ]);
      })
      .catch(err => {
        toastr.error(
          i18n.t("clientes.toastr.remove.error.title"),
          i18n.t("clientes.toastr.remove.error.message")
        );
        throw err;
      })
  }
}

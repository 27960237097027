import React, { Component } from 'react'

import { withTranslation, Trans } from 'react-i18next'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateGestor } from './gestorActions'

class GestorEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReadonly: true
    }
  }

  componentDidMount() {
    this.loadInfo()
    // const { t } = this.props;
    // Inputmask(t("gestores.form.maskTelefone")).mask(document.getElementById('telefone'))
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  loadInfo = () => {
    const { nome, telefone, recebe_email, email } = this.props.gestor

    document.getElementById('nome').value = nome
    document.getElementById('telefone').value = telefone
    document.getElementById('email').value = email
    document.getElementById('recebe_email').value = recebe_email
  }

  handleSubmit = e => {
    e.preventDefault()
    
    const gestor = {
      id: this.props.gestor.id,
      telefone: e.target.telefone.value,
      recebe_email: e.target.recebe_email.value,
    }

    const user = {
      id: this.props.gestor.user_id,
      nome: e.target.nome.value,
      email: e.target.email.value,
      senha: this.props.gestor.senha,
      status: this.props.gestor.status,
      tipo: this.props.gestor.tipo
    }
    
    // update gestor
    this.props.updateGestor(user, gestor)

    // redirect
    this.props.history.push('/gestores')
  }
  
  render() {
    if(!this.props.gestor) {
      return <Redirect to="/gestores" />
    }

    // i18n
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("gestores.edit.title")} linkTo="/gestores" />

        <div className="row">
          <div className="col-md-12">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} className="mt-3">
                <div className="form-group">
                  <label htmlFor="nome">
                    <Trans i18nKey="gestores.edit.nome">trans</Trans>
                  </label>
                  <input type="text" className="form-control" name="nome" id="nome" placeholder="José da Silva" required readOnly={this.state.isReadonly} />
                </div>
        
                <div className="form-group">
                  <label htmlFor="telefone">
                    <Trans i18nKey="gestores.edit.telefone">trans</Trans>
                  </label>
                  <input type="text" className="form-control" name="telefone" id="telefone" required readOnly={this.state.isReadonly} />
                </div>

                <div className="form-group mt-3">
              <label htmlFor="recebe_email"><Trans i18nKey="gestores.edit.recebe_email.label">trans</Trans></label>
              <select className="form-control" name="recebe_email" id="recebe_email" required readOnly={this.state.isReadonly}>
                <option value={1}>{t("gestores.edit.recebe_email.sim")}</option>
                <option value={0}>{t("gestores.edit.recebe_email.nao")}</option>
              </select>
            </div>

                <div className="form-group">
                  <label htmlFor="email">
                    <Trans i18nKey="gestores.edit.email">email</Trans>
                  </label>
                  <input type="email" className="form-control" name="email" id="email" placeholder="exemplo@mail.com" required readOnly={this.state.isReadonly} />
                </div>
          
                {
                  this.state.isReadonly ? (
                    <button type="button" className="btn btn-primary btn-block"
                      onClick={this.changeReadonly}>
                        <Trans i18nKey="gestores.edit.buttonHabilitarCampos">trans</Trans>
                      </button>
                  ) : (
                    <div className="row">
                      <div className="col-6">
                        <button type="button" className="btn btn-secondary btn-block"
                          onClick={this.changeReadonly}>
                            <Trans i18nKey="gestores.edit.buttonCancelar">trans</Trans>
                          </button>
                      </div>
                      <div className="col-6">
                        <button type="submit" className="btn btn-primary btn-block">
                          <Trans i18nKey="gestores.edit.buttonSalvar">trans</Trans>
                        </button>
                      </div>
                    </div>
                  )
                }
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    gestor: state.gestor.gestor
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ updateGestor }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GestorEdit))

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// Internationalization
import './_i18n/i18n'

// redux toastr css
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

// react image gallery css
import 'react-image-gallery/styles/css/image-gallery.css';

import * as serviceWorker from './serviceWorker'

import reducers from './components/main/reducers'

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import promise from 'redux-promise'
import thunk from 'redux-thunk'
import multi from 'redux-multi'
require('dotenv').config()

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers, devTools)

// get user to report error
const user = JSON.parse(localStorage.getItem('user'));

// Bugsnag
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact(React)],
  onError: function (event) {
    if (user)
      event.setUser(user.id, user.email, user.nome)
  }
});

const ErrorBoundary = Bugsnag.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
  , document.getElementById('root'));

serviceWorker.register();
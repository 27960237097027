export default {
  title: 'Clienti',

  table: {
    cliente: 'Cliente',
    acoes: 'Azioni',
    notFound: 'Nessun cliente trovato'
  },

  modalDelete: {
    title: "Conferma l'azione",
    text: 'Sei sicuro di voler rimuovere questo client?',
    buttonCancelar: 'Annullare',
    buttonRemover: 'Remove'
  },

  form: {
    title: 'Registrazione del cliente',
    nome: 'Nome: ',
    email: 'E-mail: ',
    senha: {
      label: 'Password: ',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmarSenha: 'Conferma password: ',
    buttonCadastrar: 'Registrazione',
    toastr: {
      validation: {
        minLength: {
          title: 'Manca qualcosa',
          message: 'La password deve contenere almeno 6 caratteri'
        },
        confirmPassword: {
          title: 'Manca qualcosa',
          message: 'Le password devono essere le stesse'
        }
      }
    }
  },

  edit: {
    title: 'Modifica cliente',
    buttonAlterarSenha: 'Cambia password',
    buttonHabilitarCampos: 'Abilita campi',
    buttonCancelar: 'Annullare',
    buttonSalvar: 'Salva'
  },

  changePassword: {
    title: 'Cambia password',
    novaSenha: {
      label: 'Nuova password',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmPassword: 'Conferma la password',
    buttonSalvar: 'Salva',
    validation: {
      novaSenha: {
        required: 'Inserisci una password',
        minLength: 'La password deve contenere almeno 6 caratteri'
      },
      confirmPassword: {
        required: 'Conferma la tua password',
        notEqualTo: 'Le password devono essere le stesse'
      }
    }
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema nella ricerca di clienti'
      }
    },
    fetchById: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto un problema nella ricerca di un cliente'
      }
    },
    save: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del cliente'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il cliente'
      },
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del cliente'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il cliente'
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del cliente'
      }
    }
  }
}
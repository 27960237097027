import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { savePontoDeVenda } from './pontoDeVendaActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'
import { searchLocation } from '../geolocation/geolocationActions'
import { fetchAllClientes } from '../cliente/clienteActions'

import { withTranslation, Trans } from 'react-i18next'

import { save as uploadFile } from '../../config/fbConfig'

import defaultImg from '../common/imgs/default-img.png'
import ImageGallery from 'react-image-gallery'
import ImageInput from '../template/imageInput'
import Breadcrumb from '../template/breadcrumb'
import GoogleMap from '../geolocation/googleMap'
import { toastr } from 'react-redux-toastr'
import uuid from 'uuid'

class PontoDeVendaForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cliente: null,
      codigo_identificacao: '',
      nome: '',
      cidade: '',
      estado: '',
      regiao: '',
      tipo: 'P',
      estabelecimento: '',
      frequenciaVisita: 30,
      endereco: '',
      obs: '',
      latitude: 0,
      longitude: 0,
      imagem: '',
      images: [{ original: defaultImg }]
    }
  }

  componentDidMount() {
    // fetch all clientes
    this.props.fetchAllClientes();

    // set initial value of frequencia visitas
    document.getElementById('frequenciaVisita').value = 12

    // get user location
    this.getUserLocation()
  }

  componentDidUpdate(prevProps) {
    if (this.props.geolocation.features !== prevProps.geolocation.features) {
      const features = this.props.geolocation.features || []
      const mostRelevantFeature = features[0]

      this.setState({
        latitude: mostRelevantFeature.geometry.location.lat,
        longitude: mostRelevantFeature.geometry.location.lng
      })
    }
  }

  setFrequenciaVisitas = e => {
    // get tipo
    const tipo = document.getElementById('tipo').value
    let frequenciaVisita

    switch (tipo) {
      case 'P':
        frequenciaVisita = 12
        break
      case 'M':
        frequenciaVisita = 17
        break
      case 'G':
        frequenciaVisita = 21
        break
      default:
        frequenciaVisita = 12
    }

    // set frequencia visita
    this.setState({
      ...this.state,
      frequenciaVisita
    })
  }

  getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(pos => {
      const latitude = pos.coords.latitude
      const longitude = pos.coords.longitude

      this.setState({
        ...this.state,
        latitude,
        longitude
      })
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      const location = e.target.value

      this.searchForLocation(location)
    }
  }

  searchForLocation = location => {
    if (!location) {
      return
    }

    this.props.setLoading()
    this.props.searchLocation(location)
  }

  handleSubmit = e => {
    // does nothing on submit
    e.preventDefault()
  }

  updateCenter = center => {
    this.setState({
      latitude: center.lat,
      longitude: center.lng
    })
  }

  savePontoDeVenda = () => {
    const { t: translate } = this.props;

    // user must select a cliente
    if(!this.state.cliente) {
      toastr.warning(
        translate("pontosDeVenda.form.validation.cliente.required.title"), 
        translate("pontosDeVenda.form.validation.cliente.required.message")
      );
      return;
    }

    // set loader
    this.props.setLoading()

    let pontoDeVenda = {
      ...this.state,
      cliente: parseInt(this.state.cliente)
    }

    // save image
    const image = document.getElementById('image').files[0];
    if(image) {      
      const blobName = this.saveImage(image, pontoDeVenda.nome);
      pontoDeVenda = {
        ...pontoDeVenda,
        imagem: blobName
      }
    }

    // get latitude and longitude
    this.props.searchLocation(pontoDeVenda.endereco)

    // save ponto de venda
    this.props.savePontoDeVenda(pontoDeVenda)

    // redirect the user
    this.props.history.push('/pontosDeVenda')
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  saveImage = (image, pontoDeVendaNome) => {
    let blobName = null

    if (image) {
      const fileExtension = this.getFileExtension(image.name)
      blobName = `pontosDeVenda/${pontoDeVendaNome}/${uuid.v4() + fileExtension}`
      uploadFile(image, blobName);
    }

    return blobName
  }

  handleLoadImage = e => { 
    const file = e.target.files[0];

    if(!file) return;
    
    const url = window.URL.createObjectURL(file);

    // remove the default img
    // replace the previous input image
    this.setState({
      images: [{ original: url }]
    })
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("pontosDeVenda.form.title")} linkTo="/pontosDeVenda" />

        <div className="row mt-4">
          <div className="col-md-6">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="cliente">
                  <Trans i18nKey="pontosDeVenda.form.cliente">trans</Trans>
                </label>
                <select className="form-control" name="cliente" id="cliente" 
                  onChange={this.handleChange}>
                  <option value={-1} selected disabled>
                    {t("pontosDeVenda.form.selecione")}
                  </option>
                  {this.props.clientes.map((cliente, i) => (
                    <option key={i} value={cliente.id}>{cliente.nome}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="codigo_identificacao">
                  <Trans i18nKey="pontosDeVenda.form.codigoIdentificacao">trans</Trans>
                </label>
                <input type="text" className="form-control" name="codigo_identificacao" id="codigo_identificacao" value={this.state.codigo_identificacao}
                  required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="nome">
                  <Trans i18nKey="pontosDeVenda.form.nome.label">trans</Trans>
                </label>
                <input type="text" className="form-control" name="nome" id="nome" value={this.state.nome}
                  required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="endereco">
                  <Trans i18nKey="pontosDeVenda.form.endereco.label">trans</Trans>
                </label>
                <input type="text" className="form-control" name="endereco" id="endereco"
                  required onChange={this.handleChange} onKeyPress={this.handleKeyPress} />
              </div>

              <div className="form-group">
                <label htmlFor="cidade">
                  <Trans i18nKey="pontosDeVenda.form.cidade">trans</Trans>
                </label>
                <input type="text" className="form-control" name="cidade" id="cidade" value={this.state.cidade}
                  required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="estado">
                  <Trans i18nKey="pontosDeVenda.form.estado">trans</Trans>
                </label>
                <input type="text" className="form-control" name="estado" id="estado" value={this.state.estado}
                  required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="regiao">
                  <Trans i18nKey="pontosDeVenda.form.regiao">trans</Trans>
                </label>
                <input type="text" className="form-control" name="regiao" id="regiao" value={this.state.regiao}
                  required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="estabelecimento">
                  <Trans i18nKey="pontosDeVenda.form.tipoEstabelecimento">trans</Trans>
                </label>
                <input type="text" className="form-control" name="estabelecimento" id="estabelecimento" value={this.state.estabelecimento}
                  required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="tipo">
                  <Trans i18nKey="pontosDeVenda.form.tamanho">trans</Trans>
                </label>
                <select className="form-control" name="tipo" id="tipo" value={this.state.tipo}
                  required onChange={this.handleChange}>
                  <option value="P">P</option>
                  <option value="M">M</option>
                  <option value="G">G</option>
                  <option value="GG">GG</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="frequenciaVisita">
                  <Trans i18nKey="pontosDeVenda.form.frequenciaVisita.label">trans</Trans>
                  {' '}
                  <small className="text-muted">
                    <Trans i18nKey="pontosDeVenda.form.frequenciaVisita.small">trans</Trans>
                  </small>
                </label>
                <input type="number" className="form-control" name="frequenciaVisita" id="frequenciaVisita"
                  value={this.state.frequenciaVisita} required onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="obs">
                  <Trans i18nKey="pontosDeVenda.form.obs">trans</Trans>
                </label>
                <textarea className="form-control" name="obs" id="obs" cols="30" rows="5"
                  value={this.state.obs} onChange={this.handleChange}></textarea>
              </div>

              <ImageInput id="image" 
                onLoadImage={this.handleLoadImage} isRequired={false} />

              <div className="form-group">
                <button type="button" className="btn btn-block btn-primary mb-3"
                  onClick={this.savePontoDeVenda}>
                  <Trans i18nKey="pontosDeVenda.form.buttonCadastrar">trans</Trans>
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <ImageGallery items={this.state.images}
              showFullscreenButton={false}
              showPlayButton={false} 
              showThumbnails={false}/>

            <div style={{ padding: '10px', height: 'auto' }}>
              <GoogleMap center={{ lat: this.state.latitude, lng: this.state.longitude }} updateCenter={this.updateCenter} />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    clientes: state.cliente.clientes,
    geolocation: state.geolocation
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAllClientes,
  savePontoDeVenda,
  searchLocation,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PontoDeVendaForm))

export default {
  list: {
    title: 'Expositores',
    notFound: 'Nenhum expositor encontrado',

    table: {
      nome: 'Nome',
      fornecedor: 'Fornecedor',
      acoes: 'Ações'
    },
    modal: {
      title: 'Remover expositor',
      text: 'Tem certeza de que deseja remover o expositor?',
      buttonRemover: 'Remover',
      buttonCancelar: 'Cancelar',
    }
  },

  form: {
    title: 'Novo expositor',
    nome: {
      label: 'Nome',
      placeholder: 'Nome do expositor'
    },
    fornecedor: {
      label: 'Fornecedor'
    },
    produtos: {
      notFound: 'Nenhum produto encontrado',
      card: {
        fornecedor: 'Fornecedor: ',
        unidade: {
          label: 'Unidade: '
        }
      }
    },
    addItem: 'Adicione um item',
    qtdeItems: 'Qtde. de peças',
    buttonNext: 'Próximo'
  },

  estande: {
    title: {
      preview: 'Visualização',
      addProduto: 'Selecione um produto',
      newProduto: 'Novo produto'
    },
    error: 'Algo deu errado, tente atualizar a página',
    buttonFinalizar: 'Finalizar',
    buttonVoltar: 'Voltar',
    buttonPreview: 'Preview',
    linha: 'Linha'
  }
}
import React, { Component } from 'react'
import './searchProduto.css'

import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchProdutosByPontoDeVendaUnbound, fetchAllProdutosByPontoDeVenda } from './produtoActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'

class SearchProduto extends Component {
  handleSubmit = e => {
    e.preventDefault()

    // set loading spinner
    this.props.setLoading()

    const ref = e.target.ref.value
    
    this.props.searchProdutosByPontoDeVendaUnbound(ref, this.props.pontoDeVendaId)
  }

  handleClick = e => {
    e.preventDefault()

    // set loading spinner
    this.props.setLoading()

    // clear form
    document.getElementById('ref').value = ""

    this.props.fetchAllProdutosByPontoDeVenda(this.props.pontoDeVendaId)
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="search-container mb-3">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-10">
                <input type="text" className="form-control mb-2" name="ref" id="ref" 
                  placeholder={t("pontosDeVenda.edit.produtos.link.search.placeholder")} />
              </div>
              <div className="col-md-2 d-flex justify-content-between">
                <button className="btn btn-secondary d-flex mb-2">
                  <i className="material-icons ml-1">search</i>
                </button>

                <button type="button" className="btn btn-danger d-flex mb-2"
                  onClick={this.handleClick}>
                  <i className="material-icons ml-1">clear</i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  searchProdutosByPontoDeVendaUnbound, 
  fetchAllProdutosByPontoDeVenda,
  setLoading
}, dispatch)

export default connect(null, mapDispatchToProps)(withTranslation()(SearchProduto))

import axios from 'axios'
import consts from '../../consts'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

const setNotificationPontoDeVenda = count => {
  return dispatch => {
    return dispatch({
      type: 'SET_NAVBAR_NOTIFICATION_PONTO_DE_VENDA',
      payload: count
    })
  }
}

const setNotificationCadastro = count => {
  return dispatch => {
    return dispatch({
      type: 'SET_NAVBAR_NOTIFICATION_CADASTRO',
      payload: count
    })
  }
}

const setNotificationFornecedor = count => {
  return dispatch => {
    return dispatch({
      type: 'SET_NAVBAR_NOTIFICATION_FORNECEDOR',
      payload: count
    })
  }
}

const clearNotifications = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_NAVBAR_NOTIFICATIONS'
    })
  }
}

export const fetchCountFornecedoresUnattended = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/representantes/fornecedores/un_attended/count`)
      .then(res => {
        if (res.data.error) {
          return dispatch(nop())
        }

        return dispatch(setNotificationFornecedor(res.data.data['count_unattended']));
      })
      .catch(err => {
        throw err;
      })
  }
}

export const fetchCountPontosDeVendaUnattended = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/representantes/un_attended/count`)
      .then(res => {
        if (res.data.error) {
          return dispatch(nop())
        }

        return dispatch(setNotificationPontoDeVenda(res.data.data['count_unattended']));
      })
      .catch(err => {
        throw err;
      })
  }
}

export const getCountUsersNotValidated = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/user/not_validated/count`)
      .then(res => {
        if(res.data.error) {
          return dispatch(nop());
        }

        return dispatch(setNotificationCadastro(res.data.data['count_not_validated']))
      })
      .catch(err => {
        throw err;
      })
  }
}

import React, { Component } from 'react'
import Modal from '../template/modal.jsx'
import Button from '../template/button'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchGestores, removeGestor, setGestor } from './gestorActions'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import Breadcrumb from '../template/breadcrumb'

class GestorList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gestor: null
    }
  }

  componentDidMount() {
    this.props.setLoading()

    this.props.fetchGestores()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gestores !== this.props.gestores) {
      this.props.unsetLoading()
    }
  }

  removerGestor = async gestor => {
    await this.props.removeGestor(gestor);
    this.props.getCountUsersNotValidated();
  }

  editarGestor = gestor => {
    // set gestor
    this.props.setGestor(gestor)
    this.props.history.push('/gestores/edit')
  }

  setGestor = gestor => {
    this.setState({
      ...this.state,
      gestor
    })
  }

  renderGestores = () => {
    const gestores = this.props.gestores || [];
    const { t } = this.props;

    return gestores.map(gestor => {
      return (
        <tr key={gestor.id}>
          <td data-label={t("gestores.list.table.nome")} className="align-middle">{gestor.nome}</td>
          <td data-label={t("gestores.list.table.telefone")} className="align-middle">{gestor.telefone}</td>
          <td data-label={t("gestores.list.table.email")} className="align-middle">
            {
              gestor.email.length > 36 && visualViewport.width <= 600
                ? gestor.email.substr(0, 33) + '...'
                : gestor.email
            }
          </td>
          <td data-label={t("gestores.list.table.email")} className="align-middle">
            {
              gestor.recebe_email === 0 ? <Trans i18nKey="gestores.list.table.nao">trans</Trans> : <Trans i18nKey="gestores.list.table.sim">trans</Trans>}
          </td>
          <td data-label={t("gestores.list.table.acao")}>
            <Button size="sm" color="warning"
              handleclick={() => this.editarGestor(gestor)}>
                <Trans i18nKey="gestores.list.table.buttonEditar">trans</Trans>
            </Button>
            <Button className="ml-2" size="sm" color="danger"
              handleclick={() => this.setGestor(gestor)}
              data-toggle="modal" data-target="#modalDeletarGestor">
                <Trans i18nKey="gestores.list.table.buttonRemover">trans</Trans>
            </Button>
          </td>
        </tr>
      )
    })
  }

  render() {
    // i18n
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb
          title={t("gestores.list.title")}
          linkTo="/home" component={() => (
            <Button size="sm" color="success"
              handleclick={() => this.props.history.push('/gestores/user/new')}>
              <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
            </Button>
          )} />

        <div className="row">
          <div className="col-12">
            <div id="card">
              <table className="table table-fluid table-striped text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="gestores.list.table.nome">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="gestores.list.table.telefone">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="gestores.list.table.email">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="gestores.list.table.recebe_email">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="gestores.list.table.acao">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderGestores()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal id="modalDeletarGestor"
          title={t("gestores.list.modal.title")}
          text={t("gestores.list.modal.text")}
          handleClick={() => this.removerGestor(this.state.gestor)}
          size="sm"
          btnColor="danger"
          btnText={t("gestores.list.modal.buttonRemover")}
          btnCancelarText={t("gestores.list.modal.buttonCancelar")} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    gestores: state.gestor.gestores
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchGestores,
  unsetLoading,
  removeGestor,
  setLoading,
  setGestor,
  getCountUsersNotValidated
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GestorList))
import axios from 'axios';
import consts from '../../consts';
import { toastr } from 'react-redux-toastr';
import { unsetLoading, setLoading } from '../loadingSpinner/loadingSpinnerActions';
import firebase from '../../config/fbConfig';

// i18n
import i18n from '../../_i18n/i18n';

const BASE_URL = consts.API_URL;

const nop = () => {
  return {
    type: 'NOP',
  };
};

export const clearFornecedores = () => {
  return {
    type: 'CLEAR_FORNECEDORES',
  };
};

export const setFornecedor = (fornecedor) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_FORNECEDOR',
      payload: fornecedor,
    });
  };
};

export const setFornecedores = (fornecedores) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_FORNECEDORES',
      payload: fornecedores,
    });
  };
};

export const fetchFornecedores = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/fornecedores`)
      .then((res) => {
        return dispatch({
          type: 'FETCH_FORNECEDORES',
          payload: res.data.data,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const fetchFornecedorByUserId = (userId) => {
  console.log('fetching by user id');
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/fornecedores/user/${userId}`)
      .then((res) => {
        return dispatch({
          type: 'SET_FORNECEDOR',
          payload: res.data.data[0],
        });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const saveImage = (blob, blobName) => {
  return (dispatch) => {
    // create a storage ref
    const storageRef = firebase.storage().ref(blobName);

    // upload file
    const task = storageRef.put(blob);

    // upload progress bar
    task.on(
      'state_changed',
      function progress(snapshot) {},

      function error(err) {},

      function complete() {
        return dispatch(fetchFornecedores());
      }
    );
  };
};

export const saveFornecedor = (user, fornecedor) => {
  return (dispatch) => {
    if (!user) {
      toastr.error(
        i18n.t('fornecedores.toastr.save.error.title'),
        i18n.t('fornecedores.toastr.save.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t('fornecedores.toastr.save.error.title'),
        i18n.t('fornecedores.toastr.save.error.message')
      );
      return dispatch(nop());
    }

    return axios
      .post(`${BASE_URL}/fornecedores`, { user, fornecedor })
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.save.error.title'),
            i18n.t('fornecedores.toastr.save.error.message')
          );
          return dispatch([nop(), unsetLoading()]);
        }

        toastr.success(
          i18n.t('fornecedores.toastr.save.success.title'),
          i18n.t('fornecedores.toastr.save.success.message')
        );
        return dispatch([fetchFornecedores(), unsetLoading()]);
      })
      .catch((err) => {
        if (err.response.status !== 200) {
          toastr.error(
            i18n.t('fornecedores.toastr.save.error.title'),
            err.response.data.message
          );
          return dispatch(unsetLoading());
        }

        unsetLoading();
        toastr.error(
          i18n.t('fornecedores.toastr.save.error.title'),
          i18n.t('fornecedores.toastr.save.error.message')
        );
        throw err;
      });
  };
};

export const updateFornecedor = (user, fornecedor) => {
  return (dispatch, getState) => {
    if (!user) {
      toastr.error(
        i18n.t('fornecedores.toastr.update.error.title'),
        i18n.t('fornecedores.toastr.update.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t('fornecedores.toastr.update.error.title'),
        i18n.t('fornecedores.toastr.update.error.message')
      );
      return dispatch(nop());
    }

    axios
      .put(`${BASE_URL}/fornecedores`, {
        user,
        fornecedor,
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.update.error.title'),
            i18n.t('fornecedores.toastr.update.error.message')
          );
          return dispatch(nop());
        }

        toastr.success(
          i18n.t('fornecedores.toastr.save.success.title'),
          i18n.t('fornecedores.toastr.save.success.message')
        );

        // set the new fornecedor on the list to update the state
        const updatedFornecedor = {
          id: fornecedor.id,
          user_id: user.id,
          nome: user.nome,
          telefone: fornecedor.telefone,
          email: user.email,
          senha: user.senha,
          status: user.status,
          tipo: user.tipo,
        };

        const { fornecedores } = getState().fornecedor;

        const updatedFornecedores = [
          ...fornecedores.filter((f) => {
            return f.id !== fornecedor.id;
          }),
          updatedFornecedor,
        ];

        return dispatch({
          type: 'FETCH_FORNECEDORES',
          payload: updatedFornecedores,
        });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const removeFornecedor = (fornecedor) => {
  return (dispatch) => {
    const fornecedorId = fornecedor.id;
    const userId = fornecedor.user_id;

    if (!fornecedorId) {
      toastr.error(
        i18n.t('fornecedores.toastr.remove.error.title'),
        i18n.t('fornecedores.toastr.remove.error.message')
      );
      return dispatch(nop());
    }

    if (!userId) {
      toastr.error(
        i18n.t('fornecedores.toastr.remove.error.title'),
        i18n.t('fornecedores.toastr.remove.error.message')
      );
      return dispatch(nop());
    }

    const reqBody = {
      fornecedor: {
        id: fornecedorId,
      },
      user: {
        id: userId,
      },
    };

    return axios
      .delete(`${BASE_URL}/fornecedores`, {
        headers: {},
        data: reqBody,
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.remove.error.title'),
            i18n.t('fornecedores.toastr.remove.error.message')
          );
          return dispatch(nop());
        }

        toastr.success(
          i18n.t('fornecedores.toastr.remove.success.title'),
          i18n.t('fornecedores.toastr.remove.success.message')
        );
        return dispatch(fetchFornecedores());
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const unattendFornecedor = (
  representante,
  pontoDeVenda,
  fornecedor,
  motivo
) => {
  return (dispatch) => {
    if (!representante) {
      toastr.error(
        i18n.t('fornecedores.toastr.unattend.error.title'),
        i18n.t('fornecedores.toastr.unattend.error.message')
      );
      return dispatch(nop());
    }

    if (!pontoDeVenda) {
      toastr.error(
        i18n.t('fornecedores.toastr.unattend.error.title'),
        i18n.t('fornecedores.toastr.unattend.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedor) {
      toastr.error(
        i18n.t('fornecedores.toastr.unattend.error.title'),
        i18n.t('fornecedores.toastr.unattend.error.message')
      );
      return dispatch(nop());
    }

    return axios
      .post(
        `${BASE_URL}/representantes/${representante.id}/un_attend/pontosDeVenda/${pontoDeVenda.id}/fornecedores/${fornecedor.id}`,
        {
          motivo,
        }
      )
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.unattend.error.title'),
            i18n.t('fornecedores.toastr.unattend.error.message')
          );
          return dispatch(nop());
        }

        toastr.info(
          i18n.t('fornecedores.toastr.unattend.info.title'),
          i18n.t('fornecedores.toastr.unattend.info.message')
        );
        dispatch(nop());
      })
      .catch((err) => {
        toastr.error(
          i18n.t('fornecedores.toastr.unattend.error.title'),
          i18n.t('fornecedores.toastr.unattend.error.message')
        );
        throw err;
      });
  };
};

export const fetchFornecedoresToUnattend = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/representantes/fornecedores/un_attended`)
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.fetchUnattend.error.title'),
            i18n.t('fornecedores.toastr.fetchUnattend.error.message')
          );
          return dispatch(nop());
        }

        return dispatch(setFornecedores(res.data.data));
      })
      .catch((err) => {
        toastr.error(
          i18n.t('fornecedores.toastr.fetchUnattend.error.title'),
          i18n.t('fornecedores.toastr.fetchUnattend.error.message')
        );
        throw err;
      });
  };
};

export const removeFornecedorFromPontoDeVenda = (
  pontoDeVendaId,
  fornecedorId,
  representanteId
) => {
  return (dispatch) => {
    if (!pontoDeVendaId) {
      toastr.error(
        i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.title'),
        i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedorId) {
      toastr.error(
        i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.title'),
        i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.message')
      );
      return dispatch(nop());
    }

    if (!representanteId) {
      toastr.error(
        i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.title'),
        i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.message')
      );
      return dispatch(nop());
    }

    return axios
      .delete(
        `${BASE_URL}/fornecedores/${fornecedorId}/remove/pontosDeVenda/${pontoDeVendaId}/representante/${representanteId}`
      )
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.title'),
            i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.message')
          );
        }

        dispatch(nop());
      })
      .catch((err) => {
        toastr.error(
          i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.title'),
          i18n.t('fornecedores.toastr.removeFromPontoDeVenda.error.message')
        );
        throw err;
      });
  };
};

export const attendFornecedor = (
  pontoDeVendaId,
  fornecedorId,
  representanteId
) => {
  return (dispatch) => {
    if (!pontoDeVendaId) {
      toastr.error(
        i18n.t('fornecedores.toastr.attend.error.title'),
        i18n.t('fornecedores.toastr.attend.error.message')
      );
      return dispatch(nop());
    }

    if (!fornecedorId) {
      toastr.error(
        i18n.t('fornecedores.toastr.attend.error.title'),
        i18n.t('fornecedores.toastr.attend.error.message')
      );
      return dispatch(nop());
    }

    if (!representanteId) {
      toastr.error(
        i18n.t('fornecedores.toastr.attend.error.title'),
        i18n.t('fornecedores.toastr.attend.error.message')
      );
      return dispatch(nop());
    }

    return axios
      .delete(
        `${BASE_URL}/representantes/${representanteId}/attend/pontosDeVenda/${pontoDeVendaId}/fornecedores/${fornecedorId}`
      )
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('fornecedores.toastr.attend.error.title'),
            i18n.t('fornecedores.toastr.attend.error.message')
          );
          dispatch(nop());
        }
      })
      .catch((err) => {
        toastr.error(
          i18n.t('fornecedores.toastr.attend.error.title'),
          i18n.t('fornecedores.toastr.attend.error.message')
        );
        throw err;
      });
  };
};

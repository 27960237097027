export default {
  list: {
    title: 'Mappa del calore - Punti vendita',
    notFound: 'Nessun punto vendita trovato ...',
    table: {
      notVisited: 'Non visitato',
      codigoIdentificacao: 'Codice muro',
      nome: 'Ragione sociale',
      cidade: 'Comune',
      endereco: 'Indirizzo',
      representante: 'Rappresentante',
      frequenciaVisita: {
        label: 'Frequenza di visita',
        small: '(giorni)'
      },
      obs: 'Osservazioni',
      ultimaVisita: 'Ultima visita',
      tempoUltimaVisita: {
        label: "Tempo dall'ultima visita",
        small: '(giorni)'
      },
      proximaVisita: {
        label: 'Prossima visita in',
        small: '(giorni)'
      },
      fornecedores: 'Fornitori',
      acao: 'Azione',
      buttonVisitar: 'Visita',
      buttonConsultar: 'Consultare',
      buttonRemover: 'Remove',
      modalDelete: {
        title: 'Modulo di esclusione del punto vendita',
        title2: "Motivo dell'esclusione",
        small: 'Questa esclusione sarà approvata dal management team di Franchini, potrebbe esserti chiesto di giustificare questi argomenti.',
        buttonCancelar: 'Annullare',
        buttonConfirmar: 'Conferma'
      },
      modalContinueVisitacao: {
        title: 'In attesa di visita in questo punto vendita',
        text: "C'è una visita che è stata precedentemente avviata in questo punto vendita, vuoi continuare?",
        buttonSim: 'Sì',
        buttonNao: 'Non'
      }
    }
  }
}
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import axios from 'axios'

import consts from '../../consts'

const BASE_URL = consts.API_URL;

export default function SelectLanguage() {
  const selectedLang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();

  const user = useSelector(state => state.auth.user);

  const changeLanguage = async lang => {
    i18n.changeLanguage(lang);

    // change localstorage lang
    localStorage.setItem('lang', lang);

    try {
      // change user lang
      await axios.put(`${BASE_URL}/usuarios/${user.id}/lang`, {
        lang
      });
    } catch (e) {
      console.log(`%c Erro ao alterar o idioma do usuário: ${e}`, 'color: tomato');
    }
  }

  return (
    <div className="p-3">
      <p>{t("settings.language.text")}</p>

      <select name="lang" id="lang" className="form-control"
        onChange={e => changeLanguage(e.target.value)}>
        <option value="pt" selected={selectedLang === 'pt'}>Português</option>
        <option value="it" selected={selectedLang === 'it'}>Italiano</option>
      </select>
    </div>
  )
}

const INITIAL_STATE = {
  features: []
}

const geolocationReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'SET_FEATURES':
      return {
        ...state,
        features: action.payload
      }
    case 'CLEAR_FEATURES':
      return {
        ...state,
        features: []
      }
    default:
      return state
  }
}

export default geolocationReducer

import React, { Component } from 'react'
import Breadcrumb from '../../template/breadcrumb'
import { Redirect } from 'react-router-dom'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setContato, updateContato } from './contatoActions'

export class ContatoEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReadonly: true
    }
  }

  componentDidMount() {
    this.loadInfo()

    // Inputmask("+39 999 999 9999").mask(document.getElementById('telefone'))
    // Inputmask("+39 999 999 9999").mask(document.getElementById('celular'))
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  loadInfo = () => {
    const { nome, descricao, telefone, celular, cargo, email } = this.props.contato

    document.getElementById('nome').value = nome
    document.getElementById('descricao').value = descricao
    document.getElementById('telefone').value = telefone
    document.getElementById('celular').value = celular
    document.getElementById('cargo').value = cargo
    document.getElementById('email').value = email
  }

  handleSubmit = e => {
    e.preventDefault()
    
    const contato = {
      id: this.props.contato.id,
      nome: e.target.nome.value,
      descricao: e.target.descricao.value,
      telefone: e.target.telefone.value,
      celular: e.target.celular.value,
      cargo: e.target.cargo.value,
      email: e.target.email.value
    }
    
    // update contato
    this.props.updateContato(contato, this.props.pontoDeVenda)

    // redirect
    this.props.history.push('/pontosDeVenda/edit')
  }

  render() {
    if(!this.props.pontoDeVenda || !this.props.contato) {
      return <Redirect to="/pontosDeVenda/edit" />
    }

    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("pontosDeVenda.edit.contatos.edit.title")} linkTo="/pontosDeVenda/edit" />

        <div className="box-login mt-4 mb-3">
          <form onSubmit={this.handleSubmit} className="mt-3">   
            <div className="form-group">
              <label htmlFor="nome">
                <Trans i18nKey="pontosDeVenda.edit.contatos.edit.nome">trans</Trans>
              </label>
              <input type="text" className="form-control" name="nome" id="nome" 
                readOnly={this.state.isReadonly}
                onChange={this.handleChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="cargo">
                <Trans i18nKey="pontosDeVenda.edit.contatos.edit.cargo">trans</Trans>
              </label>
              <input type="text" className="form-control" name="cargo" id="cargo" 
                readOnly={this.state.isReadonly}
                onChange={this.handleChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="telefone">
                <Trans i18nKey="pontosDeVenda.edit.contatos.edit.telefone">trans</Trans>
              </label>
              <input type="text" className="form-control" readOnly={this.state.isReadonly}
                name="telefone" id="telefone" onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <label htmlFor="celular">
                <Trans i18nKey="pontosDeVenda.edit.contatos.edit.celular">trans</Trans>
              </label>
              <input type="text" className="form-control" readOnly={this.state.isReadonly}
                name="celular" id="celular" onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                <Trans i18nKey="pontosDeVenda.edit.contatos.edit.nome">trans</Trans>
              </label>
              <input type="email" className="form-control" name="email" id="email" 
                readOnly={this.state.isReadonly}
                onChange={this.handleChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="descricao">
                <Trans i18nKey="pontosDeVenda.edit.contatos.edit.obs">trans</Trans>
              </label>
              <input type="text" className="form-control" name="descricao" id="descricao"
                readOnly={this.state.isReadonly}
                onChange={this.handleChange} required/>
            </div>
      
            {
              this.state.isReadonly ? (
                <button type="button" className="btn btn-primary btn-block"
                  onClick={this.changeReadonly}>
                  <Trans i18nKey="pontosDeVenda.edit.contatos.edit.buttonHabilitarCampos">trans</Trans>
                </button>
              ) : (
                <div className="row">
                  <div className="col-6">
                    <button type="button" className="btn btn-secondary btn-block"
                      onClick={this.changeReadonly}>
                      <Trans i18nKey="pontosDeVenda.edit.contatos.edit.buttonCancelar">trans</Trans>
                    </button>
                  </div>
                  <div className="col-6">
                    <button type="submit" className="btn btn-primary btn-block">
                      <Trans i18nKey="pontosDeVenda.edit.contatos.edit.buttonSalvar">trans</Trans>
                    </button>
                  </div>
                </div>
              )
            }
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    pontoDeVenda: state.pontoDeVenda.pontoDeVenda,
    contato: state.contato.contato
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  setContato, 
  updateContato 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContatoEdit))

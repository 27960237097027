import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Breadcrumb = props => {
  const {
    component: Component,
    isHideBackBtn,
    btnFunction,
    linkTo,
    title
  } = props

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="d-flex" style={{ alignItems: 'center' }}>
          { isHideBackBtn ? null : (
            <Link onClick={() => btnFunction ? btnFunction() : null} to={linkTo}>
              <i className="material-icons primary">arrow_back_ios</i>
            </Link>
          )}
          <h4>{title}</h4>

          {Component ? (
            <div className="d-flex justify-content-end" style={{ flex: 2 }}>
              <Component />
            </div>
          ) : null}
        </div>
        <hr className="divider"/>
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  component: PropTypes.any,
  isHideBackBtn: PropTypes.bool,
  btnFunction: PropTypes.func,
  linkTo: PropTypes.string,
  title: PropTypes.string
}

Breadcrumb.defaultProps = {
  isHideBackBtn: false,
  linkTo: '/home',
  title: 'Voltar ao início'
}

export default memo(Breadcrumb)

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unsetLoading } from './components/loadingSpinner/loadingSpinnerActions'

import { css } from '@emotion/core'
import { ClipLoader } from 'react-spinners'
import OfflineIndicator from './components/template/offlineIndicator/index'
import Main from './components/main/main'
import {
  syncDatabase,
  openDatabaseIndexedDB,
  isDataSync
} from './config/idbConfig'
import { setTables } from './components/databaseSync/databaseSyncActions'
import { useTranslation } from 'react-i18next'
import { saveImage } from './components/visita/visitaActions'

const override = css`
    display: block;
    margin: 0 auto;
`;

export default function App() {
  // application state
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.loading.loading);
  const loadingMessage = useSelector(state => state.loading.message);

  // offline database
  const tables = useSelector(state => state.databaseSync.tables);

  // logged user
  const user = useSelector(state => state.auth.user);

  const { i18n } = useTranslation();

  const checkLanguage = () => {
    const lang = user?.lang || 'it';

    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);

    dispatch(unsetLoading());
  }

  async function updateOfflineDatabase() {
    // check if tables are up to date
    const isSync = await checkTablesSync();

    // if not, update tables
    if (!isSync) await syncDatabase(tables);
  }

  function checkTablesSync() {
    return new Promise(async (resolve, reject) => {
      try {
        let isDatabaseSync = true;
        const db = await openDatabaseIndexedDB('franchini-offline');

        const newTablesPromise = tables.map(async t => {
          const isTableSync = await isDataSync(db, t.table, t.url);

          if (!isTableSync) isDatabaseSync = false;

          return {
            ...t,
            sync: isTableSync
          };
        });

        const newTables = await Promise.all(newTablesPromise);

        dispatch(setTables(newTables));

        return resolve(isDatabaseSync);
      } catch (e) {
        return reject(e);
      }
    });
  }

  async function uploadImages() {
    try {
      const db = await openDatabaseIndexedDB('franchini-offline');

      const images = await db.getAll('imagem');

      await db.clear('imagem');

      for (let i = 0; i < images.length; i++) {
        const { data } = images[i];

        const { blob, blobName } = data;

        dispatch(saveImage(blob, blobName));
      }
    } catch (e) {
      console.log('Falha ao salvar imagens', e);
    }
  }

  useEffect(() => {
    // check default user language
    checkLanguage();

    // check if offline database is up to date
    updateOfflineDatabase();

    // upload images that couldn't be uploaded before
    uploadImages();
  }, []);

  return (
    <>
      <OfflineIndicator />

      <div className="App">
        <Main />

        <div style={{ position: 'fixed', left: '50%', top: '35%', zIndex: 2, color: '#F0F8FF' }}>
          <div className='sweet-loading spinner-bg' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={'#F0F8FF'}
              loading={isLoading}
            />
            {loadingMessage}
          </div>
        </div>
      </div>

      <div className="loading-background" style={{ display: isLoading ? 'block' : 'none' }}></div>
    </>
  )
}

import React, { Component } from 'react'
import Modal from '../template/modal.jsx'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRepresentantes, removeRepresentante, setRepresentante } from './representanteActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions'

class RepresentanteList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      representante: null
    }
  }

  componentDidMount() {
    this.props.setLoading()

    this.props.fetchRepresentantes()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.representantes !== this.props.representantes) {
      this.props.unsetLoading()
    }
  }

  removerRepresentante = async representante => {
    await this.props.removeRepresentante(representante);
    this.props.getCountUsersNotValidated();
  }

  editarRepresentante = representante => {
    // set representante
    this.props.setRepresentante(representante)
    this.props.history.push('/representantes/edit')
  }

  setRepresentante = representante => {
    this.setState({
      ...this.state,
      representante
    })
  }

  renderRepresentantes = () => {
    const representantes = this.props.representantes || []

    // i18n
    const { t } = this.props;

    return representantes.map(representante => {
      return (
        <tr key={representante.id}>
          <td data-label={t("representantes.list.table.nome")} className="align-middle">{representante.nome}</td>
          <td data-label={t("representantes.list.table.telefone")} className="align-middle">{representante.telefone}</td>
          <td data-label={t("representantes.list.table.email")} className="align-middle">
            {
              representante.email.length > 36 && visualViewport.width <= 600 
                ? representante.email.substr(0, 33) + '...' 
                : representante.email
            }
          </td>
          <td data-label={t("representantes.list.table.acao")}>
            <Button size="sm" color="warning"
              handleclick={() => this.editarRepresentante(representante)}>
                <Trans i18nKey="representantes.list.table.buttonEditar">trans</Trans>
            </Button>
            <Button className="ml-2" size="sm" color="danger"
              handleclick={() => this.setRepresentante(representante)}
              data-toggle="modal" data-target="#modalDeletarRepresentante">
                <Trans i18nKey="representantes.list.table.buttonRemover">trans</Trans>
            </Button>
          </td>
        </tr>
      )
    })
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("representantes.list.title")}
          linkTo="/home" component={() => (
            <Button size="sm" color="success"
              handleclick={() => this.props.history.push('/representantes/user/new')}>
                <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
            </Button>
          )} />
        
        <div className="row">
          <div className="col-12">
            <div id="card">
              <table className="table table-fluid table-striped text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="representantes.list.table.nome">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="representantes.list.table.telefone">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="representantes.list.table.email">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="representantes.list.table.acao">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderRepresentantes()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal id="modalDeletarRepresentante" 
          title={t("representantes.list.modal.title")} 
          text={t("representantes.list.modal.text")} 
          handleClick={() => this.removerRepresentante(this.state.representante)} 
          size="sm" 
          btnColor="danger" 
          btnText={t("representantes.list.modal.buttonRemover")}
          btnCancelarText={t("representantes.list.modal.buttonCancelar")} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    representantes: state.representante.representantes
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  getCountUsersNotValidated,
  fetchRepresentantes, 
  removeRepresentante, 
  setRepresentante,
  unsetLoading,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RepresentanteList))

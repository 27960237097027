export default {
  title: 'Impostazioni',
  home: {
    text: 'Seleziona la configurazione desiderata nel menu a fianco'
  },
  requestsQueue: {
    title: 'Coda di richiesta',
    buttonRefresh: {
      title: 'Richieste di aggiornamento'
    },
    buttonForceRequests: {
      title: 'Richieste di forza'
    },
    table: {
      date: 'Dati/ora',
      method: 'Metodo',
      url: 'URL',
      body: 'Corpo',
      headers: 'Intestazioni'
    },
    notFound: 'Nessuna richiesta in attesa in coda'
  },
  offlineRequests: {
    title: 'Richieste offline',
    buttonRefresh: {
      title: 'Richieste di aggiornamento'
    },
    table: {
      date: 'Dati/ora',
      method: 'Metodo',
      url: 'URL',
      body: 'Corpo'
    },
    notFound: 'Nessuna richiesta trovata'
  },
  databaseSync: {
    title: 'Sincronizza database',
    text: 'Sincronizza i dati offline con il database ...',
    buttonSync: {
      title: 'Scarica le tabelle'
    },
    table: {
      tabela: 'Tavolo',
      sincronizado: 'Sincronizzato?'
    }
  },
  geoposition: {
    title: 'La tua posizione attuale',
    text: 'Sei qui: '
  },
  language: {
    title: 'Seleziona la lingua',
    text: 'Seleziona la tua lingua'
  },
  timezone: {
    title: 'Seleziona il fuso orario',
    text: 'Seleziona il fuso orario per la tua regione'
  },
  mockPedidoVenda: {
    title: "Mock up del layout dell'ordine di vendita"
  },
}

import axios from 'axios';
import consts from '../../consts';

const BASE_URL = consts.API_URL;

const nop = () => {
  return {
    type: 'NOP'
  }
}

export const setExpositores = expositores => {
  return {
    type: 'EXPOSITOR_LIST_SET_EXPOSITORES',
    payload: expositores
  }
}

export const clearAll = () => {
  return {
    type: 'EXPOSITOR_LIST_CLEAR_ALL'
  }
}

export const fetchExpositores = () => {
  return async dispatch => {
    try {
      const response = await axios.get(`${BASE_URL}/expositores`);

      return dispatch(setExpositores(response.data.data));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'

import { Trans, useTranslation } from 'react-i18next'

import { useSelector, useDispatch } from 'react-redux'
import { fetchRepresentantesByPontoDeVenda } from '../representante/representanteActions'
import { bindPontoDeVendaToRepresentante, removeLinkToRepresentante } from './pontoDeVendaActions'

export default function BindPontoDeVendaToRepresentante() {
  const pontoDeVenda = useSelector(state => state.pontoDeVenda.pontoDeVenda);
  const representantes = useSelector(state => state.representante.representantes);
  const representante = useSelector(state => state.representante.representante);

  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (!pontoDeVenda) {
      history.push('/home');
      return;
    }

    dispatch(fetchRepresentantesByPontoDeVenda(pontoDeVenda.id));
  }, []);

  if (!pontoDeVenda) {
    return <Redirect to="/home" />;
  }

  const pageTitle = `${t("pontosDeVenda.edit.representantes.link.title")} <${pontoDeVenda.nome}>`;

  function bindRepresentanteToPontoDeVenda(r) {
    const pontoDeVendaRepresentanteId =
      representante && representante.ponto_de_venda_representante_id;

    // as the ponto de venda can only have one representante this will remove (if exists)
    // the actual link between ponto de venda and representante
    if (pontoDeVendaRepresentanteId) dispatch(removeLinkToRepresentante(pontoDeVendaRepresentanteId));

    dispatch(bindPontoDeVendaToRepresentante(pontoDeVenda, r));

    // redirect the user
    history.push('/pontosDeVenda/edit');
  }

  function unbindRepresentanteFromPontoDeVendaByRepresentanteAndPontoDeVenda(representante) {
    const representanteId = representante.id;

    if (!pontoDeVenda.id) {
      return;
    }

    if (!representanteId) {
      return;
    }

    dispatch(unbindRepresentanteFromPontoDeVendaByRepresentanteAndPontoDeVenda(
      representanteId,
      pontoDeVenda.id
    ));

    // redirect the user
    history.push('/pontosDeVenda/edit');
  }

  function ButtonVincular(props) {
    const { representante } = props;

    return (
      <button className="btn btn-sm btn-info mb-2" onClick={() => bindRepresentanteToPontoDeVenda(representante)}>
        <Trans i18nKey="pontosDeVenda.edit.representantes.link.table.buttonSelecionar">trans</Trans>
      </button>
    )
  }

  function ButtonDesvincular(props) {
    const { representante } = props;

    return (
      <Button color="danger" size="sm"
        handleclick={() => unbindRepresentanteFromPontoDeVendaByRepresentanteAndPontoDeVenda(representante)}>
        <Trans i18nKey="pontosDeVenda.edit.representantes.link.table.buttonDesvincular">trans</Trans>
      </Button>
    )
  }

  function renderRepresentantes(representantes) {
    return representantes.map(representante => {
      return (
        <tr key={representante.id}>
          <td data-label={t("pontosDeVenda.edit.representantes.link.table.nome")} className="align-middle">{representante.nome}</td>
          <td data-label={t("pontosDeVenda.edit.representantes.link.table.telefone")} className="align-middle">{representante.telefone}</td>
          <td data-label={t("pontosDeVenda.edit.representantes.link.table.email")} className="align-middle">
            {
              representante.email.length > 36 && visualViewport.width <= 600
                ? representante.email.substr(0, 33) + '...'
                : representante.email
            }
          </td>
          <td data-label={t("pontosDeVenda.edit.representantes.link.table.acao")}>
            {
              representante.representante_id === null ?
                <ButtonVincular representante={representante} />
                : (
                  <ButtonDesvincular representante={representante} />
                )
            }
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      <Breadcrumb title={pageTitle} linkTo="/pontosDeVenda/edit" />

      <div className="row">
        <div className="col-12">
          <div id="card" style={{ overflowX: 'auto' }}>
            <table className="table table-fluid table-striped text-center">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">
                    <Trans i18nKey="pontosDeVenda.edit.representantes.link.table.nome">trans</Trans>
                  </th>
                  <th scope="col">
                    <Trans i18nKey="pontosDeVenda.edit.representantes.link.table.telefone">trans</Trans>
                  </th>
                  <th scope="col">
                    <Trans i18nKey="pontosDeVenda.edit.representantes.link.table.email">trans</Trans>
                  </th>
                  <th scope="col">
                    <Trans i18nKey="pontosDeVenda.edit.representantes.link.table.acao">trans</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderRepresentantes(representantes)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

export const login = (user) => {
  return dispatch => {
    if(!user.email) {
      toastr.warning(
        i18n.t("login.toastr.auth.email.warning.title"),
        i18n.t("login.toastr.auth.email.warning.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    if(!user.senha) {
      toastr.warning(
        i18n.t("login.toastr.auth.senha.warning.title"),
        i18n.t("login.toastr.auth.senha.warning.message")
      )
      return dispatch([nop(), unsetLoading()])
    }

    axios.post(`${BASE_URL}/auth`, {
      user
    })
    .then(res => {
      if(res.data.error.status === 'INVALID_EMAIL') {
        console.log("email invalido")
        toastr.error(
          i18n.t("login.toastr.auth.auth.error.title"),
          i18n.t("login.toastr.auth.auth.error.message")
        )
        
        return dispatch(unsetLoading())
      }
      
      if(res.data.error.status === 'INVALID_PASSWORD') {
        toastr.error(
          i18n.t("login.toastr.auth.auth.error.title"),
          i18n.t("login.toastr.auth.auth.error.message")
        )

        return dispatch(unsetLoading())
      }

      if(res.data.data[0].status !== 'VA') {
        toastr.warning(
          i18n.t("login.toastr.auth.validation.warning.title"),
          i18n.t("login.toastr.auth.validation.warning.message")
        )
        return dispatch([nop(), unsetLoading()])
      }

      toastr.success(
        i18n.t("login.toastr.auth.success.title"),
        i18n.t("login.toastr.auth.success.message")
      )
      return dispatch([{
        type: 'LOGIN',
        payload: res.data.data[0]
      }, unsetLoading()])
    })
    .catch(err => {
      toastr.error(
        i18n.t("login.toastr.auth.error.title"),
        i18n.t("login.toastr.auth.error.message")
      )
      dispatch(unsetLoading())
      throw err
    })
  }
}

export const logout = () => {
  return dispatch => {
    return dispatch({
      type: 'LOGOUT'
    })
  }
}

export const recoverPassword = email => {
  return dispatch => {
    if(!email) {
      toastr.warning(
        i18n.t("login.recoverPassword.warning.title"),
        i18n.t("login.recoverPassword.warning.message")
      )
      return dispatch(nop())
    }

    axios.post(`${BASE_URL}/password/recovery`, { email })
      .then(res => {
        if(res.data.error) {
          toastr.error(
            i18n.t("login.recoverPassword.error.title"),
            i18n.t("login.recoverPassword.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("login.recoverPassword.success.title"),
          i18n.t("login.recoverPassword.success.message")
        )
        // email sent
        return dispatch(nop())
      })
      .catch(err => {
        throw err
      })
  }
}

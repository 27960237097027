import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changePassword } from '../user/userActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'

import { withTranslation, Trans } from 'react-i18next'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'

import { toastr } from 'react-redux-toastr'

class ChangePasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    if(this.isPasswordInvalid(e.target)) {
      return
    }

    const userId = this.props.fornecedor.user_id
    const email = this.props.fornecedor.email
    const password = e.target.password.value
    const confirmPassword = e.target.confirmPassword.value

    // set loading spinner
    this.props.setLoading()
    this.props.changePassword(userId, email, password, confirmPassword)

    this.props.history.push("/fornecedores")
  }

  isPasswordInvalid = form => {
    // i18n
    const { t } = this.props;

    if(form.password.value.length < 6) {
      toastr.warning(
        t("fornecedores.changePassword.validation.password.minLength.title"), 
        t("fornecedores.changePassword.validation.password.minLength.message")
      )
      return true
    }
    
    if(form.password.value !== form.confirmPassword.value) {
      toastr.warning(
        t("fornecedores.changePassword.validation.password.notEqualTo.title"), 
        t("fornecedores.changePassword.validation.password.notEqualTo.message")
      )
      return true
    }
    
    return false
  }

  render() {
    if(!this.props.fornecedor) return <Redirect to="/fornecedores" />

    // i18n
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("fornecedores.changePassword.title")} />

        <div className="form-container mt-3">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="password">
                <Trans i18nKey="fornecedores.changePassword.novaSenha.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="password" id="password" placeholder="******" required />
              <small className="form-text text-muted">
                <Trans i18nKey="fornecedores.changePassword.novaSenha.small">trans</Trans>
              </small>
            </div>
    
            <div className="form-group">
              <label htmlFor="confirmPassword">
                <Trans i18nKey="fornecedores.changePassword.confirmarSenha">trans</Trans>
              </label>
              <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" placeholder="******" required />
            </div>
    
            <hr className="divider"/>
    
            <button type="submit" className="btn btn-primary btn-block">
              <Trans i18nKey="fornecedores.changePassword.buttonSalvar">trans</Trans>
            </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    fornecedor: state.fornecedor.fornecedor
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  changePassword,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangePasswordForm))

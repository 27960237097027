const INITIAL_STATE = {
  tabelaPreco: null,
  tabelasPreco: [],
  produto: null,
  pontoDeVenda: null
}

const tabelaPrecoReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_TABELAS_PRECO':
      return {
        ...state,
        tabelasPreco: action.payload
      }
    case 'SET_TABELA_PRECO':
      return {
        ...state,
        tabelaPreco: action.payload
      }
    case 'TABELA_PRECO_SET_PRODUTO':
      return {
        ...state,
        produto: action.payload
      }
    case 'TABELA_PRECO_SET_PONTO_DE_VENDA':
        return {
          ...state,
          pontoDeVenda: action.payload
        }
    case 'CLEAR_TABELAS_PRECO':
      return {
        ...state,
        tabelaPreco: null,
        tabelasPreco: [],
        produto: null,
        pontoDeVenda: null
      }
    default: 
      return state
  }
}

export default tabelaPrecoReducer

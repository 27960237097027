export default {
  // representantes
  visitas: 'Visitas',
  pedidosVenda: 'Pedidos de venda',
  mapaCalor: 'Mapa de calor',

  // gestores
  clientes: 'Clientes',
  gestores: 'Gestores',
  representantes: 'Representantes',
  fornecedores: 'Fornecedores',
  produtos: 'Produtos',
  pontosDeVenda: 'Pontos de venda',
  tabelasPreco: 'Tabelas de preço',
  validacao: {
    validacao: 'Validação',
    exclusaoPontosDeVenda: 'Exclusão pontos de venda',
    novosCadastros: 'Novos cadastros',
    exclusaoFornecedores: 'Exclusão de fornecedores',
  },
  expositores: 'Expositores',

  alterarSenha: "Alterar senha",
  entrar: "Entrar",
  cadastrar: "Cadastre-se",

  reso: "Reso",
  estatistiche: "Estatistiche",
  planogrammi: "Planogrammi",
}

export function findGreater(array) {
  let greater = array[0];

  for (let i = 0; i < array.length; i++) {
    const number = array[i];

    if (number > greater) greater = number;
  }

  return greater;
}

export function groupBy(array, groupByKey, sumKey) {
  const map = new Map();

  array.forEach((item) => {
    const mapKey = item[groupByKey];

    const currentValue =
      map.get(mapKey)
        ? map.get(mapKey)[sumKey]
        : 0;

    map.set(mapKey, {
      ...item,
      [sumKey]: currentValue + item[sumKey]
    });
  });

  return Array.from(map, ([_, value]) => ({ ...value }));
}

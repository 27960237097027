import React from 'react';
import PropTypes from 'prop-types';

function Row({ children }) {
  return (
    <div className="row mb-3">
      {children}
    </div>
  )
}

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, 
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}

export default Row;

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../template/button'
import Icon from '../template/icon'
import ModalButton from '../template/modalButton'
import Modal from '../template/modal'

function ClienteList({ clientes, ...props }) {
  const [cliente, setCliente] = useState(null);

  // i18n
  const { t } = useTranslation();

  function renderClientes(clientes) {
    return clientes.map(cliente => (
      <tr key={cliente.id}>
        <td>{cliente.nome}</td>
        {(props.removeCliente || props.editCliente) && (
          <td>
            {props.removeCliente && (
              <ModalButton target="#modalDelete" size="sm" color="danger"
                handleclick={() => setCliente(cliente)}>
                <Icon icon="delete"/>
              </ModalButton>
            )}

            {props.editCliente && (
              <Button className="ml-1" size="sm" color="warning"
                handleclick={() => props.editCliente(cliente)}>
                <Icon icon="edit"/>
              </Button>
            )}
          </td>
        )}
      </tr>
    ))
  }

  return (
    <div>
      {clientes.length > 0 ? (
        <table className="table table-fluid table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">{t("clientes.table.cliente")}</th>
              {(props.removeCliente || props.editCliente) && <th scope="col">{t("clientes.table.acoes")}</th>}
            </tr>
          </thead>
          <tbody>
            {renderClientes(clientes)}
          </tbody>
        </table>
      ) : <p>{t("clientes.list.notFound")}</p>}

      {/* Modal */}
      <Modal id="modalDelete" 
        title={t("clientes.modalDelete.title")} 
        text={t("clientes.modalDelete.text")}  
        handleClick={() => props.removeCliente(cliente)} 
        size="sm" 
        btnColor="danger" 
        btnText={t("clientes.modalDelete.buttonRemover")}
        btnCancelarText={t("clientes.modalDelete.buttonCancelar")}/>
    </div>
  )
}

ClienteList.propTypes = {
  clientes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nome: PropTypes.string,
    imagem: PropTypes.string
  })),
  removeCliente: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  editCliente: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ])
}

ClienteList.defaultProps = {
  removeCliente: false,
  editCliente: false
}

export default ClienteList;

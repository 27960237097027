import React from 'react';

import ProdutoForm from '../produto/produtoForm';
import uuid from 'uuid';
import { saveImage } from '../produto/produtoActions';
import { useDispatch } from 'react-redux'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions';
import axios from 'axios';
import consts from '../../consts';

const BASE_URL = consts.API_URL;

export default function NewProdutoForm({ onSetProduto: handleSetProduto }) {
  const dispatch = useDispatch();

  async function handleSubmit(e) {
    e.preventDefault();

    const {
      ean,
      descricao_ean,
      descricao,
      unidade,
      artigo,
      quantidade_kit
    } = e.target;

    const imagem = e.target.imagem.files[0];
    let blobName = null;

    if (imagem) {
      const fileExtension = getFileExtension(imagem.name);
      blobName = `produtos/${descricao.value}/${uuid.v4() + fileExtension}`;
      dispatch(saveImage(imagem, blobName));
    }

    // if the produto does not have an image, is not necessary to send an 'image' attr
    const produtoImg = blobName ? {
      imagem: blobName
    } : {}

    const produto = {
      ...produtoImg,
      ean: ean.value,
      descricao_ean: descricao_ean.value,
      descricao: descricao.value,
      unidade: unidade.value,
      quantidade_kit: quantidade_kit.value,
      cod_artigo: artigo.value
    }

    const fornecedor = {
      id: e.target.fornecedor.value
    }

    dispatch(setLoading());

    // save produto
    const response = await axios.post(`${BASE_URL}/produtos`, { fornecedor, produto });

    // get produto
    const savedProdutoResponse = await axios.get(`${BASE_URL}/produtos/${response.data.data.insertId}`);

    // set produto on expositor
    handleSetProduto(savedProdutoResponse.data.data[0]);

    dispatch(unsetLoading());
  }

  function getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'));
  }

  return (
    <div>
      <ProdutoForm onSubmit={handleSubmit} />
    </div>
  )
}

/** Will split the string if it is too long */
function splitString(str) {
  if(str.length > 35) return str.substr(0, 34) + ' ...';

  return str;
}

export {
  splitString
}

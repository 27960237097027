import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { saveImage, saveLog, saveVisita } from './visitaActions';
import {
  setLoading,
  unsetLoading,
} from '../loadingSpinner/loadingSpinnerActions';
import axios from 'axios';
import consts from '../../consts';

import Alert from '../template/alert';
import Breadcrumb from '../template/breadcrumb';
import Button from '../template/button';
import FlexContainer from '../template/flexContainer';
import Grid from '../template/grid';
import ImageGallery from 'react-image-gallery';
import ImageInput from '../template/imageInput';
import Media from 'react-media';
import { Redirect } from 'react-router-dom';
import Row from '../template/row';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import defaultImg from '../common/imgs/default-img.png';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import uuid from 'uuid';

export class VisitaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,

      // images to image gallery
      images: [
        {
          original: defaultImg,
          thumbnail: defaultImg,
        },
      ],

      // dynamically show image inputs
      isShowImageInput1: true,
      isShowImageInput2: false,
      isShowImageInput3: false,

      //object save visita
      saveVisitObjects: {},

      // to redirect only after data is retrieved from api
      permissionToRedirectToNewPedidoVenda: false,
      redirectToNewPedidoVenda: false,
      userLocation: {
        latitude: 0.0,
        longitude: 0.0,
      },
    };
  }

  componentDidUpdate(prevProps) {
    // if (this.props.pedidoVenda === prevProps.pedidoVenda) {
    //   return
    // }

    if (!this.state.permissionToRedirectToNewPedidoVenda) {
      return;
    }

    // if (!this.props.pedidoVenda.fornecedor) {
    //   return;
    // }

    // if (!this.props.pedidoVenda.visita) {
    //   return;
    // }

    // if (!this.props.pedidoVenda.pontoDeVenda) {
    //   return;
    // }

    // redirect the user to new pedido de venda
    this.setState({
      redirectToNewPedidoVenda: true,
    });
    this.props.unsetLoading();
  }

  componentDidMount() {
    this.getUserLocation();
  }

  getUserLocation = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const latitude = pos.coords.latitude;
      const longitude = pos.coords.longitude;

      this.setState({
        ...this.state,
        userLocation: {
          latitude,
          longitude,
        },
      });
    });
  };

  renderAlertBody = (pontoDeVenda, fornecedor) => {
    return (
      <div>
        <b>
          <Trans i18nKey='visitas.wizard.new.form.pontoDeVenda'>trans</Trans>
        </b>{' '}
        {pontoDeVenda.nome} <br />
        <span>
          {pontoDeVenda.endereco}
          {pontoDeVenda.cidade ? `, ${pontoDeVenda.cidade}` : null}
          {pontoDeVenda.estado ? ` - ${pontoDeVenda.estado}` : null}
        </span>{' '}
        <br />
        <b>
          <Trans i18nKey='visitas.wizard.new.form.fornecedor'>trans</Trans>
        </b>{' '}
        {fornecedor.nome}
      </div>
    );
  };

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'));
  }

  loadImage = () => {
    const files = Array.from(
      document.getElementById('foto_ponto_de_venda').files
    );

    const images = files.map((file) => {
      const url = window.URL.createObjectURL(file);

      return {
        original: url,
        thumbnail: url,
      };
    });

    this.setState({
      images,
    });
  };

  handleLoadImage = (e) => {
    const id = e.target.id;
    const file = e.target.files[0];

    // show second image input
    if (id === 'visita-image-1') this.setState({ isShowImageInput2: true });

    // show third image input
    if (id === 'visita-image-2') this.setState({ isShowImageInput3: true });

    if (!file) return;

    const url = window.URL.createObjectURL(file);

    // remove the default img
    // replace the previous input image
    const images = this.state.images.filter(
      (image) => image.original !== defaultImg && image.id !== id
    );
    this.setState({
      images: [
        ...images,
        {
          id,
          files: file,
          original: url,
          thumbnail: url,
        },
      ],
    });
  };

  handleDeleteImage = (e) => {
    const id = e.target.id;
    if (id === 'visita-image-1') {
      const imagesFilter = this.state?.images?.filter(
        (image) => image.id !== id
      );
      this.setState({ images: imagesFilter });
    }
    if (id === 'visita-image-2') {
      const imagesFilter = this.state?.images?.filter(
        (image) => image.id !== id
      );
      this.setState({ isShowImageInput2: false });
      this.setState({ images: imagesFilter });
    }
    if (id === 'visita-image-3') {
      const imagesFilter = this.state?.images?.filter(
        (image) => image.id !== id
      );
      this.setState({ isShowImageInput3: false });
      this.setState({ images: imagesFilter });
    }
    // if (id === 'visita-image-1') this.setState({ isShowImageInput2: true });
  };

  handleSaveImage = (pontoDeVenda, image) => {
    try {
      let blobName = null;

      if (image) {
        const fileExtension = this.getFileExtension(image.name);
        blobName = `visitas/${pontoDeVenda.nome}/${uuid.v4() + fileExtension}`;
        this.props.saveImage(image, blobName);
      }

      return blobName;
    } catch (error) {
      const userId = this.props.user.id;
      const pvId = this.props.currentVisita.pontoDeVenda.id;
      const fnId = this.props.currentVisita.fornecedor.id;
      const message = error.toString();

      this.props.saveLog(userId, pvId, fnId, message);
    }
  };

  handleSaveVisita = async (callback, checkButton) => {
    const { pontoDeVenda, fornecedor, representante, visitacao } = this.props;

    const file_1 = this.state.images[0]?.files;
    const file_2 =
      document.getElementById('visita-image-2') && this.state.images[1]?.files;
    const file_3 =
      document.getElementById('visita-image-3') && this.state.images[2]?.files;

    // min 1 image
    if (!file_1) {
      toastr.warning(
        'Está faltando algo',
        'Por favor, selecione uma foto do ponto de venda'
      );
      this.props.unsetLoading();
      return;
    }

    let visita = {};

    // save images
    if (file_3) {
      const blobName = this.handleSaveImage(pontoDeVenda, file_3);

      visita = {
        ...visita,
        foto_ponto_de_venda_3: blobName,
      };
    }

    if (file_2) {
      const blobName = this.handleSaveImage(pontoDeVenda, file_2);

      visita = {
        ...visita,
        foto_ponto_de_venda_2: blobName,
      };
    }

    const blobName = this.handleSaveImage(pontoDeVenda, file_1);

    visita = {
      ...visita,
      foto_ponto_de_venda: blobName,
    };

    // visita attrs
    const estoque_alto = document.getElementById('estoque_alto').checked;
    const exposicao_completa = document.getElementById('exposicao_completa')
      .checked;
    const gerente_rejeitou_pedido = document.getElementById(
      'gerente_rejeitou_pedido'
    ).checked;
    const expositor_organizado = document.getElementById('expositor_organizado')
      .checked;
    const obs = document.getElementById('obs').value;

    const timezone = localStorage.getItem('timezone') || 'America/Sao_Paulo';

    visita = {
      ...visita,
      data_visita: moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss'),
      estoque_alto,
      exposicao_completa,
      gerente_rejeitou_pedido,
      expositor_organizado,
      obs,
      latitude_representante: this.state.userLocation.latitude,
      longitude_representante: this.state.userLocation.longitude,
    };

    // save visita
    try {
      const BASE_URL = consts.API_URL;
      const response = await axios.get(
        `${BASE_URL}/pontosDeVenda/${pontoDeVenda.id}/fornecedores/${fornecedor.id}/expositores`
      );
      console.log('Expositores dos fornecedor do ponto de venda', response);
      const [expositor] = response.data.data;
      if (expositor) {
        if (checkButton) {
          this.props.saveVisita(
            visita,
            representante,
            pontoDeVenda,
            fornecedor,
            visitacao
          );
        } else {
          this.setState({
            ...this.state,
            saveVisitObjects: {
              visita,
              representante,
              pontoDeVenda,
              fornecedor,
              visitacao,
            },
          });
        }
        // console.log('Não tem expositor - Redirecionando');
      } else {
        this.props.saveVisita(
          visita,
          representante,
          pontoDeVenda,
          fornecedor,
          visitacao
        );
        // console.log('Tem expositor - Não Redirecionando');
        this.setState({
          ...this.state,
          saveVisitObjects: {
            visita,
            representante,
            pontoDeVenda,
            fornecedor,
            visitacao,
          },
        });
      }
      callback();
      // console.log('Pós if - Save Visita');
    } catch (error) {
      console.log('error on save visita: ', error);
      this.props.unsetLoading();
    }
  };

  handleRedirectToVisitaList = () => {
    this.setState({
      ...this.state,
      redirect: true,
    });
  };

  handleRedirectToFornecedoresSelect = () => {
    this.props.loadPreviousStep();
  };

  handleRedirectToNewPedidoVenda = () => {
    this.setState({
      permissionToRedirectToNewPedidoVenda: true,
    });
  };

  render() {
    if (this.state.redirect) return <Redirect to='/visitas' />;

    // passing 'isRedirectToFornecedores' as a prop so the component knows it has to redirect to fornecedores select
    if (this.state.redirectToNewPedidoVenda)
      return (
        <Redirect
          to={{
            pathname: '/pedidosVenda/new',
            state: {
              isRedirectToFornecedores: true,
              saveVisitObjects: this.state.saveVisitObjects,
            },
          }}
        />
      );

    const { pontoDeVenda, fornecedor, t: translate } = this.props;

    return (
      <>
        <Alert
          text={translate('visitas.wizard.new.form.selecionados')}
          body={this.renderAlertBody(pontoDeVenda, fornecedor)}
          color='light'
        />

        <Breadcrumb
          title={translate('visitas.wizard.new.form.title')}
          linkTo='/visitas/new'
          btnFunction={() => this.props.loadPreviousStep()}
        />

        <Row>
          <Grid cols='12 7'>
            <FlexContainer alignItemsCenter className='mb-3'>
              <form>
                <Row>
                  <Grid cols='12 6'>
                    <div className='form-group form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='estoque_alto'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='estoque_alto'
                      >
                        <Trans i18nKey='visitas.wizard.new.form.estoque_alto'>
                          trans
                        </Trans>
                      </label>
                    </div>

                    <div className='form-group form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='exposicao_completa'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='exposicao_completa'
                      >
                        <Trans i18nKey='visitas.wizard.new.form.exposicao_completa'>
                          trans
                        </Trans>
                      </label>
                    </div>

                    <div className='form-group form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='gerente_rejeitou_pedido'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='gerente_rejeitou_pedido'
                      >
                        <Trans i18nKey='visitas.wizard.new.form.gerente_rejeitou_pedido'>
                          trans
                        </Trans>
                      </label>
                    </div>

                    <div className='form-group form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='expositor_organizado'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='expositor_organizado'
                      >
                        <Trans i18nKey='visitas.wizard.new.form.expositor_organizado'>
                          trans
                        </Trans>
                      </label>
                    </div>
                  </Grid>

                  <Grid cols='12 6'>
                    <FlexContainer className='h-100'>
                      {this.state.isShowImageInput1 ? (
                        <ImageInput
                          className='form-group flex-1 mr-1'
                          id='visita-image-1'
                          handleDeleteImage={this.handleDeleteImage}
                          onLoadImage={this.handleLoadImage}
                          isRequired={true}
                        />
                      ) : null}
                      {this.state.isShowImageInput2 ? (
                        <ImageInput
                          className='form-group flex-1 mr-1'
                          id='visita-image-2'
                          onLoadImage={this.handleLoadImage}
                          handleDeleteImage={this.handleDeleteImage}
                          isRequired={false}
                        />
                      ) : null}
                      {this.state.isShowImageInput3 ? (
                        <ImageInput
                          className='form-group flex-1'
                          id='visita-image-3'
                          onLoadImage={this.handleLoadImage}
                          handleDeleteImage={this.handleDeleteImage}
                          isRequired={false}
                        />
                      ) : null}
                    </FlexContainer>
                  </Grid>
                </Row>

                <Media query={{ maxWidth: 599 }}>
                  {(matches) =>
                    matches ? (
                      <div className='form-group'>
                        <ImageGallery
                          items={this.state.images}
                          showFullscreenButton={false}
                          showPlayButton={false}
                        />
                      </div>
                    ) : null
                  }
                </Media>

                <div className='form-group'>
                  <label htmlFor='obs'>
                    <Trans i18nKey='visitas.wizard.new.form.obs'>trans</Trans>
                  </label>
                  <textarea
                    name='obs'
                    className='form-control'
                    id='obs'
                    cols='40'
                    rows='5'
                    style={{ resize: 'none' }}
                  ></textarea>
                </div>
              </form>
            </FlexContainer>
          </Grid>

          <Media query={{ maxWidth: 599 }}>
            {(matches) =>
              matches ? null : (
                <Grid cols='12 5'>
                  <ImageGallery
                    items={this.state.images}
                    showFullscreenButton={false}
                    showPlayButton={false}
                  />
                </Grid>
              )
            }
          </Media>
        </Row>

        <hr />

        <Row>
          <Grid cols='12'>
            <p
              style={{
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 600,
              }}
            >
              <Trans i18nKey='visitas.wizard.new.form.lancarPedido'>
                trans
              </Trans>
            </p>
          </Grid>
        </Row>

        <Row>
          <Grid cols='12 6'>
            <Button
              color='secondary'
              size='block'
              className='mb-3'
              handleclick={() =>
                this.handleSaveVisita(
                  this.handleRedirectToFornecedoresSelect,
                  true
                )
              }
            >
              <span>
                <Trans i18nKey='visitas.wizard.new.form.buttonNao'>trans</Trans>
              </span>
            </Button>
          </Grid>

          <Grid cols='12 6'>
            <Button
              color='success'
              size='block'
              handleclick={() => {
                this.props.setLoading();
                this.handleSaveVisita(this.handleRedirectToNewPedidoVenda);
              }}
            >
              <span>
                <Trans i18nKey='visitas.wizard.new.form.buttonLancar'>
                  trans
                </Trans>
              </span>
            </Button>
          </Grid>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pedidoVenda: state.visita.pedidoVenda,
    user: state.auth.user,
    currentVisita: state.visita,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveVisita,
      setLoading,
      unsetLoading,
      saveImage,
      saveLog,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VisitaForm));

import React from 'react'
import PropTypes from 'prop-types'
import Button from '../template/button'
import Icon from '../template/icon'
import moment from 'moment'
import Media from 'react-media'
import ModalButton from '../template/modalButton'

import { useTranslation, Trans } from 'react-i18next'
import { groupBy } from '../../utils/arrays'

function PedidoVendaCard(props) {
  const {
    pedidoVenda,
    index,
    removePedidoVenda,
    isReadOnly,
    dataParent,
    isShowItems,
    imgModal,
    handleSetImage,
    showPontoVenda,
    showRepresentante,
  } = props;

  // i18n
  const { t: translate } = useTranslation();

  const renderItemsPedidoVenda = (produtos) => {

    return groupBy(produtos.filter(p => p.quantidade > 0), 'produto_id', 'quantidade')
      .map((produto) => {
        return (
          <tr key={produto.id}>
            <td
              data-label={translate(
                "pedidosVenda.list.card.itens.table.codigoArtigo"
              )}
            >
              <span className="font-weight-bold">{produto.cod_artigo}</span>
            </td>
            <td
              data-label={translate(
                "pedidosVenda.list.card.itens.table.descricao"
              )}
            >
              {produto.descricao}
            </td>
            <td>
              <Trans i18nKey="pedidosVenda.list.card.itens.table.pc">
                trans
              </Trans>
            </td>
            <td
              data-label={translate(
                "pedidosVenda.list.card.itens.table.quantidadeKit"
              )}
            >
              {produto.quantidade}{" "}
              <span className="font-weight-bold">{`x${produto.quantidade_kit}`}</span>
            </td>
            <td
              data-label={translate("pedidosVenda.list.card.itens.table.total")}
            >
              {produto.quantidade * produto.quantidade_kit}
            </td>
          </tr>
        );
      });
  };

  return (
    <div className="card mb-3">
      <div className="card-header d-flex">
        <h4 className="mb-0" style={{ flex: 2 }}>
          <Media query={{ maxWidth: 599 }}>
            {matches => matches ? null : <span>{`${translate("pedidosVenda.list.card.title")} #${pedidoVenda.cod_pedido || '000'}`}</span>}
          </Media>
        </h4>

        {
          isReadOnly ? null : (
            <div className="d-flex justify-content-right">
              <Button color="outline-light" size="sm"
                handleclick={() => removePedidoVenda(pedidoVenda)}
                data-toggle="modal" data-target="#modalDeletarPedidoVenda">
                <Icon icon="delete" />
              </Button>
            </div>
          )
        }
      </div>

      <div className="card-body">
        <div className="d-flex justify-content-between">
          <table className="table table-fluid">
            <tbody>
              <tr>
                <th>
                  <Trans i18nKey="pedidosVenda.list.card.fornecedor">trans</Trans>
                </th>
                <td>{pedidoVenda.fornecedor_nome}</td>
              </tr>
              <tr>
                <th>
                  <Trans i18nKey="pedidosVenda.list.card.prazoEntrega">trans</Trans>
                </th>
                <td>{pedidoVenda.fornecedor_prazo_entrega}</td>
              </tr>
              <tr>
                <th>
                  <Trans i18nKey="pedidosVenda.list.card.pontoDeVenda">trans</Trans>
                </th>
                <td>{pedidoVenda.ponto_de_venda_nome}</td>
              </tr>
              <tr>
                <th>
                  <Trans i18nKey="pedidosVenda.list.card.dataVisita">trans</Trans>
                </th>
                <td>{moment.utc(pedidoVenda.data_visita).format("DD/MM/YYYY")}</td>
              </tr>
              {pedidoVenda.obs ? (
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.obs">trans</Trans>
                  </th>
                  <td>{pedidoVenda.obs}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-between" id={`footer${index}`}>
        {pedidoVenda.imagem_expositor ? (
          <ModalButton target={imgModal}
            color="secondary"
            size="sm"
            handleclick={() => handleSetImage(pedidoVenda.imagem_expositor)}>
            <Trans i18nKey="pedidosVenda.list.card.buttonViewImg">trans</Trans>
          </ModalButton>
        ) : null}
        <p className="mb-0">
          <button className="btn btn-default d-flex" type="button" data-toggle="collapse" data-target={`#collapse${index}`}
            aria-expanded="true" aria-controls={`collapse${index}`}
            style={{ boxShadow: 'none' }}>
            <Trans i18nKey="pedidosVenda.list.card.itens.title">trans</Trans>
            {showPontoVenda && <p style={{ paddingLeft: '5px', paddingRight: '5px' }}>-</p>}
            {showPontoVenda && <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.title">trans</Trans>}
            {showRepresentante && <p style={{ paddingLeft: '5px', paddingRight: '5px' }}>-</p>}
            {showRepresentante && <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.title">trans</Trans>}
            <Icon icon="play_arrow" />
          </button>
        </p>
      </div>

      {/* Itens */}
      <div id={`collapse${index}`} className={`collapse ${isShowItems ? 'show' : null}`} aria-labelledby={`footer${index}`} data-parent={`#${dataParent}`}>
        <div className="card-body">
          {pedidoVenda.produtos.length > 0 ? (
            <div id="flip-scroll">
              <table className="table table-fluid">
                <thead className="thead-dark">
                  <tr>
                    <th>
                      <Trans i18nKey="pedidosVenda.list.card.itens.table.codigoArtigo">trans</Trans>
                    </th>
                    <th>
                      <Trans i18nKey="pedidosVenda.list.card.itens.table.descricao">trans</Trans>
                    </th>
                    <th>
                      <Trans i18nKey="pedidosVenda.list.card.itens.table.unidade">trans</Trans>
                    </th>
                    <th>
                      <Trans i18nKey="pedidosVenda.list.card.itens.table.quantidadeKit">trans</Trans>
                    </th>
                    <th>
                      <Trans i18nKey="pedidosVenda.list.card.itens.table.total">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    renderItemsPedidoVenda(pedidoVenda.produtos)
                  }
                </tbody>
              </table>
            </div>
          ) : <p><Trans i18nKey="pedidosVenda.list.card.itens.notFound">trans</Trans></p>}
          {showPontoVenda &&
            <table className="table table-fluid">
              <thead className="thead-dark">
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.title">trans</Trans>
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.codigo_identificacao">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_codigo_identificacao}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.nome">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_nome}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.endereco">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_endereco}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.cidade">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_cidade}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.regiao">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_regiao}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.estado">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_estado}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.frequencia_visita">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_frequencia_visita}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.tipo">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_tipo}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.pontoDeVendaList.obs">trans</Trans>
                  </th>
                  <td>{pedidoVenda.ponto_de_venda_obs}</td>
                </tr>
              </tbody>
            </table>
          }
          {showRepresentante &&
            <table className="table table-fluid">
              <thead className="thead-dark">
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.title">trans</Trans>
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.nome">trans</Trans>
                  </th>
                  <td>{pedidoVenda.representante_nome}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.email">trans</Trans>
                  </th>
                  <td>{pedidoVenda.representante_email}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.status">trans</Trans>
                  </th>
                  <td>{pedidoVenda.representante_status}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.tipo">trans</Trans>
                  </th>
                  <td>{pedidoVenda.representante_tipo}</td>
                </tr>
                <tr>
                  <th>
                    <Trans i18nKey="pedidosVenda.list.card.RepresentanteList.telefone">trans</Trans>
                  </th>
                  <td>{pedidoVenda.representante_telefone}</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
      </div>


    </div>
  )
}

PedidoVendaCard.propTypes = {
  pedidoVenda: PropTypes.shape({
    id: PropTypes.string,
    cod_pedido: PropTypes.number,
    fornecedor_nome: PropTypes.string,
    fornecedor_prazo_entrega: PropTypes.number,
    ponto_de_venda_nome: PropTypes.string,
    data_visita: PropTypes.instanceOf(Date),
    produtos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      cod_artigo: PropTypes.string,
      descricao: PropTypes.string,
      unidade: PropTypes.string,
      quantidade_kit: PropTypes.number,
      quantidade: PropTypes.number,
    }))
  }).isRequired,
  index: PropTypes.number,
  removePedidoVenda: PropTypes.func,
  isReadOnly: PropTypes.bool,
  dataParent: PropTypes.string,
  isShowItems: PropTypes.bool
}

PedidoVendaCard.defaultProps = {
  removePedidoVenda: () => { },
  isReadOnly: false,
  index: 1,
  dataParent: 'accordion',
  isShowItems: false,
  showPontoVenda: false,
  showRepresentante: false
}
export default PedidoVendaCard

import React, { Component } from 'react'
import Modal from '../../template/modal'
import { Link } from 'react-router-dom'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { removeContato, fetchContatosByPontoDeVenda, setContato } from './contatoActions'

class ContatoList extends Component {
  componentDidMount() {
    if(!this.props.pontoDeVenda) {
      this.props.history.push('/pontosDeVenda')
    }

    this.props.fetchContatosByPontoDeVenda(this.props.pontoDeVenda)
  }

  state = {
    contato: null
  }

  removerContato = contato => {
    this.props.removeContato(contato, this.props.pontoDeVenda)

    const contatos = this.state.contatos.filter(c => {
      return c.id !== contato.id
    })

    this.setState({
      ...this.state,
      contatos
    })
  }

  editarContato = contato => {
    this.props.setContato(contato)
  }

  setContato = contato => {
    this.setState({
      ...this.state,
      contato
    })
  }

  renderContatos = contatos => {
    const { t } = this.props;

    return contatos.map(contato => {
      return (
        <tr key={contato.id}>
          <td data-label={t("pontosDeVenda.edit.contatos.list.table.nome")} className="align-middle">{contato.nome}</td>
          <td data-label={t("pontosDeVenda.edit.contatos.list.table.cargo")} className="align-middle">{contato.cargo}</td>
          <td data-label={t("pontosDeVenda.edit.contatos.list.table.celular")} className="align-middle">{contato.celular}</td>
          <td data-label={t("pontosDeVenda.edit.contatos.list.table.email")} className="align-middle">{contato.email}</td>
          <td data-label={t("pontosDeVenda.edit.contatos.list.table.obs")} className="align-middle">{contato.descricao}</td>
          <td data-label="Ação">
            <Link to="/contatos/edit" className="btn btn-sm btn-warning"
              onClick={() => this.editarContato(contato)}>
              <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.buttonEditar">trans</Trans>
            </Link>
            <button className="ml-2 btn btn-sm btn-danger" data-toggle="modal" data-target="#modalDeletarContato"
              onClick={() => this.setContato(contato)}>
              <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.buttonRemover">trans</Trans>
            </button>
          </td>
        </tr>
      )
    })
  }

  render() {
    const { t } = this.props;
    const contatos = this.props.contatos || []

    return (
      <React.Fragment>
        <div className="mb-3 d-flex align-items-center">
          <h4 style={{ flex: 2 }}>
            <Trans i18nKey="pontosDeVenda.edit.contatos.list.title">trans</Trans>
          </h4>

          <Link to="/contatos/new" className="btn btn-sm btn-success">
            <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
          </Link>
        </div>

        <div style={{ overflowX: 'auto' }}>
          <table className="table table-sm table-fluid table-striped text-center">
            <thead className="thead-dark">
              <tr>
                <th className="align-middle" scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.nome">trans</Trans>
                </th>
                <th className="align-middle" scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.cargo">trans</Trans>
                </th>
                <th className="align-middle" scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.celular">trans</Trans>
                </th>
                <th className="align-middle" scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.email">trans</Trans>
                </th>
                <th className="align-middle" scope="col">
                  <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.obs">trans</Trans>
                </th>
                <th className="align-middle" scope="col" width="170">
                  <Trans i18nKey="pontosDeVenda.edit.contatos.list.table.acao">trans</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderContatos(contatos)}
            </tbody>
          </table>
        </div>

        {/* Modal */}
        <Modal id="modalDeletarContato" 
          title={t("pontosDeVenda.edit.contatos.list.modal.title")} 
          text={t("pontosDeVenda.edit.contatos.list.modal.text")} 
          handleClick={() => this.removerContato(this.state.contato)} 
          size="sm" 
          btnColor="danger" 
          btnText={t("pontosDeVenda.edit.contatos.list.modal.buttonRemover")}
          btnCancelarText={t("pontosDeVenda.edit.contatos.list.modal.buttonCancelar")} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    contatos: state.contato.contatos
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  removeContato,
  fetchContatosByPontoDeVenda,
  setContato
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContatoList))

import React from 'react';
import PropTypes from 'prop-types';

function getClasses(numbers) {
  const cols = numbers ? numbers.split(' ') : [];
  let classes = '';

  if(cols[0]) classes += `col-sm-${cols[0]}`;
  if(cols[1]) classes += ` col-md-${cols[1]}`;
  if(cols[2]) classes += ` col-lg-${cols[2]}`;

  return classes;
}

function Grid({ cols, children }) {
  return (
    <div className={getClasses(cols)}>
      {children}
    </div>
  )
}

Grid.propTypes = {
  cols: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element, 
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}

Grid.defaultProps = {
  cols: '12'
}

export default Grid;

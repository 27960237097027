import navbar from './pt/navbar'
import clientes from './pt/clientes'
import gestores from './pt/gestores'
import representantes from './pt/representantes'
import fornecedores from './pt/fornecedores'
import produtos from './pt/produtos'
import pontosDeVenda from './pt/pontosDeVenda'
import mapaDeCalor from './pt/mapaDeCalor'
import validacao from './pt/validacao'
import pedidosVenda from './pt/pedidosVenda'
import visitas from './pt/visitas'
import settings from './pt/settings'
import pagination from './pt/pagination'
import login from './pt/login'
import expositores from './pt/expositores'
import construcao from './pt/construcao'
import common from './pt/common'

export default {
  navbar,
  clientes,
  gestores,
  representantes,
  fornecedores,
  produtos,
  pontosDeVenda,
  mapaDeCalor,
  validacao,
  pedidosVenda,
  visitas,
  settings,
  pagination,
  login,
  expositores,
  common,
  construcao,
}

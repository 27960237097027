import React, { Component } from 'react';
import './searchVisitacoes.css';
import PropTypes from 'prop-types';
import {
  setReturnToSearch,
  setRef,
  setFrom,
  setTo,
  setStatus,
} from './visitacaoActions';
import Button from '../template/button';
import { withTranslation, Trans } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class SearchVisitacoes extends Component {
  submit = (ref, from, to, status, props) => {
    const { onSearch: handleSearch, t: translate } = props;

    if (!ref && !from && !to && !status) {
      toastr.warning(
        translate('visitas.list.search.validation.ref.required.title'),
        translate('visitas.list.search.validation.ref.required.message')
      );
      return;
    }

    handleSearch(ref, from, to, status === 'all' ? null : status);
  };

  handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();

    const ref = e.target.ref.value;
    const from = e.target.from.value;
    const to = e.target.to.value;
    const status = e.target.status.value;

    console.log('status: ', status);
    this.submit(ref, from, to, status, this.props);
  };

  fields = (ref = '', from = '', to = '', status = 'all') => {
    document.getElementById('ref').value = ref;
    document.getElementById('from').value = from;
    document.getElementById('to').value = to;
    document.getElementById('status').value = status ? status : 'all';
  };

  componentDidMount = () => {
    const { store, returnToSearch } = this.props;

    if (returnToSearch) {
      this.props.setReturnToSearch(false);

      this.fields(store.ref, store.from, store.to, store.status);

      this.submit(store.ref, store.from, store.to, store.status, this.props);
    } else {
      this.props.setRef('');
      this.props.setTo(null);
      this.props.setStatus(null);
      this.props.setFrom(null);

      this.fields();
    }
  };

  handleClearSearch = (e) => {
    const { onClearSearch: handleClearSearch } = this.props;

    e.preventDefault();

    // clear form
    this.fields();

    handleClearSearch();
  };

  render() {
    const { t: translate } = this.props;

    return (
      <>
        <div className='search-container mb-3'>
          {/* Search form */}
          <form onSubmit={this.handleSubmit}>
            <div className='row'>
              <div className='col-md-3 col-sm-6'>
                <div className='form-group'>
                  <label htmlFor='from'>
                    <Trans i18nKey='visitas.list.search.inicio'>trans</Trans>
                  </label>
                  <input
                    type='date'
                    name='from'
                    id='from'
                    className='form-control'
                  />
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='form-group'>
                  <label htmlFor='to'>
                    <Trans i18nKey='visitas.list.search.fim'>trans</Trans>
                  </label>
                  <input
                    type='date'
                    name='to'
                    id='to'
                    className='form-control'
                  />
                </div>
              </div>

              {/* Status filter */}
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                  <label htmlFor='status'>
                    <Trans i18nKey='visitas.list.search.status.label'>
                      trans
                    </Trans>
                  </label>
                  <select name='status' id='status' className='form-control'>
                    <option value='all'>
                      {translate('visitas.list.search.status.todas')}
                    </option>
                    <option value='IN'>
                      {translate('visitas.list.search.status.iniciada')}
                    </option>
                    <option value='FN'>
                      {translate('visitas.list.search.status.finalizada')}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {/* /.row */}

            <br />

            <div className='row'>
              <div className='col-md-10'>
                <input
                  type='search'
                  className='form-control mb-2'
                  name='ref'
                  id='ref'
                  placeholder={translate('visitas.list.search.placeholder')}
                />
              </div>
              <div className='col-md-2 d-flex justify-content-between'>
                <Button
                  color='secondary'
                  className='d-flex mb-2'
                  title={translate('visitas.list.search.buttonPesquisar.title')}
                >
                  <i className='material-icons ml-1'>search</i>
                </Button>

                <Button
                  color='danger'
                  className='d-flex mb-2'
                  handleclick={this.handleClearSearch}
                  title={translate('visitas.list.search.buttonLimpar.title')}
                >
                  <i className='material-icons ml-1'>clear</i>
                </Button>
              </div>
            </div>
            {/* /.row */}
          </form>
        </div>
      </>
    );
  }
}

SearchVisitacoes.propTypes = {
  onSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    store: state.visitacao.search,
    returnToSearch: state.visitacao.returnToSearch,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setReturnToSearch,
      setRef,
      setFrom,
      setTo,
      setStatus,
    },
    dispatch
  );

// export default withTranslation()(SearchVisitacoes);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SearchVisitacoes));

const INITIAL_STATE = {
  produtos: [],
  fornecedores: [],
}

export default function newExpositorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NEW_EXPOSITOR_SET_PRODUTOS':
      return {
        ...state,
        produtos: action.payload
      }
    case 'NEW_EXPOSITOR_SET_FORNECEDORES':
      return {
        ...state,
        fornecedores: action.payload
      }
    case 'NEW_EXPOSITOR_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

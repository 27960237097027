import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'
import { connect } from 'react-redux'

class PrivateRoute extends React.Component {  
  render() {
    if(!this.props.auth.isAuthenticated) return <Redirect to="/login" />
    
    const { component: Component, roles } = this.props
    const userRole = this.props.auth.user.tipo

    if(!roles.includes(userRole)) return <Redirect to="/login" />

    return (
      <Route {...this.props} render={(props) => (
        <Component {...props} /> 
      )} />
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(PrivateRoute)

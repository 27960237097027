import React from 'react'
import { useSelector } from 'react-redux'

import consts from '../consts'
import HeatMap from '../components/heatMap/pontoDeVendaListPage'

export default function Home(props) {
  const { RP, GE } = consts.userType;
  const userLoggedIn = useSelector(state => state.auth.user);

  // representante
  if(userLoggedIn && userLoggedIn.tipo === RP) {
    return <HeatMap {...props} />
  }

  if(userLoggedIn && userLoggedIn.tipo === GE) {
    return <HeatMap {...props} />
  }

  return (
    <div>
      <h1>Home</h1>
      <hr/>
    </div>
  )
}

import React, { Component } from 'react';
import Breadcrumb from '../template/breadcrumb';
import SearchVisitacoes from './searchVisitacoes';
import consts from '../../consts';

import { withTranslation, Trans } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchVisitacoes,
  fetchVisitacoesByRepresentante,
  fetchVisitacoesByCliente,
  fetchVisitacoesByFornecedor,
  searchVisitacoesByPontoDeVenda,
  setRef,
  setFrom,
  setTo,
  setStatus,
  setReturnToSearch,
} from './visitacaoActions';
import { clearFornecedores } from '../fornecedor/fornecedorActions';
import Pagination from '../pagination/index';

const { GE, RP, FN, CL } = consts.userType;

export class VisitacaoList extends Component {
  state = {
    isLoading: false,
    visitacoes: [],
    page: 1,
    itemsPerPage: 15,
  };

  componentDidMount() {
    // clear fornecedores
    this.props.clearFornecedores();

    const {
      fetchVisitacoesByRepresentante,
      fetchVisitacoesByCliente,
      fetchVisitacoesByFornecedor,
      searchVisitacoesByPontoDeVenda,
      visitacoes,
      auth,
    } = this.props;

    const userRole = auth.user.tipo;

    // check if redux already has visitacoes
    if (visitacoes.length > 0) {
      this.setVisitacoes(visitacoes);
    }

    // gestor
    if (userRole === GE) {
      this.setState(
        {
          isLoading: true,
        },
        () => searchVisitacoesByPontoDeVenda('', 1, this.state.itemsPerPage)
      );
    }

    // representante
    if (userRole === RP && !this.props.returnToSearch) {
      // Impede se existir filtro ao voltar do visitar, o filtro continua
      this.setState(
        {
          isLoading: true,
        },
        () => {
          fetchVisitacoesByRepresentante(
            auth.user.id,
            '',
            1,
            this.state.itemsPerPage
          ).then(() => this.setState({ isLoading: false }));
        }
      );
    }

    // cliente
    if (userRole === CL && !this.props.returnToSearch) {
      // Impede se existir filtro ao voltar do visitar, o filtro continua
      this.setState(
        {
          isLoading: true,
        },
        () => {
          fetchVisitacoesByCliente(
            auth.user.id,
            '',
            1,
            this.state.itemsPerPage
          ).then(() => this.setState({ isLoading: false }));
        }
      );
    }

    if (userRole === FN) {
      this.setState(
        {
          isLoading: true,
        },
        fetchVisitacoesByFornecedor(
          auth.user.id,
          '',
          1,
          this.state.itemsPerPage
        )
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitacoes !== this.props.visitacoes) {
      this.setVisitacoes(this.props.visitacoes);
    }
  }

  setVisitacoes = (visitacoes) => {
    this.setState({
      visitacoes,
      isLoading: false,
    });
  };

  ButtonNew = () => {
    const { auth } = this.props;

    const userRole = auth.user.tipo;

    // representante
    if (userRole === RP) {
      return (
        <button
          type='button'
          className='btn btn-success'
          onClick={() => this.props.history.push('/visitas/new')}
        >
          <i className='material-icons' style={{ lineHeight: 'inherit' }}>
            add
          </i>
        </button>
      );
    }

    return null;
  };

  loadVisitacao = (visitacao) => {
    const { t: translate } = this.props;

    if (visitacao.offline) {
      toastr.info(
        translate('visitas.list.table.toastr.offline.title'),
        translate('visitas.list.table.toastr.offline.message')
      );
      return;
    }

    // redirect the user
    this.props.history.push(`/visitacoes/${visitacao.id}`);
  };

  renderVisitacoes = (visitacoes) => {
    const { t: translate } = this.props;

    return visitacoes.map((visitacao) => {
      return (
        <tr key={visitacao.id}>
          <td>
            <span className='font-weight-bold'>{visitacao.cod_visitacao}</span>
          </td>
          <td style={{ textAlign: 'start' }}>
            <span className='font-weight-bold'>
              {visitacao.pontoDeVenda.nome}
            </span>
          </td>
          <td style={{ textAlign: 'start' }}>
            <span className='font-weight-bold'>
              {visitacao.pontoDeVenda.cidade}
            </span>
          </td>
          <td style={{ textAlign: 'start' }}>
            <span className='font-weight-bold'>
              {visitacao.pontoDeVenda.endereco}
            </span>
          </td>
          <td>{moment(visitacao.data).format('DD/MM/YYYY - HH:mm:ss')}</td>
          <td>
            {visitacao.status === 'FN'
              ? translate('visitas.list.table.status.finalizada')
              : visitacao.status === 'IN'
              ? translate('visitas.list.table.status.iniciada')
              : translate('visitas.list.table.status.naoIniciada')}
          </td>
          <td>
            <button
              type='button'
              className='btn btn-sm btn-info'
              onClick={() => this.loadVisitacao(visitacao)}
            >
              <Trans i18nKey='visitas.list.table.buttonVisualizar'>trans</Trans>
            </button>
          </td>
        </tr>
      );
    });
  };

  handlePaginate = (page) => {
    if (page === this.state.page) return;

    function callback() {
      const { auth } = this.props;
      const userRole = auth.user.tipo;

      if (userRole === GE)
        return this.props.searchVisitacoesByPontoDeVenda(
          this.props.ref,
          page,
          this.state.itemsPerPage,
          this.props.from,
          this.props.to,
          this.props.status
        );

      if (userRole === RP)
        return this.props.fetchVisitacoesByRepresentante(
          auth.user.id,
          this.props.ref,
          page,
          this.state.itemsPerPage,
          this.props.from,
          this.props.to,
          this.props.status
        );

      if (userRole === FN)
        return this.props.fetchVisitacoesByFornecedor(
          auth.user.id,
          this.props.ref,
          page,
          this.state.itemsPerPage,
          this.props.from,
          this.props.to,
          this.props.status
        );

      if (userRole === CL)
        return this.props.fetchVisitacoesByCliente(
          auth.user.id,
          this.props.ref,
          page,
          this.state.itemsPerPage,
          this.props.from,
          this.props.to,
          this.props.status
        );
    }

    this.setState(
      {
        isLoading: true,
        page,
      },
      callback
    );
  };

  handleSearchVisitacoes = (ref = '', from = '', to = '', status = '') => {
    const page = 1;

    this.props.setRef(ref);
    this.props.setFrom(from);
    this.props.setTo(to);
    this.props.setStatus(status);

    function callback() {
      const { auth } = this.props;
      const userRole = auth.user.tipo;

      if (userRole === GE)
        return this.props.searchVisitacoesByPontoDeVenda(
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );

      if (userRole === RP) {
        return this.props.fetchVisitacoesByRepresentante(
          auth.user.id,
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );
      }

      if (userRole === FN)
        return this.props.fetchVisitacoesByFornecedor(
          auth.user.id,
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );

      if (userRole === CL)
        return this.props.fetchVisitacoesByCliente(
          auth.user.id,
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );
    }

    this.setState(
      {
        isLoading: true,
        page,
      },
      callback
    );
  };

  handleClearSearch = () => {
    const page = 1;
    const ref = '';
    const from = null;
    const to = null;
    const status = null;

    this.props.setRef(ref);
    this.props.setFrom(from);
    this.props.setTo(to);
    this.props.setStatus(status);

    function callback() {
      const { auth } = this.props;
      const userRole = auth.user.tipo;

      if (userRole === GE)
        return this.props.searchVisitacoesByPontoDeVenda(
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );

      if (userRole === RP)
        return this.props.fetchVisitacoesByRepresentante(
          auth.user.id,
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );

      if (userRole === FN)
        return this.props.fetchVisitacoesByFornecedor(
          auth.user.id,
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );

      if (userRole === CL)
        return this.props.fetchVisitacoesByCliente(
          auth.user.id,
          ref,
          page,
          this.state.itemsPerPage,
          from,
          to,
          status
        );
    }

    this.setState(
      {
        isLoading: true,
        page,
      },
      callback
    );
  };

  render() {
    const visitacoes = this.state.visitacoes || [];

    const { t: translate } = this.props;

    return (
      <>
        <Breadcrumb
          title={translate('visitas.list.title')}
          component={this.ButtonNew}
        />
        <SearchVisitacoes
          onSearch={this.handleSearchVisitacoes}
          onClearSearch={this.handleClearSearch}
        />

        <div className='row'>
          <div className='col-md-12'>
            {this.state.isLoading ? (
              <Skeleton count={20} height={30} />
            ) : visitacoes.length <= 0 ? (
              <p>
                <Trans i18nKey='visitas.list.notFound'>trans</Trans>
              </p>
            ) : (
              <div id='card'>
                <table className='table table-sm table-fluid table-striped text-center'>
                  <thead className='thead-dark'>
                    <tr>
                      <th
                        className='align-middle'
                        scope='col'
                        style={{ width: 150 }}
                      >
                        <Trans i18nKey='visitas.list.table.codigoVisitacao'>
                          trans
                        </Trans>
                      </th>
                      <th
                        className='align-middle'
                        scope='col'
                        style={{ textAlign: 'start' }}
                      >
                        <Trans i18nKey='visitas.list.table.pontoDeVenda'>
                          trans
                        </Trans>
                      </th>
                      <th
                        className='align-middle'
                        scope='col'
                        style={{ textAlign: 'start' }}
                      >
                        <Trans i18nKey='visitas.list.table.cidade'>trans</Trans>
                      </th>
                      <th
                        className='align-middle'
                        scope='col'
                        style={{ textAlign: 'start' }}
                      >
                        <Trans i18nKey='visitas.list.table.endereco'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.list.table.data'>trans</Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.list.table.status.label'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.list.table.acao'>trans</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderVisitacoes(visitacoes)}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <Pagination
              page={this.state.page}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.totalItems}
              paginate={this.handlePaginate}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    visitacoes: state.visitacao.visitacoes,
    ref: state.visitacao.search.ref,
    from: state.visitacao.search.from,
    to: state.visitacao.search.to,
    status: state.visitacao.search.status,
    totalItems: state.visitacao.pagination.totalItems,
    returnToSearch: state.visitacao.returnToSearch,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchVisitacoesByPontoDeVenda,
      fetchVisitacoesByRepresentante,
      fetchVisitacoesByCliente,
      fetchVisitacoesByFornecedor,
      clearFornecedores,
      fetchVisitacoes,
      setReturnToSearch,
      setRef,
      setFrom,
      setTo,
      setStatus,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VisitacaoList));

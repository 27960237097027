import React from 'react'

import ReduxToastr from 'react-redux-toastr'

const Toastr = () => {
  return (
    <ReduxToastr timeOut={3000} 
      newestOnTop={false} 
      preventDuplicates={true}
      position={"top-right"} 
      transitionIn={"fadeIn"} 
      transitionOut={"fadeOut"}
      closeOnToastrClick />
  )
}

export default Toastr

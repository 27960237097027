import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'
import Modal from '../template/modal'
import PedidoVendaCard from './pedidoVendaCard'
import Search from './pedidoVendaSearch'
import Row from '../template/row'
import Grid from '../template/grid'
import Pagination from '../pagination/index'
import Skeleton from 'react-loading-skeleton'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchPedidosVenda,
  removePedidoVenda,
  search,
  searchId,
  setRef,
  clearSearch as clearRef
} from './pedidoVendaActions'
import { setLoading, unsetLoading, setMessage } from '../loadingSpinner/loadingSpinnerActions'
import { storage } from '../../config/fbConfig'

import consts from '../../consts';
import CreateGlassMagnifier from '../template/CreateGlassMagnifier'

const { RP } = consts.userType;

export class PedidoVendaList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      pedidoVenda: null,
      pedidoImage: null,
      page: 1,
      itemsPerPage: 15,

      ref: ''
    }
  }

  componentDidMount() {
    // fetch pedidos de venda
    this.setState({
      isLoading: true
    }, this.getAllPedidosVenda());

  }

  getAllPedidosVenda = (ref = '', page = 1) => {
    this.props.role === RP
      ? this.props.searchId(this.props.userId, ref, page, this.state.itemsPerPage)
      : this.props.search(ref, page, this.state.itemsPerPage)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pedidosVenda !== this.props.pedidosVenda) {
      this.setState({
        isLoading: false
      });
      this.props.unsetLoading();
    }
  }

  renderItemsPedidoVenda = produtos => {
    return produtos.map(produto => {
      return (
        <tr key={produto.id}>
          <td>{produto.cod_artigo}</td>
          <td>{produto.descricao}</td>
          <td>{produto.unidade}</td>
          <td>{produto.quantidade}</td>
        </tr>
      )
    })
  }

  removePedidoVenda = async pedidoVenda => {
    this.props.setLoading();
    this.props.setMessage('Removendo Pedido');
    await this.props.removePedidoVenda(pedidoVenda.id);
  }

  setPedidoVenda = pedidoVenda => {
    this.setState({
      pedidoVenda
    })
  }

  renderPedidosVenda = () => {

    const showPontoVenda = true
    const showRepresentante = true

    const pedidosVenda = this.props.pedidosVenda || []
    return pedidosVenda.map((pedidoVenda, index) => {
      return (
        <PedidoVendaCard key={index}
          index={index}
          pedidoVenda={pedidoVenda}
          removePedidoVenda={this.setPedidoVenda}
          dataParent="accordionPedidoVenda"
          imgModal="#modalViewImg"
          handleSetImage={this.handleLoadImage}
          showPontoVenda={showPontoVenda}
          showRepresentante={showRepresentante}
        />
      )
    })
  }

  handleLoadImage = imagePath => {
    const ref = storage.ref(imagePath);
    ref.getDownloadURL().then(url => {
      this.setState({ pedidoImage: url });
    })
  }

  handleSearch = e => {
    e.preventDefault();

    const page = 1;
    const ref = e.target.ref.value;

    this.setState({
      isLoading: true,
      page,
      ref
    }, () => this.getAllPedidosVenda(ref, page));
  }

  handleClearSearch = () => {
    // get all pedidos 
    this.setState({
      isLoading: true,
      ref: ''
    }, this.getAllPedidosVenda());

    this.props.clearRef();

    // clear the search field
    document.getElementById('ref').value = '';
  }

  isEmpty = arr => {
    if (!arr) return true;

    if (arr === null) return true;

    if (arr === undefined) return true;

    if (arr.length <= 0) return true;

    return false;
  }

  handlePaginate = page => {
    if (page === this.state.page) return;

    this.setState({
      isLoading: true,
      page
    }, () => this.getAllPedidosVenda(this.state.ref, page));
  }

  render() {


    const { t: translate } = this.props;

    return (
      <>
        <Breadcrumb title={translate("pedidosVenda.list.title")} />
        <Search onSubmit={this.handleSearch}
          onClear={this.handleClearSearch} />

        <Row>
          <Grid cols="12">
            {this.state.isLoading ? <Skeleton count={3} height={200} />
              : this.isEmpty(this.props.pedidosVenda)
                ? <p><Trans i18nKey="pedidosVenda.list.notFound">trans</Trans></p>
                : (
                  <div id="accordionPedidoVenda">
                    <div className="accordion" id="accordionPedidosVenda">
                      {this.renderPedidosVenda()}
                    </div>
                  </div>
                )}
          </Grid>
        </Row>

        <Row>
          <Grid cols="12">
            <Pagination
              page={this.state.page}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.totalCount}
              paginate={this.handlePaginate}
              nextLabel={translate("pagination.buttonProximo")}
              previousLabel={translate("pagination.buttonAnterior")} />
          </Grid>
        </Row>

        {/* Modal delete */}
        <Modal id="modalDeletarPedidoVenda"
          title={translate("pedidosVenda.list.modalDelete.title")}
          text={translate("pedidosVenda.list.modalDelete.text")}
          handleClick={() => this.removePedidoVenda(this.state.pedidoVenda)}
          size="sm"
          btnColor="danger"
          btnText={translate("pedidosVenda.list.modalDelete.buttonRemover")}
          btnCancelarText={translate("pedidosVenda.list.modalDelete.buttonCancelar")} />

        {/* Modal view img */}
        <Modal id="modalViewImg"
          title={translate("pedidosVenda.list.modalViewImg.title")}
          hideFooter>
          {this.state.pedidoImage ?  <CreateGlassMagnifier src={this.state.pedidoImage}  /*className="img-thumbnail img-responsive"*/ style={{ 'min-height': '25vh', 'max-height': '50vh' }} alt="Pedido img" />  : <Skeleton count={1} height={100} />}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    ref: state.pedidoVenda.search.ref,
    totalCount: state.pedidoVenda.pagination.totalItems,
    pedidosVenda: state.pedidoVenda.pedidosVenda,
    userId: state.auth.user.id,
    role: state.auth.user.tipo,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPedidosVenda,
  removePedidoVenda,
  unsetLoading,
  setLoading,
  setMessage,
  clearRef,
  setRef,
  search,
  searchId
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PedidoVendaList))

const INITIAL_STATE = {
  isAuthenticated: localStorage.getItem('user') ? true : false,
  user: JSON.parse(localStorage.getItem('user')) || null
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload));
      localStorage.setItem('lang', action.payload.lang || 'it');
      localStorage.setItem('timezone', action.payload.locale || 'Europe/Rome');

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      }
    case 'LOGOUT':
      localStorage.removeItem('user')
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    default:
      return state
  }
}

export default authReducer

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, Trans } from 'react-i18next'
import Row from '../template/row'
import Grid from '../template/grid'
import { Form } from '@unform/web'
import Input from '../form/input'
import Select from '../form/select'
import Button from '../template/button'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFornecedores, clearAll } from './newExpositorActions'

function NewExpositorForm({ onSubmit: handleSubmit }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const fornecedores = useSelector(state => state.expositor.new.fornecedores);

  useEffect(() => {
    dispatch(fetchFornecedores());

    return () => dispatch(clearAll());
  }, []);

  return (
    <div>
      <Row>
        <Grid cols="12">
          <div style={{ maxWidth: 800, margin: '0 auto' }}>
            <Form onSubmit={handleSubmit}>
              <Input id="nome" name="nome"
                label={t("expositores.form.nome.label")}
                placeholder={t("expositores.form.nome.placeholder")} />

              <Select id="fornecedor_id" name="fornecedor_id" label={t("expositores.form.fornecedor.label")}>
                {fornecedores.map(fornecedor => (
                  <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.nome}</option>
                ))}
              </Select>

              <Button type="submit" size="block">
                <Trans i18nKey="expositores.form.buttonNext">trans</Trans>
              </Button>
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  )
}

NewExpositorForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default NewExpositorForm;

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../template/button'
import FlexContainer from '../template/flexContainer'
import Icon from '../template/icon'

import { openDatabaseIndexedDB } from '../../config/idbConfig'
import axios from 'axios'
import moment from 'moment'

export class RequestsQueue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: [],

      isPerforming: false,
      statusText: '',
      statusColor: '#000'
    }
  }

  db = null
  requestStore = null

  async componentDidMount() {
    this.db = await openDatabaseIndexedDB('workbox-background-sync');

    this.getRequests();
  }

  getRequests = async () => {
    const items = await this.db.getAll('requests')

    const requests = items.map(req => {
      const isFirebaseRequest = req.requestData.url.indexOf('firebasestorage.googleapis.com') > -1;

      const body = isFirebaseRequest ? '' : this.ab2str(req.requestData.body)

      return {
        ...req,
        requestData: {
          ...req.requestData,
          body: body.length > 0 ? JSON.parse(body) : '-' // sometimes body is not a string (images)
        }
      }
    })

    this.setState({
      requests
    })
  }

  setStatus = (text, color) => {
    // set status text 
    this.setState({
      statusText: text,
      statusColor: color
    });
  }

  forceRequests = async () => {
    const requests = this.state.requests;

    if (requests.length <= 0) {
      return;
    }

    this.setStatus('Iniciando requisições...', '#3c3c3c');

    console.log("-- FORÇANDO REQUISIÇÕES --")
    requests.forEach(req => {
      // perform the request
      const options = {
        method: req.requestData.method,
        url: req.requestData.url,
        data: req.requestData.body
      }

      console.log("Requisição: ", options)
      axios(options)
        .then(res => {
          // remove the request
          console.log(`%c -> Sucesso ao fazer requisição: ${res}`, 'color: green');

          console.log("[background-sync] Removendo requisição da fila...");

          this.setStatus('Requisição concluída. Removendo requisição da fila...', 'green');

          this.db.delete('requests', req.id)
            .then(() => {
              console.log("%c [background-sync] Sucesso ao remover requisição da fila", 'color: green');
              this.setStatus('Requisição removida com sucesso', 'green');
            })
            .catch(error => {
              console.log(`%c [background-sync] Falha ao remover requisição da fila: ${error}`, 'color: tomato');
              this.setStatus(`Falha ao remover requisição da fila: ${error}`, 'tomato');
            });
        })
        .catch(err => {
          this.setStatus(`Erro ao fazer requisições: ${err}`, 'tomato');
          console.log(`%c -> Erro ao fazer requisição: ${err}`, 'color: tomato');
        })
        .finally(() => {
          this.getRequests();
        });
    })
  }

  renderRequests = () => {
    const requests = this.state.requests

    return requests.map(req =>
      <tr key={req.id}>
        <th scope="col">
          <span className="font-weight-bold">{req.id}</span>
        </th>
        <td>{moment(req.timestamp).format("DD/MM/YYYY - HH:mm:ss")}</td>
        <td>{req.requestData.method}</td>
        <td>{req.requestData.url}</td>
        <td>
          <pre>
            {JSON.stringify(req.requestData.body, undefined, 2)}
          </pre>
        </td>
        <td>
          <pre>
            {JSON.stringify(req.requestData.headers, undefined, 2) || '-'}
          </pre>
        </td>
      </tr>
    )
  }

  ab2str = buf => {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }

  render() {
    const { t } = this.props;

    return (
      <div className="p-3">
        <FlexContainer>
          <Button className="d-flex mb-4 w-200" color="default" size="sm"
            handleclick={this.getRequests}
            title={t("settings.requestsQueue.buttonRefresh.title")}>
            <Icon icon="refresh" />
          </Button>

          <Button className="d-flex mb-4 w-200" color="default" size="sm"
            handleclick={this.forceRequests}
            title={t("settings.requestsQueue.buttonForceRequests.title")}>
            <Icon icon="cloud_upload" />
          </Button>
        </FlexContainer>

        <p style={{ color: this.state.statusColor }}>{this.state.statusText}</p>

        {
          this.state.requests.length > 0 ?
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("settings.requestsQueue.table.date")}</th>
                  <th>{t("settings.requestsQueue.table.method")}</th>
                  <th>{t("settings.requestsQueue.table.url")}</th>
                  <th>{t("settings.requestsQueue.table.body")}</th>
                  <th>{t("settings.requestsQueue.table.headers")}</th>
                </tr>
              </thead>
              <tbody>
                {this.renderRequests()}
              </tbody>
            </table> : <p>{t("settings.requestsQueue.notFound")}</p>
        }
      </div>
    )
  }
}

export default withTranslation()(RequestsQueue);

import React, { Component } from 'react'
import './produtoList.css'
import defaultImg from '../common/imgs/default-img.png'
import Modal from '../template/modal.jsx'
import Breadcrumb from '../template/breadcrumb'
import SearchProduto from './searchProduto'

import { withTranslation, Trans } from 'react-i18next'

import firebase from '../../config/fbConfig'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchProdutos, removeProduto, setProduto } from './produtoActions'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import Button from '../template/button'

class ProdutoList extends Component {
  componentDidMount() {
    this.props.setLoading()

    this.props.fetchProdutos()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.produtos !== this.props.produtos) {
      this.props.unsetLoading()
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      produto: null
    }
  }

  componentWillReceiveProps() {
    setTimeout(() => {
      this.loadImages()
    }, 500)
  }

  updateImagesMetadata = async storageRef => {
    try {
      if(!this.isCacheControlMetadataSet(storageRef)) {
        await storageRef.updateMetadata({ cacheControl: 'public,max-age=86400' })
      }

      return
    } catch(e) {
      console.log("Error on updating images metadata: ", e);
    }
  }

  isCacheControlMetadataSet = async storageRef => {
    // check if ref already has 'cache-control' header set
    try {
      const metadata = await storageRef.getMetadata()

      if(metadata.cacheControl) {
        return true
      }

      return false
    } catch(e) {
      throw e
    }
  }

  loadImages = () => {
    this.props.produtos.forEach(produto => {
      const imgId = 'img-' + produto.id

      if(produto.imagem && produto.imagem !== 'undefined') {
        const storageRef = firebase.storage().ref(produto.imagem)

        this.updateImagesMetadata(storageRef)

        storageRef.getDownloadURL()
          .then(url => {
            if(document.getElementById(imgId)) {
              document.getElementById(imgId).src = url
            }
          })
          .catch(err => {
            console.log("Erro ao carregar imagem: ", err)
            throw err
          })
      }
    })
  }

  removeProduto = produto => {
    this.props.removeProduto(produto)
  }

  editProduto = produto => {
    // set produto
    this.props.setProduto(produto)
    this.props.history.push('/produtos/edit')
  }

  setProduto = produto => {
    this.setState({
      ...this.state,
      produto
    })
  }

  renderProdutos = () => {
    const produtos = this.props.produtos || []

    return produtos.map(produto => {
      const imgId = 'img-' + produto.id
      
      return (
        <div key={produto.id} className="card mb-3" style={{ maxWidth: 540, minWidth: 300 }}>
          <div className="row no-gutters">
            <div className="col-md-4">
              <a href={null} style={{ cursor: 'pointer' }} onClick={() => this.editProduto(produto)}>
                <img id={imgId} src={defaultImg} className="card-img" alt={produto.descricao} />
              </a>
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <a href={null} style={{ cursor: 'pointer' }} onClick={() => this.editProduto(produto)}>
                  <h5 className="card-title">{produto.cod_artigo}</h5>
                  <p className="card-text">
                    {produto.descricao}
                    <br/>
                  </p>
                </a>
                <div className="card-text">
                  <div className="text-right">
                    <a href={null} style={{ cursor: 'pointer' }} className="text-warning mr-2" 
                      onClick={() => this.editProduto(produto)}>
                      <i className="material-icons">edit</i>
                    </a>
                    <a href={null} style={{ cursor: 'pointer' }} className="text-danger mr-2"
                      data-toggle="modal" data-target="#modalDeletarProduto"
                      onClick={() => this.setProduto(produto)}>
                      <i className="material-icons">delete_outline</i>
                    </a>
                  </div>
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    <b><Trans i18nKey="produtos.list.card.fornecedor">trans</Trans>: </b>{produto.fornecedor_nome} | 
                    {' '}
                    <b><Trans i18nKey="produtos.list.card.unidade">trans</Trans>: </b>{produto.unidade}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("produtos.list.title")}
          linkTo="/home" component={() => (
            <Button size="sm" color="success"
              handleclick={() => this.props.history.push('/produtos/new')}>
                <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
            </Button>
          )} />

        {/* search produto */}
        <SearchProduto />
        
        <div className="d-flex card-container">
          {this.renderProdutos()}
        </div>

        {/* Modal */}
        <Modal id="modalDeletarProduto" 
          title={t("produtos.list.modal.title")} 
          text={t("produtos.list.modal.text")} 
          handleClick={() => this.removeProduto(this.state.produto)} 
          size="sm" 
          btnColor="danger" 
          btnText={t("produtos.list.modal.buttonRemover")}
          btnCancelarText={t("produtos.list.modal.buttonCancelar")} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    produtos: state.produto.produtos
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  fetchProdutos, 
  removeProduto, 
  unsetLoading,
  setLoading,
  setProduto,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProdutoList))

import { useState, useEffect } from 'react'

export function useGeolocation() {
  const [center, setCenter] = useState({ lat: 0.00, lng: 0.00 });

  const updatePosition = pos => {
    setCenter({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude
    });
  }

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(updatePosition);

    return () => navigator.geolocation.clearWatch(id);
  }, []);

  return center;
}

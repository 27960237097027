import React, { Component } from 'react'
import uuid from 'uuid'
import Breadcrumb from '../template/breadcrumb'

import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { saveProduto, saveImage } from './produtoActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'
import ProdutoForm from './produtoForm'

class NewProdutoForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const {
      ean,
      descricao_ean,
      descricao,
      unidade,
      artigo,
      quantidade_kit
    } = e.target

    const imagem = e.target.imagem.files[0]
    let blobName = null

    if (imagem) {
      const fileExtension = this.getFileExtension(imagem.name)
      blobName = `produtos/${descricao.value}/${uuid.v4() + fileExtension}`
      this.props.saveImage(imagem, blobName)
    }

    // if the produto does not have an image, is not necessary to send an 'image' attr
    const produtoImg = blobName ? {
      imagem: blobName
    } : {}

    const produto = {
      ...produtoImg,
      ean: ean.value,
      descricao_ean: descricao_ean.value,
      descricao: descricao.value,
      unidade: unidade.value,
      quantidade_kit: quantidade_kit.value,
      cod_artigo: artigo.value
    }

    const fornecedor = {
      id: e.target.fornecedor.value
    }

    this.props.setLoading()
    this.props.saveProduto(fornecedor, produto)

    // redirect to /produtos
    this.props.history.push('/produtos')
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Breadcrumb title={t("produtos.form.title")} linkTo="/produtos" />

        <ProdutoForm onSubmit={this.handleSubmit} />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  saveProduto,
  saveImage,
  setLoading
}, dispatch)

export default connect(null, mapDispatchToProps)(withTranslation()(NewProdutoForm))

export default {
  list: {
    title: 'Mapa de calor - Pontos de venda',
    notFound: 'Nenhum ponto de venda encontrado ...',
    table: {
      notVisited: 'Não visitado',
      codigoIdentificacao: 'Cód. Identificação',
      nome: 'Nome',
      cidade: 'Cidade',
      endereco: 'Endereço',
      representante: 'Representante',
      frequenciaVisita: {
        label: 'Frequência visita',
        small: '(dias)'
      },
      obs: 'Obs',
      ultimaVisita: 'Última visita',
      tempoUltimaVisita: {
        label: 'Tempo desde a última visita',
        small: '(dias)'
      },
      proximaVisita: {
        label: 'Próxima visita em',
        small: '(dias)'
      },
      fornecedores: 'Fornecedores',
      acao: 'Ação',
      buttonVisitar: 'Visitar',
      buttonConsultar: 'Consultar',
      buttonRemover: 'Remover',
      modalDelete: {
        title: 'Formulário de exclusão de ponto de venda',
        title2: 'Motivo da exclusão',
        small: 'Esta exclusão passará por aprovação da equipe gestora da Franchini, você poderá ser indagado a justificar estes argumentos.',
        buttonCancelar: 'Cancelar',
        buttonConfirmar: 'Confirmar'
      },
      modalContinueVisitacao: {
        title: 'Visita pendente neste ponto de venda',
        text: 'Há uma visita que foi anteriormente iniciada nesse ponto de venda, você deseja continuar?',
        buttonSim: 'Sim',
        buttonNao: 'Não'
      }
    }
  }
}
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Button from './button'

function searchForm({ placeholder='', handleSearch, clearSearch, onChange }) {

  // const { t } = useTranslation();  

  return (
    <form className="search-container mb-3" style={{paddingTop: '1.2rem'}} onSubmit={e => handleSearch(e)}>
        <div className="row">
          <div className="col-md-10">
            <input type="search" onChange={onChange} className="form-control mb-2" name="ref" id="ref" 
              placeholder={placeholder} />
          </div>
          <div className="col-md-2 d-flex justify-content-between">
            <Button color="secondary" className="d-flex mb-2" 
              // title={t("produtos.list.search.buttonSearch.title")}
            >
              <i className="material-icons ml-1">search</i>
            </Button>
      
            <Button type='reset' color="danger" className="d-flex mb-2"
              handleclick={clearSearch} 
              // title={t("produtos.list.search.buttonClear.title")}
            >
              <i className="material-icons ml-1">clear</i>
            </Button>
          </div>
      </div>
    </form>
  )
}


export default searchForm

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import defaultImg from '../common/imgs/default-img.png'
import { storage } from '../../config/fbConfig'
import { Trans, useTranslation } from 'react-i18next'

import './estandeCard.css'

function EstandeCard({ item, label, selected, readonly, empty, onChangeQuantidade: handleChangeQuantidade }) {
  const { t } = useTranslation();

  useEffect(() => {
    // load images
    if (item && item.imagem) {
      const ref = storage.ref(item.imagem);

      ref.getDownloadURL()
        .then(url => {
          if (document.getElementById(`img-${item.pos + item.id}`))
            document.getElementById(`img-${item.pos + item.id}`).src = url;
        });
    }
  }, [item]);

  return (
    <div className={`scroll-container-card ${empty && 'empty'} ${selected ? 'active' : ''}`}>
      {label && <p className="text-center font-weight-bold">{label}</p>}

      {empty ? (
        <div className="empty-item">
          <p className="text-center"><Trans i18nKey="expositores.form.addItem">trans</Trans></p>
        </div>
      ) : (
          <div>
            <img id={`img-${item.pos + item.id}`} src={defaultImg} alt="Imagem do produto"
              className="img-fluid img-thumbnail item-img mb-2" />

            {readonly ? null : <input type="number" className="form-control mb-2"
              value={item.quantidade}
              onChange={e => handleChangeQuantidade(item, e.target.value)}
              placeholder={t("expositores.form.qtdeItems")} />}

            <input type="text" className="form-control ean mb-2"
              value={item.ean}
              disabled />

            <input type="text" className="form-control artigo mb-2"
              value={item.cod_artigo}
              disabled />
          </div>
        )}
    </div>
  )
}

EstandeCard.propTypes = {
  item: PropTypes.object,
  label: PropTypes.string,
  empty: PropTypes.bool,
  selected: PropTypes.bool,
  readonly: PropTypes.bool,
  onChangeQuantidade: PropTypes.func,
}

export default EstandeCard;

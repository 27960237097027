import React from 'react'
import PropTypes from 'prop-types'
import Badge from './badge'

function Dropdown({ title, countNotifications, children }) {
  return (
    <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle" href="---#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {title}
        {' '}
        {countNotifications ? <Badge color="light">{countNotifications}</Badge> : null}
      </a>
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        {children}
      </div>
    </li>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  countNotifications: PropTypes.number,
  children: PropTypes.array.isRequired
}

export default Dropdown;

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import pt from './lang/pt'
import it from './lang/it'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translations: pt
      },
      it: {
        translations: it
      }
    },
    fallbackLng: 'pt',
    debug: true,

    // namespace
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'
import SearchProduto from '../produto/searchProduto'
import defaultImg from '../common/imgs/default-img.png'
import firebase from '../../config/fbConfig'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchProdutos } from '../produto/produtoActions'
import { setProduto } from './tabelaPrecoActions'

export class SelectProduto extends Component {
  componentDidMount() {
    this.props.fetchProdutos()
  }

  constructor(props) {
    super(props)
    this.state = {
      produtos: []
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      ...this.state,
      produtos: props.produtos
    }) 

    setTimeout(() => {
      this.loadImages()
    }, 500);
  }

  selectProduto = produto => {
    // set produto on props
    this.props.setProduto(produto)

    // then move to step 2
    this.props.loadNextStep()
  }

  loadImages = () => {
    this.state.produtos.forEach(produto => {
      const imgId = 'img-' + produto.id

      if(produto.imagem && produto.imagem !== 'undefined') {
        const storageRef = firebase.storage().ref(produto.imagem)

        storageRef.getDownloadURL()
          .then(url => {
            if(document.getElementById(imgId)) {
              document.getElementById(imgId).src = url
            }
          })
          .catch(err => {
            console.log("Erro ao carregar imagem: ", err)
            throw err
          })
      }
    })
  }

  renderProdutos = () => {
    const produtos = this.state.produtos || []

    return produtos.map(produto => {
      const imgId = 'img-' + produto.id
      
      return (
        <div key={produto.id} className="card mb-3" style={{ maxWidth: 540, minWidth: 300 }}>
          <div className="row no-gutters">
            <div className="col-md-4">
              <img id={imgId} src={defaultImg} className="card-img" alt={produto.descricao} />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">{produto.cod_artigo}</h5>
                <p className="card-text">
                  {produto.descricao}
                  <br/>
                </p>
                <div className="card-text">
                  <div className="text-right">
                  <button className="btn btn-sm btn-info mb-1" onClick={() => this.selectProduto(produto)}>
                    Selecionar
                  </button>
                  </div>
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    <b>Fornecedor: </b>{produto.fornecedor_nome} | <b>Unidade: </b>{produto.unidade}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    // update the produtos list in real-time
    setTimeout(() => {
      this.setState({
        ...this.state,
        produtos: this.props.produtos
      })  
    }, 100)

    return (
      <React.Fragment>
        <Breadcrumb title="Selecionar produto" linkTo="/tabelasPreco/save" btnFunction={this.props.previousStep} />

        {/* search produto */}
        <SearchProduto />
        
        <div className="d-flex card-container">
          {this.renderProdutos()}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    produtos: state.produto.produtos
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  fetchProdutos,
  setProduto
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SelectProduto)

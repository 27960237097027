import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Button from '@bit/ericktrettel.franchini.button'
import Button from './button';
import { MdCameraAlt, MdDone, MdClear } from 'react-icons/md';

function ImageInput({
  id,
  onLoadImage: handleLoadImage,
  isRequired,
  handleDeleteImage,
  ...props
}) {
  const [done, setDone] = useState(false);

  return (
    <div className='form-group h-100' {...props}>
      <Button
        type='button'
        className='h-100 border border-dark border-3'
        size='block'
        color={done ? 'secondary' : 'light'}
        handleclick={() => document.getElementById(id).click()}
      >
        {done ? (
          handleDeleteImage ? (
            <MdClear size={18} />
          ) : (
            <MdDone />
          )
        ) : (
          <MdCameraAlt size={18} />
        )}
      </Button>

      <input
        type={done ? (handleDeleteImage ? 'text' : 'file') : 'file'}
        name={id}
        id={id}
        accept='image/*'
        onClick={
          done
            ? handleDeleteImage
              ? (e) => {
                  handleDeleteImage(e);
                  setDone(false);
                }
              : null
            : null
        }
        className='d-none'
        onChange={(e) => {
          handleLoadImage(e);
          setDone(true);
        }}
        required={isRequired}
      />
    </div>
  );
}

ImageInput.propTypes = {
  id: PropTypes.string.isRequired,
  handleLoadImage: PropTypes.func,
  isRequired: PropTypes.bool,
};

ImageInput.defaultProps = {
  handleLoadImage: () => {},
  isRequired: false,
};

export default ImageInput;

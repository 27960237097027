import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'

const BASE_URL = consts.API_URL

const nop = () => {
  return dispatch => {
    return dispatch({
      type: 'NOP'
    })
  }
}

export const setTabelaPreco = tabelaPreco => {
  return dispatch => {
    return dispatch({
      type: 'SET_TABELA_PRECO',
      payload: tabelaPreco
    })
  }
}

const setTabelasPreco = tabelasPreco => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_TABELAS_PRECO',
      payload: tabelasPreco
    })
  }
}

export const setProduto = produto => {
  return dispatch => {
    return dispatch({
      type: 'TABELA_PRECO_SET_PRODUTO',
      payload: produto
    })
  }
}

export const setPontoDeVenda = pontoDeVenda => {
  return dispatch => {
    return dispatch({
      type: 'TABELA_PRECO_SET_PONTO_DE_VENDA',
      payload: pontoDeVenda
    })
  }
}

export const clearPontosDeVenda = () => {
  return dispatch => {
    return dispatch({
      type: 'CLEAR_TABELAS_PRECO'
    })
  }
}

const formatCurrency = valor => {
  return parseFloat(valor.replace("R$", "").replace(".", "").replace(",", "."))
}

export const fetchTabelasPreco = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/tabelasPreco`)
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar tabelas de preço")
          return dispatch(nop())
        }

        return dispatch(setTabelasPreco(res.data.data))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar tabelas de preço")
        throw err
      })
  }
}

export const fetchProdutoByTabelaPreco = tabelaPreco => {
  return dispatch => {
    if(!tabelaPreco) {
      toastr.error("Oops!", "Tivemos um problema ao buscar produto pela tabela de preço")
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/produtos/tabelasPreco/${tabelaPreco.id}`)
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar produto pela tabela de preço")
          return dispatch(nop())
        }

        return dispatch(setProduto(res.data.data[0]))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar produto pela tabela de preço")
        throw err
      })
  }
}

export const fetchPontoDeVendaByTabelaPreco = tabelaPreco => {
  return dispatch => {
    if(!tabelaPreco) {
      toastr.error("Oops!", "Tivemos um problema ao buscar ponto de venda pela tabela de preço")
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/pontosDeVenda/tabelasPreco/${tabelaPreco.id}`)
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar ponto de venda pela tabela de preço")
          return dispatch(nop())
        }

        return dispatch(setPontoDeVenda(res.data.data[0]))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar ponto de venda pela tabela de preço")
        throw err
      })
  }
}

export const saveTabelaPreco = (tabelaPreco, produto, pontoDeVenda) => {
  return dispatch => {
    if(!tabelaPreco) {
      toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
      return dispatch(unsetLoading())
    }

    if(!produto) {
      toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
      return dispatch(unsetLoading())
    }

    if(!pontoDeVenda) {
      toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
      return dispatch(unsetLoading())
    }

    // format currency
    const formattedValor = formatCurrency(tabelaPreco.valor)
    tabelaPreco.valor = formattedValor

    axios.post(`${BASE_URL}/tabelasPreco`, {
      tabelaPreco,
      produto,
      pontoDeVenda
    })
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
          return dispatch(unsetLoading())
        }

        toastr.success("Sucesso", "Tabela de preço salva com sucesso")
        return dispatch([fetchTabelasPreco(), unsetLoading()])
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
        dispatch(unsetLoading())
        throw err
      })
  }
}

export const updateTabelaPreco = (tabelaPreco, produto, pontoDeVenda) => {
  return dispatch => {
    if(!tabelaPreco) {
      toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
      return dispatch(nop())
    }

    if(!produto) {
      toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
      return dispatch(unsetLoading())
    }

    if(!pontoDeVenda) {
      toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
      return dispatch(unsetLoading())
    }

    // format currency
    const formattedValor = formatCurrency(tabelaPreco.valor)
    tabelaPreco.valor = formattedValor

    axios.put(`${BASE_URL}/tabelasPreco`, {
      tabelaPreco,
      produto,
      pontoDeVenda
    })
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
          return dispatch(nop())
        }

        toastr.success("Sucesso", "Tabela de preço salva com sucesso")
        return dispatch(fetchTabelasPreco())
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao salvar tabela de preço")
        throw err
      })
  }
}


export const removeTabelaPreco = tabelaPreco => {
  return dispatch => {
    if(!tabelaPreco) {
      toastr.error("Oops!", "Tivemos um problema ao remover a tabela de preço")
      return dispatch(nop())
    }

    axios.delete(`${BASE_URL}/tabelasPreco`, {
      headers: {},
      data: {
        tabelaPreco
      }
    })
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao remover a tabela de preço")
          return dispatch(nop())
        }

        toastr.success("Sucesso", "Sucesso ao remover a tabela de preça")
        return dispatch(fetchTabelasPreco())
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao remover a tabela de preço")
        throw err
      })
  }
}

export default {
  exclusaoPontosDeVenda: {
    title: "Convalida l'esclusione dei punti vendita",
    notFound: 'Nessuna esclusione del punto vendita da convalidare ...',
    table: {
      pontoDeVenda: 'Punto vendita',
      representante: 'Rappresentante',
      justificativa: 'Fondamento logico',
      buttonVer: 'Vedere',
      modalJustificativa: {
        title: "Giustificazione dell'esclusione",
        title2: 'Ragione',
        buttonRejeitar: 'Rifiutare',
        buttonAceitar: 'Accettare'
      }
    },
  },
  cadastros: {
    title: 'Convalida voci',
    table: {
      email: 'E-mail',
      tipo: {
        label: 'Tipo',
        gestor: 'Manager',
        representante: 'Rappresentante',
        fornecedor: 'Fornitore',
        cliente: 'Cliente'
      },
      status: {
        label: 'Stato',
        validado: 'Convalidato',
        naoValidado: 'Non convalidato'
      },
      acao: 'Azione',
      buttonValidarCadastro: 'Convalida la registrazione'
    }
  },
  exclusaoFornecedores: {
    title: "Convalida l'esclusione del fornitore",
    notFound: 'Nessuna esclusione fornitore da convalidare ...',
    table: {
      pontoDeVenda: 'Punto vendita',
      fornecedor: 'Fornitore',
      representante: 'Rappresentante',
      justificativa: 'Fondamento logico',
      buttonVer: 'Vedere',
      modalJustificativa: {
        title: "Giustificazione dell'esclusione",
        title2: 'Ragione',
        buttonRejeitar: 'Rifiutare',
        buttonAceitar: 'Accettare'
      }
    }
  }
}

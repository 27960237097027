import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import consts from '../../consts';
import { toastr } from 'react-redux-toastr';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../template/button';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from '../template/breadcrumb'
import { setPontoDeVenda } from './pontoDeVendaActions';
import { useDispatch } from 'react-redux';

const BASE_URL = consts.API_URL;

export default function BindPontoDeVendaToExpositor() {
  const [isLoading, setLoading] = useState(true);

  const [expositores, setExpositores] = useState([]);
  const [linkedExpositor, setLinkedExpositor] = useState(null);

  const { t } = useTranslation();

  const history = useHistory();

  const { pontoDeVendaId, fornecedorId } = useParams();

  const dispatch = useDispatch();

  async function getAllExpositores() {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/fornecedores/${fornecedorId}/expositores`);

      setExpositores(response.data.data);
    } catch (e) {
      toastr.error(t("pontosDeVenda.edit.fornecedores.expositores.error.getExpositores"));
    } finally {
      setLoading(false);
    }
  }

  async function getExpositorLinkedToPontoDeVendaFornecedor() {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}/fornecedores/${fornecedorId}/expositores`);

      if (response.data.data.length > 0) setLinkedExpositor(response.data.data[0]);
    } catch (e) {
      toastr.error(t("pontosDeVenda.edit.fornecedores.expositores.error.getExpositorPontoDeVenda"));
    } finally {
      setLoading(false);
    }
  }

  async function getPontoDeVenda() {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}`);

      if (response.data.data.length > 0) dispatch(setPontoDeVenda(response.data.data[0]));
    } catch (e) {
      toastr.error(t("pontosDeVenda.edit.fornecedores.expositores.error.getPontoDeVenda"));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPontoDeVenda();
    getAllExpositores();
    getExpositorLinkedToPontoDeVendaFornecedor();
  }, []);

  if (!pontoDeVendaId || !fornecedorId) {
    history.push('/pontosDeVenda');
    return;
  }

  async function handleLinkExpositorToPontoDeVendaFornecedor(pontoDeVendaId, fornecedorId, expositorId) {
    try {
      await axios.put(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}/fornecedores/${fornecedorId}/expositores/${expositorId}`);

      getExpositorLinkedToPontoDeVendaFornecedor();
    } catch (e) {
      toastr.error(t("pontosDeVenda.edit.fornecedores.expositores.error.link"));
    }
  }

  function renderExpositores(expositores) {
    return expositores.map(expositor => (
      <tr key={expositor.id}>
        <td data-label={t("pontosDeVenda.edit.fornecedores.expositores.table.nome")}
          className="align-middle text-center">{expositor.nome}</td>
        <td data-label={t("pontosDeVenda.edit.fornecedores.expositores.table.acao")}
          className="align-middle text-center">
          {expositor.id === linkedExpositor?.id
            ? (
              <small className="text-muted">
                <Trans i18nKey="pontosDeVenda.edit.fornecedores.expositores.table.smallVinculado">trans</Trans>
              </small>
            )
            : (
              <Button size="sm" color="info"
                handleclick={() => handleLinkExpositorToPontoDeVendaFornecedor(pontoDeVendaId, fornecedorId, expositor.id)}>
                <Trans i18nKey="pontosDeVenda.edit.fornecedores.expositores.table.buttonVincular">trans</Trans>
              </Button>
            )}
        </td>
      </tr>
    ))
  }

  return (
    <div>
      <Breadcrumb title={t("pontosDeVenda.edit.fornecedores.expositores.title")} linkTo="/pontosDeVenda/edit" />

      {isLoading ? <Skeleton count={5} height={30} />
        : expositores.length === 0
          ? (
            <p>
              <Trans i18nKey="pontosDeVenda.edit.fornecedores.expositores.notFound">trans</Trans>
            </p>
          )
          : (
            <div style={{ overflowX: 'auto' }}>
              <table className="table table-sm table-fluid table-striped text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="pontosDeVenda.edit.fornecedores.expositores.table.nome">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="pontosDeVenda.edit.fornecedores.expositores.table.acao">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderExpositores(expositores)}
                </tbody>
              </table>
            </div>
          )}
    </div>
  )
}

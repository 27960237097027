export default {
  list: {
    title: 'Fornitori',
    table: {
      nome: 'Nome',
      telefone: 'Numero di telefono',
      email: 'E-mail',
      acao: 'Azione',
      buttonEditar: 'Modifica',
      buttonRemover: 'Remove'
    },
    modal: {
      title: "Conferma l'azione",
      text: 'Sei sicuro di voler rimuovere questo fornitore?',
      buttonCancelar: 'Annullare',
      buttonRemover: 'Remove'
    }
  },

  form: {
    title: 'Nuovo fornitore',
    title2: 'Registrazione del fornitore',
    nome: 'Nome: ',
    email: 'E-mail: ',
    telefone: 'Numero di telefono: ',
    maskTelefone: '+3\\9 999 999 9999',
    prazoEntrega: {
      label: 'Tempi di consegna',
      small: '(giorni)'
    },
    senha: {
      label: 'Password: ',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmarSenha: 'Conferma password: ',
    buttonProximo: 'Prossimo',
    buttonCadastrar: 'Registrazione',
    validation: {
      senha: {
        minLength: {
          title: 'Manca qualcosa',
          message: 'La password deve contenere almeno 6 caratteri'
        },
        notEqualTo: {
          title: 'Manca qualcosa',
          message: 'Le password devono essere le stesse'
        }
      },
      imagem: {
        required: {
          title: 'Manca qualcosa',
          message: "Seleziona un'immagine"
        }
      }
    }
  },

  edit: {
    title: 'Modifica provider',
    nome: 'Nome: ',
    telefone: 'Numero di telefono: ',
    email: 'E-mail: ',
    prazoEntrega: {
      label: 'Tempi di consegna',
      small: '(giorni)'
    },
    buttonAlterarSenha: 'Cambia password',
    buttonHabilitarCampos: 'Abilita campi',
    buttonCancelar: 'Annullare',
    buttonSalvar: 'Salva'
  },

  changePassword: {
    title: 'Cambia password',
    novaSenha: {
      label: 'Nuova password: ',
      small: 'La password deve contenere almeno 6 caratteri'
    },
    confirmarSenha: 'Conferma password: ',
    buttonSalvar: 'Salva',
    validation: {
      password: {
        minLength: {
          title: 'Manca qualcosa',
          message: 'La password deve contenere almeno 6 caratteri'
        },
        notEqualTo: {
          title: 'Manca qualcosa',
          message: 'Le password devono essere le stesse'
        }
      }
    }
  },

  toastr: {
    save: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del fornitore'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il fornitore'
      }
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del fornitore'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il fornitore'
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del fornitore'
      },
      success: {
        title: 'Successo',
        message: 'Rimozione riuscita del fornitore'
      }
    },
    unattend: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del fornitore'
      },
      info: {
        title: 'In corso ...',
        message: "La tua richiesta è stata inviata per l'approvazione del gestore"
      }
    },
    fetchUnattended: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca di fornitori rimossi dal punto vendita'
      }
    },
    removeFromPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del fornitore do ponto de venda'
      }
    },
    attend: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il collegamento del fornitore al punto vendita'
      }
    }
  }
}
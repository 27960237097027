import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Row from '../template/row'
import Grid from '../template/grid'
import Button from '../template/button'
import Icon from '../template/icon'

function PedidoVendaSearch({ onSubmit: handleSubmit, onClear: handleClear }) {
  const { t: translate } = useTranslation();
  
  return (
    <div className="search-container mb-3">
      <form onSubmit={handleSubmit}>
        <Row>
          <Grid cols="12 10">
            <input type="search" name="ref" id="ref"
              className="form-control" 
              placeholder={translate("pedidosVenda.list.search.form.input.placeholder")}/>
          </Grid>

          <Grid cols="12 2">
            <div className="d-flex justify-content-between">
              <Button type="submit" color="secondary"
                className="d-flex">
                <Icon icon="search"/>
              </Button>

              <Button type="button" color="danger"
                handleclick={handleClear}
                className="d-flex">
                <Icon icon="clear"/>
              </Button>
            </div>
          </Grid>
        </Row>
      </form>
    </div>
  )
}

PedidoVendaSearch.propTypes = {
  /** Submit event for search */
  onSubmit: PropTypes.func.isRequired,

  /** Clear search function */
  onClear: PropTypes.func.isRequired
}

export default PedidoVendaSearch;

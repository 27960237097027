import React, { Component } from 'react'
import './pontoDeVendaList.css'
import PropTypes from 'prop-types'
import Media from 'react-media'
import Button from '../template/button'
import Modal from '../template/modal'
import ModalButton from '../template/modalButton'
import Row from '../template/row'
import Grid from '../template/grid'
import moment from 'moment'

import { withTranslation, Trans } from 'react-i18next'

import consts from '../../consts'

export class PontoDeVendaList extends Component {
  state = {
    pontoDeVenda: null,
    representante: null,
    motivo: ''
  }

  getDaysSinceLastVisit = (diasFaltantes, frequenciaVisita) => {
    return frequenciaVisita - diasFaltantes
  }

  getUltimaVisita = daysSinceLastVisit => {
    return moment().subtract(daysSinceLastVisit, 'days').format('DD/MM/YYYY')
  }

  getRowColor = diasFaltantes => {
    let classes = 'clickable-row accordion-toggle'

    if (diasFaltantes <= 0) classes = classes.concat(` table-red`);
    if (diasFaltantes > 0 && diasFaltantes <= 5) classes = classes.concat(` table-yellow`);
    if (diasFaltantes > 5) classes = classes.concat(` table-green`);

    return classes
  }

  isNextVisitUrgent = diasFaltantes => {
    if (!diasFaltantes) {
      return true
    }

    if (diasFaltantes <= 5) {
      return true
    }

    return false
  }

  setPontoDeVenda = pontoDeVenda => {
    this.setState({
      pontoDeVenda
    })
  }

  setRepresentante = representante => {
    this.setState({
      representante
    })
  }

  unattendPontoDeVenda = ({ pontoDeVenda, representante, motivo }) => {
    const {
      onUnattendPontoDeVenda: handleUnattendPontoDeVenda
    } = this.props;

    handleUnattendPontoDeVenda(representante, pontoDeVenda, motivo);
  }

  // creates a new visita of the selected ponto de venda
  handleCreateNewVisita = pontoDeVenda => {
    const {
      onCreateVisita: handleCreateVisita
    } = this.props;

    handleCreateVisita(pontoDeVenda);
  }

  readPontoDeVendaVisitas = pontoDeVenda => {
    const {
      onReadPontoDeVendaVisitas: handleReadPontoDeVendaVisitas
    } = this.props;

    handleReadPontoDeVendaVisitas(pontoDeVenda.id);
  }

  renderFornecedores = pontoDeVenda => {
    if (pontoDeVenda.fornecedores.length > 0) {
      return (
        <>
          <p>
            <span className="font-weight-bold">
              <Trans i18nKey="mapaDeCalor.list.table.fornecedores">trans</Trans>
            </span>
          </p>
          <ul className="list-group list-group-flush">
            {
              pontoDeVenda.fornecedores.map((fornecedor, i) => <li key={i} className="list-group-item">{fornecedor.nome}</li>)
            }
          </ul>
        </>
      )
    }

    return <p>Nenhum fornecedor...</p>
  }

  renderPontosDeVenda = (pontosDeVenda, userRole) => {
    const { RP, GE } = consts.userType;

    const { t: translate } = this.props;

    return pontosDeVenda.map((pontoDeVenda, index) => {
      if (userRole === RP && !pontoDeVenda.atendendo) return null;

      return (
        <React.Fragment key={pontoDeVenda.id}>
          <tr className={this.getRowColor(pontoDeVenda.dias_faltantes)}
            data-toggle="collapse" data-target={`#demo-${index}`}>
            <th data-label={translate("mapaDeCalor.list.table.codigoIdentificacao")} className="text-center">
              {pontoDeVenda.codigo_identificacao}
            </th>
            <th data-label={translate("mapaDeCalor.list.table.nome")} className="text-center">
              {pontoDeVenda.nome}
            </th>
            <td data-label={translate("mapaDeCalor.list.table.cidade")} className="text-center">
              {pontoDeVenda.cidade}
            </td>
            <td data-label={translate("mapaDeCalor.list.table.endereco")} className="text-center">
              {pontoDeVenda.endereco}
            </td>
            {userRole === RP ? null : (
              <td data-label={translate("mapaDeCalor.list.table.representante")} className="text-center">
                {pontoDeVenda.representante_nome || '-'}
              </td>
            )}
            <td data-label={translate("mapaDeCalor.list.table.frequenciaVisita.label")} className="text-center">{pontoDeVenda.frequencia_visita}</td>
            {userRole === GE ? null : (
              <td data-label={translate("mapaDeCalor.list.table.obs")} className="text-center">
                {pontoDeVenda.obs || '-'}
              </td>
            )}
            <td data-label={translate("mapaDeCalor.list.table.ultimaVisita")} className="text-center">
              {
                pontoDeVenda.dias_faltantes
                  ? this.getUltimaVisita(this.getDaysSinceLastVisit(pontoDeVenda.dias_faltantes, pontoDeVenda.frequencia_visita))
                  : translate("mapaDeCalor.list.table.notVisited")
              }
            </td>
            <td data-label={translate("mapaDeCalor.list.table.tempoUltimaVisita.label")} className="text-center">
              {
                pontoDeVenda.dias_faltantes
                  ? this.getDaysSinceLastVisit(pontoDeVenda.dias_faltantes, pontoDeVenda.frequencia_visita)
                  : '-'
              }
            </td>
            <td data-label={translate("mapaDeCalor.list.table.proximaVisita.label")} className="text-center">
              {
                pontoDeVenda.dias_faltantes && pontoDeVenda.dias_faltantes >= 0 ? pontoDeVenda.dias_faltantes : '-'
              }
            </td>
            <td data-label={translate("mapaDeCalor.list.table.fornecedores")} className="text-center">
              <span className="font-weight-bold">
                {pontoDeVenda.fornecedores.length}
              </span>
            </td>
            {
              userRole === RP ? (
                <>
                  <Media query={{ maxWidth: 599 }}>
                    {matches => matches ? (
                      <td data-label={translate("mapaDeCalor.list.table.acao")}>
                        <Button size="sm" color="success"
                          handleclick={() => this.handleCreateNewVisita(pontoDeVenda)}>
                          <Trans i18nKey="mapaDeCalor.list.table.buttonVisitar">trans</Trans>
                        </Button>

                        <Button className="ml-1" size="sm" color="info"
                          handleclick={() => this.readPontoDeVendaVisitas(pontoDeVenda)}>
                          <Trans i18nKey="mapaDeCalor.list.table.buttonConsultar">trans</Trans>
                        </Button>

                        <ModalButton className="ml-1" size="sm" color="danger"
                          target="#modalUnattend"
                          handleclick={() => {
                            this.setPontoDeVenda(pontoDeVenda);
                            this.setRepresentante(this.props.representante);
                          }}>
                          <Trans i18nKey="mapaDeCalor.list.table.buttonRemover">trans</Trans>
                        </ModalButton>
                      </td>
                    ) : (
                        <td data-label={translate("mapaDeCalor.list.table.acao")}
                          className="d-flex flex-column">
                          <Button size="sm" color="success"
                            handleclick={() => this.handleCreateNewVisita(pontoDeVenda)}>
                            <Trans i18nKey="mapaDeCalor.list.table.buttonVisitar">trans</Trans>
                          </Button>

                          <Button className="mt-1" size="sm" color="info"
                            handleclick={() => this.readPontoDeVendaVisitas(pontoDeVenda)}>
                            <Trans i18nKey="mapaDeCalor.list.table.buttonConsultar">trans</Trans>
                          </Button>

                          <ModalButton className="mt-1" size="sm" color="danger"
                            target="#modalUnattend"
                            handleclick={() => {
                              this.setPontoDeVenda(pontoDeVenda);
                              this.setRepresentante(this.props.representante);
                            }}>
                            <Trans i18nKey="mapaDeCalor.list.table.buttonRemover">trans</Trans>
                          </ModalButton>
                        </td>
                      )}
                  </Media>
                </>
              ) : null
            }
          </tr>

          {/* Hidden row */}
          <tr>
            <td colSpan={userRole === RP ? 11 : 10} className="hidden-row">
              <div id={`demo-${index}`} className="accordian-body collapse p-1">
                {this.renderFornecedores(pontoDeVenda)}
              </div>
            </td>
          </tr>
        </React.Fragment>
      )
    })
  }

  clearState = () => {
    this.setState({
      pontoDeVenda: null,
      representante: null,
      motivo: ''
    })
  }

  render() {
    const {
      pontosDeVenda,
      role: userRole
    } = this.props

    const { RP, GE } = consts.userType

    const { t: translate } = this.props;

    return (
      <>
        <div id="card" style={{ overflowX: 'auto' }}>
          {
            pontosDeVenda.length > 0 ? (
              <table className="table table-fluid">
                <thead className="thead-dark">
                  <tr>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.codigoIdentificacao">trans</Trans>
                    </th>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.nome">trans</Trans>
                    </th>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.cidade">trans</Trans>
                    </th>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.endereco">trans</Trans>
                    </th>
                    {userRole === RP ? null : (
                      <th className="align-middle text-center">
                        <Trans i18nKey="mapaDeCalor.list.table.representante">trans</Trans>
                      </th>
                    )}
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.frequenciaVisita.label">trans</Trans>
                      {' '}
                      <small className="text-muted">
                        <Trans i18nKey="mapaDeCalor.list.table.frequenciaVisita.small">trans</Trans>
                      </small>
                    </th>
                    {userRole === GE ? null : (
                      <th className="align-middle text-center">
                        <Trans i18nKey="mapaDeCalor.list.table.obs">trans</Trans>
                      </th>
                    )}
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.ultimaVisita">trans</Trans>
                    </th>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.tempoUltimaVisita.label">trans</Trans>
                      {' '}
                      <small className="text-muted">
                        <Trans i18nKey="mapaDeCalor.list.table.tempoUltimaVisita.small">trans</Trans>
                      </small>
                    </th>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.proximaVisita.label">trans</Trans>
                      {' '}
                      <small className="text-muted">
                        <Trans i18nKey="mapaDeCalor.list.table.proximaVisita.small">trans</Trans>
                      </small>
                    </th>
                    <th className="align-middle text-center">
                      <Trans i18nKey="mapaDeCalor.list.table.fornecedores">trans</Trans>
                    </th>
                    {
                      userRole === RP ? (
                        <th className="align-middle text-center">
                          <Trans i18nKey="mapaDeCalor.list.table.acao">acao</Trans>
                        </th>
                      ) : null
                    }
                  </tr>
                </thead>

                <tbody>
                  {this.renderPontosDeVenda(pontosDeVenda, userRole)}
                </tbody>
              </table>
            ) : <p>{translate("mapaDeCalor.list.notFound")}</p>
          }
        </div>

        <Modal id="modalUnattend"
          title={translate("mapaDeCalor.list.table.modalDelete.title")}
          handleCancelarClick={this.clearState}
          handleClick={() => {
            this.unattendPontoDeVenda({ ...this.state });
            this.clearState();
          }}
          size="lg"
          btnColor="success"
          btnText={translate("mapaDeCalor.list.table.modalDelete.buttonConfirmar")}
          btnCancelarText={translate("mapaDeCalor.list.table.modalDelete.buttonCancelar")}>
          <Row>
            <Grid cols="12">
              <p>
                <span className="font-weight-bold">
                  <Trans i18nKey="mapaDeCalor.list.table.modalDelete.title2">trans</Trans>
                </span>
              </p>
              <textarea name="motivo" id="motivo" cols="30" rows="10" className="form-control"
                onChange={e => this.setState({ [e.target.name]: e.target.value })} value={this.state.motivo}></textarea>
              <small className="text-muted">
                <Trans i18nKey="mapaDeCalor.list.table.modalDelete.small">trans</Trans>
              </small>
            </Grid>
          </Row>
        </Modal>
      </>
    )
  }
}

PontoDeVendaList.propTypes = {
  pontosDeVenda: PropTypes.array,
  representante: PropTypes.object,
  role: PropTypes.string,
  onUnattendPontoDeVenda: PropTypes.func
}

export default withTranslation()(PontoDeVendaList)

import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'
import Row from '../template/row'
import Grid from '../template/grid'
import InputMask from 'react-input-mask'
import defaultImg from '../common/imgs/default-img.png'

import { withTranslation, Trans } from 'react-i18next'

import uuid from 'uuid'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { saveRepresentante, saveImage } from './representanteActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'

class RepresentanteForm extends Component {
  handleSubmit = async e => {
    e.preventDefault()

    const user = this.props.user
    let representante = {
      telefone: e.target.telefone.value
    }

    // check file
    const file = e.target.imagem.files[0];

    if (file) {
      const blobName = this.handleSaveImage(user, file);

      representante = {
        ...representante,
        imagem: blobName
      }
    }

    this.props.setLoading()
    await this.props.saveRepresentante(user, representante)
    this.props.getCountUsersNotValidated();

    // redirect to /representantes
    this.props.history.push('/representantes')
  }

  handleSaveImage = (user, image) => {
    let blobName = null;

    if (image) {
      const fileExtension = this.getFileExtension(image.name);
      blobName = `representantes/${user.nome}/${uuid.v4() + fileExtension}`;
      this.props.saveImage(image, blobName);
    }

    return blobName;
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  loadImage = e => {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);

    // set image
    document.getElementById('imagem_representante').src = url
  }

  render() {
    if (!this.props.user) return <Redirect to="/signup" />

    // i18n
    const { t } = this.props;

    return (
      <>
        <Breadcrumb title={t("representantes.form.title")} linkTo="/representantes/user/new" />

        <Row>
          <Grid cols="12 6">
            <div className="h-100 d-flex align-items-center">
              <div className="flex-fill">
                <form onSubmit={this.handleSubmit} className="mt-3">
                  <div className="form-group">
                    <label htmlFor="telefone">
                      <Trans i18nKey="representantes.form.telefone">trans</Trans>
                    </label>
                    <InputMask mask={t("representantes.form.maskTelefone")} maskChar=" " type="text" className="form-control" name="telefone" id="telefone" required />
                  </div>

                  <div className="form-group">
                    <Button type="button" size="block" color="light"
                      style={{ height: '100%', border: '3px solid' }}
                      handleclick={() => document.getElementById('imagem').click()}>
                      <i className="material-icons" style={{ lineHeight: 'inherit', fontSize: '3em' }}>camera_alt</i>
                    </Button>

                    <input type="file" name="imagem" id="imagem"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={this.loadImage} />
                  </div>

                  <Button type="submit"
                    size="block"
                    color="primary"
                    className="mb-3">
                      <Trans i18nKey="representantes.form.buttonCadastrar">trans</Trans>
                  </Button>
                </form>
              </div>
            </div>
          </Grid>

          <Grid cols="12 6">
            <img id="imagem_representante" className="img-thumbnail img-responsive"
              src={defaultImg} alt="Foto do representante" />
          </Grid>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  saveRepresentante,
  setLoading,
  saveImage,
  getCountUsersNotValidated
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RepresentanteForm))

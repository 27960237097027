export default {
  API_URL: process.env.REACT_APP_NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : 'https://gpv-backend.herokuapp.com',
  userType: {
    GE: 'GE',
    RP: 'RP',
    FN: 'FN',
    CL: 'CL'
  }
}
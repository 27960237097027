const INITIAL_STATE = {
  fornecedor: null,
  fornecedores: []
}

const pontoDeVendaFornecedorReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_FORNECEDORES_PONTO_DE_VENDA':
      return {
        ...state,
        fornecedores: action.payload
      }
    case 'SET_FORNECEDOR_PONTO_DE_VENDA':
      return {
        ...state,
        fornecedor: action.payload
      }
    case 'CLEAR_FORNECEDORES_PONTO_DE_VENDA':
      return {
        ...state,
        fornecedor: null,
        fornecedores: []
      }
    default:
      return state
  }
}

export default pontoDeVendaFornecedorReducer

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../template/button'
import Icon from '../template/icon'
import ModalButton from '../template/modalButton'
import Modal from '../template/modal'

function ExpositorList({ expositores, onRemove: handleRemove, onEdit: handleEdit }) {
  const [expositor, setExpositor] = useState(null);

  // i18n
  const { t } = useTranslation();

  function renderExpositores(expositores) {
    return expositores.map(expositor => (
      <tr key={expositor.id}>
        <td>{expositor.nome}</td>
        {expositor.fornecedor && <td>{expositor.fornecedor.nome}</td>}
        {(handleRemove || handleEdit) && (
          <td>
            {handleRemove && (
              <ModalButton target="#modalDelete" size="sm" color="danger"
                handleclick={() => setExpositor(expositor)}>
                <Icon icon="delete" />
              </ModalButton>
            )}

            {handleEdit && (
              <Button className="ml-1" size="sm" color="warning"
                handleclick={() => handleEdit(expositor)}>
                <Icon icon="edit" />
              </Button>
            )}
          </td>
        )}
      </tr>
    ))
  }

  return (
    <div>
      {expositores.length > 0 ? (
        <table className="table table-fluid table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">{t("expositores.list.table.nome")}</th>
              {expositores[0].fornecedor && <th scope="col">{t("expositores.list.table.fornecedor")}</th>}
              {(handleRemove || handleEdit) && <th scope="col">{t("expositores.list.table.acoes")}</th>}
            </tr>
          </thead>
          <tbody>
            {renderExpositores(expositores)}
          </tbody>
        </table>
      ) : <p>{t("expositores.list.notFound")}</p>}

      {/* Modal */}
      <Modal id="modalDelete"
        title={t("expositores.list.modal.title")}
        text={t("expositores.list.modal.text")}
        handleClick={() => handleRemove(expositor)}
        size="sm"
        btnColor="danger"
        btnText={t("expositores.list.modal.buttonRemover")}
        btnCancelarText={t("expositores.list.modal.buttonCancelar")} />
    </div>
  )
}

ExpositorList.propTypes = {
  clientes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nome: PropTypes.string,
    fornecedor_id: PropTypes.number,
  })),
  onRemove: PropTypes.func,
  onEdit: PropTypes.func
}

ExpositorList.defaultProps = {
  removeCliente: false,
  editCliente: false
}

export default ExpositorList;

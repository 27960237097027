import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { saveCliente, saveImage } from './clienteActions'

import defaultImg from '../common/imgs/default-img.png'
import Row from '../template/row'
import Grid from '../template/grid'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'
import ImageInput from '../template/imageInput'
import ImageGallery from 'react-image-gallery'

import uuid from 'uuid'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'

export default function ClienteForm({ ...props }) {
  const [images, setImages] = useState([{ original: defaultImg, thumbnail: defaultImg }]);

  // i18n
  const { t } = useTranslation();

  // application state
  const dispatch = useDispatch();

  function handleSaveImage(clienteNome, image) {
    let blobName = null;

    if (image) {
      const fileExtension = getFileExtension(image.name);
      blobName = `clientes/${clienteNome}/${uuid.v4() + fileExtension}`;
      dispatch(saveImage(image, blobName));
    }

    return blobName;
  }

  function getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'));
  }

  function isInvalid(form) {
    if(form.password.value.length < 6) {
      toastr.warning(t("clientes.form.toastr.validation.minLength.title"), t("clientes.form.toastr.validation.minLength.message"))
      return true
    }
    
    if(form.password.value !== form.confirmPassword.value) {
      toastr.warning(t("clientes.form.toastr.validation.confirmPassword.title"), t("clientes.form.toastr.validation.confirmPassword.message"))
      return true
    }
    
    return false
  }

  function handleSubmit(e) {
    e.preventDefault();
    
    if(isInvalid(e.target)) {
      return;
    }

    const file_1 = document.getElementById('imagem').files[0];
    const nome = document.getElementById('nome').value;

    let cliente = {
      nome
    }

    const user = {
      nome: e.target.nome.value,
      email: e.target.email.value,
      senha: e.target.password.value,
      tipo: consts.userType.CL
    }

    const blobName = handleSaveImage(nome, file_1)

    cliente = {
      ...cliente,
      imagem: blobName
    }

    // save cliente
    dispatch(saveCliente(cliente, user));

    // redirect the user
    props.history.push('/clientes');
  }

  function handleLoadImage(e) {
    const file = e.target.files[0];

    const url = window.URL.createObjectURL(file);

    // remove the default img
    const imgs = images.filter(img => img.original !== defaultImg);

    setImages([...imgs, { original: url, thumbnail: url }]);
  }

  return (
    <>
      <Breadcrumb title={t("clientes.form.title")} linkTo="/clientes" />

      <Row>
        <Grid cols="12 6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="nome">
                <Trans i18nKey="clientes.form.nome">trans</Trans>
              </label>
              <input className="form-control" type="text" name="nome" id="nome"/>
            </div>

            <div className="form-group">
              <label htmlFor="email">
                <Trans i18nKey="clientes.form.email">trans</Trans>
              </label>
              <input type="email" className="form-control" name="email" id="email" placeholder="exemplo@mail.com" required />
            </div>
    
            <div className="form-group">
              <label htmlFor="password">
                <Trans i18nKey="clientes.form.senha.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="password" id="password" placeholder="******" required />
              <small className="form-text text-muted">
                <Trans i18nKey="clientes.form.senha.small">trans</Trans>
              </small>
            </div>
    
            <div className="form-group">
              <label htmlFor="confirmPassword">
                <Trans i18nKey="clientes.form.confirmarSenha">trans</Trans>
              </label>
              <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" placeholder="******" required />
            </div>

            <ImageInput className="form-group" id="imagem" onLoadImage={handleLoadImage} isRequired={false} />

            <Button type="submit" size="block" color="primary">
              <Trans i18nKey="clientes.form.buttonCadastrar">trans</Trans>
            </Button>
          </form>
        </Grid>

        <Grid cols="12 6">
          <ImageGallery items={images}
            showFullscreenButton={false}
            showBullets={false}
            showNav={false}
            showPlayButton={false} />
        </Grid>
      </Row>
    </>
  )
}

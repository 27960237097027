const INITIAL_STATE = {
  cliente: null,
  clientes: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_CLIENTES':
      return {
        ...state,
        clientes: action.payload
      }
    case 'SET_CLIENTE':
      return {
        ...state,
        cliente: action.payload
      }
    case 'CLEAR_CLIENTES':
      return {
        ...state,
        cliente: null,
        clientes: []
      }
    default:
      return state;
  }
}

import React from 'react';
import consts from '../../consts';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// Components
import Navbar from '../navbar/navbar';
import PrivateRoute from './privateRoute';
import RoleBasedRoute from './roleBasedRoute';

// Pages
import Home from '../../pages/home';
import Login from '../../pages/login';
import GestorForm from '../gestor/gestorForm';
import SignUp from '../user/userForm';
import GestorList from '../gestor/gestorList';
import GestorEdit from '../gestor/gestorEdit';
import PasswordRecovery from '../../pages/passwordRecovery';
import ChangePasswordForm from '../user/changePasswordForm';
import RepresentanteList from '../representante/representanteList';
import RepresentanteForm from '../representante/representanteForm';
import RepresentanteEdit from '../representante/representanteEdit';
import RepresentanteUserForm from '../representante/userForm';
import RepresentanteChangePasswordForm from '../representante/changePasswordForm';
import GestorUserForm from '../gestor/userForm';
import ValidarCadastro from '../gestor/validacao/validarCadastro';
import ValidarExclusaoPontosDeVenda from '../gestor/validacao/validarExclusaoPontosDeVenda';
import FornecedorForm from '../fornecedor/fornecedorForm';
import FornecedorUserForm from '../fornecedor/userForm';
import FornecedorChangePasswordForm from '../fornecedor/changePasswordForm';
import FornecedorEdit from '../fornecedor/fornecedorEdit';
import FornecedorList from '../fornecedor/fornecedorList';
import ProdutoList from '../produto/produtoList';
import NewProdutoForm from '../produto/newProdutoForm';
import ProdutoEdit from '../produto/produtoEdit';
import Workbook from '../workbook/workbook';
import PontoDeVendaForm from '../pontoDeVenda/pontoDeVendaForm';
import PontoDeVendaList from '../pontoDeVenda/pontoDeVendaList';
import PontoDeVendaEdit from '../pontoDeVenda/pontoDeVendaEdit';
import BindPontoDeVendaToProduto from '../pontoDeVenda/bindPontoDeVendaToProduto';
import BindPontoDeVendaToRepresentante from '../pontoDeVenda/bindPontoDeVendaToRepresentante';
import BindPontoDeVendaToFornecedor from '../pontoDeVenda/bindPontoDeVendaToFornecedor';
import ContatoForm from '../pontoDeVenda/contato/contatoForm';
import ContatoEdit from '../pontoDeVenda/contato/contatoEdit';
import TabelaPrecoList from '../tabelaPreco/tabelaPrecoList';
import TabelaPrecoWizard from '../tabelaPreco/tabelaPrecoWizard';
import VisitaWizard from '../visita/visitaWizard';
import PedidoVendaList from '../pedidoVenda/pedidoVendaList';
import VisitacaoList from '../visitacao/visitacaoList';
import VisitacaoPage from '../visitacao/visitacaoPage';
import VisitaListPage from '../visita/visitaListPage';
import Settings from '../settings/home';
import HeatMapComponent from '../heatMap/pontoDeVendaListPage';
import VisitaPage from '../visita/visitaPage';
import ValidarExclusaoFornecedores from '../gestor/validacao/validarExclusaoFornecedores';
import PontoDeVendaPage from '../pontoDeVenda/pontoDeVendaPage';
import ClienteListPage from '../cliente/clienteListPage';
import ClienteForm from '../cliente/clienteForm';
import ClienteEdit from '../cliente/clienteEdit';
import ClienteChangePasswordForm from '../cliente/changePasswordForm/index';
import PedidoVendaLayoutMock from '../mocks/pedidoVendaLayout';
import NewExpositorWizard from '../expositor/newExpositorWizard';
import ExpositorListPage from '../expositor/expositorListPage';
import BindPontoDeVendaToExpositor from '../pontoDeVenda/bindPontoDeVendaToExpositor';
import PedidoVendaWizard from '../pedidoVenda/pedidoVendaWizard';
import Construcao from '../../pages/construcao';

const Routes = () => {
  const { GE, RP, FN, CL } = consts.userType;

  return (
    <BrowserRouter>
      <Navbar />
      <div className='container'>
        <Switch>
          <PrivateRoute exact path='/' component={Home} />
          <PrivateRoute path='/home' component={Home} />
          <Route path='/login' component={Login} />
          <PrivateRoute path='/signup' component={SignUp} />
          <Route path='/passwordRecovery' component={PasswordRecovery} />

          <PrivateRoute path='/password/new' component={ChangePasswordForm} />

          {/* Pedido de venda layout mock */}
          <PrivateRoute
            path='/mocks/pedido_venda'
            component={PedidoVendaLayoutMock}
          />

          {/* Workbook upload */}
          <Route path='/workbook' component={Workbook} />

          {/* Gestores */}
          <PrivateRoute exact path='/gestores' component={GestorList} />
          <Route path='/gestores/new' component={GestorForm} />
          <PrivateRoute path='/gestores/user/new' component={GestorUserForm} />
          <PrivateRoute path='/gestores/edit' component={GestorEdit} />
          <RoleBasedRoute
            path='/gestores/validacao/cadastros'
            component={ValidarCadastro}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/gestores/validacao/pontosDeVenda'
            component={ValidarExclusaoPontosDeVenda}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/gestores/validacao/fornecedores'
            component={ValidarExclusaoFornecedores}
            roles={[GE]}
          />

          {/* Representantes */}
          <RoleBasedRoute
            roles={[GE]}
            exact
            path='/representantes'
            component={RepresentanteList}
          />
          <Route path='/representantes/new' component={RepresentanteForm} />
          <RoleBasedRoute
            roles={[GE]}
            path='/representantes/user/new'
            component={RepresentanteUserForm}
          />
          <RoleBasedRoute
            roles={[GE]}
            path='/representantes/edit'
            component={RepresentanteEdit}
          />
          <RoleBasedRoute
            roles={[GE]}
            path='/representantes/password'
            component={RepresentanteChangePasswordForm}
          />

          {/* Fornecedores */}
          <RoleBasedRoute
            roles={[GE]}
            exact
            path='/fornecedores'
            component={FornecedorList}
          />
          <Route path='/fornecedores/new' component={FornecedorForm} />
          <RoleBasedRoute
            roles={[GE]}
            path='/fornecedores/user/new'
            component={FornecedorUserForm}
          />
          <RoleBasedRoute
            roles={[GE]}
            path='/fornecedores/edit'
            component={FornecedorEdit}
          />
          <RoleBasedRoute
            roles={[GE]}
            path='/fornecedores/password'
            component={FornecedorChangePasswordForm}
          />

          {/* Produtos */}
          <RoleBasedRoute
            exact
            path='/produtos'
            component={ProdutoList}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/produtos/new'
            component={NewProdutoForm}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/produtos/edit'
            component={ProdutoEdit}
            roles={[GE]}
          />

          {/* Contatos do ponto de venda */}
          <RoleBasedRoute
            path='/contatos/new'
            component={ContatoForm}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/contatos/edit'
            component={ContatoEdit}
            roles={[GE]}
          />

          {/* Ponto de Venda */}
          <RoleBasedRoute
            exact
            path='/pontosDeVenda'
            component={PontoDeVendaList}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/pontosDeVenda/new'
            component={PontoDeVendaForm}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/pontosDeVenda/edit'
            component={PontoDeVendaEdit}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/pontosDeVenda/produtos'
            component={BindPontoDeVendaToProduto}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/pontosDeVenda/representantes'
            component={BindPontoDeVendaToRepresentante}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/pontosDeVenda/fornecedores'
            component={BindPontoDeVendaToFornecedor}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/pontosDeVenda/:pontoDeVendaId/fornecedores/:fornecedorId/expositores'
            component={BindPontoDeVendaToExpositor}
            roles={[GE]}
          />

          {/* Heat map */}
          <RoleBasedRoute
            path='/pontosDeVenda/heat_map'
            component={HeatMapComponent}
            roles={[GE, RP]}
          />

          {/* List visitas by ponto de venda */}
          <RoleBasedRoute
            path='/pontosDeVenda/:id'
            component={PontoDeVendaPage}
            roles={[GE, RP]}
          />

          {/* Tabelas de preço */}
          <RoleBasedRoute
            exact
            path='/tabelasPreco'
            component={TabelaPrecoList}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/tabelasPreco/save'
            component={TabelaPrecoWizard}
            roles={[GE]}
          />

          {/* Visitas / Visitações */}
          <RoleBasedRoute
            exact
            path='/visitas/all'
            component={VisitaListPage}
            roles={[RP]}
          />
          <RoleBasedRoute
            exact
            path='/visitas'
            component={VisitacaoList}
            roles={[GE, RP, FN, CL]}
          />
          <RoleBasedRoute
            path='/visitas/new'
            component={VisitaWizard}
            roles={[RP]}
          />
          <RoleBasedRoute
            path='/visitacoes/:id'
            component={VisitacaoPage}
            roles={[RP, GE, CL, FN]}
          />
          <RoleBasedRoute
            path='/visitas/:id'
            component={VisitaPage}
            roles={[RP, GE, CL, FN]}
          />

          {/* Pedidos de venda */}
          <RoleBasedRoute
            exact
            path='/pedidosVenda'
            component={PedidoVendaList}
            roles={[RP, GE]}
          />
          <RoleBasedRoute
            path='/(pedidosVenda/new|shopping)/'
            component={PedidoVendaWizard}
            roles={[RP]}
          />

          {/* Clientes */}
          <RoleBasedRoute
            exact
            path='/clientes'
            component={ClienteListPage}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/clientes/new'
            component={ClienteForm}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/clientes/:id/edit'
            component={ClienteEdit}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/clientes/:id/password'
            component={ClienteChangePasswordForm}
            roles={[GE]}
          />

          {/* Expositor */}
          <RoleBasedRoute
            exact
            path='/expositores'
            component={ExpositorListPage}
            roles={[GE]}
          />
          <RoleBasedRoute
            path='/expositores/new'
            component={NewExpositorWizard}
            roles={[GE]}
          />

          <RoleBasedRoute
            roles={[RP]}
            path='/construcao'
            component={Construcao}
          />

          {/* Settings */}
          <PrivateRoute path='/config' component={Settings} />

          <Redirect to='/home' />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default Routes;

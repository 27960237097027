import React, { Component } from "react";

import { connect } from "react-redux";

import { withTranslation, Trans } from "react-i18next";

export class Construcao extends Component {
  render() {
    // i18n

    return (
      <div style={{ textAlign: "center", fontSize: "2rem" }}>
        <Trans i18nKey="construcao.page.title">trans</Trans>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(withTranslation()(Construcao));

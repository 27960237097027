import React, { Component } from 'react'
import consts from '../../consts'
import '../user/userForm.css'
import { toastr } from 'react-redux-toastr'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setUser } from '../user/userActions'
import Breadcrumb from '../template/breadcrumb'

class UserForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    // validate password
    if(this.isInvalid(e.target)) {
      return
    }

    // save user info on store
    this.saveUserInfo(e.target) 
    this.redirectUser()
  }

  redirectUser = () => {
    this.props.history.push('/gestores/new')
  }

  saveUserInfo = form => {
    const user = {
      nome: form.nome.value,
      email: form.email.value,
      senha: form.password.value,
      tipo: consts.userType.GE
    }
    
    this.props.setUser(user)
  }

  isInvalid = form => {
    const { t } = this.props;
    if(form.password.value.length < 6) {
      toastr.warning(t("gestores.form.validation.password.minLength.title"), t("gestores.form.validation.password.minLength.message"))
      return true
    }
    
    if(form.password.value !== form.confirmPassword.value) {
      toastr.warning(t("gestores.form.validation.password.notEqualTo.title"), t("gestores.form.validation.password.notEqualTo.message"))
      return true
    }
    
    return false
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={t("gestores.form.title")} linkTo="/gestores"/>

        <div className="form-container mt-3">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="nome">
                <Trans i18nKey="gestores.form.nome">trans</Trans>
              </label>
              <input type="text" className="form-control" name="nome" id="nome" placeholder="João da Silva" required />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">
                <Trans i18nKey="gestores.form.email">trans</Trans>
              </label>
              <input type="email" className="form-control" name="email" id="email" placeholder="exemplo@mail.com" required />
            </div>
    
            <div className="form-group">
              <label htmlFor="password">
                <Trans i18nKey="gestores.form.senha.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="password" id="password" placeholder="******" required />
              <small className="form-text text-muted">
                <Trans i18nKey="gestores.form.senha.small">trans</Trans>
              </small>
            </div>
    
            <div className="form-group">
              <label htmlFor="confirmPassword">
                <Trans i18nKey="gestores.form.confirmarSenha">trans</Trans>
              </label>
              <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" placeholder="******" required />
            </div>
    
            <hr className="divider"/>
    
            <button type="submit" className="btn btn-primary btn-block mb-3">
              <Trans i18nKey="gestores.form.buttonProximo">trans</Trans>
            </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ setUser }, dispatch)

export default connect(null, mapDispatchToProps)(withTranslation()(UserForm))

import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'
import Row from '../template/row'
import Grid from '../template/grid'
import ModalButton from '../template/modalButton'
import Modal from '../template/modal'
import PontoDeVendaList from './pontoDeVendaList'
import PontoDeVendaSearch from '../pontoDeVenda/pontoDeVendaSearch'
import Pagination from '../pagination/index'
import Skeleton from 'react-loading-skeleton'

import { withTranslation } from 'react-i18next'

import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchCountPontosDeVenda_nextVisit,
  fetchPontosDeVenda_nextVisitPageable,
  fetchPontosDeVendaByRepresentantes_nextVisit,

  searchPontosDeVenda_nextVisitPageable,
  setRef,
  setWithoutRepresentantes,
  clearRef,
  clearWithoutRepresentantes
} from '../pontoDeVenda/pontoDeVendaActions'
import {
  fetchRepresentanteByUserId,
  unattendPontoDeVenda,
  setRepresentante
} from '../representante/representanteActions'
import { setPontoDeVenda } from '../visita/visitaActions'
import {
  saveVisitacao,
  removeVisitacao,
  setVisitacao,
  fetchVisitasByVisitacao
} from '../visitacao/visitacaoActions'
import { fetchFornecedoresByPontoDeVendaAndVisitacao } from '../pontoDeVenda/fornecedor/fornecedorActions'
import { setLoading, unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import { openDatabaseIndexedDB, clearAll } from '../../config/idbConfig'
import { setRedirectTo } from '../router/routerActions'

import consts from '../../consts'
import { Redirect } from 'react-router-dom'

export class PontoDeVendaListPage extends Component {
  state = {
    isLoading: false,
    pontosDeVenda: [],
    page: 1,
    itemsPerPage: 15,

    isRedirectToNewVisita: false,
    visitas: [],
    // continue previous visitação
    pontoDeVenda: null,
    visitacao: null
  }

  componentDidMount() {
    // in case user has a 'GE' role, then load all pontos de venda
    // or else, load only the pontos de venda linked to the representante
    const { GE, RP } = consts.userType
    const {
      tipo: userRole,
      id
    } = this.props.auth.user

    // clear indexedDB
    this.clearIDB();

    if (userRole === GE) {
      this.setState({
        isLoading: true
      }, () => this.props.searchPontosDeVenda_nextVisitPageable('', this.state.page, this.state.itemsPerPage));
      return
    }

    if (userRole === RP) {
      this.setState({
        isLoading: true
      }, () => {
        this.props.fetchPontosDeVendaByRepresentantes_nextVisit(id, '', 1, this.state.itemsPerPage);
        this.props.fetchRepresentanteByUserId(id);
      });
      return;
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.pontosDeVenda !== this.props.pontosDeVenda) {
      this.setState({
        isLoading: false,
        pontosDeVenda: this.props.pontosDeVenda
      })
    }
    if (prevProps.visitas !== this.props.visitas) {
      this.setState({
        visitas: this.props.visitas
      })
    }
  }




  fetchVisitas = (lastIncompleteVisitacao) => {
    this.props.fetchVisitasByVisitacao(lastIncompleteVisitacao)
  }

  handleUnattendPontoDeVenda = async (representante, pontoDeVenda, motivo) => {
    const {
      id
    } = this.props.auth.user;

    this.props.setLoading();
    await this.props.unattendPontoDeVenda(representante, pontoDeVenda, motivo);
    await this.props.fetchPontosDeVendaByRepresentantes_nextVisit(id, '');
    this.props.unsetLoading();
  }

  clearIDB = async () => {
    try {
      // clear visitas and pedidos de venda on idb
      localStorage.removeItem('visitando');
      localStorage.removeItem('isPedidoVenda');

      const dbVisitacao = await openDatabaseIndexedDB('visitacao');
      const dbPedidoVenda = await openDatabaseIndexedDB('pedidoVenda');

      await clearAll(dbVisitacao, 'visitacao');
      await clearAll(dbPedidoVenda, 'pedidoVenda');
    } catch (e) {
      console.log(`%c Erro ao limpar IDB: ${e}`, 'color: tomato');
    }
  }

  getLastIncompleteVisitacao = pontoDeVenda => {
    return new Promise(async (resolve, reject) => {
      const BASE_URL = consts.API_URL;

      try {
        const response = await axios.get(`${BASE_URL}/visitacoes/incomplete/pontoDeVenda/${pontoDeVenda.id}`);

        resolve(response.data.data[0]);
      } catch (e) {
        if(e.message === 'Network Error') {
          return resolve(false);
        }

        console.log(`Erro ao verificar se ponto de venda possui visitações em aberto: ${e}`);
        reject(e);
      }
    })
  }

  fetchVisitasByVisitacao = visitacao => {
    return new Promise(async (resolve, reject) => {
      const BASE_URL = consts.API_URL;

      try {
        const response = await axios.get(`${BASE_URL}/visitacoes/${visitacao.id}/visitas`);

        resolve(response.data.data);
      } catch (e) {
        if(e.message === 'Network Error') {
          return resolve(false);
        }

        console.log(`Erro ao buscar visitas na API: erro - ${e}`);
        reject(e);
      }
    })
    
  }


  fetchPedidoVendaByVisita = visitaId => {
    return new Promise(async (resolve, reject) => {
      const BASE_URL = consts.API_URL;

      try {
        const response = await axios.get(`${BASE_URL}/pedidosVenda/visita/${visitaId}`)

        resolve(response.data.data);
      } catch (e) {
        if(e.message === 'Network Error') {
          return resolve(false);
        }

        console.log(`Erro ao buscar visitas na API: erro - ${e}`);
        reject(e);
      }
    })
  }

  handleCreateNewVisita = async pontoDeVenda => {
    // set the path to go back
    this.props.setRedirectTo('/');
    // check if ponto de venda has a open visita
    
    const lastIncompleteVisitacao = await this.getLastIncompleteVisitacao(pontoDeVenda);
    
    let flag = false
    if (lastIncompleteVisitacao) {
      const visitas = await this.fetchVisitasByVisitacao(lastIncompleteVisitacao)
      if (visitas) {
        visitas.map(el => {
          const pedidovenda = this.fetchPedidoVendaByVisita(el.id)
          if (pedidovenda) {
            flag = true
          }
          return
        })
        if (flag) this.continueVisitacao(lastIncompleteVisitacao, pontoDeVenda)
      } 
    } 
    if (lastIncompleteVisitacao && !flag) {
      // ask user if he wants to continue a previous visitação
      document.getElementById('buttonModalContinueVisitacao').click();
      this.setState({ visitacao: lastIncompleteVisitacao, pontoDeVenda });
    } else if (!flag) {
      // create new visita
      this.initializeVisita(pontoDeVenda);
    }
  }

  continueVisitacao = (visitacao, pontoDeVenda) => {
    // get fornecedores by visitacao and ponto de venda
    this.props.setVisitacao(visitacao);
    this.props.setPontoDeVenda(pontoDeVenda);
    this.props.setRepresentante(this.props.representante);

    this.props.fetchFornecedoresByPontoDeVendaAndVisitacao(pontoDeVenda, visitacao);

    // redirect
    this.props.history.push('/visitas/new');
  }

  discardVisitacao = visitacao => {
    this.props.removeVisitacao(visitacao);

    // initialize a new visitação on the ponto de venda
    this.initializeVisita(this.state.pontoDeVenda);
  }

  initializeVisita = async pontoDeVenda => {
    // set visitacao flow has begun
    localStorage.setItem('visitando', 'true');

    // set ponto de venda and representante on indexedDB
    try {
      const db = await openDatabaseIndexedDB('visitacao');

      // clear database first of all
      await clearAll(db, 'visitacao');

      await db.add('ponto_de_venda', {
        id: pontoDeVenda.id,
        timestamp: new Date().getTime(),
        data: pontoDeVenda
      });

      await db.add('representante', {
        id: this.props.representante.id,
        timestamp: new Date().getTime(),
        data: this.props.representante
      });
    } catch (e) {
      console.log(`%c Erro ao inserir ponto de venda no indexedDB: ${e}`, 'color: tomato');
    }

    // set ponto de venda
    this.props.setPontoDeVenda(pontoDeVenda)

    // init visitacao
    this.props.saveVisitacao(pontoDeVenda)

    // redirect to new visita page
    // this.props.history.push('/visitas/new');
    this.setState({ isRedirectToNewVisita: true });
  }

  handleReadPontoDeVendaVisitas = pontoDeVendaId => {
    // redirect to visita list
    this.props.history.push(`/pontosDeVenda/${pontoDeVendaId}`);
  }

  handlePaginate = page => {
    if (page === this.state.page) return;

    const { ref, withoutRepresentantes } = this.props.pontoDeVenda;

    // callback function based on the user role
    const callback = () => {
      const { GE, RP } = consts.userType;
      const { tipo: userRole } = this.props.auth.user;

      if (userRole === GE) {
        this.props.searchPontosDeVenda_nextVisitPageable(ref, page, this.state.itemsPerPage, withoutRepresentantes)
      }

      if (userRole === RP) {
        const { id } = this.props.auth.user;

        this.props.fetchPontosDeVendaByRepresentantes_nextVisit(id, ref, page, this.state.itemsPerPage);
      }
    }

    this.setState({
      page,
      isLoading: true
    }, callback());
  }

  handleSearch = e => {
    e.preventDefault();

    const page = 1;
    const ref = e.target.ref.value;

    // set reference text on application state
    this.props.setRef(ref);

    // callback function based on the user role
    const callback = () => {
      const { GE, RP } = consts.userType;
      const { tipo: userRole } = this.props.auth.user;

      if (userRole === GE) {
        const withoutRepresentantes = e.target.withoutRepresentantes.checked;

        this.props.searchPontosDeVenda_nextVisitPageable(ref, page, this.state.itemsPerPage, withoutRepresentantes);
        this.props.setWithoutRepresentantes(withoutRepresentantes);
      }

      if (userRole === RP) {
        const { id } = this.props.auth.user;

        this.props.fetchPontosDeVendaByRepresentantes_nextVisit(id, ref, page, this.state.itemsPerPage);
      }
    }

    // search pontos de venda with reference text and navigate to page 1
    this.setState({
      page,
      isLoading: true
    }, callback());
  }

  handleClearSearch = () => {
    const { GE, RP } = consts.userType;
    const { tipo: userRole } = this.props.auth.user;

    // clear search reference on application state
    this.props.clearRef();
    this.props.clearWithoutRepresentantes();

    // clear form
    document.getElementById('ref').value = ""

    if (document.getElementById('withoutRepresentantes'))
      document.getElementById('withoutRepresentantes').checked = false;

    const page = 1;

    // callback function based on the user role
    const callback = () => {
      if (userRole === GE) {
        this.props.searchPontosDeVenda_nextVisitPageable('', page, this.state.itemsPerPage);
      }

      if (userRole === RP) {
        const { id } = this.props.auth.user;

        this.props.fetchPontosDeVendaByRepresentantes_nextVisit(id, '', page, this.state.itemsPerPage);
      }
    }

    // go back to page 1 with all pontos de venda
    this.setState({
      page,
      isLoading: true
    }, callback());
  }

  render() {

    const {
      pontosDeVenda,
      isRedirectToNewVisita
    } = this.state

    // redirect to create a new visitacao, passing a prop
    // to ignore the isVisitando message
    if (isRedirectToNewVisita) {
      return <Redirect to={{
        pathname: '/visitas/new',
        state: { ignoreInitiatedVisitacao: true }
      }} />
    }

    const {
      tipo: userRole
    } = this.props.auth.user

    const { GE } = consts.userType

    const { t: translate } = this.props;

    return (
      <>
        <Breadcrumb title={translate("mapaDeCalor.list.title")} isHideBackBtn={true} />

        <PontoDeVendaSearch onSubmit={this.handleSearch}
          onClearSearch={this.handleClearSearch}
          showFilters={userRole === GE} />

        <Row>
          <Grid cols="12">
            {this.state.isLoading ? <Skeleton count={6} height={50} />
              : (
                <PontoDeVendaList pontosDeVenda={pontosDeVenda}
                  itemsPerPage={this.state.itemsPerPage}
                  paginate={this.handlePaginate}
                  count={this.state.pontosDeVendaCount}
                  representante={this.props.representante}
                  role={userRole}
                  onUnattendPontoDeVenda={this.handleUnattendPontoDeVenda}
                  onCreateVisita={this.handleCreateNewVisita}
                  onReadPontoDeVendaVisitas={this.handleReadPontoDeVendaVisitas} />
              )}
          </Grid>
        </Row>

        <Row>
          <Grid cols="12">
            <Pagination
              page={this.state.page}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.pontosDeVendaCount}
              paginate={this.handlePaginate}
              nextLabel={translate("pagination.buttonProximo")}
              previousLabel={translate("pagination.buttonAnterior")} />
          </Grid>
        </Row>

        <ModalButton id="buttonModalContinueVisitacao"
          style={{ display: 'none' }}
          target="#modalContinueVisitacao" />

        <Modal id="modalContinueVisitacao"
          title={translate("mapaDeCalor.list.table.modalContinueVisitacao.title")}
          text={translate("mapaDeCalor.list.table.modalContinueVisitacao.text")}
          btnText={translate("mapaDeCalor.list.table.modalContinueVisitacao.buttonSim")}
          handleClick={() => this.continueVisitacao(this.state.visitacao, this.state.pontoDeVenda)}
          btnCancelarText={translate("mapaDeCalor.list.table.modalContinueVisitacao.buttonNao")}
          handleCancelarClick={() => this.discardVisitacao(this.state.visitacao)} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  pontosDeVenda: state.pontoDeVenda.pontosDeVenda,
  pontosDeVendaCount: state.pontoDeVenda.count,
  pontoDeVenda: state.pontoDeVenda,
  representante: state.representante.representante,
  fornecedores: state.fornecedor.fornecedores,
  visitas: state.visitacao.visitas,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPontosDeVendaByRepresentantes_nextVisit,
  fetchFornecedoresByPontoDeVendaAndVisitacao,
  searchPontosDeVenda_nextVisitPageable,
  fetchPontosDeVenda_nextVisitPageable,
  fetchCountPontosDeVenda_nextVisit,
  fetchRepresentanteByUserId,
  clearWithoutRepresentantes,
  fetchVisitasByVisitacao,
  setWithoutRepresentantes,
  unattendPontoDeVenda,
  setRepresentante,
  setPontoDeVenda,
  removeVisitacao,
  setRedirectTo,
  saveVisitacao,
  unsetLoading,
  setVisitacao,
  setLoading,
  clearRef,
  setRef,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PontoDeVendaListPage))

import React, { Component } from 'react'
import './searchProduto.css'
import Button from '../template/button'

import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchProdutos, fetchProdutos } from './produtoActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'

class SearchProduto extends Component {
  handleSubmit = e => {
    e.preventDefault()

    // set loading spinner
    this.props.setLoading()

    const ref = e.target.ref.value
    
    this.props.searchProdutos(ref)
  }

  clearSearch = e => {
    e.preventDefault()

    // set loading spinner
    this.props.setLoading()

    // clear form
    document.getElementById('ref').value = ""

    this.props.fetchProdutos()
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="search-container mb-3">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-10">
                <input type="search" className="form-control mb-2" name="ref" id="ref" 
                  placeholder={t("produtos.list.search.placeholder")} />
              </div>
              <div className="col-md-2 d-flex justify-content-between">
                <Button color="secondary" className="d-flex mb-2" title={t("produtos.list.search.buttonSearch.title")}>
                  <i className="material-icons ml-1">search</i>
                </Button>

                <Button color="danger" className="d-flex mb-2"
                  handleclick={this.clearSearch} title={t("produtos.list.search.buttonClear.title")}>
                  <i className="material-icons ml-1">clear</i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  searchProdutos,
  fetchProdutos,
  setLoading
}, dispatch)

export default connect(null, mapDispatchToProps)(withTranslation()(SearchProduto))

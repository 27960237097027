export default {
  exclusaoPontosDeVenda: {
    title: 'Validar exclusão de pontos de venda',
    notFound: 'Nenhuma exclusão de ponto de venda a validar ...',
    table: {
      pontoDeVenda: 'Ponto de venda',
      representante: 'Representante',
      justificativa: 'Justificativa',
      buttonVer: 'Ver',
      modalJustificativa: {
        title: 'Justificativa de exclusão',
        title2: 'Motivo',
        buttonRejeitar: 'Rejeitar',
        buttonAceitar: 'Aceitar'
      }
    },
  },
  cadastros: {
    title: 'Validar cadastros',
    table: {
      email: 'E-mail',
      tipo: {
        label: 'Tipo',
        gestor: 'Gestor',
        representante: 'Representante',
        fornecedor: 'Fornecedor',
        cliente: 'Cliente'
      },
      status: {
        label: 'Status',
        validado: 'Validado',
        naoValidado: 'Não validado'
      },
      acao: 'Ação',
      buttonValidarCadastro: 'Validar cadastro'
    }
  },
  exclusaoFornecedores: {
    title: 'Validar exclusão de fornecedores',
    notFound: 'Nenhuma exclusão de fornecedor a validar ...',
    table: {
      pontoDeVenda: 'Ponto de venda',
      fornecedor: 'Fornecedor',
      representante: 'Representante',
      justificativa: 'Justificativa',
      buttonVer: 'Ver',
      modalJustificativa: {
        title: 'Justificativa de exclusão',
        title2: 'Motivo',
        buttonRejeitar: 'Rejeitar',
        buttonAceitar: 'Aceitar'
      }
    }
  }
}
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from '../template/breadcrumb';
import NewExpositorForm from './newExpositorForm';
import Expositor from '../estande/expositor';
import ExpositorProdutoList from './expositorProdutoList';
import Button from '../template/button';
import { findGreater } from '../../utils/arrays';
import { isEqual } from '../../utils/objects';
import Row from '../template/row';
import Grid from '../template/grid';
import axios from 'axios';
import consts from '../../consts';
import { toastr } from 'react-redux-toastr';
import { MdAdd } from 'react-icons/md';
import NewProdutoForm from './newProdutoForm';
import { useDispatch } from 'react-redux';
import {
  setLoading,
  unsetLoading,
} from '../loadingSpinner/loadingSpinnerActions';

const BASE_URL = consts.API_URL;

export default function NewExpositorWizard() {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const [step, setStep] = useState('EXPOSITOR_FORM');

  const [expositor, setExpositor] = useState(null);
  const [estandeRows, setEstandeRows] = useState([1]);
  const [estande, setEstande] = useState([]);

  // row to add item
  const [rowToAdd, setRowToAdd] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function handleEdit() {
      // is editting
      if (location.state?.isEditting) {
        setStep(location.state.step || 'EXPOSITOR_ITENS');
        setExpositor(location.state.expositor);

        // get/set items expositor
        const itensResponse = await axios.get(
          `${BASE_URL}/expositores/${location.state.expositor.id}/itens`
        );

        if (itensResponse.data.data.length > 0) {
          const estande = itensResponse.data.data.map((item) => ({
            ...item.produto,
            pos: [item.linha, item.coluna],
          }));

          setEstande(estande);

          // set estande rows
          const rows = [...new Set(estande.map((item) => item.pos[0]))];

          setEstandeRows(rows.map((item, index) => index + 1));
        }
      }
    }

    handleEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(data) {
    if (!data.nome) return;

    // save expositor
    const response = await axios.post(`${BASE_URL}/expositores`, {
      expositor: { nome: data.nome },
      fornecedor: { id: data.fornecedor_id },
    });

    // set saved expositor
    setExpositor({
      id: response.data.data.insertId,
      ...data,
    });

    setStep('EXPOSITOR_ITENS');
  }

  async function handleSaveExpositorItems() {
    try {
      dispatch(setLoading());

      // if editting, delete all itens before saving the new ones
      if (location.state?.isEditting) {
        const currentItens = await axios.get(
          `${BASE_URL}/expositores/${location.state.expositor.id}/itens`
        );
        if (currentItens.data.data.length > 0)
          await axios.delete(`${BASE_URL}/expositores/${expositor.id}/itens`);
      }

      await axios.post(
        `${BASE_URL}/expositores/${expositor.id}/itens/multiple`,
        {
          estande: estande.map((i) => ({
            item: { linha: i.pos[0], coluna: i.pos[1] },
            produto: { ...i },
          })),
        }
      );
    } catch (e) {
      toastr.error('Falha ao salvar estande do expositor');
    } finally {
      dispatch(unsetLoading());

      history.push('/expositores');
    }
  }

  function handleAddRow() {
    // find last row of estande
    const lastRow = findGreater(estandeRows);

    setEstandeRows([...estandeRows, lastRow + 1]);
  }

  function handleRemoveRow(row) {
    // remove last row of estande
    let rows = estandeRows;
    rows.splice(rows.length - 1, 1);

    setEstandeRows(rows);

    // remove itens of selected row and reposition the items after the selected row
    const newEstande = estande
      .filter((item) => item.pos[0] !== row)
      .map((item) => {
        if (item.pos[0] > row) {
          return {
            ...item,
            pos: [item.pos[0] - 1, item.pos[1]],
          };
        }

        return item;
      });

    setEstande(newEstande);
  }

  function handleGoToProdutoList(row) {
    setRowToAdd(row);

    setStep('EXPOSITOR_ADD_PRODUTO');
  }

  function handleRemoveColumn(item) {
    setEstande(estande.filter((i) => !isEqual(i.pos, item.pos)));
  }

  function handleSelectProduto(produto) {
    if (!rowToAdd) return;

    // get last column of row to add produto
    const columnsOfSelectedRow = estande.filter(
      (item) => item.pos[0] === rowToAdd
    );

    const positions =
      columnsOfSelectedRow.length === 0
        ? [0]
        : columnsOfSelectedRow.map((item) => item.pos[1]);

    const lastColumn = findGreater(positions);

    // add produto
    setEstande([...estande, { ...produto, pos: [rowToAdd, lastColumn + 1] }]);

    // clear row to add
    setRowToAdd(null);

    // go back to estande
    setStep('EXPOSITOR_ITENS');
  }

  function ButtonPreview() {
    return (
      <Button
        color='secondary'
        size='sm'
        handleclick={() => setStep('EXPOSITOR_PREVIEW')}
      >
        {t('expositores.estande.buttonPreview')}
      </Button>
    );
  }

  function ButtonAddProduto() {
    return (
      <Button
        color='success'
        size='sm'
        handleclick={() => setStep('EXPOSITOR_NEW_PRODUTO')}
      >
        <MdAdd size={18} />
      </Button>
    );
  }

  function renderStep(step) {
    const wizard = {
      EXPOSITOR_FORM: <NewExpositorForm onSubmit={handleSubmit} />,
      EXPOSITOR_ITENS: (
        <Expositor
          data={estande}
          rows={estandeRows}
          onAddRow={handleAddRow}
          onRemoveRow={handleRemoveRow}
          onAddColumn={handleGoToProdutoList}
          onRemoveColumn={handleRemoveColumn}
          isBuildingExpositor
        />
      ),
      EXPOSITOR_PREVIEW: (
        <>
          <Expositor data={estande} rows={estandeRows} />

          <Row>
            <Grid cols='12 6'>
              <Button
                size='block'
                color='secondary'
                handleclick={() => setStep('EXPOSITOR_ITENS')}
              >
                {t('expositores.estande.buttonVoltar')}
              </Button>
            </Grid>
            <Grid cols='12 6'>
              <Button size='block' handleclick={handleSaveExpositorItems}>
                {t('expositores.estande.buttonFinalizar')}
              </Button>
            </Grid>
          </Row>
        </>
      ),
      EXPOSITOR_ADD_PRODUTO: (
        <ExpositorProdutoList
          fornecedorId={expositor?.fornecedor_id || expositor?.fornecedor.id}
          onSelectProduto={handleSelectProduto}
        />
      ),
      EXPOSITOR_NEW_PRODUTO: (
        <NewProdutoForm onSetProduto={handleSelectProduto} />
      ),
    };

    return wizard[step] || <p>{t('expositores.estande.error')}</p>;
  }

  function renderComponent(step) {
    const wizard = {
      EXPOSITOR_FORM: null,
      EXPOSITOR_ITENS: <ButtonPreview />,
      EXPOSITOR_PREVIEW: null,
      EXPOSITOR_ADD_PRODUTO: <ButtonAddProduto />,
    };

    return wizard[step] || null;
  }

  function renderTitle(step) {
    const wizard = {
      EXPOSITOR_FORM: t('expositores.form.title'),
      EXPOSITOR_ITENS: expositor && expositor.nome,
      EXPOSITOR_PREVIEW: `${expositor && expositor.nome} - ${t(
        'expositores.estande.title.preview'
      )}`,
      EXPOSITOR_ADD_PRODUTO: `${expositor && expositor.nome} - ${t(
        'expositores.estande.title.addProduto'
      )}`,
      EXPOSITOR_NEW_PRODUTO: t('expositores.estande.title.newProduto'),
    };

    return wizard[step] || t('expositores.form.title');
  }

  function renderLink(step) {
    const wizard = {
      EXPOSITOR_FORM: '/expositores',
      EXPOSITOR_ITENS: '/expositores',
      EXPOSITOR_PREVIEW: '/expositores/new',
      EXPOSITOR_ADD_PRODUTO: '/expositores/new',
      EXPOSITOR_NEW_PRODUTO: '/expositores/new',
    };

    return wizard[step] || '/expositores';
  }

  function renderFunction(step) {
    const wizard = {
      EXPOSITOR_FORM: null,
      EXPOSITOR_ITENS: null,
      EXPOSITOR_PREVIEW: () => setStep('EXPOSITOR_ITENS'),
      EXPOSITOR_ADD_PRODUTO: () => setStep('EXPOSITOR_ITENS'),
      EXPOSITOR_NEW_PRODUTO: () => setStep('EXPOSITOR_ADD_PRODUTO'),
    };

    return wizard[step] || null;
  }

  return (
    <div>
      <Breadcrumb
        title={renderTitle(step)}
        linkTo={renderLink(step)}
        btnFunction={renderFunction(step)}
        isHideBackBtn={
          step === 'EXPOSITOR_ITENS' && !location.state?.isEditting
        }
        component={() => renderComponent(step)}
      />

      {renderStep(step)}
    </div>
  );
}

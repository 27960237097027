export function isEqual(item1, item2) {
  return JSON.stringify(item1) === JSON.stringify(item2);
}

export function isEqualByProps(obj1, obj2, ...props) {
  let isEqual = true;

  props.forEach(prop => {
    if (JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop]))
      isEqual = false;
  });

  return isEqual;
}

import React, { useState, memo } from 'react'
import './googleMap.css'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

const GoogleMap = memo(function GoogleMap({ center, readOnly, updateCenter }) {
	const [_map, setMap] = useState(null);

	const handleApiLoaded = (map) => {
		setMap(map);
	}

	const handleMapClick = (lat, lng) => {
		const center = { lat, lng };

		updateCenter(center);

		if(_map) _map.panTo(center);
	}

	return (
		<div className="map-container">
			<GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN }}
				className="map"
				center={center}
				defaultZoom={16}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				onClick={readOnly ? null : ({ lat, lng }) => handleMapClick(lat, lng)}>
				<Marker lat={center.lat} lng={center.lng} />
			</GoogleMapReact>
		</div>
	)
})

GoogleMap.propTypes = {
	center: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	}).isRequired,

	/** Function to update the 'center' obj on the parent component */
	updateCenter: PropTypes.func,

	/** Read-only map - user cannot interact with the center obj */
	readOnly: PropTypes.bool
}

GoogleMap.defaultProps = {
	readOnly: false
}

export default GoogleMap;

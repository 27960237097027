const INITIAL_STATE = {
  produto: null,
  produtos: []
}

const produtoReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_PRODUTOS':
      return {
        ...state,
        produtos: action.payload
      }
    case 'SET_PRODUTO':
      return {
        ...state,
        produto: action.payload
      }
    default: 
      return state
  }
}

export default produtoReducer
import React, { Component } from 'react'
import CurrencyFormat from 'react-currency-format'
import Breadcrumb from '../template/breadcrumb'
import { Redirect } from 'react-router-dom'
import moment from 'moment'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateTabelaPreco } from './tabelaPrecoActions'

export class TabelaPrecoEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReadonly: true,
      redirect: false,
      valor: ''
    }
  }

  componentDidMount() {
    this.loadInfo()
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  loadInfo = () => {
    const { 
      valor, 
      vigencia_inicio, 
      vigencia_fim,
      promocao 
    } = this.props.tabelaPreco

    document.getElementById('valor').value = valor
    document.getElementById('vigencia_inicio').value = moment(vigencia_inicio).format("YYYY-MM-DD")
    document.getElementById('vigencia_fim').value = moment(vigencia_fim).format("YYYY-MM-DD")
    
    if(promocao) {
      document.getElementById('promocao').checked = true
    }

    this.setState({
      ...this.state,
      valor
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    
    const produto = this.props.produto
    const pontoDeVenda = this.props.pontoDeVenda

    const promocao = e.target.promocao.checked

    const tabelaPreco = {
      ...this.props.tabelaPreco,
      vigencia_inicio: e.target.vigencia_inicio.value,
      vigencia_fim: e.target.vigencia_fim.value,
      valor: e.target.valor.value,
      promocao
    }
    
    // update cargo
    this.props.updateTabelaPreco(tabelaPreco, produto, pontoDeVenda)

    // redirect
    this.setState({
      ...this.state,
      redirect: true
    })
  }

  render() {
    if(!this.props.tabelaPreco) {
      return <Redirect to="/tabelasPreco"/>
    }

    if(this.state.redirect) {
      return <Redirect to="/tabelasPreco"/>
    }

    return (
      <React.Fragment>
        <Breadcrumb title="Editar tabela de preço" linkTo="/tabelasPreco" />

        <div className="box-login mt-4">
          <form onSubmit={this.handleSubmit} className="mt-3 mb-3">
            <fieldset>
              <legend>Produto e ponto de venda</legend>
              <div className="form-group">
                <label htmlFor="produto"><b>Produto: </b></label>
                {
                  this.props.produto ? (
                    <div>
                      <p>{this.props.produto.descricao} - {this.props.produto.cod_artigo}</p>
                    </div>
                  ) : (
                    <div>
                      <p>Nenhum produto selecionado</p>
                    </div>
                  )
                }
              </div>

              <div className="form-group">
                <label htmlFor="pontoDeVenda"><b>Ponto de venda: </b></label>
                {
                  this.props.pontoDeVenda ? (
                    <div>
                      <p>{this.props.pontoDeVenda.nome}</p>
                    </div>
                  ) : (
                    <div>
                      <p>Nenhum ponto de venda selecionado</p>
                    </div>
                  )
                }
              </div>

              {
                this.state.isReadonly ? null : (
                  <div className="form-group">
                    <button type="button" id="pontoDeVenda" 
                      className="btn p-0 btn-sm btn-block btn-secondary d-flex align-items-center justify-content-center" 
                      onClick={this.props.loadNextStep}>
                      Selecionar <i className="material-icons ml-1" 
                      style={{ lineHeight: 'inherit' }}>add</i>
                    </button> 
                  </div>
                )
              }
            </fieldset>

            <hr/>

            <fieldset>
              <legend>Vigência e valor</legend>
              <div className="form-group">
                <label htmlFor="vigencia_inicio">Início: </label>
                <input type="date" className="form-control" name="vigencia_inicio" id="vigencia_inicio"
                  readOnly={this.state.isReadonly} required/>
              </div>

              <div className="form-group">
                <label htmlFor="vigencia_fim">Fim: </label>
                <input type="date" className="form-control" name="vigencia_fim" id="vigencia_fim"
                  readOnly={this.state.isReadonly} required/>
              </div>

              <div className="form-group">
                <label htmlFor="valor">Valor: </label>
                {
                  this.state.isReadonly ? (
                    <input type="text" id="valor" name="valor" className="form-control" value={this.state.valor}
                      readOnly={this.state.isReadonly} required/>
                  ) : (
                    <CurrencyFormat id="valor" name="valor" className="form-control"
                      thousandSeparator={'.'} 
                      prefix={'R$'}
                      decimalSeparator={','}
                      decimalScale={2}
                      value={this.state.valor}
                      onChange={this.handleChange} required/>
                  )
                }
              </div>

              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="promocao" id="promocao"/>
                <label className="form-check-label" htmlFor="promocao">Está em promoção?</label>
              </div>
            </fieldset>
      
            {
              this.state.isReadonly ? (
                <button type="button" className="btn btn-primary btn-block"
                  onClick={this.changeReadonly}>Habilitar campos</button>
              ) : (
                <div className="row">
                  <div className="col-6">
                    <button type="button" className="btn btn-secondary btn-block"
                      onClick={this.changeReadonly}>Cancelar</button>
                  </div>
                  <div className="col-6">
                    <button type="submit" className="btn btn-primary btn-block">Salvar</button>
                  </div>
                </div>
              )
            }
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    tabelaPreco: state.tabelaPreco.tabelaPreco,
    produto: state.tabelaPreco.produto,
    pontoDeVenda: state.tabelaPreco.pontoDeVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  updateTabelaPreco
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TabelaPrecoEdit)

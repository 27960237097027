import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'
import SearchProduto from '../produto/searchProdutoUnbound'

import '../produto/produtoList.css'
import defaultImg from '../common/imgs/default-img.png'
import firebase from '../../config/fbConfig'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAllProdutosByPontoDeVenda } from '../produto/produtoActions'
import { bindPontoDeVendaToProduto, unbindProdutoFromPontoDeVenda } from './pontoDeVendaActions'

export class BindPontoDeVendaToProduto extends Component {
  componentDidMount() {
    if(!this.props.pontoDeVenda) {
      this.props.history.push('/home')
      return
    }

    this.props.fetchAllProdutosByPontoDeVenda(this.props.pontoDeVenda.id)
  }

  constructor(props) {
    super(props)

    this.state = {
      produtos: []
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      ...this.state,
      produtos: props.produtos
    }) 

    setTimeout(() => {
      this.loadImages()
    }, 500);
  }

  bindProdutoToPontoDeVenda = produto => {
    const { pontoDeVenda } = this.props

    this.props.bindPontoDeVendaToProduto(pontoDeVenda, produto)
  }

  unbindProdutoFromPontoDeVenda = id => {
    const { pontoDeVenda } = this.props;

    this.props.unbindProdutoFromPontoDeVenda(id, pontoDeVenda);
  }

  ButtonVincular = props => {
    const { produto } = props

    return (
      <button className="btn btn-sm btn-info mb-2" onClick={() => this.bindProdutoToPontoDeVenda(produto)}>
        <Trans i18nKey="pontosDeVenda.edit.produtos.link.card.buttonVincular">trans</Trans>
      </button>
    )
  }

  ButtonDesvincular = props => {
    const { produto } = props;

    return (
      <Button size="sm" color="danger" className="mb-2"
        handleclick={() => this.unbindProdutoFromPontoDeVenda(produto.produto_id)}>
        <Trans i18nKey="pontosDeVenda.edit.produtos.link.card.buttonDesvincular">trans</Trans>
      </Button>
    )
  }

  loadImages = () => {
    this.state.produtos.forEach(produto => {
      const imgId = 'img-' + produto.id

      if(produto.imagem && produto.imagem !== 'undefined') {
        const storageRef = firebase.storage().ref(produto.imagem)
  
        storageRef.getDownloadURL()
          .then(url => {
            if(document.getElementById(imgId)) {
              document.getElementById(imgId).src = url
            }
          })
          .catch(err => {
            console.log("Erro ao carregar imagem: ", err)
            throw err
          })
      }
    })
  }

  renderProdutos = () => {
    const produtos = this.state.produtos || []

    return produtos.map(produto => {
      const imgId = 'img-' + produto.id
      
      return (
        <div key={produto.id} className="card mb-3" style={{ maxWidth: 540, minWidth: 300 }}>
          <div className="row no-gutters">
            <div className="col-md-4">
              <a href={null} style={{ cursor: 'pointer' }}>
                <img id={imgId} src={defaultImg} className="card-img" alt={produto.descricao} />
              </a>
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <a href={null} style={{ cursor: 'pointer' }}>
                  <h5 className="card-title">{produto.cod_artigo}</h5>
                  <p className="card-text">
                    {produto.descricao}
                    <br/>
                  </p>
                </a>
                <div className="card-text">
                  <div className="text-right">
                    {
                      produto.produto_id === null ? 
                        <this.ButtonVincular produto={produto} />
                      : <this.ButtonDesvincular produto={produto} />
                    }
                  </div>
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    <b><Trans i18nKey="pontosDeVenda.edit.produtos.link.card.fornecedor">trans</Trans></b>{produto.fornecedor_nome} | 
                    {' '}
                    <b><Trans i18nKey="pontosDeVenda.edit.produtos.link.card.unidade">trans</Trans></b>{produto.unidade}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    setTimeout(() => {
      this.setState({
        ...this.state,
        produtos: this.props.produtos
      })  
    }, 100);

    const { pontoDeVenda } = this.props

    if(!pontoDeVenda) {
      return <Redirect to="/home" />
    }

    const { t } = this.props;

    const pageTitle = `${t("pontosDeVenda.edit.produtos.link.title")} <${pontoDeVenda.nome}>`;

    return (
      <React.Fragment>
        <Breadcrumb title={pageTitle} linkTo="/pontosDeVenda/edit" />

        <SearchProduto pontoDeVendaId={this.props.pontoDeVenda.id} />
        
        <div className="d-flex card-container">
          {this.renderProdutos()}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    pontoDeVenda: state.pontoDeVenda.pontoDeVenda,
    produtos: state.produto.produtos
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  bindPontoDeVendaToProduto,
  unbindProdutoFromPontoDeVenda,
  fetchAllProdutosByPontoDeVenda,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BindPontoDeVendaToProduto))

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Breadcrumb from '../../template/breadcrumb'
import Row from '../../template/row'
import Grid from '../../template/grid'
import ModalButton from '../../template/modalButton'
import Modal from '../../template/modal'
import Skeleton from 'react-loading-skeleton'

import { useTranslation, Trans } from 'react-i18next'

import { fetchPontosDeVendaToUnattend } from '../../pontoDeVenda/pontoDeVendaActions'

import { fetchCountPontosDeVendaUnattended } from '../../navbar/navbarActions'

import {
  attendPontoDeVenda,
  unbindRepresentanteFromPontoDeVenda
} from '../../representante/representanteActions'

import { setLoading, unsetLoading } from '../../loadingSpinner/loadingSpinnerActions'

export default function ValidarExclusaoPontosDeVenda() {
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRender, setFirstRender] = useState(true);
  const [pontosDeVenda, setPontosDeVenda] = useState([]);
  const [motivo, setMotivo] = useState('');
  const [pontoDeVendaRepresentanteId, setPontoDeVendaRepresentanteId] = useState(null);

  // application state
  const pontosDeVendaState = useSelector(state => state.pontoDeVenda.pontosDeVenda);
  const dispatch = useDispatch();

  // i18n
  const { t: translate } = useTranslation();

  // find all pontos de venda
  useEffect(() => {
    dispatch(fetchPontosDeVendaToUnattend());
  }, []);

  // set pontos de venda on component state
  useEffect(() => {
    if(isFirstRender) {
      setFirstRender(false);
      return;
    }

    // if the reducer already has pontos de venda it will fill this component
    // with the 'wrong' pontos de venda on the component first render
    if (pontosDeVendaState && !isFirstRender) {
      setPontosDeVenda(pontosDeVendaState);
      setIsLoading(false);
    }
  }, [pontosDeVendaState]);

  function renderPontosDeVenda(pontosDeVenda) {
    return pontosDeVenda.map(pontoDeVenda => (
      <tr key={pontoDeVenda.id}>
        <td className="align-middle">{pontoDeVenda.nome}</td>
        <td className="align-middle text-center">{pontoDeVenda.representante_nome}</td>
        <td className="align-middle text-center">
          <ModalButton size="sm" color="success"
            target="#modalJustificativa"
            handleclick={() => {
              setMotivo(pontoDeVenda.motivo);
              setPontoDeVendaRepresentanteId(pontoDeVenda.ponto_de_venda_representante_id);
            }}>
            <Trans i18nKey="validacao.exclusaoPontosDeVenda.table.buttonVer">trans</Trans>
          </ModalButton>
        </td>
      </tr>
    ))
  }

  function clearRequestState() {
    // clear component state
    setMotivo('');
    setPontoDeVendaRepresentanteId(null);
  }

  async function allowRequest() {
    // set loading
    dispatch(setLoading());

    // unbind representante from ponto de venda
    await dispatch(unbindRepresentanteFromPontoDeVenda(pontoDeVendaRepresentanteId));

    dispatch(fetchCountPontosDeVendaUnattended());

    // update pontos de venda requests
    await dispatch(fetchPontosDeVendaToUnattend());

    // clear actual request
    clearRequestState();

    // remove loading spinner
    dispatch(unsetLoading());
  }

  async function denyRequest() {
    // set loading
    dispatch(setLoading());

    // remove not attending marker
    await dispatch(attendPontoDeVenda(pontoDeVendaRepresentanteId));

    dispatch(fetchCountPontosDeVendaUnattended());

    // update pontos de venda requests
    await dispatch(fetchPontosDeVendaToUnattend());

    // clear actual request
    clearRequestState();

    // remove loading spinner
    dispatch(unsetLoading());
  }

  return (
    <div>
      <Breadcrumb title={translate("validacao.exclusaoPontosDeVenda.title")} />

      <Row>
        <Grid cols="12">
          {isLoading ? <Skeleton count={5} height={35} />
            : pontosDeVenda.length > 0 ? (
              <div id="flip-scroll">
                <table className="table table-fluid table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        <Trans i18nKey="validacao.exclusaoPontosDeVenda.table.pontoDeVenda">trans</Trans>
                      </th>
                      <th scope="col" className="text-center">
                        <Trans i18nKey="validacao.exclusaoPontosDeVenda.table.representante">trans</Trans>
                      </th>
                      <th scope="col" className="text-center">
                        <Trans i18nKey="validacao.exclusaoPontosDeVenda.table.justificativa">trans</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderPontosDeVenda(pontosDeVenda)}
                  </tbody>
                </table>
              </div>
            ) : <p><Trans i18nKey="validacao.exclusaoPontosDeVenda.notFound">trans</Trans></p>}
        </Grid>
      </Row>

      {/* Modal */}
      <Modal id="modalJustificativa"
        title={translate("validacao.exclusaoPontosDeVenda.table.modalJustificativa.title")}
        size="lg"
        btnColor="success"
        btnCancelarColor="danger"
        btnText={translate("validacao.exclusaoPontosDeVenda.table.modalJustificativa.buttonAceitar")}
        btnCancelarText={translate("validacao.exclusaoPontosDeVenda.table.modalJustificativa.buttonRejeitar")}
        handleClick={allowRequest}
        handleCancelarClick={denyRequest}>
        <Row>
          <Grid cols="12">
            <p>
              <span className="font-weight-bold">
                <Trans i18nKey="validacao.exclusaoPontosDeVenda.table.modalJustificativa.title2">trans</Trans>
              </span>
            </p>
            <textarea className="form-control" name="motivo" id="motivo"
              cols="30" rows="10"
              value={motivo}
              disabled></textarea>
          </Grid>
        </Row>
      </Modal>
    </div>
  )
}

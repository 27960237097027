import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ color, size, children, handleclick, className: classes, link, ...props }) => {
  return (
    <button {...props} className={link ? `btn btn-link ${classes}` : `btn btn-${size} btn-${color} ${classes}`}
      onClick={handleclick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  /** Bootstrap color of the button (primary, secondary, danger...) */
  color: PropTypes.string,
  /** Bootstrap size of the button (sm, md, lg...) */
  size: PropTypes.string,
  /** Anything to be displayed inside the button e.g. icon */
  children: PropTypes.any,
  /** onClick button function */
  handleclick: PropTypes.any,
  /** Other optional classes to be added to the button */
  className: PropTypes.string,
  /** Button link */
  link: PropTypes.bool,
  cursor: PropTypes.string
}

Button.defaultProps = {
  color: 'primary',
  size: 'md',
  children: 'Enviar',
  handleclick: () => { },
  className: '',
  cursor: 'pointer'
}

export default Button

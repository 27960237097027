import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchFornecedoresToUnattend,
  clearFornecedores,
  removeFornecedorFromPontoDeVenda,
  attendFornecedor
} from '../../fornecedor/fornecedorActions'
import { fetchCountFornecedoresUnattended } from '../../navbar/navbarActions'
import { setLoading, unsetLoading } from '../../loadingSpinner/loadingSpinnerActions'
import Breadcrumb from '../../template/breadcrumb'
import Row from '../../template/row'
import Grid from '../../template/grid'
import Modal from '../../template/modal'
import ModalButton from '../../template/modalButton'

import { useTranslation, Trans } from 'react-i18next'

export default function ValidarExclusaoFornecedores() {
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedorToUnattend, setFornecedorToUnattend] = useState(null);
  const [motivo, setMotivo] = useState('');

  // application state
  const fornecedoresState = useSelector(state => state.fornecedor.fornecedores);
  const dispatch = useDispatch();

  // i18n
  const { t: translate } = useTranslation();

  // find all pontos de venda
  useEffect(() => {
    dispatch(fetchFornecedoresToUnattend());

    return () => dispatch(clearFornecedores());
  }, []);

  // set pontos de venda on component state
  useEffect(() => {
    if (fornecedoresState) {
      setFornecedores(fornecedoresState);
    }
  }, [fornecedoresState]);

  function renderFornecedores(fornecedores) {
    return fornecedores.map((fornecedor, index) => (
      <tr key={index}>
        <td className="align-middle">{fornecedor.ponto_de_venda_nome}</td>
        <td className="align-middle text-center">{fornecedor.fornecedor_nome}</td>
        <td className="align-middle text-center">{fornecedor.representante_nome}</td>
        <td className="align-middle text-center">
          <ModalButton size="sm" color="success"
            target="#modalJustificativa"
            handleclick={() => {
              setMotivo(fornecedor.motivo);
              setFornecedorToUnattend(fornecedor);
            }}>
            <Trans i18nKey="validacao.exclusaoFornecedores.table.buttonVer">trans</Trans>
          </ModalButton>
        </td>
      </tr>
    ))
  }

  function clearRequestState() {
    // clear component state
    setMotivo('');
    setFornecedorToUnattend(null);
  }

  async function allowRequest() {
    // set loading
    dispatch(setLoading());

    // unbind fornecedor from ponto de venda
    await dispatch(removeFornecedorFromPontoDeVenda(
      fornecedorToUnattend.ponto_de_venda_id,
      fornecedorToUnattend.fornecedor_id,
      fornecedorToUnattend.representante_id
    ));

    dispatch(fetchCountFornecedoresUnattended());

    // update pontos de venda requests
    await dispatch(fetchFornecedoresToUnattend());

    // clear actual request
    clearRequestState();

    // remove loading spinner
    dispatch(unsetLoading());
  }

  async function denyRequest() {
    // set loading
    dispatch(setLoading());

    // remove not attending marker
    await dispatch(attendFornecedor(
      fornecedorToUnattend.ponto_de_venda_id,
      fornecedorToUnattend.fornecedor_id,
      fornecedorToUnattend.representante_id
    ));

    dispatch(fetchCountFornecedoresUnattended());

    // update fornecedores requests
    await dispatch(fetchFornecedoresToUnattend());

    // clear actual request
    clearRequestState();

    // remove loading spinner
    dispatch(unsetLoading());
  }

  return (
    <div>
      <Breadcrumb title={translate("validacao.exclusaoFornecedores.title")} />

      <Row>
        <Grid cols="12">
          {fornecedores.length > 0 ? (
            <div id="flip-scroll">
              <table className="table table-fluid table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="validacao.exclusaoFornecedores.table.pontoDeVenda">trans</Trans>
                    </th>
                    <th scope="col" className="text-center">
                      <Trans i18nKey="validacao.exclusaoFornecedores.table.fornecedor">trans</Trans>
                    </th>
                    <th scope="col" className="text-center">
                      <Trans i18nKey="validacao.exclusaoFornecedores.table.representante">trans</Trans>
                    </th>
                    <th scope="col" className="text-center">
                      <Trans i18nKey="validacao.exclusaoFornecedores.table.justificativa">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderFornecedores(fornecedores)}
                </tbody>
              </table>
            </div>
          ) : (
              <p>
                <Trans i18nKey="validacao.exclusaoFornecedores.notFound">trans</Trans>
              </p>
            )}
        </Grid>
      </Row>

      {/* Modal */}
      <Modal id="modalJustificativa"
        title={translate("validacao.exclusaoFornecedores.table.modalJustificativa.title")}
        size="lg"
        btnColor="success"
        btnCancelarColor="danger"
        btnText={translate("validacao.exclusaoFornecedores.table.modalJustificativa.buttonAceitar")}
        btnCancelarText={translate("validacao.exclusaoFornecedores.table.modalJustificativa.buttonRejeitar")}
        handleClick={allowRequest}
        handleCancelarClick={denyRequest}>
        <Row>
          <Grid cols="12">
            <p>
              <span className="font-weight-bold">
                <Trans i18nKey="validacao.exclusaoFornecedores.table.modalJustificativa.title2">trans</Trans>
              </span>
            </p>
            <textarea className="form-control" name="motivo" id="motivo"
              cols="30" rows="10"
              value={motivo}
              disabled></textarea>
          </Grid>
        </Row>
      </Modal>
    </div>
  )
}

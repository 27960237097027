const INITIAL_STATE = {
  redirectTo: null
}

export default function routerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ROUTER_SET_REDIRECT':
      return {
        ...state,
        redirectTo: action.payload
      }
    case 'ROUTER_CLEAR':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

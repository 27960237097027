export const setRedirectTo = path => {
  return {
    type: 'ROUTER_SET_REDIRECT',
    payload: path
  }
}

export const clearAll = () => {
  return {
    type: 'ROUTER_CLEAR'
  }
}

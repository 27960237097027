import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import CurrencyFormat from 'react-currency-format'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastr } from 'react-redux-toastr'

import { saveTabelaPreco, setTabelaPreco } from './tabelaPrecoActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'

class TabelaPrecoForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirect: false,
      vigencia_inicio: '',
      vigencia_fim: '',
      valor: ''
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const { 
      produto, 
      pontoDeVenda 
    } = this.props.tabelaPreco
    
    const promocao = document.getElementById('promocao').checked
    const tabelaPreco = {
      ...this.state,
      promocao
    }

    if(!produto) {
      toastr.warning("Algo está faltando", "Você deve selecionar um produto")
      return
    }

    if(!pontoDeVenda) {
      toastr.warning("Algo está faltando", "Você deve selecionar um ponto de venda")
      return
    }

    this.props.setLoading()
    this.props.saveTabelaPreco(tabelaPreco, produto, pontoDeVenda)

    // redirect to /tabelasPreco
    this.setState({
      ...this.state,
      redirect: true
    })
  }

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }
  
  render() {
    if(this.state.redirect) {
      return <Redirect to="/tabelasPreco" />
    }

    return (
      <React.Fragment>
        <Breadcrumb title="Nova tabela de preço" linkTo="/tabelasPreco" />

        <div className="box-login mt-4">
          <form onSubmit={this.handleSubmit} className="mt-3 mb-3">
            <fieldset>
              <legend>Produto e ponto de venda</legend>
              <div className="form-group">
                <label htmlFor="produto"><b>Produto: </b></label>
                {
                  this.props.produto ? (
                    <div>
                      <p>{this.props.produto.descricao} - {this.props.produto.cod_artigo}</p>
                    </div>
                  ) : (
                    <div>
                      <p>Nenhum produto selecionado</p>
                    </div>
                  )
                }
              </div>

              <div className="form-group">
                <label htmlFor="pontoDeVenda"><b>Ponto de venda: </b></label>
                {
                  this.props.pontoDeVenda ? (
                    <div>
                      <p>{this.props.pontoDeVenda.nome}</p>
                    </div>
                  ) : (
                    <div>
                      <p>Nenhum ponto de venda selecionado</p>
                    </div>
                  )
                }
              </div>

              <div className="form-group">
              <button type="button" id="pontoDeVenda" 
                className="btn p-0 btn-sm btn-block btn-secondary d-flex align-items-center justify-content-center" 
                onClick={this.props.loadNextStep}>
                Selecionar <i className="material-icons ml-1" 
                style={{ lineHeight: 'inherit' }}>add</i>
              </button> 
              </div>
            </fieldset>

            <hr/>

            <fieldset>
              <legend>Vigência e valor</legend>
              <div className="form-group">
                <label htmlFor="vigencia_inicio">Início: </label>
                <input type="date" className="form-control" name="vigencia_inicio" id="vigencia_inicio"
                  value={this.state.vigencia_inicio} onChange={this.handleChange} required/>
              </div>

              <div className="form-group">
                <label htmlFor="vigencia_fim">Fim: </label>
                <input type="date" className="form-control" name="vigencia_fim" id="vigencia_fim"
                  value={this.state.vigencia_fim} onChange={this.handleChange} required/>
              </div>

              <div className="form-group">
                <label htmlFor="valor">Valor: </label>
                <CurrencyFormat id="valor" name="valor" className="form-control"
                  thousandSeparator={'.'} 
                  prefix={'R$'}
                  decimalSeparator={','}
                  decimalScale={2}
                  value={this.state.valor}
                  onChange={this.handleChange} required/>
              </div>

              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="promocao" id="promocao"/>
                <label className="form-check-label" htmlFor="promocao">Está em promoção?</label>
              </div>
            </fieldset>
      
            <button type="submit" className="btn btn-primary btn-block">Cadastrar</button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    tabelaPreco: state.tabelaPreco,
    produto: state.tabelaPreco.produto,
    pontoDeVenda: state.tabelaPreco.pontoDeVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  saveTabelaPreco, 
  setTabelaPreco,
  setLoading 
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TabelaPrecoForm)

import React, { useState, useEffect } from 'react'
import Button from '../template/button'
import Icon from '../template/icon'
import FlexContainer from '../template/flexContainer'
import Skeleton from 'react-loading-skeleton'

import {
  syncDatabase,
  openDatabaseIndexedDB,
  isDataSync
} from '../../config/idbConfig'

import { setTables } from '../databaseSync/databaseSyncActions'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

export default function DatabaseSync() {
  // i18n
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const tables = useSelector(state => state.databaseSync.tables);

  const [isLoading, setLoading] = useState(true);

  // check which tables are synchronized
  useEffect(() => {
    checkTablesSync()
      .then(() => setLoading(false))
      .catch(e => console.log(`Algo deu errado: ${e}`))
      .finally(() => setLoading(false));
  }, []);

  function checkTablesSync() {
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDatabaseIndexedDB('franchini-offline');

        const newTablesPromise = tables.map(async t => {
          const isSync = await isDataSync(db, t.table, t.url);

          return {
            ...t,
            sync: isSync
          };
        });

        const newTables = await Promise.all(newTablesPromise);

        dispatch(setTables(newTables));

        return resolve('Success');
      } catch (e) {
        return reject(e);
      }
    });
  }

  async function synchronizeData() {
    setLoading(true);

    try {
      await syncDatabase(tables);

      console.log('%c [database-sync] Sucesso ao sincronizar os dados com o banco', 'color: green');

      await checkTablesSync();
    } catch (e) {
      console.log(`%c [database-sync] Erro ao sincronizar dados com o banco: ${e}`, 'color: tomato');
    } finally {
      setLoading(false);
    }
  }

  function renderRows(rows) {
    return rows.map((row, i) => (
      <tr key={i} className={`table-${row.sync ? 'success' : 'warning'}`}>
        <td>{row.table}</td>
        <td>{row.sync ? 'Sim' : 'Não'}</td>
      </tr>
    ))
  }

  return (
    <div className="p-3">
      <FlexContainer alignItemsCenter>
        <Button className="d-flex mb-4 w-200" color="default" size="sm"
          handleclick={synchronizeData}
          title={t("settings.databaseSync.buttonSync.title")}>
          <Icon icon="sync" />
        </Button>
      </FlexContainer>

      <p>{t("settings.databaseSync.text")}</p>

      {isLoading ? <Skeleton count={5} /> : (
        <table className="table table-sm table-fluid table-bordered">
          <thead>
            <tr>
              <th>{t("settings.databaseSync.table.tabela")}</th>
              <th>{t("settings.databaseSync.table.sincronizado")}</th>
            </tr>
          </thead>
          {renderRows(tables)}
        </table>
      )}
    </div>
  )
}

import React, { Component } from 'react'
import consts from '../../consts'
import './userForm.css'
import { toastr } from 'react-redux-toastr'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setUser } from './userActions'

class UserForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    // validate password
    if(this.isInvalid(e.target)) {
      return
    }

    // save user info on store
    this.saveUserInfo(e.target) 
    this.redirectUser(e.target.tipo.value)
  }

  redirectUser = type => {
    const { GE, RP, FN } = consts.userType

    switch(type) {
      case GE:
        this.props.history.push('/gestores/new')
        break
      case RP:
        this.props.history.push('/representantes/new')
        break
      case FN:
        this.props.history.push('/fornecedores/new')
        break
      default: 
        this.props.history.push('/signup')
    }
  }

  saveUserInfo = form => {
    const user = {
      nome: form.nome.value,
      email: form.email.value,
      senha: form.password.value,
      tipo: form.tipo.value
    }
    
    this.props.setUser(user)
  }

  isInvalid = form => {
    if(form.password.value.length < 6) {
      toastr.warning('Algo está faltando', 'Sua senha deve ter no mínimo 6 caracteres')
      return true
    }
    
    if(form.password.value !== form.confirmPassword.value) {
      toastr.warning('Algo está faltando', 'As senhas devem ser iguais')
      return true
    }
    
    return false
  }

  render() {
    const { GE, RP, FN } = consts.userType
    return (
      <React.Fragment>
        <h1 className="text-center">Cadastre-se</h1>
        <div className="form-container mt-3">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="nome">Seu nome</label>
              <input type="text" className="form-control" name="nome" id="nome" placeholder="João da Silva" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Seu e-mail</label>
              <input type="email" className="form-control" name="email" id="email" placeholder="exemplo@mail.com" required />
            </div>
    
            <div className="form-group">
              <label htmlFor="password">Sua senha</label>
              <input type="password" className="form-control" name="password" id="password" placeholder="******" required />
              <small className="form-text text-muted">A senha deve conter no mínimo 6 caracteres</small>
            </div>
    
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirme sua senha</label>
              <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" placeholder="******" required />
            </div>
    
            <hr className="divider"/>
    
            <div className="form-group">
              <label htmlFor="tipo">O que você é?</label>
              <select className="form-control" name="tipo" id="tipo" required>
                <option value={GE}>Eu sou um Gestor</option>
                <option value={RP}>Eu sou um Representante</option>
                <option value={FN}>Eu sou um Fornecedor</option>
              </select>
            </div>
    
            <button type="submit" className="btn btn-primary btn-block">Próximo</button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ setUser }, dispatch)

export default connect(null, mapDispatchToProps)(UserForm)

export default {
  list: {
    title: 'Punti vendita',
    notFound: 'Nessun punto vendita da visualizzare ...',
    search: {
      notLinked: 'Nessun rappresentante legato?',
      filterClick: 'Fare clic su filtro',
      placeholder: 'Ricerca per codice muro, ragione sociale, comune, regione, indirizzo, osservazioni',
      buttonSearch: {
        title: 'Ricerca'
      },
      buttonClear: {
        title: 'Pulito'
      }
    },
    table: {
      codigoIdentificacao: 'Codice muro',
      nome: 'Ragione sociale',
      cidade: 'Comune',
      estado: 'Pr',
      regiao: 'Regione',
      estabelecimento: 'Insegna',
      tamanho: 'Tipo',
      frequenciaVisita: {
        text: 'Frequenza di visita',
        small: '(giorni)'
      },
      endereco: 'Indirizzo',
      obs: 'Osservazioni',
      acao: 'Azione',
      buttonEditar: 'Modifica',
      buttonRemover: 'Remove'
    },
    modal: {
      title: "Conferma l'azione",
      text: 'Sei sicuro di voler rimuovere questo punto vendita?',
      buttonCancelar: 'Annullare',
      buttonRemover: 'Remove'
    }
  },

  form: {
    title: 'Registrazione al punto vendita',
    cliente: 'Cliente: ',
    selecione: 'Selezionare ...',
    codigoIdentificacao: 'Codice muro: ',
    nome: {
      label: 'Ragione sociale: ',
      placeholder: 'Nome del punto vendita'
    },
    endereco: {
      label: 'Indirizzo: ',
      placeholder: ''
    },
    cidade: 'Comune: ',
    estado: 'Pr: ',
    regiao: 'Regione: ',
    tipoEstabelecimento: 'Insegna: ',
    tamanho: 'Tipo: ',
    frequenciaVisita: {
      label: 'Frequenza di visita',
      small: '(giorni)'
    },
    obs: 'Osservazioni',
    buttonCadastrar: 'Registrazione',
    validation: {
      cliente: {
        required: {
          title: 'Manca qualcosa',
          message: 'Seleziona cliente'
        }
      }
    }
  },
  edit: {
    title: 'Modifica punto vendita',
    buttonVincularProdutos: 'Link prodotti',
    buttonRepresentante: 'Rappresentante: ',
    buttonNoRepresentante: 'Seleziona rappresentante',
    selecione: 'Selezionare ...',
    cliente: 'Cliente: ',
    codigoIdentificacao: 'Codice muro: ',
    nome: 'Ragione sociale: ',
    endereco: 'Indirizzo: ',
    cidade: 'Comune: ',
    estado: 'Pr: ',
    regiao: 'Regione: ',
    tipoEstabelecimento: 'Insegna: ',
    tamanho: 'Tipo: ',
    frequenciaVisita: {
      label: 'Frequenza di visita',
      small: '(giorni)'
    },
    obs: 'Osservazioni',
    buttonHabilitarCampos: 'Abilita campi',
    buttonCancelar: 'Annullare',
    buttonSalvar: 'Salva',

    produtos: {
      link: {
        title: 'Collega i prodotti a',

        search: {
          placeholder: 'Cerca per descrizione del prodotto o fornitore ...',
        },

        card: {
          produtoVinculado: 'Prodotto collegato',
          fornecedor: 'Fornitore: ',
          unidade: 'Unità: ',
          buttonVincular: 'Link',
          buttonDesvincular: 'Unlink'
        }
      }
    },

    representantes: {
      link: {
        title: 'Collega i rappresentanti a',
        table: {
          nome: 'Nome',
          telefone: 'Numero di telefono',
          email: 'E-mail',
          acao: 'Azione',
          representanteVinculado: 'Rappresentante collegato',
          buttonSelecionar: 'Selezionare',
          buttonDesvincular: 'Unlink'
        }
      }
    },

    contatos: {
      list: {
        title: 'Contatti',
        table: {
          nome: 'Nome',
          cargo: 'Ufficio',
          celular: 'Cellulare',
          email: 'E-mail',
          obs: 'Osservazioni',
          acao: 'Azione',
          buttonEditar: 'Modifica',
          buttonRemover: 'Remove'
        },
        modal: {
          title: "Conferma l'azione",
          text: 'Sei sicuro di voler rimuovere questo contatto?',
          buttonCancelar: 'Annullare',
          buttonRemover: 'Remove'
        }
      },
      form: {
        title: 'Registrazione del contatto',
        nome: {
          label: 'Nome: ',
          placeholder: 'José da Silva'
        },
        cargo: {
          label: 'Ufficio: ',
          placeholder: 'Direttore'
        },
        telefone: 'Numero di telefono: ',
        maskTelefone: '+3\\9 999 999 9999',
        celular: 'Numero di cellulare: ',
        maskCelular: '+3\\9 999 999 9999',
        email: {
          label: 'E-mail',
          placeholder: 'exemplo@mail.com'
        },
        obs: 'Osservazioni: ',
        buttonCadastrar: 'Registrazione'
      },
      edit: {
        title: 'Modifica contatto',
        nome: 'Nome: ',
        cargo: 'Ufficio: ',
        telefone: 'Numero di telefono: ',
        celular: 'Numero di cellulare: ',
        email: 'E-mail: ',
        obs: 'Osservazioni: ',
        buttonHabilitarCampos: 'Abilita campi',
        buttonCancelar: 'Annullare',
        buttonSalvar: 'Salva'
      }
    },

    fornecedores: {
      list: {
        title: 'Fornitori',
        table: {
          nome: 'Nome',
          email: 'E-mail',
          expositor: 'Espositore',
          buttonExpositor: 'Link espositore',
          acao: 'Azione',
        },
      },
      link: {
        title: 'Collegare i fornitori a',
        table: {
          nome: 'Nome',
          telefone: 'Numero di telefono',
          email: 'E-mail',
          acao: 'Azione',
          buttonDesvincular: 'Unlink',
          buttonVincular: 'Link'
        }
      },
      expositores: {
        title: 'Collega un espositore al venditore del punto vendita',
        table: {
          nome: 'Espositore',
          acao: 'Azione',
          buttonVincular: 'Link',
          smallVinculado: 'Collegato'
        },
        notFound: "Nessun espositore trovato",
        error: {
          getExpositores: 'Errore durante il recupero degli espositori',
          getExpositorPontoDeVenda: "Errore durante la ricerca dell'espositore collegato al punto vendita",
          getPontoDeVenda: 'Errore durante il recupero del punto vendita',
          link: "Errore durante il collegamento dell'espositore al punto vendita"
        }
      }
    },

    logs: {
      list: {
        title: 'Logs',
        notFound: 'Nessun registro trovato ...',
        table: {
          mensagem: 'Messaggio',
          data: 'Data',
          representante: {
            add: 'Cambio rappresentante: il rappresentante {{nome}} è stato aggiunto al punto vendita',
            remove: 'Cambio rappresentante: Il rappresentante {{nome}} è stato rimosso dal punto vendita'
          },
          fornecedor: {
            add: 'Cambio di fornitore: il fornitore {{nome}} è stato aggiunto al punto vendita',
            remove: 'Cambio di fornitore: il fornitore {{nome}} è stato rimosso dal punto vendita'
          }
        }
      }
    }
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca di punti vendita'
      }
    },
    search: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante la ricerca nel punto vendita'
      }
    },
    fetchUnattend: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca di punti vendita'
      }
    },
    searchByRepresentante: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca di punti vendita'
      }
    },
    fetchById: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante la ricerca del punto vendita'
      }
    },
    fetchByRepresentanteNextVisit: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la ricerca di punti vendita per rappresentante'
      }
    },
    fetchNextVisit: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto un problema a cercare il tempo per la prossima visita al punto vendita'
      }
    },
    fetchCountNextVisit: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto un problema a cercare il tempo per la prossima visita al punto vendita'
      }
    },
    fetchNextVisitPageable: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto un problema a cercare il tempo per la prossima visita ai punti vendita'
      }
    },
    save: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del punto vendita'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il punto vendita'
      }
    },
    update: {
      address: {
        error: {
          title: 'Spiacenti!',
          message: "Non abbiamo trovato l'indirizzo fornito, potresti essere più specifico?"
        }
      },
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo riscontrato un problema durante il salvataggio del punto vendita'
      },
      success: {
        title: 'Successo',
        message: 'Successo nel salvare il punto vendita'
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del punto vendita'
      },
      succes: {
        title: 'Successo',
        message: 'Punto vendita rimosso con successo'
      }
    },
    bindProduto: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il collegamento del punto vendita al prodotto'
      },
      success: {
        title: 'Successo',
        message: 'Il punto vendita è stato collegato al prodotto selezionato'
      }
    },
    unlinkProduto: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante lo scollegamento del prodotto dal punto vendita'
      }
    },
    bindRepresentante: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il collegamento del punto vendita al rappresentante'
      },
      success: {
        title: 'Successo',
        message: 'Il punto vendita è stato collegato al rappresentante selezionato'
      }
    },
    unlinkRepresentante: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la rimozione del collegamento rappresentativo'
      }
    },
    bindFornecedor: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il collegamento del punto vendita al fornitore'
      },
      success: {
        title: 'Successo',
        message: 'Il punto vendita è stato collegato al fornitore selezionato'
      }
    },
    unlinkFornecedor: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante lo scollegamento del fornitore dal punto vendita'
      }
    },

    searchLocation: {
      error: {
        title: 'Qualcosa è andato storto',
        message: "Si è verificato un problema durante la ricerca di quell'indirizzo"
      },
      notFound: {
        title: 'Indirizzo non trovato',
        message: "Google Maps non ha trovato l'indirizzo che hai inserito"
      },
    }
  }
}
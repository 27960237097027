export default {
  list: {
    title: 'Pedidos de venda',
    notFound: 'Não há pedidos a serem exibidos ...',
    card: {
      title: 'Pedido',
      fornecedor: 'Fornecedor',
      prazoEntrega: 'Prazo de entrega',
      pontoDeVenda: 'Ponto de venda',
     
      dataVisita: 'Data da visita',
      buttonViewImg: 'Imagem do pedido',
      obs: 'Observações',
      itens: {
        title: 'Itens do pedido',
        notFound: 'Nenhum produto encontrado',
        table: {
          codigoArtigo: 'Cód. Artigo',
          descricao: 'Descrição',
          unidade: 'Unidade',
          pc: 'PC',
          quantidadeKit: 'Quantidade x kit',
          total: 'Total'
        },
      },
      pontoDeVendaList: {
        title: "Ponto de venda",
        codigo_identificacao: 'Codigo identificação',
        nome: 'Nome',
        endereco: 'Endereço',
        cidade: 'Cidade',
        regiao: 'Região',
        estado: 'Estado',
        frequencia_visita: 'Frequencia de visita',
        tipo: 'Tipo',
        obs: 'Observação',
      },
      RepresentanteList: {
        title: "Representante",
        email: 'Email',
        nome: 'Nome',
        status: 'Status',
        tipo: 'Tipo',
        telefone: 'Telefone',
      },
    },
    modalDelete: {
      title: 'Confirmar ação',
      text: 'Você tem certeza de que quer remover este pedido de venda e seus produtos?',
      buttonCancelar: 'Cancelar',
      buttonRemover: 'Remover'
    },
    modalViewImg: {
      title: 'Imagem do pedido'
    },

    search: {
      form: {
        input: {
          placeholder: 'Pesquise por código do pedido'
        }
      }
    }
  },

  shoppingCart: {
    title: 'Pedido de venda',
    wizard: {
      selectProduto: {
        title: 'Ponto de venda: ',
        notFound: 'Nenhum produto encontrado',
        buttonCarrinho: 'Carrinho',
        produto: {
          card: {
            buttonAdicionar: 'Adicionar',
            adicionado: 'Adicionado',
            fornecedor: 'Fornecedor: ',
            unidade: {
              label: 'Unidade: ',
              pc: 'PC'
            }
          }
        },
        buttonCancelar: 'Cancelar',
        buttonGoToCart: 'Ir para o carrinho'
      },
      cart: {
        notFound: 'Nenhum produto no carrinho',
        table: {
          produto: 'Produto',
          quantidade: 'Quantidade',
          total: 'Total',
          acao: 'Ação',
        },
        buttonCancelar: 'Cancelar',
        buttonAdicionarMais: 'Adicionar mais',
        buttonRevisarPedido: 'Revisar pedido'
      },
      revisar: {
        buttonVoltar: 'Voltar',
        buttonFinalizar: 'Finalizar pedido de venda'
      },
      validation: {
        alreadyInCart: {
          title: 'Oops!',
          message: 'Este produto já está no carrinho'
        },
        min: {
          title: 'Oops!',
          message: 'A quantidade mínima é zero'
        },
        empty: {
          title: 'Está faltando algo',
          message: 'Você precisa adicionar pelo menos um produto'
        }
      },
      toastr: {
        isPedidoVenda: {
          title: 'Pedido de venda já iniciado',
          message: 'Você está continuando um pedido de venda já iniciado'
        }
      }
    }
  },

  expositor: {
    selectImage: 'Deseja adicionar uma imagem ao pedido?',
    selectedImage: "Imagem selecionada",
    viewImage: 'Visualizar imagem',
    buttonVoltar: 'Voltar',
    buttonFinalizar: 'Finalizar',
    obs: 'Observações',
    search: 'Pesquisar por ean',
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar pedidos de venda'
      }
    },
    fetchByVisita: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar o pedido de venda por visita'
      }
    },
    search: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os pedidos de venda'
      }
    },
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar pedido de venda'
      },
      success: {
        title: 'Sucesso',
        message: 'Pedido de venda salvo com sucesso'
      }
    },
    saveProdutos: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar os produtos do ponto de venda'
      }
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar pedido de venda'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover pedido de venda'
      },
      success: {
        title: 'Sucesso',
        message: 'Pedido de venda removido com sucesso'
      }
    },
    removeByVisita: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o pedido de venda'
      }
    },
    fetchExpositor: {
      error: {
        title: 'Sem expositor',
        message: 'Nenhum expositor cadastrado para esse fornecedor'
      }
    }
  },

  loading: {
    redirect: 'Redirecionando...',
    visita: 'Salvando Visita...',
    pedido: 'Salvando Pedido...',
    email: 'Enviando Email...'
  }
}

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button'

import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAllFornecedoresByPontoDeVenda, fetchFornecedoresByPontoDeVenda } from './fornecedor/fornecedorActions'
import { bindPontoDeVendaToFornecedor, unbindFornecedorFromPontoDeVenda } from './pontoDeVendaActions'

export class BindPontoDeVendaToRepresentante extends Component {
  componentDidMount() {
    if(!this.props.pontoDeVenda) {
      this.props.history.push('/home')
      return
    }
    this.props.fetchAllFornecedoresByPontoDeVenda(this.props.pontoDeVenda.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fornecedores != this.props.fornecedores) {
      this.setState({
        ...this.state,
        fornecedores: this.props.fornecedores
      })  
    }

  }

  constructor(props) {
    super(props)
    this.state = {
      fornecedores: []
    }
  }

  bindFornecedorToPontoDeVenda = fornecedor => {
    const { pontoDeVenda } = this.props
    this.props.bindPontoDeVendaToFornecedor(pontoDeVenda, fornecedor)
  }

  unbindFornecedorFromPontoDeVenda = id => {
    const { pontoDeVenda } = this.props;
    this.props.unbindFornecedorFromPontoDeVenda(id, pontoDeVenda);
  }

  renderFornecedores = () => {
    const fornecedores = this.state.fornecedores || []

    return fornecedores.map(fornecedor => {
      const isBound = fornecedor.fornecedor_id;
      
      const { t } = this.props;
 
      return (
        <tr key={fornecedor.id} className={isBound ? 'table-active' : null}>
          <td data-label={t("pontosDeVenda.edit.fornecedores.link.table.nome")} className="align-middle">{fornecedor.nome}</td>
          <td data-label={t("pontosDeVenda.edit.fornecedores.link.table.telefone")} className="align-middle">{fornecedor.telefone}</td>
          <td data-label={t("pontosDeVenda.edit.fornecedores.link.table.email")} className="align-middle">
            {
              fornecedor.email.length > 36 && visualViewport.width <= 600 
                ? fornecedor.email.substr(0, 33) + '...' 
                : fornecedor.email
            }
          </td>
          <td data-label="Ação">
            {
              !isBound
              ? <button className="btn btn-sm btn-info mb-2" onClick={() => this.bindFornecedorToPontoDeVenda(fornecedor)} >
                  <Trans i18nKey="pontosDeVenda.edit.fornecedores.link.table.buttonVincular">trans</Trans>
                </button>
              : <Button size="sm" color="danger"  handleclick={() => this.unbindFornecedorFromPontoDeVenda(fornecedor.fornecedor_id)} >
                 <Trans i18nKey="pontosDeVenda.edit.fornecedores.link.table.buttonDesvincular">trans</Trans>
                </Button>
            }
          </td>
        </tr>
      )
    })
  }

  render() {
    const { pontoDeVenda } = this.props

    if(!pontoDeVenda) {
      return <Redirect to="/home" />
    }

    const { t } = this.props;

    const pageTitle = `${t("pontosDeVenda.edit.fornecedores.link.title")} <${pontoDeVenda.nome}>`

    return (
      <React.Fragment>
        <Breadcrumb title={pageTitle} linkTo="/pontosDeVenda/edit" />
        <div className="row">
          <div className="col-12">
            <div id="card">
              <table className="table table-fluid text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <Trans i18nKey="pontosDeVenda.edit.fornecedores.link.table.nome">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="pontosDeVenda.edit.fornecedores.link.table.telefone">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="pontosDeVenda.edit.fornecedores.link.table.email">trans</Trans>
                    </th>
                    <th scope="col">
                      <Trans i18nKey="pontosDeVenda.edit.fornecedores.link.table.acao">trans</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.renderFornecedores()
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    pontoDeVenda: state.pontoDeVenda.pontoDeVenda,
    fornecedores: state.pontoDeVendaFornecedor.fornecedores
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  bindPontoDeVendaToFornecedor,
  fetchFornecedoresByPontoDeVenda,
  unbindFornecedorFromPontoDeVenda,
  fetchAllFornecedoresByPontoDeVenda,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BindPontoDeVendaToRepresentante))
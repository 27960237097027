const INITIAL_STATE = {
  contato: null,
  contatos: []
}

const contatoReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_CONTATOS':
      return {
        ...state,
        contatos: action.payload
      }
    case 'SET_CONTATO':
      return {
        ...state,
        contato: action.payload
      }
    case 'CLEAR_CONTATOS':
      return {
        ...state,
        contato: null,
        contatos: []
      }
    default:
      return state
  }
}

export default contatoReducer

import React, { Component } from 'react'

import { withTranslation, Trans } from 'react-i18next'

import InputMask from 'react-input-mask'

import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { saveGestor } from './gestorActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'

class GestorForm extends Component {
  handleSubmit = async e => {
    e.preventDefault()

    const user = this.props.user
    const gestor = {
      telefone: e.target.telefone.value,
      recebe_email: e.target.recebe_email.value
    }

    this.props.setLoading()
    await this.props.saveGestor(user, gestor)
    this.props.getCountUsersNotValidated();

    // redirect to /gestores
    this.props.history.push('/gestores')
  }
  
  render() {
    if(!this.props.user) {
      return <Redirect to="/signup" />
    }

    // i18n
    const { t } = this.props;
    
    return (
      <React.Fragment>
        <h1 className="text-center">{t("gestores.form.h1")}</h1>
        <div className="box-login mt-4">
          <form onSubmit={this.handleSubmit} className="mt-3">    
            <div className="form-group">
              <label htmlFor="telefone">
                <Trans i18nKey="gestores.form.telefone">trans</Trans>
              </label>
              <InputMask mask={t("gestores.form.maskTelefone")} maskChar=" " type="text" className="form-control" name="telefone" id="telefone" required />
              <div className="form-group mt-3">
              <label htmlFor="recebe_email"><Trans i18nKey="gestores.form.recebe_email.label">trans</Trans></label>
              <select className="form-control" name="recebe_email" id="recebe_email" required>
                <option value={1}>{t("gestores.form.recebe_email.sim")}</option>
                <option value={0}>{t("gestores.form.recebe_email.nao")}</option>
              </select>
            </div>
            </div>
      
            <button type="submit" className="btn btn-primary btn-block">
              <Trans i18nKey="gestores.form.buttonCadastrar">trans</Trans>
            </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ 
  saveGestor, 
  setLoading,
  getCountUsersNotValidated
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GestorForm))

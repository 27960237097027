import navbar from './it/navbar'
import clientes from './it/clientes'
import gestores from './it/gestores'
import representantes from './it/representantes'
import fornecedores from './it/fornecedores'
import produtos from './it/produtos'
import pontosDeVenda from './it/pontosDeVenda'
import mapaDeCalor from './it/mapaDeCalor'
import validacao from './it/validacao'
import pedidosVenda from './it/pedidosVenda'
import visitas from './it/visitas'
import settings from './it/settings'
import pagination from './it/pagination'
import login from './it/login'
import expositores from './it/expositores'
import construcao from './it/construcao'
import common from './it/common'

export default {
  navbar,
  clientes,
  gestores,
  representantes,
  fornecedores,
  produtos,
  pontosDeVenda,
  mapaDeCalor,
  validacao,
  pedidosVenda,
  visitas,
  settings,
  pagination,
  login,
  expositores,
  common,
  construcao,
}

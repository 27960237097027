export default {
  title: 'Configurações',
  home: {
    text: 'Selecione a configuração desejada no menu ao lado'
  },
  requestsQueue: {
    title: 'Fila de requisições',
    buttonRefresh: {
      title: 'Atualizar requisições'
    },
    buttonForceRequests: {
      title: 'Forçar requisições'
    },
    table: {
      date: 'Data/hora',
      method: 'Método',
      url: 'URL',
      body: 'Corpo',
      headers: 'Cabeçalhos'
    },
    notFound: 'Nenhuma requisição aguardando na fila'
  },
  offlineRequests: {
    title: 'Requisições offline',
    buttonRefresh: {
      title: 'Atualizar requisições'
    },
    table: {
      date: 'Data/hora',
      method: 'Método',
      url: 'URL',
      body: 'Corpo'
    },
    notFound: 'Nenhuma requisição encontrada',
  },
  databaseSync: {
    title: 'Sincronizar banco de dados',
    text: 'Sincronizar dados offline com o banco de dados ...',
    buttonSync: {
      title: 'Fazer download das tabelas'
    },
    table: {
      tabela: 'Tabela',
      sincronizado: 'Sincronizado?'
    }
  },
  geoposition: {
    title: 'Sua localização atual',
    text: 'Você está aqui: '
  },
  language: {
    title: 'Selecionar idioma',
    text: 'Selecione seu idioma'
  },
  timezone: {
    title: 'Selecionar fuso horário',
    text: 'Selecione o fuso horário da sua região'
  },
  mockPedidoVenda: {
    title: 'Mock do layout de pedido de venda'
  },
}
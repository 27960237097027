export default {
  list: {
    title: 'Visite',
    notFound: 'Nessuna visita trovata ...',
    search: {
      inicio: 'Prima',
      fim: 'Fine',
      status: {
        label: 'Stato',
        todas: 'Tutto',
        iniciada: 'Avviato',
        finalizada: 'Finalizzato',
      },
      placeholder: 'Cerca per punto vendita ...',
      buttonPesquisar: {
        title: 'Ricerca',
      },
      buttonLimpar: {
        title: 'Pulito',
      },
      validation: {
        ref: {
          required: {
            title: 'Manca qualcosa',
            message:
              'Immettere il nome del punto vendita o selezionare una data',
          },
        },
      },
    },
    table: {
      codigoVisitacao: 'Codice di visita',
      pontoDeVenda: 'Punto vendita',
      cidade: 'Comune',
      endereco: 'Indirizzo',
      data: 'Dati',
      status: {
        label: 'Stato',
        iniciada: 'Avviato',
        finalizada: 'Finalizzato',
        naoIniciada: 'No iniziato',
      },
      acao: 'Azione',
      buttonVisualizar: 'Visualize',
      toastr: {
        offline: {
          title: 'Sei offline',
          message:
            'Sembra che tu non abbia una connessione Internet stabile, la tua visita verrà elaborata non appena verrà ristabilita la connessione',
        },
      },
    },
  },

  page: {
    title: 'Informazioni sulla visita',
    loading: 'Caricamento in corso ...',
    title2: 'Visita',
    data: 'Visita effettuata alla data: <1>{{data}}</1>',
    estoque_alto: {
      label: 'Giacenza Alta?',
      sim: 'Sì',
      nao: 'No',
    },
    exposicao_completa: {
      label: 'Expo Pieno - no riordinò?',
      sim: 'Sí',
      nao: 'No',
    },
    gerente_rejeitou_pedido: {
      label: 'Responsabile non ha voluto fare ordine?',
      sim: 'Sí',
      nao: 'No',
    },
    expositor_organizado: {
      label: 'Expo sistemato messo fuori la merce?',
      sim: 'Sí',
      nao: 'No',
    },
    obs: {
      label: 'Osservazioni',
      nenhuma: 'Nessuno'
    },
    pontoDeVenda: 'Punto vendita',
    pontoDeVendaCidade: 'Comune',
    pontoDeVendaEndereco: 'Indirizzo',
    representante: 'Rappresentante',
    fornecedor: 'Fornitore',
    pedidosVenda: {
      notFound: 'Nessun ordine cliente ...',
    },
  },

  wizard: {
    new: {
      title: 'Nuova visita',
      notFound: 'Nessun punto vendita trovato ...',
      selectPontoDeVenda: {
        title: 'Seleziona il punto vendita per iniziare a visitare',
        table: {
          nome: 'Nome',
          regiao: 'Regione',
          tipo: 'Tipo',
          frequenciaVisita: 'Frequenza di visita (giorni)',
          endereco: 'Indirizzo',
          obs: 'Osservazioni',
          acao: 'Azione',
          buttonSelecionar: 'Selezionare',
        },
      },
      selectFornecedor: {
        title: 'Fornitori di:',
        notFound: 'Nessun fornitore trovato ...',
        table: {
          nome: 'Nome',
          visita: 'Visita',
          data: 'Data',
          acao: 'Azione',
          removerFornecedor: 'Rimuovi fornitore',
          buttonVisitar: 'Visita',
          modalDelete: {
            title: 'Modulo di esclusione del fornitore',
            title2: "Motivo dell'esclusione: ",
            small:
              'Questa esclusione sarà approvata dal management team di Franchini, potrebbe esserti chiesto di giustificare questi argomenti.',
            buttonCancelar: 'Annullare',
            buttonConfirmar: 'Conferma',
          },
        },
        buttonCancelar: 'Annullare',
        buttonEncerrarVisitacao: 'Termina la visita',
      },
      form: {
        selecionados: 'Punto vendita e fornitore selezionati',
        pontoDeVenda: 'Punto vendita: ',
        fornecedor: 'Fornitore: ',
        title: 'Foto espositore / giacenza',
        estoque_alto: 'Giacenza Alta?',
        exposicao_completa: 'Expo Pieno - no riordinò?',
        gerente_rejeitou_pedido: 'Responsabile non ha voluto fare ordine?',
        expositor_organizado: 'Expo sistemato messo fuori la merce?',
        obs: 'Commenti',
        lancarPedido: `Ho finito la visita, devo ancora fare un${"'"}ordine?`,
        buttonNao: 'No',
        buttonLancar: 'Sì',
      },
      toastr: {
        isVisitando: {
          title: 'La visita è già iniziata',
          message: 'Stai continuando una visita avviata in precedenza',
        },
      },
    },

    edit: {
      title: 'Modifica visita',
    },
  },

  visitacaoPage: {
    title: 'Torna alle visite',
    loading: 'Caricamento in corso ...',
    card: {
      codigoVisitacao: 'Codice di visita: ',
      text:
        'Visita al punto vendita <1>{{pontoDeVendaNome}}</1> alla data <1>{{data}}</1>',
      status: {
        iniciada: 'Avviato',
        finalizada: 'Finalizzato',
        naoIniciada: 'No iniziatoa',
      },
      buttonEncerrarVisitacao: 'Termina la visita',
      modalRemover: {
        title: "Conferma l'azione",
        text: 'Sei sicuro di voler rimuovere questa visita?',
        buttonCancelar: 'Annullare',
        buttonRemover: 'Remove',
      },
    },
    list: {
      title: 'Visite',
      notFound: 'Nessuna visita da visualizzare',
      table: {
        pontoDeVenda: 'Punto vendita',
        fornecedor: 'Fornitore',
        representante: 'Rappresentante',
        data: 'Data',
        estoque_alto: {
          label: 'Giacenza Alta?',
          sim: 'Sì',
          nao: 'No',
        },
        exposicao_completa: {
          label: 'Expo Pieno - no riordinò?',
          sim: 'Sí',
          nao: 'No',
        },
        gerente_rejeitou_pedido: {
          label: 'Responsabile non ha voluto fare ordine?',
          sim: 'Sí',
          nao: 'No',
        },
        expositor_organizado: {
          label: 'Expo sistemato messo fuori la merce?',
          sim: 'Sí',
          nao: 'No',
        },
        acao: 'Azione',
        buttonVisualizar: 'Visualize',
        buttonEditar: 'Modifica',
        buttonRemover: 'Remove',
        modalRemover: {
          title: "Conferma l'azione",
          text: 'Sei sicuro di voler rimuovere questa visita?',
          buttonCancelar: 'Annullare',
          buttonRemover: 'Remove',
        },
      },
    },
  },

  toastr: {
    fetchById: {
      error: {
        title: 'Spiacenti!',
        message:
          'Abbiamo riscontrato un problema durante la ricerca della visita',
      },
    },
    fetchByVisitacao: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il recupero delle visite',
      },
    },
    fetchByPontoDeVenda: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il recupero delle visite',
      },
    },
    save: {
      error: {
        title: 'Spiacenti!',
        message:
          'Si è verificato un problema durante il salvataggio della tua visita. Controlla la tua connessione e riprova',
      },
      success: {
        title: 'Successo',
        message: 'Visita di salvataggio riuscita',
      },
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message:
          'Si è verificato un problema durante il salvataggio della tua visita',
      },
      success: {
        title: 'Successo',
        message: 'Visita di salvataggio riuscita',
      },
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message:
          'Abbiamo riscontrato un problema durante la rimozione della visita',
      },
      success: {
        title: 'Successo',
        message: 'Visita rimossa correttamente',
      },
      block: {
        title: 'Oops!',
        message: 'Impossibile rimuovere la visita perché ci sono ordini di vendita collegati a una delle visite',
      },
    },
    close: {
      error: {
        title: 'Spiacenti!',
        message: 'Abbiamo avuto problemi a chiudere la visita',
      },
      success: {
        title: 'Successo',
        message: 'Visita finalizzata',
      },
    },
    saveImage: {
      error: {
        title: "Errore durante il salvataggio dell'immagine",
        message:
          'Si è verificato un problema durante il salvataggio della tua immagine. Salviamo la tua immagine localmente per riprovare più tardi, per una maggiore sicurezza salvala nella galleria',
      },
    },
  },
};

import React, { Component } from 'react'
import uuid from 'uuid'

import { withTranslation, Trans } from 'react-i18next'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import Row from '../template/row'
import Grid from '../template/grid'
import firebase from '../../config/fbConfig'
import defaultImg from '../common/imgs/default-img.png'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateProduto, saveImage } from './produtoActions'

class ProdutoEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgUrl: null,
      isReadonly: true
    }
  }

  componentDidMount() {
    if (!this.props.produto) return

    this.loadInfo()

    if (!this.props.produto.imagem || this.props.produto.imagem === 'undefined') {
      document.getElementById('imagem').src = defaultImg
      return
    }

    const imgRef = firebase.storage().ref(this.props.produto.imagem)
    imgRef.getDownloadURL()
      .then(url => {
        document.getElementById('imagem').src = url

        this.setState({
          ...this.state,
          imgUrl: url
        })
      })
      .catch(err => {
        throw err
      })
  }

  changeReadonly = () => {
    if (this.state.imgUrl) {
      document.getElementById('imagem').src = this.state.imgUrl
    }

    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  loadInfo = () => {
    const { 
      ean, 
      descricao_ean, 
      descricao, 
      cod_artigo, 
      quantidade_kit 
    } = this.props.produto

    document.getElementById('ean').value = ean
    document.getElementById('descricao_ean').value = descricao_ean
    document.getElementById('descricao').value = descricao
    document.getElementById('artigo').value = cod_artigo
    document.getElementById('quantidade_kit').value = quantidade_kit
  }

  handleChange = e => {
    const image = e.target.files[0]

    document.getElementById('imagem').src = window.URL.createObjectURL(image)
  }

  handleSubmit = e => {
    e.preventDefault()

    const imagem = e.target.imagem.files[0]
    let blobName = null

    // TODO: validate image (check formats, etc.)
    if (imagem) {
      const fileExtension = this.getFileExtension(imagem.name)
      blobName = `produtos/${e.target.descricao.value}/${uuid.v4() + fileExtension}`
      this.props.saveImage(imagem, blobName)
    }

    if (!blobName) {
      blobName = this.props.produto.imagem
    }

    const produtoImg = blobName ? {
      imagem: blobName
    } : {}

    const produto = {
      ...produtoImg,
      id: this.props.produto.id,
      cod_artigo: e.target.artigo.value,
      ean: e.target.ean.value,
      descricao_ean: e.target.descricao_ean.value,
      descricao: e.target.descricao.value,
      unidade: e.target.unidade.value,
      quantidade_kit: e.target.quantidade_kit.value
    }

    // update produto
    this.props.updateProduto(produto)

    // redirect
    this.props.history.push('/produtos')
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  render() {
    if (!this.props.produto) return <Redirect to="/produtos" />

    const { t } = this.props;

    return (
      <>
        <Breadcrumb title={t("produtos.edit.title")} linkTo="/produtos" />

        <Row>
          <Grid cols="12 5">
            <div>
              <img className="img-thumbnail" id="imagem" src="" alt="" />
            </div>
          </Grid>

          <Grid cols="12 7">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="artigo">
                  <Trans i18nKey="produtos.edit.codigoArtigo">trans</Trans>
                </label>
                <input type="text" className="form-control" name="artigo" id="artigo" placeholder="20360" required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="ean">
                  <Trans i18nKey="produtos.edit.codigoEan">trans</Trans>
                </label>
                <input type="text" className="form-control" name="ean" id="ean"
                  readOnly={this.state.isReadonly} required />
              </div>

              <div className="form-group">
                <label htmlFor="descricao_ean">
                  <Trans i18nKey="produtos.edit.descricaoEan">trans</Trans>
                </label>
                <input type="text" className="form-control" name="descricao_ean" id="descricao_ean"
                  readOnly={this.state.isReadonly} required />
              </div>

              <div className="form-group">
                <label htmlFor="descricao">
                  <Trans i18nKey="produtos.edit.descricao">trans</Trans>
                </label>
                <input type="text" className="form-control" name="descricao" id="descricao" 
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="quantidade_kit">Quantidade do kit</label>
                <input type="text" className="form-control" name="quantidade_kit" id="quantidade_kit" 
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="unidade">
                  <Trans i18nKey="produtos.edit.unidade.label">trans</Trans>
                </label>
                <select className="form-control" name="unidade" id="unidade" required readOnly={this.state.isReadonly}>
                  <option value="PC">
                    {t("produtos.edit.unidade.pc")}
                  </option>
                </select>
              </div>

              {
                this.state.isReadonly ? null : (
                  <div className="form-group">
                    <label htmlFor="imagem">
                      <Trans i18nKey="produtos.edit.imagem">trans</Trans>
                    </label><br />
                    <input type="file" name="imagem" id="imagem" accept="image/*" />
                  </div>
                )
              }

              {
                this.state.isReadonly ? (
                  <button type="button" className="btn btn-primary btn-block mb-3"
                    onClick={this.changeReadonly}>
                    <Trans i18nKey="produtos.edit.buttonHabilitarCampos">trans</Trans>
                  </button>
                ) : (
                    <div className="row">
                      <div className="col-6">
                        <button type="button" className="btn btn-secondary btn-block mb-3"
                          onClick={this.changeReadonly}>
                          <Trans i18nKey="produtos.edit.buttonCancelar">trans</Trans>
                        </button>
                      </div>
                      <div className="col-6">
                        <button type="submit" className="btn btn-primary btn-block mb-3">
                          <Trans i18nKey="produtos.edit.buttonSalvar">trans</Trans>
                        </button>
                      </div>
                    </div>
                  )
              }
            </form>
          </Grid>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    produto: state.produto.produto,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  updateProduto,
  saveImage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProdutoEdit))

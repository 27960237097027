import axios from 'axios';
import consts from '../../consts';
import { toastr } from 'react-redux-toastr';
import {
  openDatabaseIndexedDB,
  storeRequestOnIndexedDB,
  clearAll,
} from '../../config/idbConfig';
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions';
import uuid from 'uuid';
import moment from 'moment-timezone';
import i18n from '../../_i18n/i18n';

const BASE_URL = consts.API_URL;

const nop = () => {
  return (dispatch) => {
    return dispatch({
      type: 'NOP',
    });
  };
};

export const setVisitacao = (visitacao) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_VISITACAO',
      payload: visitacao,
    });
  };
};

const setVisitacoes = (visitacoes) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_VISITACOES',
      payload: visitacoes,
    });
  };
};

export const clearVisitacoes = () => {
  return (dispatch) => {
    return dispatch({
      type: 'CLEAR_VISITACOES',
    });
  };
};

export const setEdit = (edit) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITACAO_SET_EDIT',
      payload: edit,
    });
  };
};

export const setReturnToSearch = (returnToSearch) => {
  return (dispatch) => {
    return dispatch({
      type: 'VISITACAO_SET_RETURN_TO_SEARCH',
      payload: returnToSearch,
    });
  };
};

export const setRef = (ref) => {
  return {
    type: 'VISITACAO_SET_REF',
    payload: ref,
  };
};

export const setFrom = (from) => {
  return {
    type: 'VISITACAO_SET_FROM',
    payload: from,
  };
};

export const setTo = (to) => {
  return {
    type: 'VISITACAO_SET_TO',
    payload: to,
  };
};

export const setStatus = (status) => {
  return {
    type: 'VISITACAO_SET_STATUS',
    payload: status,
  };
};

export const setTotalItems = (totalItems) => {
  return {
    type: 'VISITACAO_SET_TOTAL_ITEMS',
    payload: totalItems,
  };
};

export const setVisitas = (visitas) => {
  return {
    type: 'VISITACAO_SET_VISITAS',
    payload: visitas,
  };
};

export const clearVisitas = () => {
  return {
    type: 'VISITACAO_CLEAR_VISITAS',
  };
};

export const fetchVisitacoes = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/visitacoes`)
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
          return dispatch(nop());
        }

        return dispatch(setVisitacoes(res.data.data));
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          return dispatch(unsetLoading());
        }

        toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
        throw err;
      });
  };
};

export const fetchVisitacaoById = (id) => {
  return (dispatch) => {
    if (!id) {
      toastr.error('Oops!', 'Tivemos um problema ao buscar a visitação');
      return dispatch(nop());
    }

    axios
      .get(`${BASE_URL}/visitacoes/${id}`)
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar a visitação');
          return dispatch(nop());
        }

        return dispatch(setVisitacao(res.data.data[0]));
      })
      .catch((err) => {
        toastr.error('Oops!', 'Tivemos um problema ao buscar a visitação');
        throw err;
      });
  };
};

export const fetchVisitacoesByRepresentante = (
  userId,
  ref,
  page,
  itemsPerPage,
  from = '',
  to = '',
  status = null
) => {
  return (dispatch) => {
    if (!userId) {
      toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
      return dispatch(nop());
    }

    return axios
      .get(`${BASE_URL}/visitacoes/representante/${userId}`, {
        params: { ref, from, to, status, page, itemsPerPage },
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
          return dispatch(nop());
        }

        return dispatch([
          setVisitacoes(res.data.data),
          setTotalItems(res.data['_metadata'].totalItems),
        ]);
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          return dispatch(unsetLoading());
        }

        toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
        throw err;
      });
  };
};

export const fetchVisitacoesByCliente = (
  userId,
  ref,
  page,
  itemsPerPage,
  from = '',
  to = '',
  status = null
) => {
  return (dispatch) => {
    if (!userId) {
      toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
      return dispatch(nop());
    }

    return axios
      .get(`${BASE_URL}/visitacoes/cliente/${userId}`, {
        params: { ref, from, to, status, page, itemsPerPage },
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
          return dispatch(nop());
        }

        return dispatch([
          setVisitacoes(res.data.data),
          setTotalItems(res.data['_metadata'].totalItems),
        ]);
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          return dispatch(unsetLoading());
        }

        toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
        throw err;
      });
  };
};

export const fetchVisitacoesByFornecedor = (
  userId,
  ref,
  page,
  itemsPerPage,
  from,
  to,
  status
) => {
  return (dispatch) => {
    if (!userId) {
      toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
      return dispatch(nop());
    }

    axios
      .get(`${BASE_URL}/visitacoes/fornecedor/${userId}`, {
        params: { ref, page, itemsPerPage, from, to, status },
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
          return dispatch(nop());
        }

        return dispatch([
          setVisitacoes(res.data.data),
          setTotalItems(res.data['_metadata'].totalItems),
        ]);
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          return dispatch(unsetLoading());
        }

        toastr.error('Oops!', 'Tivemos um problema ao buscar visitações');
        throw err;
      });
  };
};

export const searchVisitacoesByPontoDeVenda = (
  ref,
  page,
  itemsPerPage,
  from,
  to,
  status
) => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/visitacoes/search`, {
        params: { ref, page, itemsPerPage, from, to, status },
      })
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao buscar as visitações');
          return dispatch(nop());
        }

        return dispatch([
          setVisitacoes(res.data.data),
          setTotalItems(res.data['_metadata'].totalItems),
        ]);
      })
      .catch((err) => {
        toastr.error('Oops!', 'Tivemos um problema ao buscar as visitações');
        throw err;
      });
  };
};

export const setVisitacaoOnline = (id) => {
  return (dispatch) => {
    if (!id) {
      return dispatch(nop());
    }

    axios
      .post(`${BASE_URL}/set_online/visitacoes/${id}`)
      .then((res) => {
        return dispatch(nop());
      })
      .catch((err) => {
        return dispatch(nop());
      });
  };
};

// will create a new visitacao and also set it on state
export const saveVisitacao = (pontoDeVenda) => {
  return (dispatch, getState) => {
    if (!pontoDeVenda) {
      toastr.error('Oops!', 'Tivemos um problema ao criar a visitação');
      return dispatch(nop());
    }

    const id = uuid.v4().replace(/-/g, '');

    const timezone = localStorage.getItem('timezone') || 'America/Sao_Paulo';

    const data = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss');

    const visitacao = {
      id,
      status: 'IN',
      data,
      pontoDeVenda,
      offline: true,
    };

    const req = {
      method: 'post',
      url: `${BASE_URL}/visitacoes`,
      data: {
        id,
        pontoDeVenda,
        data,
      },
    };

    axios(req)
      .then(async (res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao criar a visitação');
          return dispatch(nop());
        }

        // save visitacao on indexedDB
        const db = await openDatabaseIndexedDB('visitacao');
        await db.add('visitacao', {
          id,
          timestamp: new Date().getTime(),
          data: visitacao,
        });

        return dispatch([
          fetchVisitacaoById(id),

          // visitacoes are first saved as 'offline'
          setVisitacaoOnline(id),
          setVisitacoes([...getState().visitacao.visitacoes, visitacao]),
        ]);
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          // saving request on local storage
          storeRequestOnIndexedDB(req);

          return dispatch([
            setVisitacao(visitacao),
            setVisitacoes([...getState().visitacao.visitacoes, visitacao]),
          ]);
        }

        toastr.error('Oops!', 'Tivemos um problema ao criar a visitação');
        throw err;
      });
  };
};

async function clearVisitacaoIndexedDB(visitacao) {
  try {
    const db = await openDatabaseIndexedDB('visitacao');
    const idbVisitacoes = await db.getAll('visitacao');

    // only clear if visitacao is same as the one being removed
    if (idbVisitacoes[0].id !== visitacao.id) {
      return;
    }

    await clearAll(db, 'visitacao');

    localStorage.removeItem('visitando');
  } catch (e) {
    console.log(`%c [IDB] Erro ao limpar a visitação: ${e}`, 'color: tomato');
  }
}

export const removeVisitacao = (visitacao, callback) => {
  return (dispatch) => {
    if (!visitacao) {
      toastr.error('Oops!', 'Tivemos um problema ao remover a visitação');
      return dispatch(nop());
    }

    const req = {
      method: 'delete',
      url: `${BASE_URL}/visitacoes/${visitacao.id}`,
    };

    return axios(req)
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao remover a visitação');
        }

        // search and remove this visitacao on indexedDB
        clearVisitacaoIndexedDB(visitacao);

        return callback ? dispatch(callback) : dispatch(nop());
      })
      .catch((err) => {
        // network error -> store request
        if (err.message === 'Network Error') {
          storeRequestOnIndexedDB(req);
        }

        // in case a pedidoVenda is blocking this visita from being deleted
        if (err.response.data.error && err.response.data.error.errno === 1451) {
          toastr.error(
            i18n.t('visitas.toastr.remove.block.title'),
            i18n.t('visitas.toastr.remove.block.message')
          );
          throw  i18n.t('visitas.toastr.remove.block.message');
        }

        toastr.error('Oops!', 'Tivemos um problema ao remover a visitação');
        throw err;
      });
  };
};

export const closeVisitacao = (visitacao) => {
  return (dispatch) => {
    if (!visitacao) {
      toastr.error('Oops!', 'Tivemos um problema ao fechar a visitação');
      return dispatch(nop());
    }

    const req = {
      method: 'post',
      url: `${BASE_URL}/visitacoes/${visitacao.id}/close`,
      data: {},
    };

    axios(req)
      .then((res) => {
        if (res.data.error) {
          toastr.error('Oops!', 'Tivemos um problema ao fechar a visitação');
        }

        toastr.success(
          i18n.t('visitas.toastr.close.success.title'),
          i18n.t('visitas.toastr.close.success.message')
        );
        return dispatch(fetchVisitacaoById(visitacao.id));
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          storeRequestOnIndexedDB(req);
          return;
        }

        toastr.error(
          i18n.t('visitas.toastr.close.error.title'),
          i18n.t('visitas.toastr.close.error.message')
        );
        throw err;
      });
  };
};

export const fetchVisitasByVisitacao = (visitacao) => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/visitacoes/${visitacao.id}/visitas`)
      .then((res) => {
        if (res.data.error) {
          toastr.error(
            i18n.t('visitas.toastr.fetchByVisitacao.error.title'),
            i18n.t('visitas.toastr.fetchByVisitacao.error.message')
          );
          return dispatch(nop());
        }
        return dispatch(setVisitas(res.data.data));
      })
      .catch((err) => {
        toastr.error(
          i18n.t('visitas.toastr.fetchByVisitacao.error.title'),
          i18n.t('visitas.toastr.fetchByVisitacao.error.message')
        );
        throw err;
      });
  };
};

import React, { Component } from 'react'
import Breadcrumb from '../template/breadcrumb'
import Row from '../template/row'
import Grid from '../template/grid'
import Button from '../template/button'
import ImageInput from '../template/imageInput'
import defaultImg from '../common/imgs/default-img.png'
import InputMask from 'react-input-mask'
import uuid from 'uuid'

import { withTranslation, Trans } from 'react-i18next'

import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { saveFornecedor, saveImage } from './fornecedorActions'
import { getCountUsersNotValidated } from '../navbar/navbarActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'
import { toastr } from 'react-redux-toastr'

class FornecedorForm extends Component {
  handleSubmit = async e => {
    e.preventDefault()

    // i18n
    const { t } = this.props;

    const user = this.props.user
    let fornecedor = {
      telefone: e.target.telefone.value,
      prazo_entrega: e.target.prazo_entrega.value
    }

    const file = e.target.imagem.files[0];
    if(!file) {
      toastr.warning(
        t("fornecedores.form.validation.imagem.required.title"), 
        t("fornecedores.form.validation.imagem.required.message")
      );
      return;
    }
   
    const blobName = this.handleSaveImage(user, file);

    fornecedor = {
      ...fornecedor,
      imagem: blobName
    }

    this.props.setLoading()
    await this.props.saveFornecedor(user, fornecedor);
    this.props.getCountUsersNotValidated();

    // redirect to /fornecedores
    this.props.history.push('/fornecedores')
  }

  handleSaveImage = (user, image) => {
    let blobName = null;

    if (image) {
      const fileExtension = this.getFileExtension(image.name);
      blobName = `fornecedores/${user.nome}/${uuid.v4() + fileExtension}`;
      this.props.saveImage(image, blobName);
    }

    return blobName;
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  handleLoadImage = e => {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);

    // set image
    document.getElementById('imagem_fornecedor').src = url
  }

  render() {
    if (!this.props.user) return <Redirect to="/signup" />

    // i18n
    const { t } = this.props;

    return (
      <>
        <Breadcrumb title={t("fornecedores.form.title2")} linkTo="/fornecedores/user/new" />

        <Row>
          <Grid cols="12 6">
            <form onSubmit={this.handleSubmit} className="mt-3">
              <div className="form-group">
                <label htmlFor="telefone">
                  <Trans i18nKey="fornecedores.form.telefone">trans</Trans>
                </label>
                <InputMask mask={t("fornecedores.form.maskTelefone")} maskChar=" " type="text" 
                  className="form-control" name="telefone" id="telefone" required />
              </div>

              <div className="form-group">
                <label htmlFor="prazo_entrega">
                  <Trans i18nKey="fornecedores.form.prazoEntrega.label">trans</Trans>
                  {' '}
                   <small className="text-muted">
                     <Trans i18nKey="fornecedores.form.prazoEntrega.small">trans</Trans>
                   </small>
                  </label>
                <input type="number" name="prazo_entrega" id="prazo_entrega"
                  className="form-control" required/>
              </div>

              <ImageInput id="imagem" onLoadImage={this.handleLoadImage} isRequired={true}/>

              <Button type="submit" size="block" color="primary">
                <Trans i18nKey="fornecedores.form.buttonCadastrar">trans</Trans>
              </Button>
            </form>
          </Grid>

          <Grid cols="12 6">
            <img id="imagem_fornecedor" className="img-thumbnail img-responsive"
              src={defaultImg} alt="Foto do fornecedor" />
          </Grid>
        </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getCountUsersNotValidated,
  saveFornecedor,
  setLoading,
  saveImage
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FornecedorForm))

import React from 'react'

import Breadcrumb from '../template/breadcrumb';
import Expositor from '../estande/expositor';

function generateData(rows, cols) {
  let data = [];

  for (let i = 1; i <= rows; i++) {
    for (let j = 1; j <= cols; j++) {
      const image = j % 2 === 0
        ? 'produtos/Auricolari sport con archetto ultramorbido/f8a12888-c06c-4bf6-84fa-1e3b79be3e9f.jpg'
        : 'produtos/BULLONERIE PREMONTATI ADULTO - € 9,90 - DIOTRIA +1,50/12dc6baf-db30-4bc8-b405-6269b3ffdc4a.jpg';

      data = [...data, {
        pos: [i, j],
        ean: '80180802788' + i + j,
        artigo: 'BTMOSQUITOK-6',
        image
      }]
    }
  }

  return data;
}

// const data = generateData(6, 13);
const data = [
  { pos: [1, 1], ean: '8018080286544', artigo: 'FREEPSLIM3000K', image: 'mock/expositor/A1.PNG' },
  { pos: [1, 2], ean: '8018080286551', artigo: 'FREEPSLIM3000W', image: 'mock/expositor/B1.PNG' },
  { pos: [1, 3], ean: '8018080283000', artigo: 'SYPB2500W', image: 'mock/expositor/C1.PNG' },
  { pos: [1, 4], ean: '8018080283024', artigo: 'SYPB2500K', image: 'mock/expositor/D1.PNG' },
  { pos: [1, 5], ean: '8018080290541', artigo: 'SLIDEPULL4XK', image: 'mock/expositor/E1.PNG' },
  { pos: [1, 6], ean: '8018080290510', artigo: 'SLIDEPULL3XK', image: 'mock/expositor/F1.PNG' },
  { pos: [1, 7], ean: '8018080340222', artigo: 'AUSWANMFIIPHW', image: 'mock/expositor/G1.PNG' },
  { pos: [1, 8], ean: '8018080291999', artigo: 'ACOUSTICK', image: 'mock/expositor/H1.PNG' },
  { pos: [1, 9], ean: '8018080292019', artigo: 'ACOUSTICW', image: 'mock/expositor/I1.PNG' },
  { pos: [1, 10], ean: '8018080301001/8018080301018', artigo: 'LIVEK/LIVEW', image: 'mock/expositor/J1.PNG' },
  { pos: [1, 11], ean: '8018080278846', artigo: 'BTSPKFIZZYB', image: 'mock/expositor/K1.PNG' },
  { pos: [1, 12], ean: '8018080278822', artigo: 'BTSPKFIZZYK', image: 'mock/expositor/L1.PNG' },
  { pos: [2, 1], ean: '8018080279034', artigo: 'HANDYDRIVEPROD', image: 'mock/expositor/A2.PNG' },
  { pos: [2, 2], ean: '8018080251238', artigo: 'HANDYDRIVEK', image: 'mock/expositor/B2.PNG' },
  { pos: [2, 3], ean: '8018080251238', artigo: 'HANDYDRIVEK', image: 'mock/expositor/C2.PNG' },
  { pos: [2, 4], ean: '8018080308338', artigo: 'HANDYWINGPROK', image: 'mock/expositor/D2.PNG' },
  { pos: [2, 5], ean: '8018080251252', artigo: 'HANDYWINGK', image: 'mock/expositor/E2.PNG' },
  { pos: [2, 6], ean: '8018080251252', artigo: 'HANDYWINGK', image: 'mock/expositor/F2.PNG' },
  { pos: [2, 7], ean: '8018080278891', artigo: 'BTMOSQUITOK', image: 'mock/expositor/G2.PNG' },
  { pos: [2, 8], ean: '8018080308604', artigo: 'BTVOXK', image: 'mock/expositor/H2.PNG' },
  { pos: [2, 9], ean: '8018080275593', artigo: 'AUCLASSICK', image: 'mock/expositor/I2.PNG' },
  { pos: [2, 10], ean: '8018080275609', artigo: 'AUCLASSICW', image: 'mock/expositor/J2.PNG' },
  { pos: [2, 11], ean: '8018080275999', artigo: 'AUINEARK', image: 'mock/expositor/K2.PNG' },
  { pos: [2, 12], ean: '8018080276002', artigo: 'AUINEARW', image: 'mock/expositor/L2.PNG' },
  { pos: [3, 1], ean: '8018080175466', artigo: 'USBDATACMFIIPH5W', image: 'mock/expositor/A3.PNG' },
  { pos: [3, 2], ean: '8018080175466', artigo: 'USBDATACMFIIPH5W', image: 'mock/expositor/B3.PNG' },
  { pos: [3, 3], ean: '8018080270413', artigo: 'USBDATACUSBA-CW', image: 'mock/expositor/C3.PNG' },
  { pos: [3, 4], ean: '8018080270413', artigo: 'USBDATACUSBA-CW', image: 'mock/expositor/D3.PNG' },
  { pos: [3, 5], ean: '8018080131745', artigo: 'USBDATACABMICROUSB', image: 'mock/expositor/E3.PNG' },
  { pos: [3, 6], ean: '8018080131745', artigo: 'USBDATACABMICROUSB', image: 'mock/expositor/F3.PNG' },
  { pos: [3, 7], ean: '8018080241314', artigo: 'USBDATAMFISMARTP', image: 'mock/expositor/G3.PNG' },
  { pos: [3, 8], ean: '8018080325786', artigo: 'USBDATATYCSMARTP', image: 'mock/expositor/H3.PNG' },
  { pos: [3, 9], ean: '8018080241321', artigo: 'USBDATAMFISMARTB', image: 'mock/expositor/I3.PNG' },
  { pos: [3, 10], ean: '8018080325779', artigo: 'USBDATATYCSMARTB', image: 'mock/expositor/J3.PNG' },
  { pos: [3, 11], ean: '8018080287138', artigo: 'FREEPSMART5000W', image: 'mock/expositor/K3.PNG' },
  { pos: [3, 12], ean: '8018080287138', artigo: 'FREEPSMART5000W', image: 'mock/expositor/L3.PNG' },
  { pos: [4, 1], ean: '8018080238116', artigo: 'ACHUSBMOBILE2AW', image: 'mock/expositor/A4.PNG' },
  { pos: [4, 2], ean: '8018080304040', artigo: 'ACHSMUSB10WW', image: 'mock/expositor/B4.PNG' },
  { pos: [4, 3], ean: '8018080255458', artigo: 'ACHUSBMOBILE2AK', image: 'mock/expositor/C4.PNG' },
  { pos: [4, 4], ean: '8018080304057', artigo: 'ACHUSBMOBILE1AK', image: 'mock/expositor/D4.PNG' },
  { pos: [4, 5], ean: '8018080304057', artigo: 'ACHUSBMOBILE1AK', image: 'mock/expositor/E4.PNG' },
  { pos: [4, 6], ean: '8018080135804', artigo: 'ACHMICROUSB1', image: 'mock/expositor/F4.PNG' },
  { pos: [4, 7], ean: '8018080241253', artigo: 'USBDATAMUSBSMARP', image: 'mock/expositor/G4.PNG' },
  { pos: [4, 8], ean: '8018080339004', artigo: 'ACHUSBSMART2AP', image: 'mock/expositor/H4.PNG' },
  { pos: [4, 9], ean: '8018080241260', artigo: 'USBDATAMUSBSMARB', image: 'mock/expositor/I4.PNG' },
  { pos: [4, 10], ean: '8018080339028', artigo: 'ACHUSBSMART2AB', image: 'mock/expositor/J4.PNG' },
  { pos: [4, 11], ean: '8018080240881', artigo: 'BUTTERFLYSMARTW', image: 'mock/expositor/K4.PNG' },
  { pos: [4, 12], ean: '8018080240881', artigo: 'BUTTERFLYSMARTW', image: 'mock/expositor/L4.PNG' },
  { pos: [5, 1], ean: '8018080230509', artigo: 'MICROCBRUSB2AW', image: 'mock/expositor/A5.PNG' },
  { pos: [5, 2], ean: '8018080203237', artigo: 'MICROCBRUSBDUAL2A', image: 'mock/expositor/B5.PNG' },
  { pos: [5, 3], ean: '8018080304071', artigo: 'MICROCBRUSB1AK', image: 'mock/expositor/C5.PNG' },
  { pos: [5, 4], ean: '8018080304071', artigo: 'MICROCBRUSB1AK', image: 'mock/expositor/D5.PNG' },
  { pos: [5, 5], ean: '8018080135989', artigo: 'CBRMICROUSB1', image: 'mock/expositor/E5.PNG' },
  { pos: [5, 6], ean: '8018080135989', artigo: 'CBRMICROUSB1', image: 'mock/expositor/F5.PNG' },
  { pos: [5, 7], ean: '8018080240898', artigo: 'BUTTERFLYSMARTP', image: 'mock/expositor/G5.PNG' },
  { pos: [5, 8], ean: '8018080265686', artigo: 'HANDYSMARTP', image: 'mock/expositor/H5.PNG' },
  { pos: [5, 9], ean: '8018080240928', artigo: 'BUTTERFLYSMARTB', image: 'mock/expositor/I5.PNG' },
  { pos: [5, 10], ean: '8018080265648', artigo: 'HANDYSMARTB', image: 'mock/expositor/J5.PNG' },
  { pos: [5, 11], ean: '8018080265631', artigo: 'HANDYSMARTW', image: 'mock/expositor/K5.PNG' },
  { pos: [5, 12], ean: '8018080265631', artigo: 'HANDYSMARTW', image: 'mock/expositor/L5.PNG' },
  { pos: [6, 1], ean: '8018080342448', artigo: 'MUSICSOUNDCOL2019W', image: 'mock/expositor/AB6.PNG' },
  { pos: [6, 2], ean: '8018080342462', artigo: 'MUSICSOUNDCOL2019K', image: 'mock/expositor/CD6.PNG' },
  { pos: [6, 3], ean: '8018080342462', artigo: 'MUSICSOUNDCOL2019K', image: 'mock/expositor/EF6.PNG' },
  { pos: [6, 4], ean: '8018080317200', artigo: 'BTMUSICSOUND20181', image: 'mock/expositor/GH6.PNG' },
  { pos: [6, 5], ean: '8018080295058', artigo: 'BTMUSICSOUNDK', image: 'mock/expositor/IJ6.PNG' },
  { pos: [6, 6], ean: '8018080295058', artigo: 'BTMUSICSOUNDK', image: 'mock/expositor/KL6.PNG' },
]

export default function PedidoVendaLayoutMock() {
  return (
    <div>
      <Breadcrumb title="Pedido de venda layout mock" linkTo="/config" />

      <Expositor data={data} />
    </div>
  )
}

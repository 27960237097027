import React from 'react'
import PropTypes from 'prop-types'

import Button from '../template/button'
import { Trans } from 'react-i18next'

function ButtonSelect({ onClick, ...props }) {
  return (
    <Button size="sm" color="info"
      handleclick={onClick} {...props}>
      <Trans i18nKey="common.buttonSelect.label">trans</Trans>
    </Button>
  )
}

ButtonSelect.propTypes = {
  onClick: PropTypes.func,
}

export default ButtonSelect;

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from '@unform/core'

function Input({ id, label, name, children, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  return (
    <div className="form-group">
      { label && <label htmlFor={id}>{label}</label> }
      <input ref={inputRef} id={id} 
        className="form-control"
        defaultValue={defaultValue} {...props}/>
      { children }
      { error && <p className="text-danger">{error}</p> }
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
}

export default Input;

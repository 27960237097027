export default {
  list: {
    title: 'Ordini di vendita',
    notFound: 'Non ci sono ordini da visualizzare ...',
    card: {
      title: 'Ordini',
      fornecedor: 'Fornitore',
      prazoEntrega: 'Tempi di consegna',
      pontoDeVenda: 'Punto vendita',
      
      dataVisita: 'Data della visita',
      buttonViewImg: "immagine dell'ordine",
      obs: 'Commenti',
      itens: {
        title: 'Ordina articoli',
        notFound: 'Nessun prodotto trovato',
        table: {
          codigoArtigo: 'Codice articolo',
          descricao: 'Descrizione',
          unidade: 'Unità',
          pc: 'PZ',
          quantidadeKit: 'Quantità x kit',
          total: 'Totale'
        },
      },
      pontoDeVendaList: {
        title: "Punto vendita",
        codigo_identificacao: 'Codice di identificazione',
        nome: 'Nome',
        endereco: 'Indirizzo',
        cidade: 'comune',
        regiao: 'Regione',
        estado: 'Pr:',
        frequencia_visita: 'frequenza delle visite',
        tipo: 'Insegna',
        obs: 'Commenti',
      },
      RepresentanteList: {
        title: "Rappresentante",
        email: 'E-mail',
        nome: 'Nome',
        status: 'Status',
        tipo: 'Tipo',
        telefone: 'Telefono',
      },
    },
    modalDelete: {
      title: "Conferma l'azione",
      text: 'Sei sicuro di voler rimuovere questo ordine cliente e i suoi prodotti?',
      buttonCancelar: 'Annullare',
      buttonRemover: 'Remove'
    },
    modalViewImg: {
      title: "Immagine dell'ordine"
    },

    search: {
      form: {
        input: {
          placeholder: 'Cerca per codice ordine'
        }
      }
    }
  },

  shoppingCart: {
    title: 'Ordine di vendita',
    wizard: {
      selectProduto: {
        title: 'Punto vendita: ',
        notFound: 'Nessun prodotto trovato',
        buttonCarrinho: 'Carrello',
        produto: {
          card: {
            buttonAdicionar: 'Aggiungi al carrello',
            adicionado: 'Aggiunto',
            fornecedor: 'Fornitore: ',
            unidade: {
              label: 'Unità: ',
              pc: 'PZ'
            }
          }
        },
        buttonCancelar: 'Annullare',
        buttonGoToCart: 'Vai al carrello'
      },
      cart: {
        notFound: 'Nessun prodotto nel carrello',
        table: {
          produto: 'Prodotto',
          quantidade: 'Quantità',
          total: 'Totale',
          acao: 'Azione',
        },
        buttonCancelar: 'Annullare',
        buttonAdicionarMais: 'Aggiungi altro',
        buttonRevisarPedido: 'Ordine di revisione'
      },
      revisar: {
        buttonVoltar: 'Ritorno',
        buttonFinalizar: "Finalizza l'ordine cliente"
      },
      validation: {
        alreadyInCart: {
          title: 'Spiacenti!',
          message: 'Questo prodotto è già nel carrello'
        },
        min: {
          title: 'Spiacenti!',
          message: 'La quantità minima è zero'
        },
        empty: {
          title: 'Manca qualcosa',
          message: 'Devi aggiungere almeno un prodotto'
        }
      },
      toastr: {
        isPedidoVenda: {
          title: 'Ordine cliente già avviato',
          message: 'Stai continuando un ordine di vendita già avviato'
        }
      }
    }
  },

  expositor: {
    selectImage: "Caricamento ordine digitale o fotografico?",
    selectedImage: "Immagine selezionata",
    viewImage: "Guarda l'immagine",
    buttonVoltar: 'Indietro',
    buttonFinalizar: "Spedire L'ordine",
    obs: 'Commenti',
    search: 'Cerca ean'
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il recupero degli ordini cliente'
      }
    },
    fetchByVisita: {
      error: {
        title: 'Spiacenti!',
        message: "Si è verificato un problema durante il recupero dell'ordine cliente per visita"
      }
    },
    search: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il recupero degli ordini cliente'
      }
    },
    save: {
      error: {
        title: 'Spiacenti!',
        message: "Si è verificato un problema durante il salvataggio dell'ordine di vendita"
      },
      success: {
        title: 'Successo',
        message: 'Ordine cliente salvato correttamente'
      }
    },
    saveProdutos: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il salvataggio dei prodotti dal punto vendita'
      }
    },
    update: {
      error: {
        title: 'Spiacenti!',
        message: "Si è verificato un problema durante il salvataggio dell'ordine di vendita"
      }
    },
    remove: {
      error: {
        title: 'Spiacenti!',
        message: "Si è verificato un problema durante la rimozione dell'ordine di vendita"
      },
      success: {
        title: 'Successo',
        message: 'Ordine cliente rimosso correttamente'
      }
    },
    removeByVisita: {
      error: {
        title: 'Spiacenti!',
        message: "Si è verificato un problema durante la rimozione dell'ordine cliente"
      }
    },
    fetchExpositor: {
      error: {
        title: 'Senza espositore',
        message: 'Nessun espositore registrato per questo fornitore'
      }
    }
  },
  loading: {
    redirect: 'Reindirizzando...',
    visita: 'Salvare Visita...',
    pedido: 'Salvare Ordine...',
    email: 'Invio di e-mail...'
  }
}

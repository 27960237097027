export default {
  form: {
    title: 'Accesso',
    email: {
      label: 'E-mail',
      placeholder: 'esempio@mail.com'
    },
    senha: {
      label: 'Password',
      placeholder: '******'
    },
    forgotPassword: 'Hai dimenticato la password?',
    buttonLogin: 'Accesse'
  },

  forgotPassword: {
    title: 'Hai dimenticato la password?',
    form: {
      email: {
        label: 'Inserisci la tua email e te ne invieremo una nuova',
        placeholder: 'esempio@mail.com'
      },
      buttonEnviar: 'Send'
    }
  },

  changePassword: {
    title: 'Cambia password',
    form: {
      senhaAtual: {
        label: 'La tua password attuale: ',
        placeholder: '******'
      },
      novaSenha: {
        label: 'La tua nuova password: ',
        placeholder: '******',
        small: 'La password deve contenere almeno 6 caratteri'
      },
      confirmarSenha: {
        label: 'Conferma la tua password: ',
        placeholder: '******'
      },
      buttonSalvar: 'Salva',
      validation: {
        minLength: {
          title: 'Manca qualcosa',
          message: 'La password deve contenere almeno 6 caratteri'
        },
        notEqualTo: {
          title: 'Manca qualcosa',
          message: 'Le password devono essere le stesse'
        }
      }
    }
  },

  toastr: {
    fetchUsers: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante il recupero degli utenti'
      }
    },
    changePassword: {
      error: {
        title: 'Spiacenti!',
        message: 'Si è verificato un problema durante la modifica della password'
      },
      success: {
        title: 'Successo',
        message: 'Riuscita modifica della password'
      }
    },
    validateUser: {
      error: {
        title: 'Spiacenti!',
        message: "Si è verificato un problema durante la convalida dell'utente"
      },
      success: {
        title: 'Successo',
        message: 'Utente verificato correttamente'
      }
    },
    auth: {
      email: {
        warning: {
          title: 'Manca qualcosa',
          message: 'Inserisci la tua email'
        }
      },
      senha: {
        warning: {
          title: 'Manca qualcosa',
          message: 'Inserisci la tua password'
        }
      },
      error: {
        title: 'Oops!',
        message: "Abbiamo avuto un problema con l'autenticazione"
      },
      validation: {
        warning: {
          title: 'Manca qualcosa',
          message: 'Seu gestor ainda não aprovou seu cadastro'
        }
      },
      auth: {
        error: {
          title: 'Oops!',
          message: 'Nome utente o password non validi'
        }
      },
      success: {
        title: 'Successo',
        message: 'Accesso effettuato correttamente'
      }
    },
    recoverPassword: {
      warning: {
        title: 'Manca qualcosa',
        message: 'Inserisci la tua email'
      },
      error: {
        title: 'Oops!',
        message: 'Si è verificato un problema durante il recupero della password'
      },
      success: {
        title: 'Successo',
        message: 'Controlla la tua e-mail per la tua nuova password'
      }
    }
  }
}

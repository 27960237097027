import axios from 'axios'
import consts from '../../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../../loadingSpinner/loadingSpinnerActions'

const BASE_URL = consts.API_URL

const nop = () => {
  return dispatch => {
    return dispatch({
      type: 'NOP'
    })
  }
}

export const setContato = contato => {
  return dispatch => {
    return dispatch({
      type: 'SET_CONTATO',
      payload: contato
    })
  }
}

const setContatos = contatos => {
  return dispatch => {
    return dispatch({
      type: 'FETCH_CONTATOS',
      payload: contatos
    })
  }
}

export const fetchContatos = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/contatos`)
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao buscar contatos")
          return dispatch(nop())
        }

        return dispatch(setContatos(res.data.data))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar contatos")
        throw err
      })
  }
}

export const fetchContatosByPontoDeVenda = pontoDeVenda => {
  return dispatch => {
    if(!pontoDeVenda) {
      toastr.error("Oops!", "Falha ao buscar contatos pelo ponto de venda")
      return dispatch(nop())
    }

    axios.get(`${BASE_URL}/contatos/pontosDeVenda/${pontoDeVenda.id}`)
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Falha ao buscar contatos pelo ponto de venda")
          return dispatch(nop())
        }

        return dispatch(setContatos(res.data.data))
      })
      .catch(err => {
        toastr.error("Oops!", "Falha ao buscar contatos pelo ponto de venda")
        throw err
      })
  }
}

export const saveContato = (contato, pontoDeVenda) => {
  return dispatch => {
    if(!contato) {
      toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
      return dispatch(unsetLoading())
    }

    if(!pontoDeVenda) {
      toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
      return dispatch(unsetLoading())
    }

    axios.post(`${BASE_URL}/contatos`, {
      contato,
      pontoDeVenda
    })
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
          return dispatch(unsetLoading())
        }

        toastr.success("Sucesso", "Contato salvo com sucesso")
        return dispatch([fetchContatosByPontoDeVenda(pontoDeVenda), unsetLoading()])
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
        dispatch(unsetLoading())
        throw err
      })
  }
}

export const updateContato = (contato, pontoDeVenda) => {
  return dispatch => {
    if(!contato) {
      toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
      return dispatch(nop())
    }

    axios.put(`${BASE_URL}/contatos`, {
      contato
    })
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
          return dispatch(nop())
        }

        toastr.success("Sucesso", "Contato salvo com sucesso")
        return dispatch(fetchContatosByPontoDeVenda(pontoDeVenda))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao salvar o contato")
        throw err
      })
  }
}

export const removeContato = (contato, pontoDeVenda) => {
  return dispatch => {
    if(!contato) {
      toastr.error("Oops!", "Tivemos um problema ao remover o contato")
      return dispatch(nop())
    }

    axios.delete(`${BASE_URL}/contatos`, {
      headers: {},
      data: {
        contato
      }
    })
      .then(res => {
        if(res.data.error) {
          toastr.error("Oops!", "Tivemos um problema ao remover o contato")
          return dispatch(nop())
        }

        toastr.success("Sucesso", "Sucesso ao remover contato")
        return dispatch(fetchContatosByPontoDeVenda(pontoDeVenda))
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao remover o contato")
        throw err
      })
  }
}

export default {
  list: {
    title: 'Produtos',
    search: {
      placeholder: 'Pesquise por produto ou descrição ou fornecedor ...',
      buttonSearch: {
        title: 'Pesquisar'
      },
      buttonClear: {
        title: 'Limpar'
      },
    },
    card: {
      fornecedor: 'Fornecedor',
      unidade: 'Unidade'
    },
    modal: {
      title: 'Confirmar ação',
      text: 'Você tem certeza de que quer remover este produto?',
      buttonCancelar: 'Cancelar',
      buttonRemover: 'Remover'
    }
  },

  form: {
    title: 'Cadastro de produto',
    codigoArtigo: {
      label: 'Código artigo: ',
      placeholder: 'Código de identificação do produto'
    },
    codigoEan: 'Código EAN: ',
    descricaoEan: 'Descrição EAN: ',
    descricao: {
      label: 'Descrição: ',
      placeholder: 'Descrição do produto que o representante verá'
    },
    fornecedor: 'Fornecedor: ',
    quantidadeKit: {
      label: 'Quantidade do kit: ',
      placeholder: '10'
    },
    unidade: {
      label: 'Unidade: ',
      pc: 'Peça'
    },
    imagem: 'Imagem: ',
    buttonEscolherImagem: 'Escolher imagem',
    buttonCadastrar: 'Cadastrar'
  },

  edit: {
    title: 'Editar produto',
    codigoArtigo: 'Código Artigo: ',
    codigoEan: 'Código EAN: ',
    descricaoEan: 'Descrição EAN: ',
    descricao: 'Descrição: ',
    quantidadeKit: 'Quantidade do kit: ',
    unidade: {
      label: 'Unidade: ',
      pc: 'Peça'
    },
    imagem: 'Imagem: ',
    buttonHabilitarCampos: 'Habilitar campos',
    buttonCancelar: 'Cancelar',
    buttonSalvar: 'Salvar'
  },

  toastr: {
    fetchAll: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os produtos'
      }
    },
    fetchByPontoDeVenda: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os produtos'
      }
    },
    fetchByFornecedor: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao buscar os produtos deste fornecedor'
      }
    },
    search: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao pesquisar o produto'
      }
    },
    searchByPontoDeVendaUnbound: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao pesquisar o produto'
      }
    },
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o produto'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o produto'
      }
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o produto'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o produto'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o produto'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao remover o produto'
      }
    }
  }
}
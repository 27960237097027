import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'

// i18n
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

export const setGestor = gestor => {
  return dispatch => {
    return dispatch({
      type: 'SET_GESTOR',
      payload: gestor
    })
  }
}

export const fetchGestores = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/gestores`)
      .then(res => {
        return dispatch({
          type: 'FETCH_GESTORES',
          payload: res.data.data
        })
      })
      .catch(err => {
        throw err
      })
  }
}

export const saveGestor = (user, gestor) => {
  const {telefone, recebe_email} = gestor
  return dispatch => {
    if (!user) {
      toastr.error(
        i18n.t("gestores.toastr.save.error.title"),
        i18n.t("gestores.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!gestor) {
      toastr.error(
        i18n.t("gestores.toastr.save.error.title"),
        i18n.t("gestores.toastr.save.error.message")
      )
      return dispatch(nop())
    }
    console.log('telefone', telefone);
    console.log('recebe_email', recebe_email);
    return axios.post(`${BASE_URL}/gestores`, { telefone, recebe_email, user })
      .then(res => {
        if (res.data.error) {
          toastr.error('Oops!', res.data.message)
          dispatch([nop(), unsetLoading()])
          return;
        }

        toastr.success(
          i18n.t("gestores.toastr.save.success.title"),
          i18n.t("gestores.toastr.save.success.message")
        )
        dispatch([fetchGestores(), unsetLoading()])
      })
      .catch(err => {
        if (err.response.status !== 200) {
          toastr.error(i18n.t("gestores.toastr.save.error.title"), err.response.data.message)
          dispatch(unsetLoading())
          return;
        }

        unsetLoading()
        toastr.error(
          i18n.t("gestores.toastr.save.error.title"),
          i18n.t("gestores.toastr.save.error.message")
        )
        throw err
      })
  }
}

export const updateGestor = (user, gestor) => {
  return (dispatch, getState) => {
    if (!user) {
      toastr.error(
        i18n.t("gestores.toastr.update.error.title"),
        i18n.t("gestores.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    if (!gestor) {
      toastr.error(
        i18n.t("gestores.toastr.update.error.title"),
        i18n.t("gestores.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    axios.put(`${BASE_URL}/gestores`, {
      user,
      gestor
    })
      .then(res => {
        toastr.success(
          i18n.t("gestores.toastr.update.success.title"),
          i18n.t("gestores.toastr.update.success.message")
        )

        // set the new gestor on the list to update the state
        const updatedGestor = {
          id: gestor.id,
          user_id: user.id,
          nome: user.nome,
          telefone: gestor.telefone,
          recebe_email: parseInt(gestor.recebe_email),
          email: user.email,
          senha: user.senha,
          status: user.status,
          tipo: user.tipo
        }
        const { gestores } = getState().gestor
        const updatedGestores = [...gestores.filter(g => {
          return g.id !== gestor.id
        }), updatedGestor]

        return dispatch({
          type: 'FETCH_GESTORES',
          payload: updatedGestores
        })
      })
      .catch(err => {
        throw err
      })
  }
}

export const removeGestor = gestor => {
  return dispatch => {
    const gestorId = gestor.id
    const userId = gestor.user_id

    if (!gestorId) {
      toastr.error(
        i18n.t("gestores.toastr.remove.error.title"),
        i18n.t("gestores.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    if (!userId) {
      toastr.error(
        i18n.t("gestores.toastr.remove.error.title"),
        i18n.t("gestores.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    const reqBody = {
      gestor: {
        id: gestorId
      },
      user: {
        id: userId
      }
    }

    return axios.delete(`${BASE_URL}/gestores`, {
      headers: {},
      data: reqBody
    })
      .then(res => {
        toastr.success(
          i18n.t("gestores.toastr.remove.success.title"), 
          i18n.t("gestores.toastr.remove.success.message")
        )
        return dispatch(fetchGestores())
      })
      .catch(err => {
        throw err
      })
  }
}

import React, { Component } from 'react'
import Modal from '../template/modal.jsx'
import Breadcrumb from '../template/breadcrumb'
import Button from '../template/button.jsx'
import Row from '../template/row'
import Grid from '../template/grid'

import Media from 'react-media'
import { withTranslation, Trans } from 'react-i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  searchPontosDeVenda,
  setRef,
  clearRef,
  setWithoutRepresentantes,
  clearWithoutRepresentantes,
  removePontoDeVenda,
  setPontoDeVenda
} from './pontoDeVendaActions'
import PontoDeVendaSearch from './pontoDeVendaSearch.jsx'
import Pagination from '../pagination/index'
import Skeleton from 'react-loading-skeleton'

import { splitString } from '../../utils/strings'

export class PontoDeVendaList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pontoDeVenda: null,
      pontosDeVenda: [],
      page: 1,
      itemsPerPage: 15,
      isLoading: true
    }
  }

  componentDidMount() {
    this.props.searchPontosDeVenda('', this.state.page, this.state.itemsPerPage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pontosDeVenda !== this.props.pontosDeVenda) {
      this.setPontosDeVenda(this.props.pontosDeVenda);
    }
  }

  removerPontoDeVenda = pontoDeVenda => {
    this.props.removePontoDeVenda(pontoDeVenda)
  }

  editarPontoDeVenda = pontoDeVenda => {
    // set ponto de venda
    this.props.setPontoDeVenda(pontoDeVenda)
    this.props.history.push('/pontosDeVenda/edit')
  }

  setPontoDeVenda = pontoDeVenda => {
    this.setState({
      ...this.state,
      pontoDeVenda
    })
  }

  setPontosDeVenda = pontosDeVenda => {
    this.setState({
      pontosDeVenda,
      isLoading: false
    })
  }

  handlePaginate = page => {
    if (page === this.state.page) return;

    const { ref, withoutRepresentantes } = this.props.pontoDeVenda;

    this.setState({
      page,
      isLoading: true
    }, this.props.searchPontosDeVenda(ref, page, this.state.itemsPerPage, withoutRepresentantes));
  }

  handleSearch = e => {
    e.preventDefault();

    const page = 1;
    const ref = e.target.ref.value;
    const withoutRepresentantes = e.target.withoutRepresentantes.checked;

    // set reference text on application state
    this.props.setRef(ref);
    this.props.setWithoutRepresentantes(withoutRepresentantes);

    // search pontos de venda with reference text and navigate to page 1
    this.setState({
      page,
      isLoading: true
    }, this.props.searchPontosDeVenda(ref, page, this.state.itemsPerPage, withoutRepresentantes));
  }

  handleClearSearch = () => {
    // clear search reference on application state
    this.props.clearRef();
    this.props.clearWithoutRepresentantes();

    // clear form
    document.getElementById('ref').value = ""
    document.getElementById('withoutRepresentantes').checked = false

    const page = 1;

    // go back to page 1 with all pontos de venda
    this.setState({
      page,
      isLoading: true
    }, this.props.searchPontosDeVenda('', page, this.state.itemsPerPage));
  }

  renderPontosDeVenda = pontosDeVenda => {
    const { t } = this.props;

    return pontosDeVenda.map(pontoDeVenda => {
      return (
        <tr key={pontoDeVenda.id}>
          <td data-label={t("pontosDeVenda.list.table.codigoIdentificacao")} className="align-middle">{pontoDeVenda.codigo_identificacao}</td>
          <Media query={{ maxWidth: 599 }}>
            {matches => matches ? (
              <td data-label={t("pontosDeVenda.list.table.nome")} className="align-middle">{splitString(pontoDeVenda.nome)}</td>
            ) : (
              <td data-label={t("pontosDeVenda.list.table.nome")} className="align-middle">{pontoDeVenda.nome}</td>
            )}
          </Media>
          <td data-label={t("pontosDeVenda.list.table.cidade")} className="align-middle">{pontoDeVenda.cidade}</td>
          <td data-label={t("pontosDeVenda.list.table.estado")} className="align-middle">{pontoDeVenda.estado}</td>
          <td data-label={t("pontosDeVenda.list.table.regiao")} className="align-middle">{pontoDeVenda.regiao}</td>
          <td data-label={t("pontosDeVenda.list.table.estabelecimento")} className="align-middle">{pontoDeVenda.estabelecimento}</td>
          <td data-label={t("pontosDeVenda.list.table.endereco")} className="align-middle">{pontoDeVenda.endereco}</td>
          <Media query={{ maxWidth: 599 }}>
            {matches => matches ? (
              <td data-label={t("pontosDeVenda.list.table.acao")}>
                <button className="btn btn-sm btn-warning"
                  onClick={() => this.editarPontoDeVenda(pontoDeVenda)}>
                  <Trans i18nKey="pontosDeVenda.list.table.buttonEditar">trans</Trans>
                </button>
                <button className="ml-2 btn btn-sm btn-danger" data-toggle="modal" data-target="#modalDeletarPontoDeVenda"
                  onClick={() => this.setPontoDeVenda(pontoDeVenda)}>
                  <Trans i18nKey="pontosDeVenda.list.table.buttonRemover">trans</Trans>
                </button>
              </td>
            ) : (
              <td data-label={t("pontosDeVenda.list.table.acao")} className="d-flex">
                <button className="btn btn-sm btn-warning"
                  onClick={() => this.editarPontoDeVenda(pontoDeVenda)}>
                  <Trans i18nKey="pontosDeVenda.list.table.buttonEditar">trans</Trans>
                </button>
                <button className="ml-2 btn btn-sm btn-danger" data-toggle="modal" data-target="#modalDeletarPontoDeVenda"
                  onClick={() => this.setPontoDeVenda(pontoDeVenda)}>
                  <Trans i18nKey="pontosDeVenda.list.table.buttonRemover">trans</Trans>
                </button>
              </td>
            )}
          </Media>
        </tr>
      )
    })
  }

  isEmpty = arr => {
    if (arr === null) {
      return true
    }

    if (arr === undefined) {
      return true
    }

    if (arr.length <= 0) {
      return true
    }

    return false;
  }

  render() {

    const pontosDeVenda = this.state.pontosDeVenda || []

    // i18n
    const { t } = this.props;

    return (
      <>
        <Breadcrumb title={t("pontosDeVenda.list.title")} component={() => (
          <Button size="sm" color="success"
            handleclick={() => this.props.history.push('/pontosDeVenda/new')}>
            <i className="material-icons" style={{ lineHeight: 'inherit' }}>add</i>
          </Button>
        )} />

        <PontoDeVendaSearch onSubmit={this.handleSearch}
          onClearSearch={this.handleClearSearch} />

        <Row>
          <Grid cols="12">
            {this.state.isLoading ? <Skeleton count={10} height={35} />
              : this.isEmpty(this.props.pontosDeVenda) ? <p><Trans i18nKey="pontosDeVenda.list.notFound">trans</Trans></p>
                : (
                  <div id="card" style={{ overflowX: 'auto' }}>
                    <table className="table table-fluid table-striped text-center">
                      <thead className="thead-dark">
                        <tr>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.codigoIdentificacao">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.nome">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.cidade">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.estado">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.regiao">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.estabelecimento">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.endereco">trans</Trans>
                          </th>
                          <th className="align-middle" scope="col">
                            <Trans i18nKey="pontosDeVenda.list.table.acao">trans</Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderPontosDeVenda(pontosDeVenda)}
                      </tbody>
                    </table>
                  </div>
                )}
          </Grid>
        </Row>

        <Row>
          <Grid cols="12">
            <Pagination page={this.state.page}
              itemsPerPage={this.state.itemsPerPage}
              paginate={this.handlePaginate}
              totalItems={this.props.count}
              previousLabel={t("pagination.buttonAnterior")}
              nextLabel={t("pagination.buttonProximo")} />
          </Grid>
        </Row>

        {/* Modal */}
        <Modal id="modalDeletarPontoDeVenda"
          title={t("pontosDeVenda.list.modal.title")}
          text={t("pontosDeVenda.list.modal.text")}
          handleClick={() => this.removerPontoDeVenda(this.state.pontoDeVenda)}
          size="sm"
          btnColor="danger"
          btnText={t("pontosDeVenda.list.modal.buttonRemover")}
          btnCancelarText={t("pontosDeVenda.list.modal.buttonCancelar")} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    pontosDeVenda: state.pontoDeVenda.pontosDeVenda,
    count: state.pontoDeVenda.count,
    pontoDeVenda: state.pontoDeVenda
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  clearWithoutRepresentantes,
  setWithoutRepresentantes,
  searchPontosDeVenda,
  removePontoDeVenda,
  setPontoDeVenda,
  clearRef,
  setRef,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PontoDeVendaList))

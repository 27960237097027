const INITIAL_STATE = {
  representante: null,
  representantes: []
}

const representanteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_REPRESENTANTES':
      return {
        ...state,
        representantes: action.payload
      }
    case 'SET_REPRESENTANTE':
      localStorage.setItem('GPV_REPRESENTANTE', JSON.stringify(action.payload));

      return {
        ...state,
        representante: action.payload
      }
    default:
      return state
  }
}

export default representanteReducer
import React, { Component } from 'react';
import './visitaList.css';
import PropTypes from 'prop-types';

import { withTranslation, Trans } from 'react-i18next';

import { Redirect } from 'react-router-dom';
import Modal from '../template/modal';
import moment from 'moment-timezone';
import defaultImg from '../common/imgs/default-img.png';
import firebase from '../../config/fbConfig';
import Media from 'react-media';
import Button from '../template/button';
import ModalButton from '../template/modalButton';

export class VisitaList extends Component {
  state = {
    redirect: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visitas !== this.props.visitas) {
      this.loadImages();
    }
  }

  loadImages = () => {
    this.props.visitas.forEach((visita) => {
      const imgId = 'img-' + visita.id;

      if (
        visita.foto_ponto_de_venda &&
        visita.foto_ponto_de_venda !== 'undefined'
      ) {
        const storageRef = firebase.storage().ref(visita.foto_ponto_de_venda);

        storageRef
          .getDownloadURL()
          .then((url) => {
            if (document.getElementById(imgId)) {
              document.getElementById(imgId).src = url;
            }
          })
          .catch((err) => {
            console.log('Erro ao carregar imagem: ', err);
            throw err;
          });
      }
    });
  };

  redirectToVisitaPage = () => {
    this.setState({
      redirect: true,
    });
  };

  openVisita = (visita) => {
    this.setVisita(visita, this.redirectToVisitaPage);
  };

  removerVisita = (visita) => {
    this.props.removeVisita(visita);
  };

  setVisita = (visita, callback) => {
    this.setState(
      {
        ...this.state,
        visita,
      },
      callback
        ? callback
        : () => {
            return;
          }
    );
  };

  renderVisitas = () => {
    const visitas = this.props.visitas || [];
    const { t: translate } = this.props;

    console.log('fornecedor log', this.props.fornecedor);

    return visitas.map((visita) => {
      const imgId = 'img-' + visita.id;
      return this.props.fornecedor ? (
        this.props.fornecedor === visita.fornecedor_id && (
          <tr key={visita.id}>
            <td className='align-middle'>
              <div className='d-flex align-items-center'>
                <Media query={{ maxWidth: 599 }}>
                  {(matches) =>
                    matches ? (
                      <div style={{ padding: 10, paddingLeft: 0 }}>
                        <img
                          id={imgId}
                          src={defaultImg}
                          className='img-fluid img-thumbnail'
                          alt='Foto do ponto de venda'
                          style={{ width: '100px' }}
                        />{' '}
                        <span className='font-weight-bold h6'>
                          {visita.ponto_de_venda_nome}
                        </span>
                      </div>
                    ) : (
                      <>
                        <img
                          id={imgId}
                          src={defaultImg}
                          className='img-fluid img-thumbnail'
                          alt='Foto do ponto de venda'
                          style={{ width: '100px' }}
                        />
                        {this.props.loadPontoDeVendaNome && (
                          <span className='font-weight-bold h6'>
                            {' '}
                            {visita.ponto_de_venda_nome}
                          </span>
                        )}
                      </>
                    )
                  }
                </Media>
              </div>
            </td>
            <td
              data-label={translate(
                'visitas.visitacaoPage.list.table.fornecedor'
              )}
              className='align-middle'
            >
              {visita.fornecedor_nome}
            </td>
            <td
              data-label={translate(
                'visitas.visitacaoPage.list.table.representante'
              )}
              className='align-middle'
            >
              {visita.representante_nome}
            </td>
            <td
              data-label={translate('visitas.visitacaoPage.list.table.data')}
              className='align-middle'
            >
              {moment(visita.data_visita).format('DD/MM/YYYY - HH:mm:ss')}
            </td>
            <td
              data-label={translate(
                'visitas.visitacaoPage.list.table.estoque_alto.label'
              )}
              className='align-middle'
            >
              {visita.estoque_alto
                ? translate('visitas.visitacaoPage.list.table.estoque_alto.sim')
                : translate(
                    'visitas.visitacaoPage.list.table.estoque_alto.nao'
                  )}
            </td>
            <td
              data-label={translate(
                'visitas.visitacaoPage.list.table.exposicao_completa.label'
              )}
              className='align-middle'
            >
              {visita.exposicao_completa
                ? translate(
                    'visitas.visitacaoPage.list.table.exposicao_completa.sim'
                  )
                : translate(
                    'visitas.visitacaoPage.list.table.exposicao_completa.nao'
                  )}
            </td>
            <td
              data-label={translate(
                'visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.label'
              )}
              className='align-middle'
            >
              {visita.gerente_rejeitou_pedido
                ? translate(
                    'visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.sim'
                  )
                : translate(
                    'visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.nao'
                  )}
            </td>
            <td
              data-label={translate(
                'visitas.visitacaoPage.list.table.expositor_organizado.label'
              )}
              className='align-middle'
            >
              {visita.expositor_organizado
                ? translate(
                    'visitas.visitacaoPage.list.table.expositor_organizado.sim'
                  )
                : translate(
                    'visitas.visitacaoPage.list.table.expositor_organizado.nao'
                  )}
            </td>
            <td
              data-label={translate('visitas.visitacaoPage.list.table.acao')}
              className='align-middle'
            >
              <div className='d-flex'>
                <Button
                  size='sm'
                  color='info'
                  handleclick={() => this.openVisita(visita)}
                >
                  <Trans i18nKey='visitas.visitacaoPage.list.table.buttonVisualizar'>
                    trans
                  </Trans>
                </Button>
                {this.props.editarVisita ? (
                  <Button
                    size='sm'
                    color='warning'
                    handleclick={() => this.props.editarVisita(visita)}
                  >
                    <Trans i18nKey='visitas.visitacaoPage.list.table.buttonEditar'>
                      trans
                    </Trans>
                  </Button>
                ) : null}
                {this.props.removeVisita ? (
                  <ModalButton
                    size='sm'
                    color='danger'
                    className='ml-2'
                    target='#modalDeletarVisita'
                    handleclick={() => this.setVisita(visita)}
                  >
                    <Trans i18nKey='visitas.visitacaoPage.list.table.buttonRemover'>
                      trans
                    </Trans>
                  </ModalButton>
                ) : null}
              </div>
            </td>
          </tr>
        )
      ) : (
        <tr key={visita.id}>
          <td className='align-middle'>
            <div className='d-flex align-items-center'>
              <Media query={{ maxWidth: 599 }}>
                {(matches) =>
                  matches ? (
                    <div style={{ padding: 10, paddingLeft: 0 }}>
                      <img
                        id={imgId}
                        src={defaultImg}
                        className='img-fluid img-thumbnail'
                        alt='Foto do ponto de venda'
                        style={{ width: '100px' }}
                      />{' '}
                      <span className='font-weight-bold h6'>
                        {visita.ponto_de_venda_nome}
                      </span>
                    </div>
                  ) : (
                    <>
                      <img
                        id={imgId}
                        src={defaultImg}
                        className='img-fluid img-thumbnail'
                        alt='Foto do ponto de venda'
                        style={{ width: '100px' }}
                      />
                      {this.props.loadPontoDeVendaNome && (
                        <span className='font-weight-bold h6'>
                          {' '}
                          {visita.ponto_de_venda_nome}
                        </span>
                      )}
                    </>
                  )
                }
              </Media>
            </div>
          </td>
          <td
            data-label={translate(
              'visitas.visitacaoPage.list.table.fornecedor'
            )}
            className='align-middle'
          >
            {visita.fornecedor_nome}
          </td>
          <td
            data-label={translate(
              'visitas.visitacaoPage.list.table.representante'
            )}
            className='align-middle'
          >
            {visita.representante_nome}
          </td>
          <td
            data-label={translate('visitas.visitacaoPage.list.table.data')}
            className='align-middle'
          >
            {moment(visita.data_visita).format('DD/MM/YYYY - HH:mm:ss')}
          </td>
          <td
            data-label={translate(
              'visitas.visitacaoPage.list.table.estoque_alto.label'
            )}
            className='align-middle'
          >
            {visita.estoque_alto
              ? translate('visitas.visitacaoPage.list.table.estoque_alto.sim')
              : translate('visitas.visitacaoPage.list.table.estoque_alto.nao')}
          </td>
          <td
            data-label={translate(
              'visitas.visitacaoPage.list.table.exposicao_completa.label'
            )}
            className='align-middle'
          >
            {visita.exposicao_completa
              ? translate(
                  'visitas.visitacaoPage.list.table.exposicao_completa.sim'
                )
              : translate(
                  'visitas.visitacaoPage.list.table.exposicao_completa.nao'
                )}
          </td>
          <td
            data-label={translate(
              'visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.label'
            )}
            className='align-middle'
          >
            {visita.gerente_rejeitou_pedido
              ? translate(
                  'visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.sim'
                )
              : translate(
                  'visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.nao'
                )}
          </td>
          <td
            data-label={translate(
              'visitas.visitacaoPage.list.table.expositor_organizado.label'
            )}
            className='align-middle'
          >
            {visita.expositor_organizado
              ? translate(
                  'visitas.visitacaoPage.list.table.expositor_organizado.sim'
                )
              : translate(
                  'visitas.visitacaoPage.list.table.expositor_organizado.nao'
                )}
          </td>
          <td
            data-label={translate('visitas.visitacaoPage.list.table.acao')}
            className='align-middle'
          >
            <div className='d-flex'>
              <Button
                size='sm'
                color='info'
                handleclick={() => this.openVisita(visita)}
              >
                <Trans i18nKey='visitas.visitacaoPage.list.table.buttonVisualizar'>
                  trans
                </Trans>
              </Button>
              {this.props.editarVisita ? (
                <Button
                  size='sm'
                  color='warning'
                  handleclick={() => this.props.editarVisita(visita)}
                >
                  <Trans i18nKey='visitas.visitacaoPage.list.table.buttonEditar'>
                    trans
                  </Trans>
                </Button>
              ) : null}
              {this.props.removeVisita ? (
                <ModalButton
                  size='sm'
                  color='danger'
                  className='ml-2'
                  target='#modalDeletarVisita'
                  handleclick={() => this.setVisita(visita)}
                >
                  <Trans i18nKey='visitas.visitacaoPage.list.table.buttonRemover'>
                    trans
                  </Trans>
                </ModalButton>
              ) : null}
            </div>
          </td>
        </tr>
      );
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/visitas/${this.state.visita.id}`} />;
    }

    const { t: translate } = this.props;

    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-md-12'>
            {this.props.visitas.length > 0 ? (
              <div id='card'>
                <table className='table table-sm table-fluid table-striped text-center'>
                  <thead className='thead-dark'>
                    <tr>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.pontoDeVenda'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.fornecedor'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.representante'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.data'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.estoque_alto.label'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.exposicao_completa.label'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.gerente_rejeitou_pedido.label'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.expositor_organizado.label'>
                          trans
                        </Trans>
                      </th>
                      <th className='align-middle' scope='col'>
                        <Trans i18nKey='visitas.visitacaoPage.list.table.acao'>
                          trans
                        </Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderVisitas()}</tbody>
                </table>
              </div>
            ) : (
              <p>
                <Trans i18nKey='visitas.visitacaoPage.list.notFound'>
                  trans
                </Trans>
              </p>
            )}
          </div>
        </div>

        {/* Modal */}
        <Modal
          id='modalDeletarVisita'
          title={translate(
            'visitas.visitacaoPage.list.table.modalRemover.title'
          )}
          text={translate('visitas.visitacaoPage.list.table.modalRemover.text')}
          handleClick={() => this.removerVisita(this.state.visita)}
          size='sm'
          btnColor='danger'
          btnText={translate(
            'visitas.visitacaoPage.list.table.modalRemover.buttonRemover'
          )}
          btnCancelarText={translate(
            'visitas.visitacaoPage.list.table.modalRemover.buttonCancelar'
          )}
        />
      </React.Fragment>
    );
  }
}

VisitaList.propTypes = {
  visitacao: PropTypes.object.isRequired,
  visitas: PropTypes.array.isRequired,
  removeVisita: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  editarVisita: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  loadPontoDeVendaNome: PropTypes.bool,
};

VisitaList.defaultProps = {
  removeVisita: false,
  editarVisita: false,
  loadPontoDeVendaNome: true,
};

export default withTranslation()(VisitaList);

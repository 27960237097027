import axios from 'axios'
import consts from '../../consts'
import { toastr } from 'react-redux-toastr'
import { unsetLoading } from '../loadingSpinner/loadingSpinnerActions'
import { openDatabaseIndexedDB } from '../../config/idbConfig'
import firebase from '../../config/fbConfig'

// i18n
import i18n from '../../_i18n/i18n'

const BASE_URL = consts.API_URL

const nop = () => {
  return {
    type: 'NOP'
  }
}

export const setRepresentante = representante => {
  return dispatch => {
    return dispatch({
      type: 'SET_REPRESENTANTE',
      payload: representante
    })
  }
}

export const fetchRepresentantes = () => {
  return dispatch => {
    axios.get(`${BASE_URL}/representantes`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.fetchAll.error.title"),
            i18n.t("representantes.toastr.fetchAll.error.message")
          )
          return dispatch(nop())
        }

        return dispatch({
          type: 'FETCH_REPRESENTANTES',
          payload: res.data.data
        })
      })
      .catch(err => {
        toastr.error("Oops!", "Tivemos um problema ao buscar os representantes")
        throw err
      })
  }
}

export const fetchRepresentanteById = id => {
  return dispatch => {
    if (!id) {
      toastr.error(
        i18n.t("representantes.toastr.fetchById.error.title"),
        i18n.t("representantes.toastr.fetchById.error.message")
      )
      return dispatch(nop());
    }

    axios.get(`${BASE_URL}/representantes/${id}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.fetchById.error.title"),
            i18n.t("representantes.toastr.fetchById.error.message")
          )
          return dispatch(nop());
        }

        return dispatch(setRepresentante(res.data.data[0]));
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.fetchById.error.title"),
          i18n.t("representantes.toastr.fetchById.error.message")
        )
        throw err;
      })
  }
}

export const fetchRepresentanteByUserId = userId => {
  return dispatch => {
    axios.get(`${BASE_URL}/representantes/user/${userId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.fetchByUserId.error.title"),
            i18n.t("representantes.toastr.fetchByUserId.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(setRepresentante(res.data.data[0]))
      })
      .catch(async err => {
        if (err.message === 'Network Error') {
          // if user has no conection, then find the representante by user id on the indexedDB
          const db = await openDatabaseIndexedDB('franchini-offline');

          // find all representantes
          const idbRepresentantes = await db.getAll('representante');

          // get representante by user id
          const representante = idbRepresentantes.filter(r => r.data.user_id === userId)[0];

          return dispatch(setRepresentante(representante.data));
        }

        toastr.error(
          i18n.t("representantes.toastr.fetchByUserId.error.title"),
          i18n.t("representantes.toastr.fetchByUserId.error.message")
        )
        throw err
      })
  }
}

// will fetch all the representantes by given ponto de venda
// *used for more than one representante by ponto de venda
export const fetchRepresentantesByPontoDeVenda = pontoDeVendaId => {
  return dispatch => {
    axios.get(`${BASE_URL}/representantes/pontosDeVenda/${pontoDeVendaId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.fetchByPontoDeVenda.error.title"),
            i18n.t("representantes.toastr.fetchByPontoDeVenda.error.message")
          )
          return dispatch(nop())
        }

        return dispatch({
          type: 'FETCH_REPRESENTANTES',
          payload: res.data.data
        })
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.fetchByPontoDeVenda.error.title"),
          i18n.t("representantes.toastr.fetchByPontoDeVenda.error.message")
        )
        throw err
      })
  }
}

// will get the representante by given ponto de venda
// *used for only one representante by ponto de venda
export const findRepresentanteByPontoDeVenda = pontoDeVenda => {
  return dispatch => {
    axios.get(`${BASE_URL}/pontosDeVenda/${pontoDeVenda.id}/representante`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.findOneByPontoDeVenda.error.title"),
            i18n.t("representantes.toastr.findOneByPontoDeVenda.error.message")
          )
          return dispatch(nop())
        }

        return dispatch(setRepresentante(res.data.data[0]))
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.findOneByPontoDeVenda.error.title"),
          i18n.t("representantes.toastr.findOneByPontoDeVenda.error.message")
        )
        throw err
      })
  }
}

export const saveImage = (blob, blobName) => {
  return dispatch => {
    // create a storage ref
    const storageRef = firebase.storage().ref(blobName)

    // upload file
    const task = storageRef.put(blob)

    // upload progress bar
    task.on('state_changed',
      function progress(snapshot) {

      },

      function error(err) {

      },

      function complete() {
        return dispatch(fetchRepresentantes())
      }
    );
  }
}

export const saveRepresentante = (user, representante) => {
  return dispatch => {
    if (!user) {
      toastr.error(
        i18n.t("representantes.toastr.save.error.title"),
        i18n.t("representantes.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    if (!representante) {
      toastr.error(
        i18n.t("representantes.toastr.save.error.title"),
        i18n.t("representantes.toastr.save.error.message")
      )
      return dispatch(nop())
    }

    return axios.post(`${BASE_URL}/representantes`, { user, representante })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.save.error.title"),
            i18n.t("representantes.toastr.save.error.message")
          )
          return dispatch([nop(), unsetLoading()])
        }

        toastr.success(
          i18n.t("representantes.toastr.save.success.title"),
          i18n.t("representantes.toastr.save.success.message")
        )
        return dispatch([fetchRepresentantes(), unsetLoading()])
      })
      .catch(err => {
        if (err.response.status !== 200) {
          toastr.error('Oops!', err.response.data.message)
          return dispatch(unsetLoading())
        }

        unsetLoading()
        toastr.error(
          i18n.t("representantes.toastr.save.error.title"),
          i18n.t("representantes.toastr.save.error.message")
        )
        throw err
      })
  }
}

// remove the link between representante and ponto de venda by representante id and ponto de venda id
export const unbindRepresentanteFromPontoDeVendaByRepresentanteAndPontoDeVenda = (representanteId, pontoDeVendaId) => {
  return dispatch => {
    if (!representanteId) {
      toastr.error(
        i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
        i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
      )
      return dispatch(nop());
    }

    if (!pontoDeVendaId) {
      toastr.error(
        i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
        i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
      )
      return dispatch(nop());
    }

    axios.delete(`${BASE_URL}/pontosDeVenda/${pontoDeVendaId}/representantes/${representanteId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
            i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
          )
        }

        return dispatch([
          fetchRepresentantesByPontoDeVenda(pontoDeVendaId),
          setRepresentante(null),
          unsetLoading()
        ]);
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
          i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
        )
        throw err;
      })
  }
}

export const unbindRepresentanteFromPontoDeVenda = pontoDeVendaRepresentanteId => {
  return dispatch => {
    if (!pontoDeVendaRepresentanteId) {
      toastr.error(
        i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
        i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
      )
      return dispatch(nop());
    }

    return axios.delete(`${BASE_URL}/pontosDeVenda/representantes/${pontoDeVendaRepresentanteId}`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
            i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
          )
        }

        dispatch(nop());
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.unlinkPontoDeVenda.error.title"),
          i18n.t("representantes.toastr.unlinkPontoDeVenda.error.message")
        )
        throw err;
      })
  }
}

export const attendPontoDeVenda = pontoDeVendaRepresentanteId => {
  return dispatch => {
    if (!pontoDeVendaRepresentanteId) {
      toastr.error(
        i18n.t("representantes.toastr.attendPontoDeVenda.error.title"),
        i18n.t("representantes.toastr.attendPontoDeVenda.error.message")
      )
      return dispatch(nop());
    }

    return axios.post(`${BASE_URL}/pontosDeVenda/representantes/${pontoDeVendaRepresentanteId}/attend`)
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.attendPontoDeVenda.error.title"),
            i18n.t("representantes.toastr.attendPontoDeVenda.error.message")
          )
        }

        dispatch(nop());
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.attendPontoDeVenda.error.title"),
          i18n.t("representantes.toastr.attendPontoDeVenda.error.message")
        )
        throw err;
      })
  }
}

export const unattendPontoDeVenda = (representante, pontoDeVenda, motivo = '') => {
  return dispatch => {
    if (!representante) {
      toastr.error(
        i18n.t("representantes.toastr.unattendPontoDeVenda.error.title"),
        i18n.t("representantes.toastr.unattendPontoDeVenda.error.message")
      )
      return dispatch(nop())
    }

    if (!pontoDeVenda) {
      toastr.error(
        i18n.t("representantes.toastr.unattendPontoDeVenda.error.title"),
        i18n.t("representantes.toastr.unattendPontoDeVenda.error.message")
      )
      return dispatch(nop())
    }

    return axios.post(`${BASE_URL}/representantes/${representante.id}/un_attend/pontosDeVenda/${pontoDeVenda.id}`, {
      motivo
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.unattendPontoDeVenda.error.title"),
            i18n.t("representantes.toastr.unattendPontoDeVenda.error.message")
          )
          dispatch(nop());
        }

        toastr.info(
          i18n.t("representantes.toastr.unattendPontoDeVenda.info.title"),
          i18n.t("representantes.toastr.unattendPontoDeVenda.info.message")
        )
        dispatch(nop());
      })
      .catch(err => {
        toastr.error(
          i18n.t("representantes.toastr.unattendPontoDeVenda.error.title"),
          i18n.t("representantes.toastr.unattendPontoDeVenda.error.message")
        )
        throw err;
      })
  }
}

export const updateRepresentante = (user, representante) => {
  return (dispatch, getState) => {
    if (!user) {
      toastr.error(
        i18n.t("representantes.toastr.update.error.title"),
        i18n.t("representantes.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    if (!representante) {
      toastr.error(
        i18n.t("representantes.toastr.update.error.title"),
        i18n.t("representantes.toastr.update.error.message")
      )
      return dispatch(nop())
    }

    axios.put(`${BASE_URL}/representantes`, {
      user,
      representante
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.update.error.title"),
            i18n.t("representantes.toastr.update.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("representantes.toastr.update.success.title"),
          i18n.t("representantes.toastr.update.success.message")
        )

        // set the new representante on the list to update the state
        const updatedRepresentante = {
          id: representante.id,
          user_id: user.id,
          nome: user.nome,
          telefone: representante.telefone,
          email: user.email,
          senha: user.senha,
          status: user.status,
          tipo: user.tipo
        }

        const { representantes } = getState().representante

        const updatedRepresentantes = [...representantes.filter(r => {
          return r.id !== representante.id
        }), updatedRepresentante]

        return dispatch({
          type: 'FETCH_REPRESENTANTES',
          payload: updatedRepresentantes
        })
      })
      .catch(err => {
        throw err
      })
  }
}

export const removeRepresentante = representante => {
  return dispatch => {
    const representanteId = representante.id
    const userId = representante.user_id

    if (!representanteId) {
      toastr.error(
        i18n.t("representantes.toastr.remove.error.title"),
        i18n.t("representantes.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    if (!userId) {
      toastr.error(
        i18n.t("representantes.toastr.remove.error.title"),
        i18n.t("representantes.toastr.remove.error.message")
      )
      return dispatch(nop())
    }

    const reqBody = {
      representante: {
        id: representanteId
      },
      user: {
        id: userId
      }
    }

    return axios.delete(`${BASE_URL}/representantes`, {
      headers: {},
      data: reqBody
    })
      .then(res => {
        if (res.data.error) {
          toastr.error(
            i18n.t("representantes.toastr.remove.error.title"),
            i18n.t("representantes.toastr.remove.error.message")
          )
          return dispatch(nop())
        }

        toastr.success(
          i18n.t("representantes.toastr.remove.success.title"),
          i18n.t("representantes.toastr.remove.success.message")
        )
        return dispatch(fetchRepresentantes())
      })
      .catch(err => {
        throw err
      })
  }
}

import axios from 'axios'
import { toastr } from "react-redux-toastr"
import consts from '../../consts'

const BASE_URL = consts.API_URL;

const nop = () => {
  return {
    type: 'NOP'
  }
}

const setProdutos = produtos => {
  return {
    type: 'NEW_EXPOSITOR_SET_PRODUTOS',
    payload: produtos
  }
}

const setFornecedores = fornecedores => {
  return {
    type: 'NEW_EXPOSITOR_SET_FORNECEDORES',
    payload: fornecedores
  }
}

export const clearAll = () => {
  return {
    type: 'NEW_EXPOSITOR_CLEAR_ALL'
  }
}

export const fetchFornecedores = () => {
  return async dispatch => {
    try {
      const response = await axios.get(`${BASE_URL}/fornecedores`);

      return dispatch(setFornecedores(response.data.data));
    } catch (e) {
      toastr.error("Falha ao buscar os fornecedores");
      return dispatch(nop());
    }
  }
}

export const fetchProdutosByFornecedor = fornecedorId => {
  return async dispatch => {
    try {
      const response = await axios.get(`${BASE_URL}/fornecedores/${fornecedorId}/produtos`);

      return dispatch(setProdutos(response.data.data));
    } catch (e) {
      toastr.error("Falha ao buscar os produtos");
      return dispatch(nop());
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'

function Badge({ children, color }) {
  return <span className={`badge badge-${color}`}>
    {children}
  </span>
}

Badge.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any.isRequired
}

Badge.defaultProps = {
  color: 'light'
}

export default Badge;

import React from 'react'
import { useGeolocation } from '../geolocation/hooks/useGeolocation'
import { useTranslation } from 'react-i18next'
import GoogleMap from '../geolocation/googleMap'

export default function UserGeoposition() {
  const center = useGeolocation();

  const { t: translate } = useTranslation();

  return (
    <div className="p-3">
      <p>{translate("settings.geoposition.text")}</p>

      <GoogleMap center={center}
        readOnly={true} />
    </div>
  )
}

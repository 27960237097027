import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function DropdownItem({ to, title }) {
  return <Link className="dropdown-item" to={to}>{title}</Link>
}

DropdownItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default DropdownItem;

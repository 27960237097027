export default {
  list: {
    title: 'Espositori',
    notFound: 'Nessun espositore trovato',

    table: {
      nome: 'Nome',
      fornecedor: 'Fornitore',
      acoes: 'Azioni'
    },
    modal: {
      title: 'Rimuovi espositore',
      text: "Sei sicuro di voler rimuovere l'espositore?",
      buttonRemover: 'Rimuovere',
      buttonCancelar: 'Annulla',
    }
  },

  form: {
    title: 'Nuovo espositore',
    nome: {
      label: 'Nome',
      placeholder: "Nome dell'espositore"
    },
    fornecedor: {
      label: 'Fornitore'
    },
    produtos: {
      notFound: 'Nessun prodotto trovato',
      card: {
        fornecedor: 'Fornitore: ',
        unidade: {
          label: 'Unità: '
        }
      }
    },
    addItem: 'Aggiungi un oggetto',
    qtdeItems: 'Qtà. di parti',
    buttonNext: 'Il prossimo'
  },

  estande: {
    title: {
      preview: 'Anteprima',
      addProduto: 'Seleziona un prodotto',
      newProduto: 'Nuovo prodotto'
    },
    error: 'Qualcosa è andato storto, prova ad aggiornare la pagina',
    buttonFinalizar: 'Finire',
    buttonVoltar: 'Ritorno',
    buttonPreview: 'Anteprima',
    linha: 'Linea'
  }
}
const INITIAL_STATE = {
  fornecedor: null,
  fornecedores: []
}

const fornecedorReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'FETCH_FORNECEDORES':
      return {
        ...state,
        fornecedores: action.payload
      }
    case 'SET_FORNECEDOR':
      return {
        ...state,
        fornecedor: action.payload
      }
    case 'CLEAR_FORNECEDORES':
      return {
        ...state,
        fornecedor: null,
        fornecedores: [],
      }
    default: 
      return state
  }
}

export default fornecedorReducer
const INITIAL_STATE = {
  visitacao: null,
  visitacoes: [],
  visitas: [],
  isEdit: false,
  returnToSearch: false,
  search: {
    ref: '',
    from: null,
    to: null,
    status: null
  },
  pagination: {
    totalItems: 0
  }
}

const visitacaoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_VISITACOES':
      return {
        ...state,
        visitacoes: action.payload
      }
    case 'SET_VISITACAO':
      return {
        ...state,
        visitacao: action.payload
      }
    case 'VISITACAO_SET_EDIT':
      return {
        ...state,
        isEdit: action.payload
      }
    case 'VISITACAO_SET_RETURN_TO_SEARCH':
      return {
        ...state,
        returnToSearch: action.payload
      }
    case 'VISITACAO_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'VISITACAO_SET_FROM':
      return {
        ...state,
        search: {
          ...state.search,
          from: action.payload
        }
      }
    case 'VISITACAO_SET_TO':
      return {
        ...state,
        search: {
          ...state.search,
          to: action.payload
        }
      }
    case 'VISITACAO_SET_STATUS':
      return {
        ...state,
        search: {
          ...state.search,
          status: action.payload
        }
      }
    case 'VISITACAO_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'VISITACAO_SET_VISITAS':
      return {
        ...state,
        visitas: action.payload
      }
    case 'VISITACAO_CLEAR_VISITAS':
      return {
        ...state,
        visitas: []
      }
    case 'CLEAR_VISITACOES':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

export default visitacaoReducer

import React, { Component } from 'react';
import Media from 'react-media';
import Icon from '../template/icon';
import Dropdown from '../template/dropdown';
import DropdownItem from '../template/dropdownItem';
import consts from '../../consts';
import './navbar.css';
import logoImg from '../../assets/logo.png';

// i18n
import { withTranslation, Trans } from 'react-i18next';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../auth/authActions';
import { setUser } from '../user/userActions';
import { getCountUsersNotValidated } from '../navbar/navbarActions';
import { fetchCountPontosDeVendaUnattended } from '../navbar/navbarActions';
import { fetchCountFornecedoresUnattended } from '../navbar/navbarActions';

import { Link } from 'react-router-dom';

class Navbar extends Component {
  componentDidMount() {
    this.handleLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.user !== this.props.auth.user) {
      this.handleLogin();
    }
  }

  handleLogin() {
    const { GE } = consts.userType;
    if (!this.props.auth.user) {
      return;
    }

    const userRole = this.props.auth.user.tipo;
    if (userRole === GE) {
      this.handleGestorLogin();
    }
  }

  handleGestorLogin = () => {
    this.props.getCountUsersNotValidated();
    this.props.fetchCountPontosDeVendaUnattended();
    this.props.fetchCountFornecedoresUnattended();
  };

  logout = () => {
    this.props.logout();
  };

  setUser = () => {
    const user = this.props.auth.user;
    this.props.setUser(user);
  };

  renderNavItemsGestor = () => {
    let dropdownProps = {};
    const countNotifications = this.props.notifications;

    if (countNotifications > 0) {
      dropdownProps = {
        ...dropdownProps,
        countNotifications,
      };
    }

    // i18n
    const { t } = this.props;

    return (
      <>
        <Link to='/clientes' className='nav-item nav-link'>
          <Trans i18nKey='navbar.clientes'>trans</Trans>
        </Link>
        <Link to='/gestores' className='nav-item nav-link'>
          <Trans i18nKey='navbar.gestores'>trans</Trans>
        </Link>
        <Link to='/representantes' className='nav-item nav-link'>
          <Trans i18nKey='navbar.representantes'>trans</Trans>
        </Link>
        <Link to='/fornecedores' className='nav-item nav-link'>
          <Trans i18nKey='navbar.fornecedores'>trans</Trans>
        </Link>
        <Link to='/produtos' className='nav-item nav-link'>
          <Trans i18nKey='navbar.produtos'>trans</Trans>
        </Link>
        <Link to='/pontosDeVenda' className='nav-item nav-link'>
          <Trans i18nKey='navbar.pontosDeVenda'>trans</Trans>
        </Link>
        <Dropdown title={t('navbar.validacao.validacao')} {...dropdownProps}>
          <DropdownItem
            to='/gestores/validacao/pontosDeVenda'
            title={t('navbar.validacao.exclusaoPontosDeVenda')}
          />
          <DropdownItem
            to='/gestores/validacao/cadastros'
            title={t('navbar.validacao.novosCadastros')}
          />
          <DropdownItem
            to='/gestores/validacao/fornecedores'
            title={t('navbar.validacao.exclusaoFornecedores')}
          />
        </Dropdown>
        <Link to='/visitas' className='nav-item nav-link'>
          <Trans i18nKey='navbar.visitas'>trans</Trans>
        </Link>
        <Link to='/pedidosVenda' className='nav-item nav-link'>
          <Trans i18nKey='navbar.pedidosVenda'>trans</Trans>
        </Link>
        <Link to='/expositores' className='nav-item nav-link'>
          <Trans i18nKey='navbar.expositores'>trans</Trans>
        </Link>
      </>
    );
  };

  renderNavItemsRepresentante = () => {
    return (
      <>
        <Link to='/visitas' className='nav-item nav-link'>
          <Trans i18nKey='navbar.visitas'>trans</Trans>
        </Link>
        <Link to='/pedidosVenda' className='nav-item nav-link'>
          <Trans i18nKey='navbar.pedidosVenda'>trans</Trans>
        </Link>
        <Link to='/construcao' className='nav-item nav-link'>
          <Trans i18nKey='navbar.reso'>trans</Trans>
        </Link>
        <Link to='/construcao' className='nav-item nav-link'>
          <Trans i18nKey='navbar.estatistiche'>trans</Trans>
        </Link>
        <Link to='/construcao' className='nav-item nav-link'>
          <Trans i18nKey='navbar.planogrammi'>trans</Trans>
        </Link>
      </>
    );
  };

  renderNavItemsFornecedor = () => {
    return (
      <>
        <Link to='/visitas' className='nav-item nav-link'>
          Visitas
        </Link>
      </>
    );
  };

  renderNavItemsCliente = () => {
    return (
      <>
        <Link to='/visitas' className='nav-item nav-link'>
          Visitas
        </Link>
      </>
    );
  };

  getNavItems = () => {
    if (!this.props.auth.user) {
      return null;
    }

    const { GE, RP, FN, CL } = consts.userType;

    if (this.props.auth.user.tipo === GE) {
      return this.renderNavItemsGestor();
    }

    if (this.props.auth.user.tipo === RP) {
      return this.renderNavItemsRepresentante();
    }

    if (this.props.auth.user.tipo === FN) {
      return this.renderNavItemsFornecedor();
    }
    if (this.props.auth.user.tipo === CL) {
      return this.renderNavItemsCliente();
    }
  };

  render() {
    const navItems = this.getNavItems();

    return (
      <nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
        <Link className='navbar-brand' to='/'>
          <img src={logoImg} alt='Logo' style={{ width: 35 }} />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <div className='navbar-nav'>{navItems}</div>
        </div>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarNavAltMarkup'
        >
          <div className='navbar-nav flex-row justify-content-between'>
            {this.props.auth.isAuthenticated ? (
              <>
                <div className='dropdown mr-1 d-flex align-items-center'>
                  <button
                    style={{ height: 30 }}
                    type='button'
                    className='btn btn-light btn-sm dropdown-toggle mr-3'
                    id='dropdownMenuOffset'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-offset='10,20'
                  >
                    {this.props.auth.user.nome}
                  </button>
                  <div
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuOffset'
                  >
                    <Link
                      className='dropdown-item'
                      to='/password/new'
                      onClick={this.setUser}
                    >
                      <Trans i18nKey='navbar.alterarSenha'>trans</Trans>
                    </Link>
                  </div>
                </div>
                <Media query={{ maxWidth: 599 }}>
                  {(matches) =>
                    matches ? (
                      <div className='d-flex'>
                        <Link
                          to='/config'
                          className='nav-item nav-link d-flex mr-2'
                        >
                          <Icon icon='settings_applications' />
                        </Link>
                        <Link
                          to='/login'
                          onClick={this.logout}
                          className='nav-item nav-link d-flex'
                        >
                          <Icon icon='exit_to_app' />
                        </Link>
                      </div>
                    ) : (
                      <>
                        <Link to='/config' className='nav-item nav-link d-flex'>
                          <Icon icon='settings_applications' />
                        </Link>
                        <Link
                          to='/login'
                          onClick={this.logout}
                          className='nav-item nav-link d-flex'
                        >
                          <Icon icon='exit_to_app' />
                        </Link>
                      </>
                    )
                  }
                </Media>
              </>
            ) : (
              <Link className='nav-item nav-link' to='/login'>
                <Trans i18nKey='navbar.entrar'>trans</Trans>
              </Link>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    notifications: state.navbar.notification.total,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      setUser,
      fetchCountPontosDeVendaUnattended,
      fetchCountFornecedoresUnattended,
      getCountUsersNotValidated,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Navbar));

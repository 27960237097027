const INITIAL_STATE = {
  loading: true,
  message: ''
}

const loadingSpinnerReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'CHANGE_LOADING':
      return {
        ...state,
        loading: !state.loading
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
        message: '',
      }
    case 'UNSET_LOADING':
      return {
        ...state,
        loading: false,
        message: ''
      }
    case 'SET_LOADING_MESSAGE':
      return {
        ...state,
        message: action.payload,
      }
    default:
      return state
  }
}

export default loadingSpinnerReducer

import React, { useState, useEffect } from 'react';
import './pedidoVendaWizard.css';

import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { setLoading as loading, unsetLoading, setMessage } from '../loadingSpinner/loadingSpinnerActions';
import { clearPedidoVendaVisita, setPontoDeVenda, setVisita, setFornecedorPedidoVenda, saveVisita } from '../visita/visitaActions';
import { useLocation, useHistory } from 'react-router-dom';
import Expositor from '../estande/expositor';
import axios from 'axios';
import consts from '../../consts';
import Row from '../template/row';
import Grid from '../template/grid';
import Button from '../template/button';
import { savePedidoVenda, setRedirect } from '../pedidoVenda/pedidoVendaActions';
import { isEqualByProps } from '../../utils/objects';
import Skeleton from 'react-loading-skeleton';
import { openDatabaseIndexedDB, clearAll } from '../../config/idbConfig';
import ImageInput from '../template/imageInput';
import Modal from '../template/modal';
import ModalButton from '../template/modalButton';
import { getFileExtension } from '../../utils/files';
import { v4 as uuidv4 } from 'uuid';
import i18n from "../../_i18n/i18n";

import { save } from '../../config/fbConfig'
import SearchForm from '../template/searchForm';

const BASE_URL = consts.API_URL;

export default function PedidoVendaWizard() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);

  const [expositor, setExpositor] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);


  const loadingState = useSelector(state => state.loading.loading)
  const canRedirect = useSelector(state => state.pedidoVenda.redirect);
  const {pontoDeVenda, fornecedor, visita, representante, visitacao} = location.state.saveVisitObjects
  // pedidoVenda img
  const [pedidoImg, setPedidoImg] = useState(null);
  const [visitaId, setVisitaId] = useState(null);


  const [obs, setObs] = useState('');

  useEffect(() => {
    async function getExpositor() {
      try {
        const response = await axios.get(`${BASE_URL}/pontosDeVenda/${pontoDeVenda.id}/fornecedores/${fornecedor.id}/expositores`);
        
        const [expositor] = response.data.data;
        

        if (!expositor) {
          toastr.error(
            t("pedidosVenda.toastr.fetchExpositor.error.title"),
            t("pedidosVenda.toastr.fetchExpositor.error.message")
          );
          redirect(pontoDeVenda);
          return;
        }

        // store expositor in idb
        storeItemIndexedDB(expositor, 'expositor');
        // set itens expositor
        setExpositor(expositor.itens.map(item => ({
          ...item.produto,
          pos: [item.linha, item.coluna],
          quantidade: null
        })));

        setLoading(false);
      } catch (e) {
        if (e.message === 'Network Error') {
          // get expositores from idb
          const db = await openDatabaseIndexedDB('franchini-offline');

          const idbExpositor = await db.getAll('expositor');
          const expositores = idbExpositor.map(i => i.data);

          const idbPontoDeVendaFornecedor = await db.getAll('ponto_de_venda_fornecedor');
          const pontosDeVendaFornecedor = idbPontoDeVendaFornecedor.map(i => i.data);

          // get pontoDeVendaFornecedor
          const pontoDeVendaFornecedor = pontosDeVendaFornecedor.filter(pvf =>
            pvf["fornecedor_id"] === fornecedor.id
            && pvf["ponto_de_venda_id"] === pontoDeVenda.id
          )[0];

          // get expositor by ponto de venda fornecedor
          const expositor = expositores.filter(e => e.id === pontoDeVendaFornecedor["expositor_id"])[0];

          if (!expositor) {
            toastr.error(
              t("pedidosVenda.toastr.fetchExpositor.error.title"),
              t("pedidosVenda.toastr.fetchExpositor.error.message")
            );
            redirect(pontoDeVenda);
            return;
          }

          // set itens expositor
          setExpositor(expositor.itens.map(item => ({
            ...item.produto,
            pos: [item.linha, item.coluna],
            quantidade: null
          })));

          setLoading(false);
        }
      }
    }

    const isPedidoVenda = localStorage.getItem('isPedidoVenda');

    // if pedidoVenda has already started, then get data from indexedDB
    if (isPedidoVenda) {
      toastr.info(
        t("pedidosVenda.shoppingCart.wizard.toastr.isPedidoVenda.title"),
        t("pedidosVenda.shoppingCart.wizard.toastr.isPedidoVenda.message")
      );

      restoreSessionIndexedDB();
    } else {
      // else, start a new pedidoVenda
      // and store data in indexedDB

      if (!canRenderComponent()) return redirect()

      getExpositor();

      const id = uuidv4().replace(/-/g, '');
      const visitaToSave = {
        ...visita,
        id
      }
      setVisitaId(visitaToSave)
      localStorage.setItem('isPedidoVenda', 'true');
      storeItemIndexedDB(visitaToSave, 'visita');
      storeItemIndexedDB(fornecedor, 'fornecedor');
      storeItemIndexedDB(pontoDeVenda, 'ponto_de_venda');
      
    }

    dispatch(unsetLoading());

    return () => dispatch([
      clearPedidoVendaVisita(),
      setRedirect(false)
    ]);
  }, []);

  useEffect(() => {
    if (canRedirect) redirect(pontoDeVenda);
  }, [canRedirect]);

  function canRenderComponent() {
    if (!visita) return false;

    if (!fornecedor) return false;

    if (!pontoDeVenda) return false;

    if (!representante) return false;

    return true;
  }

  async function restoreSessionIndexedDB() {
    try {
      const db = await openDatabaseIndexedDB('pedidoVenda');

      const idbVisita = await db.getAll('visita');
      const visita = idbVisita[0]?.data;

      const idbExpositor = await db.getAll('expositor');
      const expositor = idbExpositor[0]?.data;

      const idbPontoDeVenda = await db.getAll('ponto_de_venda');
      const pontoDeVenda = idbPontoDeVenda[0]?.data;

      const idbFornecedor = await db.getAll('fornecedor');
      const fornecedor = idbFornecedor[0]?.data;

      // set expositor
      setExpositor(expositor.itens.map(item => ({
        ...item.produto,
        pos: [item.linha, item.coluna],
        quantidade: null
      })));

      dispatch([
        setVisita(visita),
        setFornecedorPedidoVenda(fornecedor),
        setPontoDeVenda(pontoDeVenda)
      ]);

      setLoading(false);
    } catch (e) {
      console.log(`%c Erro ao restaurar sessÃ£o do IDB: ${e}`, 'color: tomato');
    }

  }

  async function storeItemIndexedDB(item, tableName) {
    try {
      const db = await openDatabaseIndexedDB('pedidoVenda');

      await db.add(tableName, {
        id: item.id,
        timestamp: new Date().getTime(),
        data: item
      });
    } catch (e) {
      console.log(`%c Erro ao adicionar ${tableName} no IDB: ${e}`, 'color: tomato');
    }
  }

  async function clearIndexedDB() {
    try {
      localStorage.removeItem('isPedidoVenda');

      const db = await openDatabaseIndexedDB('pedidoVenda');

      await clearAll(db, 'pedidoVenda');
    } catch (e) {
      console.log(`%c Erro ao limpar IDB: ${e}`, 'color: tomato');
    }
  }

  function redirect(pontoDeVenda, check) {
    clearIndexedDB();
    // must redirect to fornecedores selection in case it belongs to an visita
    if (location.state.isRedirectToFornecedores) {
      dispatch(setMessage(t("pedidosVenda.loading.redirect")));
      dispatch(setPontoDeVenda(pontoDeVenda))
      if(check) {
        history.push({pathname: '/visitas/new', state: {step: 3, saveVisitObjects: location.state.saveVisitObjects} });
      } else{
        history.push('/visitas/new');
      }
    } else {
      history.push("/visitas");
    }
  }

  function handleCancelarPedido() {
    redirect(pontoDeVenda, true);
  }

  async function handleFinalizarPedido() {
    setButtonLoading(true);
    try{

      dispatch(loading(), setMessage(t("pedidosVenda.loading.visita")))

      // formatting produtos to api -> [{ produto, quantidade }]
      const produtos = expositor.map(item => ({ produto: item, quantidade: item.quantidade === null ? 0 : item.quantidade }));

      // save image pedido
      let blobName = null;
      
       if (pedidoImg) {
        const fileExtension = getFileExtension(pedidoImg.file.name);
        blobName = `pedidosVenda/${visita.id}/${uuidv4() + fileExtension}`;

        save(pedidoImg.file, blobName);
      }
    const savePedido = true

    dispatch([saveVisita(
      visitaId,
      representante,
      pontoDeVenda,
      fornecedor,
      visitacao,
      produtos,
      blobName,
      obs,
      savePedido
      ),
     ])
    setTimeout(() => {dispatch(setMessage(t("pedidosVenda.loading.pedido")));}, 1500)

    setTimeout(() => {dispatch(setMessage(t("pedidosVenda.loading.email")));}, 2500)
     }catch(e){
      console.log("Erro ao salvar pedido",e);
      setButtonLoading(false);
    }
  }

  function handleChangeQuantidade(item, quantidade) {
    setExpositor(expositor.map(i => {
      if (isEqualByProps(i, item, 'pos', 'id')) return { ...i, quantidade: parseInt(quantidade) };
      else return i;
    }))
  }

  function handleSelectImage(e) {
    const file = e.target.files[0];

    if (!file) return;

    const imgURL = window.URL.createObjectURL(file);

    setPedidoImg({ file, url: imgURL });
  }

  // Filtro do expositor -
  const [filtro, setFiltro] = useState(null)
  const [search, setSearch] = useState('')

  function handleSearch(e) {
    e.preventDefault()
    const data = expositor.filter(element => {
      return (element.ean.indexOf(search) > -1)
    })

    setFiltro(data)
    setSearch('')
  }

  useEffect(() => {
    if (!filtro) return
    setFiltro(filtro.map(elFiltro => {
      const data = expositor.find(elExporsitor => {
        return elExporsitor.id === elFiltro.id
      })
      if (data) return data
      return elFiltro
    }))
  }, [expositor])

  function clearSearch() {
    setFiltro()
  }

  useEffect(() => {
    if(buttonLoading) dispatch(loading(), setMessage(t("pedidosVenda.loading.redirect")))
  }, [loadingState])

  return (
    <div>
      <div className="display-img-container">
        <p className="select-image-text">
          <Trans i18nKey="pedidosVenda.expositor.selectImage">trans</Trans>
        </p>
        <div className="h-100 d-flex justify-content-space-between">
          {pedidoImg && (
            <ModalButton className="mr-2" size="sm"
              color="secondary"
              target="#modalViewPedidoImg">
              <Trans i18nKey="pedidosVenda.expositor.viewImage">trans</Trans>
            </ModalButton>
          )}

          <div className="image-input-box">
            <ImageInput id="pedido-image" onLoadImage={handleSelectImage} />
          </div>
        </div>
      </div>

      {isLoading ? <Skeleton count={4} height={100} /> : (
        <>
          <SearchForm
            placeholder={t("pedidosVenda.expositor.search")}
            handleSearch={handleSearch}
            clearSearch={clearSearch}
            onChange={({ target }) => setSearch(target.value)}
          />
          <Row>
            <Grid>
              <Expositor data={(filtro) ? filtro : expositor} onChangeQuantidade={handleChangeQuantidade} />
            </Grid>
          </Row>

          <Row>
            <Grid>
              <div className="form-group">
                <label htmlFor="obs">
                  <Trans i18nKey="pedidosVenda.expositor.obs">trans</Trans>
                </label>
                <textarea className="form-control" name="obs" id="obs"
                  value={obs}
                  onChange={e => setObs(e.target.value)}></textarea>
              </div>
            </Grid>
          </Row>
        </>
      )}

      <Row>
        <Grid cols="12 6">
          <Button className="mb-3" color="secondary" size="block"
            disabled={buttonLoading}
            handleclick={handleCancelarPedido}>
            <Trans i18nKey="pedidosVenda.expositor.buttonVoltar">trans</Trans>
          </Button>
        </Grid>
        <Grid cols="12 6">
          <Button  color="primary" size="block"
            handleclick={handleFinalizarPedido} disabled={buttonLoading}>
            <Trans i18nKey="pedidosVenda.expositor.buttonFinalizar">trans</Trans>
          </Button>
        </Grid>
      </Row>

      {/* Modal visualize pedido img */}
      <Modal id="modalViewPedidoImg"
        size="sm"
        title={i18n.t("pedidosVenda.expositor.selectedImage")}
        hideFooter>
        <img className="img-fluid img-thumbnail" src={pedidoImg?.url} alt="Imagem do pedido de venda" />
      </Modal>
    </div>
  );
}
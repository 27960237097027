import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changePassword } from './userActions'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'

// i18n
import { withTranslation, Trans } from 'react-i18next'

import { toastr } from 'react-redux-toastr'

class ChangePasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    if(this.isPasswordInvalid(e.target)) {
      return
    }

    const userId = this.props.user.user.id
    const email = this.props.user.user.email
    const password = e.target.password.value
    const confirmPassword = e.target.confirmPassword.value
    const oldPassword = e.target.oldPassword.value

    this.props.changePassword(userId, email, password, confirmPassword, oldPassword)

    this.props.history.push("/home")
  }
  
  isPasswordInvalid = form => {
    const { t: translate } = this.props;

    if(form.password.value.length < 6) {
      toastr.warning(
        translate("login.changePassword.form.validation.minLength.title"),
        translate("login.changePassword.form.validation.minLength.message")
      )
      return true
    }
    
    if(form.password.value !== form.confirmPassword.value) {
      toastr.warning(
        translate("login.changePassword.form.validation.notEqualTo.title"),
        translate("login.changePassword.form.validation.notEqualTo.message")
      )
      return true
    }
    
    return false
  }



  render() {
    if(!this.props.user.user) return <Redirect to="/home" />

    const { t: translate } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb title={translate("login.changePassword.title")} />

        <div className="form-container mt-3">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="oldPassword">
                <Trans i18nKey="login.changePassword.form.senhaAtual.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="oldPassword" id="oldPassword" 
                placeholder={translate("login.changePassword.form.senhaAtual.placeholder")} required />
            </div>

            <div className="form-group">
              <label htmlFor="password">
                <Trans i18nKey="login.changePassword.form.novaSenha.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="password" id="password" 
                placeholder={translate("login.changePassword.form.novaSenha.placeholder")} required />
              <small className="form-text text-muted">
                <Trans i18nKey="login.changePassword.form.novaSenha.small">trans</Trans>
              </small>
            </div>
    
            <div className="form-group">
              <label htmlFor="confirmPassword">
                <Trans i18nKey="login.changePassword.form.confirmarSenha.label">trans</Trans>
              </label>
              <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" 
                placeholder={translate("login.changePassword.form.confirmarSenha.placeholder")} required />
            </div>
    
            <hr className="divider"/>
    
            <button type="submit" className="btn btn-primary btn-block">
              <Trans i18nKey="login.changePassword.form.buttonSalvar">trans</Trans>
            </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ changePassword }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangePasswordForm))

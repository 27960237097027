import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { clearFornecedores, fetchFornecedores } from '../fornecedor/fornecedorActions';

function ProdutoForm({ onSubmit: handleSubmit }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const fornecedores = useSelector(state => state.fornecedor.fornecedores);

  useEffect(() => {
    dispatch(fetchFornecedores());

    return () => dispatch(clearFornecedores());
  }, []);

  function handleSelectImageClick(e) {
    e.preventDefault();

    document.getElementById('imagem').click();
  }

  return (
    <div className="form-container mt-4">
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="form-group">
          <label htmlFor="artigo">
            <Trans i18nKey="produtos.form.codigoArtigo.label">trans</Trans>
          </label>
          <input type="text" className="form-control" name="artigo" id="artigo"
            placeholder={t("produtos.form.codigoArtigo.placeholder")} required />
        </div>

        <div className="form-group">
          <label htmlFor="ean">
            <Trans i18nKey="produtos.form.codigoEan">trans</Trans>
          </label>
          <input type="text" className="form-control" name="ean" id="ean"
            required />
        </div>

        <div className="form-group">
          <label htmlFor="descricao_ean">
            <Trans i18nKey="produtos.form.descricaoEan">trans</Trans>
          </label>
          <input type="text" className="form-control" name="descricao_ean" id="descricao_ean"
            required />
        </div>

        <div className="form-group">
          <label htmlFor="descricao">
            <Trans i18nKey="produtos.form.descricao.label">trans</Trans>
          </label>
          <input type="text" className="form-control" name="descricao" id="descricao"
            placeholder={t("produtos.form.descricao.placeholder")} required />
        </div>

        <div className="form-group">
          <label htmlFor="fornecedor">
            <Trans i18nKey="produtos.form.fornecedor">trans</Trans>
          </label>
          <select className="form-control" name="fornecedor" id="fornecedor" required>
            {
              fornecedores.map(fornecedor => {
                return (
                  <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.nome}</option>
                )
              })
            }
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="quantidade_kit">
            <Trans i18nKey="produtos.form.quantidadeKit.label">trans</Trans>
          </label>
          <input type="text" className="form-control" name="quantidade_kit" id="quantidade_kit"
            placeholder={t("produtos.form.quantidadeKit.placeholder")} required />
        </div>

        <div className="form-group">
          <label htmlFor="unidade">
            <Trans i18nKey="produtos.form.unidade.label">trans</Trans>
          </label>
          <select name="unidade" id="unidade" className="form-control" required>
            <option value="PC">
              {t("produtos.form.unidade.pc")}
            </option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="imagem">
            <Trans i18nKey="produtos.form.imagem">trans</Trans>
          </label>
          <input type="file" name="imagem" id="imagem" style={{ display: 'none' }} accept="image/*" />
          <button type="button" className="btn btn-sm btn-secondary"
            onClick={handleSelectImageClick} style={{ display: 'flex', alignItems: 'center' }}>
            <Trans i18nKey="produtos.form.buttonEscolherImagem">trans</Trans>
            {' '}
            <i className="material-icons ml-1">add_photo_alternate</i>
          </button>
        </div>

        <button type="submit" className="btn btn-primary btn-block mb-3">
          <Trans i18nKey="produtos.form.buttonCadastrar">trans</Trans>
        </button>
      </form>
    </div>
  )
}

ProdutoForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default ProdutoForm;

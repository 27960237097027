import React, { Component } from 'react'

import { Redirect } from 'react-router-dom'
import Breadcrumb from '../template/breadcrumb'
import ImageInput from '../template/imageInput'
import defaultImg from '../common/imgs/default-img.png'
import ImageGallery from 'react-image-gallery'
import { storage, save as uploadFile } from '../../config/fbConfig'

import uuid from 'uuid'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updatePontoDeVenda } from './pontoDeVendaActions'
import { findRepresentanteByPontoDeVenda } from '../representante/representanteActions'
import { fetchAllClientes } from '../cliente/clienteActions'
import { setLoading } from '../loadingSpinner/loadingSpinnerActions'
import { searchLocation } from '../geolocation/geolocationActions'

import { withTranslation, Trans } from 'react-i18next'

import Logs from './pontoDeVendaLog'
import ContatoList from './contato/contatoList'
import FornecedorList from './fornecedor/fornecedorList'
import GoogleMap from '../geolocation/googleMap'

class PontoDeVendaEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReadonly: true,
      center: {
        lat: 0.00,
        lng: 0.00
      },
      images: [{ original: defaultImg }]
    }
  }

  componentDidMount() {
    if (!this.props.pontoDeVenda) {
      return <Redirect to="/pontosDeVenda" />
    }

    // fetch all clientes
    this.props.fetchAllClientes()

    // find the representante by ponto de venda
    this.props.findRepresentanteByPontoDeVenda(this.props.pontoDeVenda)

    // get the the ponto de venda location
    this.setPontoDeVendaLocation()

    // load pontoDeVenda info to the fields
    this.loadInfo()
  }

  componentDidUpdate(prevProps) {
    if (this.props.geolocation.features !== prevProps.geolocation.features) {
      const features = this.props.geolocation.features || []
      const mostRelevantFeature = features[0]

      this.setState({
        center: {
          lat: mostRelevantFeature.geometry.location.lat,
          lng: mostRelevantFeature.geometry.location.lng
        }
      })
    }
  }

  changeReadonly = () => {
    this.setState({
      ...this.state,
      isReadonly: !this.state.isReadonly
    })
  }

  loadInfo = () => {
    const {
      cliente_id,
      codigo_identificacao,
      nome,
      cidade,
      estado,
      regiao,
      tipo,
      frequencia_visita,
      endereco,
      obs,
      estabelecimento,
      imagem
    } = this.props.pontoDeVenda

    document.getElementById('cliente').value = cliente_id
    document.getElementById('codigo_identificacao').value = codigo_identificacao
    document.getElementById('nome').value = nome
    document.getElementById('cidade').value = cidade
    document.getElementById('estado').value = estado
    document.getElementById('regiao').value = regiao
    document.getElementById('tipo').value = tipo
    document.getElementById('frequenciaVisita').value = frequencia_visita
    document.getElementById('endereco').value = endereco
    document.getElementById('obs').value = obs
    document.getElementById('estabelecimento').value = estabelecimento

    if (imagem) {
      const imgRef = storage.ref(imagem)
      imgRef.getDownloadURL().then(url => this.setState({ images: [{ original: url }] }))
    }
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      const location = e.target.value

      this.searchLocation(location)
    }
  }

  searchLocation = location => {
    if (!location) {
      return
    }

    this.props.setLoading()
    this.props.searchLocation(location)
  }

  handleSubmit = e => {
    // does nothing on submit
    e.preventDefault()
  }

  setPontoDeVendaLocation = () => {
    const latitude = this.props.pontoDeVenda.latitude
    const longitude = this.props.pontoDeVenda.longitude

    this.setState({
      center: {
        lat: latitude,
        lng: longitude
      }
    })
  }

  updateCenter = center => {
    this.setState({
      center: {
        lat: center.lat,
        lng: center.lng
      }
    })
  }

  savePontoDeVenda = () => {
    const cliente_id = document.getElementById('cliente').value
    const codigo_identificacao = document.getElementById('codigo_identificacao').value
    const nome = document.getElementById('nome').value
    const cidade = document.getElementById('cidade').value
    const estado = document.getElementById('estado').value
    const regiao = document.getElementById('regiao').value
    const tipo = document.getElementById('tipo').value
    const frequenciaVisita = document.getElementById('frequenciaVisita').value
    const endereco = document.getElementById('endereco').value
    const obs = document.getElementById('obs').value
    const estabelecimento = document.getElementById('estabelecimento').value

    let pontoDeVenda = {
      id: this.props.pontoDeVenda.id,
      cliente_id,
      codigo_identificacao,
      nome,
      cidade,
      estado,
      regiao,
      tipo,
      frequenciaVisita,
      endereco,
      latitude: this.state.center.lat,
      longitude: this.state.center.lng,
      obs,
      estabelecimento
    }

    // save imagem
    const image = document.getElementById('image').files[0];
    if (image) {
      const blobName = this.saveImage(image, pontoDeVenda.nome);
      pontoDeVenda = {
        ...pontoDeVenda,
        imagem: blobName
      }
    }

    // update pontoDeVenda
    this.props.updatePontoDeVenda(pontoDeVenda)

    // redirect
    this.props.history.push('/pontosDeVenda')
  }

  getFileExtension(fileName) {
    return fileName.substring(fileName.lastIndexOf('.'))
  }

  saveImage = (image, pontoDeVendaNome) => {
    let blobName = null

    if (image) {
      const fileExtension = this.getFileExtension(image.name)
      blobName = `pontosDeVenda/${pontoDeVendaNome}/${uuid.v4() + fileExtension}`
      uploadFile(image, blobName);
    }

    return blobName
  }


  handleLoadImage = e => {
    const file = e.target.files[0];

    if (!file) return;

    const url = window.URL.createObjectURL(file);

    // remove the default img
    // replace the previous input image
    this.setState({
      images: [{ original: url }]
    })
  }

  onLinkExpositor = (pontoDeVenda, fornecedor) => {
    this.props.history.push(`/pontosDeVenda/${pontoDeVenda.id}/fornecedores/${fornecedor.id}/expositores`);
  }

  render() {
    if (!this.props.pontoDeVenda) {
      return <Redirect to="/pontosDeVenda" />
    }

    const { t } = this.props;

    const BindButtons = () => {
      return (
        <div>
          <button className="btn btn-sm btn-secondary mr-2" onClick={() => this.props.history.push('/pontosDeVenda/produtos')}>
            <Trans i18nKey="pontosDeVenda.edit.buttonVincularProdutos">trans</Trans>
          </button>

          {
            this.props.representante ? (
              <React.Fragment>
                <button className="btn btn-sm btn-secondary"
                  onClick={() => this.props.history.push('/pontosDeVenda/representantes')}>
                  <span className="d-flex">
                    <span className="font-weight-bold mr-1">
                      <Trans i18nKey="pontosDeVenda.edit.buttonRepresentante">trans</Trans>
                    </span> {this.props.representante.nome}
                  </span>
                </button>
              </React.Fragment>
            ) : (
                <button className="btn btn-sm btn-secondary"
                  onClick={() => this.props.history.push('/pontosDeVenda/representantes')}>
                  <Trans i18nKey="pontosDeVenda.edit.buttonNoRepresentante">trans</Trans>
                </button>
              )
          }
        </div>
      )
    }

    return (
      <React.Fragment>
        <Breadcrumb title={t("pontosDeVenda.edit.title")} linkTo="/pontosDeVenda"
          component={BindButtons} />

        <div className="row">
          <div className="col-md-6">
            <form onSubmit={this.handleSubmit} className="mt-3">
              <div className="form-group">
                <label htmlFor="cliente">
                  <Trans i18nKey="pontosDeVenda.edit.cliente">trans</Trans>
                </label>
                <select className="form-control" name="cliente" id="cliente"
                  required disabled={this.state.isReadonly} defaultValue={this.props.pontoDeVenda.cliente_id}>
                  <option value={-1} disabled>
                    {t("pontosDeVenda.edit.selecione")}
                  </option>
                  {this.props.clientes.map(cliente => (
                    <option key={cliente.id} value={cliente.id}>{cliente.nome}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="codigo_identificacao">
                  <Trans i18nKey="pontosDeVenda.edit.codigoIdentificacao">trans</Trans>
                </label>
                <input type="text" className="form-control" name="codigo_identificacao" id="codigo_identificacao" value={this.state.codigo_identificacao}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="nome">
                  <Trans i18nKey="pontosDeVenda.edit.nome">trans</Trans>
                </label>
                <input type="text" className="form-control" name="nome" id="nome" value={this.state.nome}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="endereco">
                  <Trans i18nKey="pontosDeVenda.edit.endereco">trans</Trans>
                </label>
                <input type="text" className="form-control" name="endereco" id="endereco"
                  value={this.state.endereco}
                  onKeyPress={this.handleKeyPress}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="cidade">
                  <Trans i18nKey="pontosDeVenda.edit.cidade">trans</Trans>
                </label>
                <input type="text" className="form-control" name="cidade" id="cidade" value={this.state.cidade}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="estado">
                  <Trans i18nKey="pontosDeVenda.edit.estado">trans</Trans>
                </label>
                <input type="text" className="form-control" name="estado" id="estado" value={this.state.estado}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="regiao">
                  <Trans i18nKey="pontosDeVenda.edit.regiao">trans</Trans>
                </label>
                <input type="text" className="form-control" name="regiao" id="regiao" value={this.state.regiao}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="estabelecimento">
                  <Trans i18nKey="pontosDeVenda.edit.tipoEstabelecimento">trans</Trans>
                </label>
                <input type="text" className="form-control" name="estabelecimento" id="estabelecimento" value={this.state.estabelecimento}
                  required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="tipo">
                  <Trans i18nKey="pontosDeVenda.edit.tamanho">trans</Trans>
                </label>
                <select className="form-control" name="tipo" id="tipo" value={this.state.tipo}
                  required disabled={this.state.isReadonly}>
                  <option value="P">P</option>
                  <option value="M">M</option>
                  <option value="G">G</option>
                  <option value="GG">GG</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="frequenciaVisita">
                  <Trans i18nKey="pontosDeVenda.edit.frequenciaVisita.label">trans</Trans>
                  {' '}
                  <small className="text-muted">
                    <Trans i18nKey="pontosDeVenda.edit.frequenciaVisita.small">trans</Trans>
                  </small>
                </label>
                <input type="number" className="form-control" name="frequenciaVisita" id="frequenciaVisita"
                  value={this.state.frequenciaVisita} required readOnly={this.state.isReadonly} />
              </div>

              <div className="form-group">
                <label htmlFor="obs">
                  <Trans i18nKey="pontosDeVenda.edit.obs">trans</Trans>
                </label>
                <textarea className="form-control" name="obs" id="obs" cols="30" rows="5"
                  value={this.state.obs} readOnly={this.state.isReadonly}></textarea>
              </div>

              {
                this.state.isReadonly ? (
                  <button type="button" className="btn btn-primary btn-block mb-3"
                    onClick={this.changeReadonly}>
                    <Trans i18nKey="pontosDeVenda.edit.buttonHabilitarCampos">trans</Trans>
                  </button>
                ) : (
                    <>
                      <ImageInput id="image"
                        onLoadImage={this.handleLoadImage}
                        isRequired={false} />

                      <div className="row mb-3">
                        <div className="col-6">
                          <button type="button" className="btn btn-secondary btn-block"
                            onClick={this.changeReadonly}>
                            <Trans i18nKey="pontosDeVenda.edit.buttonCancelar">trans</Trans>
                          </button>
                        </div>
                        <div className="col-6">
                          <button type="button" className="btn btn-primary btn-block"
                            onClick={this.savePontoDeVenda}>
                            <Trans i18nKey="pontosDeVenda.edit.buttonSalvar">trans</Trans>
                          </button>
                        </div>
                      </div>
                    </>
                  )
              }
            </form>
          </div>

          <div className="col-md-6">
            {
              this.state.isReadonly ? (
                <React.Fragment>
                  <ImageGallery items={this.state.images}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showThumbnails={false} />
                  <br />

                  <ContatoList pontoDeVenda={this.props.pontoDeVenda} />
                  <br />

                  <FornecedorList pontoDeVenda={this.props.pontoDeVenda} onLinkExpositor={this.onLinkExpositor} />
                  <br />

                  <Logs pontoDeVenda={this.props.pontoDeVenda} />
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <ImageGallery items={this.state.images}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showThumbnails={false} />

                    <div style={{ padding: '10px', height: '100%' }}>
                      <GoogleMap
                        center={{ lat: this.state.center.lat, lng: this.state.center.lng }}
                        updateCenter={this.updateCenter} />
                    </div>
                  </React.Fragment>
                )
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    clientes: state.cliente.clientes,
    pontoDeVenda: state.pontoDeVenda.pontoDeVenda,
    representante: state.representante.representante,
    geolocation: state.geolocation
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  findRepresentanteByPontoDeVenda,
  updatePontoDeVenda,
  fetchAllClientes,
  searchLocation,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PontoDeVendaEdit))

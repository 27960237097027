import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchVisitasByPontoDeVenda, clearVisitas } from '../visita/visitaActions'
import { fetchPontoDeVendaById } from './pontoDeVendaActions'
import { setRedirectTo } from '../router/routerActions'

import Breadcrumb from '../template/breadcrumb'
import Grid from '../template/grid'
import Row from '../template/row'
import VisitaList from '../visita/visitaList'
import Logs from './pontoDeVendaLog'

export default function PontoDeVendaPage({ match }) {
  // get id from URL
  const pontoDeVendaId = match.params.id;

  // application state
  const dispatch = useDispatch();
  const visitasState = useSelector(state => state.visita.visitas);
  const pontoDeVendaState = useSelector(state => state.pontoDeVenda.pontoDeVenda);

  // find ponto de venda and visitas by ponto de venda
  useEffect(() => {
    dispatch([
      fetchVisitasByPontoDeVenda(pontoDeVendaId),
      fetchPontoDeVendaById(pontoDeVendaId),

      // set url to go back
      setRedirectTo(`/pontosDeVenda/${pontoDeVendaId}`)
    ]);

    return () => dispatch(clearVisitas());
  }, []);

  // styles
  const titleStyle = {
    fontSize: '1.5em',
  }

  const visitacaoTitleBoxStyle = {
    padding: 10,
    background: '#fefefe',
    borderRadius: 2,
    boxShadow: '1px 1px 5px 0 rgba(0,0,0,0.3)'
  }

  return (
    <div>
      <Breadcrumb title="Voltar ao mapa de calor" linkTo="/pontosDeVenda/heat_map" />

      <Row>
        <Grid cols="12">
          <div style={visitacaoTitleBoxStyle}>
            <p style={titleStyle}>
              Código do ponto de venda: <span className="font-weight-bold">{pontoDeVendaId}</span>
            </p>
            <p style={titleStyle}>
              Lista de visitas realizadas pelo ponto de venda <span className="font-weight-bold">{pontoDeVendaState && pontoDeVendaState.nome}</span>
            </p>
          </div>
        </Grid>
      </Row>

      <Row>
        <Grid cols="12 3">
          {pontoDeVendaState && <Logs pontoDeVenda={pontoDeVendaState} />}
        </Grid>

        <Grid cols="12 9">
          <VisitaList visitas={visitasState}
            removeVisita={false}
            editarVisita={false}
            loadPontoDeVendaNome={false} />
        </Grid>
      </Row>
    </div>
  )
}

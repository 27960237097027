import firebase from 'firebase/app'
import 'firebase/storage'
import { toastr } from 'react-redux-toastr'

import { openDatabaseIndexedDB } from './idbConfig'
import i18n from '../_i18n/i18n'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBSbGarxMPwsIY6hBw2UeQaads9OlmMwI8",
  authDomain: "franchinirappresetanze.firebaseapp.com",
  databaseURL: "https://franchinirappresetanze.firebaseio.com",
  projectId: "franchinirappresetanze",
  storageBucket: "franchinirappresetanze.appspot.com",
  messagingSenderId: "773054158927",
  appId: "1:773054158927:web:11a69fc193946a73d6323a"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

function save(blob, blobName) {
  // create a storage ref
  const storageRef = firebase.storage().ref(blobName)

  // upload file
  const task = storageRef.put(blob)

  // upload progress bar
  task.on('state_changed',
    function progress(snapshot) {

    },

    async function error(err) {
      console.log("[FIREBASE] Erro ao salvar blob: ", err);

      toastr.warning(
        i18n.t("visitas.toastr.saveImage.error.title"),
        i18n.t("visitas.toastr.saveImage.error.message")
      );

      // store the file locally
      try {
        const db = await openDatabaseIndexedDB('franchini-offline');

        await db.add('imagem', {
          timestamp: new Date().getTime(),
          data: {
            blobName,
            blob
          }
        });
      } catch (e) {
        console.log(`%c Erro ao inserir imagem no indexedDB: ${e}`, 'color: tomato');
      }
    },

    function complete() {

    }
  );
}

export {
  firebase as default,
  storage,
  save
}

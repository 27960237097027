export default {
  list: {
    title: 'Gestores',
    table: {
      nome: 'Nome',
      telefone: 'Telefone',
      email: 'E-mail',
      recebe_email: 'Recebe Email',
      acao: 'Ação',
      buttonEditar: 'Editar',
      buttonRemover: 'Remover',
      nao: 'Não',
      sim: 'Sim'
    },
    modal: {
      title: 'Confirmar ação',
      text: 'Você tem certeza de que quer remover este gestor?',
      buttonRemover: 'Remover',
      buttonCancelar: 'Cancelar'
    }
  },

  form: {
    title: 'Novo gestor',
    h1: 'Cadastro de gestor',
    nome: 'Nome: ',
    email: 'E-mail: ',
    telefone: 'Telefone: ',
    maskTelefone: '+55 (99) 99999 9999',
    recebe_email: {
      label: 'Quer receber e-mails ?',
      sim: 'Sim',
      nao: 'Não'
    },
    senha: {
      label: 'Senha: ',
      small: 'A senha deve conter no mínimo 6 caracteres'
    },
    confirmarSenha: 'Confirme a senha',
    buttonProximo: 'Próximo',
    buttonCadastrar: 'Cadastrar',
    validation: {
      password: {
        minLength: {
          title: 'Algo está faltando',
          message: 'Sua senha deve ter no mínimo 6 caracteres'
        },
        notEqualTo: {
          title: 'Algo está faltando',
          message: 'As senhas devem ser iguais'
        }
      }
    }
  },

  edit: {
    title: 'Editar gestor',
    nome: 'Nome: ',
    telefone: 'Telefone: ',
    email: 'E-mail: ',
    buttonHabilitarCampos: 'Habilitar campos',
    buttonCancelar: 'Cancelar',
    buttonSalvar: 'Salvar',
    recebe_email: {
      label: 'Quer receber e-mails ?',
      sim: 'Sim',
      nao: 'Não'
    },
  },

  toastr: {
    save: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao cadastrar o gestor'
      },
      success: {
        title: 'Sucesso',
        message: 'Gestor cadastrado com sucesso'
      }
    },
    update: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao salvar o gestor'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao salvar o gestor'
      }
    },
    remove: {
      error: {
        title: 'Oops!',
        message: 'Tivemos um problema ao remover o gestor'
      },
      success: {
        title: 'Sucesso',
        message: 'Sucesso ao remover gestor'
      }
    }
  }
}